import React, { useState, useCallback, useRef } from 'react';
import { InputField } from './InputField';
import { Button } from './Button';
import { AuthCard } from './AuthCard';
import { UserPlus, LogIn, Loader2 } from 'lucide-react';
import { validateEmail, validatePassword, validateName } from 'app/utils/app-functions';
import './index.css';  // Make sure to import the CSS where Tailwind is set up
import { useAuth } from 'app/contexts/AuthContext';
import Alert from 'app/components/atoms/alert';
import backgroundImage from "app/pages/images/background-image.jpg";

export function AuthForm({ onLogin }) {
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    name: null,
    email: null,
    password: null,
  });
  const [touched, setTouched] = useState({});
  const formRef = useRef(null);

  const validateField = useCallback(
    (field) => {
      let error = null;
      switch (field) {
        case 'email':
          error = validateEmail(formData.email);
          break;
        case 'password':
          error = validatePassword(formData.password);
          break;
        case 'name':
          error = !isLogin ? validateName(formData.name) : null;
          break;
      }
      setErrors((prev) => ({ ...prev, [field]: error }));
      return !error;
    },
    [formData, isLogin]
  );

  const handleBlur = (field) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    validateField(field);
  };

  const validateForm = () => {
    const emailValid = validateField('email');
    const passwordValid = validateField('password');
    const nameValid = isLogin || validateField('name');
    return emailValid && passwordValid && nameValid;
  };
  const onSignIn = () => {
    let email = formData?.email || ''
    let password = formData?.password || ''
    let emailError = validateEmail(formData.email)
    if (emailError == "Invalid email format") {
      Alert({ description: emailError, type: 'warning', placement: 'topRight', duration: 3 });
      return false;
    }
    else if (email == "" || password == "") {
      Alert({ description: 'Login Credentials required.', type: 'warning', placement: 'topRight', duration: 3 });
      return false;
    }
    login(email, password);
  };
  const { login } = useAuth()


  const handleSubmit = async (e) => {
    e.preventDefault();

    const allTouched = Object.keys(formData).reduce(
      (acc, key) => ({ ...acc, [key]: true }),
      {}
    );
    setTouched(allTouched);

    if (validateForm()) {
      setIsLoading(true);

      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1500));

      if (formRef.current) {
        formRef.current.classList.add('animate-submit');
        setTimeout(() => {
          if (formRef.current) {
            formRef.current.classList.remove('animate-submit');
          }
          setIsLoading(false);
          console.log('Form submitted:', formData);
        }, 800);
      }
    }
  };

  const toggleMode = () => {
    if (formRef.current) {
      formRef.current.classList.add('animate-exit');
      setTimeout(() => {
        setIsLogin(!isLogin);
        setFormData({ name: '', email: '', password: '' });
        setErrors({ name: null, email: null, password: null });
        setTouched({});
        if (formRef.current) {
          formRef.current.classList.remove('animate-exit');
          formRef.current.classList.add('animate-enter');
          setTimeout(() => {
            if (formRef.current) {
              formRef.current.classList.remove('animate-enter');
            }
          }, 300);
        }
      }, 300);
    }
  };

  return (
    <div className="min-h-screen bg-mesh flex items-center justify-center p-6">
      {/* Background Video */}
      

      {/* Blur Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>

      <div className="w-full max-w-7xl flex rounded-lg overflow-hidden">
        {/* Left side image */}
        <div className="w-7/12 bg-cover  h-[500px] mt-8" style={{ backgroundImage: `url(${backgroundImage})`, borderRadius: "20px",zIndex:"1000" }}></div>

        {/* Right side login form */}
        <div className="w-5/12 p-6 flex flex-col justify-center ">
          <AuthCard isVisible={true}>
            <div className="text-center relative">
              <div
                className={`inline-block p-10 
              mb-3 transition-all duration-500 transform
              ${isLogin ? 'rotate-0 scale-100' : 'rotate-180 scale-90'}`}
              >
                <img
                  src="/s4.png"
                  alt="Logo"
                  className="w-full h-full object-contain"
                  style={{
                    marginTop: "-120px",
                    transform: "scale(1.2)",
                    zIndex: "10",
                  }}
                />
              </div>
              {/* <h2
                className="text-2xl font-bold text-gray-900 absolute"
                style={{
                  color: "#191970",
                  top: "50%", // Adjust for better positioning
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  whiteSpace: "nowrap", // Prevent wrapping
                }}
              >
                School Management
              </h2> */}
            </div>


            <form
              ref={formRef}
              onSubmit={handleSubmit}
              noValidate
              className="space-y-4 transition-all duration-300 mt-[-100px]"  // Adjusted margin-top
            >
              {!isLogin && (
                <div
                  className={`transition-all duration-500 transform
                    ${!isLogin ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4'}`}
                >
                  <InputField
                    label="Full Name"
                    type="text"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    onBlur={() => handleBlur('name')}
                    error={touched.name ? errors.name : null}
                    placeholder="John Doe"
                  />
                </div>
              )}

              <InputField
                label="Email"
                type="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                onBlur={() => handleBlur('email')}
                error={touched.email ? errors.email : null}
                placeholder="you@example.com"
                labelClass="text-blue-500"

              />

              <InputField
                label="Password"
                type="password"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                onBlur={() => handleBlur('password')}
                error={touched.password ? errors.password : null}
                placeholder="••••••••"
              />

              <div className="mt-10">
                <Button type="submit" isLoading={isLoading} onClick={onSignIn}>
                  Sign In
                </Button>
              </div>
            </form>
          </AuthCard>
        </div>
      </div>
    </div>
  );
}
