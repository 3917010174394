import React, { useEffect, useState } from 'react';
import { User, RefreshCcw } from 'lucide-react';
import { getStudent } from 'app/api/StudentApi';
import { imagePath, studentPath } from "app/utils/constant";
import { asyncGetChatInfo } from 'app/api/ChatApi';

export const StudentList = ({
  students,
  setStudents,
  selectedStudent,
  onSelectStudent,
  handleSearch,
  searchTerm,
  setSearchTerm,
  setNewMessage,
  setWarningMessage
}) => {
  let userInfo = JSON.parse(window.localStorage.getItem("userInfo") || "{}");
  let staffId = userInfo[0]?.employeeId || "";
  
  const [isRotating, setIsRotating] = useState(false);

  const handleRefresh = async () => {
    setIsRotating(true); // Start rotation
    setTimeout(() => setIsRotating(false), 2000); // Stop rotation after 2s

    try {
      const request_Data = {
        isActive: true,
        staffId: staffId
      };
      let chatData = await asyncGetChatInfo(request_Data);
      let chatList = chatData?.data || [];
      setStudents(chatList);
      setSearchTerm("");
      setNewMessage("");
      setWarningMessage(false);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  return (
    <div className="w-80 bg-gray-50 border-r border-gray-200 overflow-y-auto">
      <div className="p-4 border-b border-gray-200">
        <h2 className="text-lg font-semibold text-gray-700">Students</h2>
        <div className="mt-2">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search students..."
            className="w-full px-3 py-2 bg-white border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          />
          <button
            onClick={handleRefresh}
            className="mt-2 flex items-center text-sm text-blue-500 hover:text-blue-700"
          >
            <RefreshCcw
              className={`w-5 h-5 mr-2 transition-transform duration-500 ${isRotating ? 'animate-spin' : ''}`}
            />
            Refresh
          </button>
        </div>
      </div>
      <div className="divide-y divide-gray-200">
        {students.map((student) => (
          <div
            key={student.studentId}
            onClick={() => onSelectStudent(student)}
            className={`p-2 cursor-pointer hover:bg-gray-100 transition-colors ${
              selectedStudent?.chatId === student.chatId ? 'bg-blue-50' : ''
            }`}
          >
            <div className="flex items-center space-x-3">
              <div className="relative">
                {(() => {
                  const profile = `${imagePath}${studentPath}${student?.studentToken || ""}/${encodeURI(student?.profilePic || "")}`;
                  return student.profilePic ? (
                    <img
                      src={profile}
                      alt={student.name}
                      className="w-10 h-10 rounded-full object-cover"
                    />
                  ) : (
                    <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center">
                      <User className="w-6 h-6 text-gray-600" />
                    </div>
                  );
                })()}
                <div
                  className={`absolute bottom-0 right-0 w-3 h-3 rounded-full border-2 border-white ${
                    student.status === 'online' ? 'bg-green-500' : 'bg-gray-400'
                  }`}
                />
              </div>

              <div className="flex-1 min-w-0">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {student.studentName}
                  </p>
                  {student.unreadCount > 0 && (
                    <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-blue-500 rounded-full">
                      {student.unreadCount}
                    </span>
                  )}
                </div>
                {student.lastMessage && (
                  <p className="text-sm text-gray-500 truncate">
                    {student.lastMessage}
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
