import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
// import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete, MdRemoveRedEye, MdDownload } from "react-icons/md";
import _ from "lodash";
// import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { circularEditForm, circularForm, studentListForm } from "./formData";
import { FiEdit, FiSend, FiTrash2 } from 'react-icons/fi';
import SimpleCard from '../../components/atoms/simple-card/SimpleCard';
import { getCircular, createCircular, updateCircular } from "app/api/CircularApi"
import { getEnrollmentDetails } from "app/api/EnrollementDetailsApi";
import { showMessage, dateConversion, pagePermission } from "app/utils/app-functions";
import { getAssignType } from "app/api/AssignTypeAPI";
import { getSchoolInfo } from "app/api/SchoolInfoApi ";
import { getUnitFilter } from "app/api/UnitApi";
import RichTextEditor from 'app/components/atoms/rich-text-editor/RichTextEditor';
import swal from "sweetalert2";
import CircularDetailsPDF from "app/components/atoms/cicularPdfForm/CircularDetailsFormPDF";
import CircularDetailsViewForm from "./CircularDetailsViewForm"
import moment from "moment";
import Alert from "app/components/atoms/alert";
import { asyncGetAssignTypeInfo } from "app/api/AssignTypeAPI";
import { asyncGetEnrollmenetDetailsInfo } from "app/api/EnrollementDetailsApi";
import CircularDetails from "./CircularDetailsViewForm";
import { Dropdown } from 'react-bootstrap';
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { asyncGetSchoolInfo } from "app/api/SchoolInfoApi ";
import { asyncGetUnitFilterInfo } from "app/api/UnitApi";

let editData = false;
let checkData = false;

const Circular = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getSchoolInfoSuccess = useSelector((state) => state.schoolInfoReducer.getSchoolInfoSuccess);
    const getSchoolInfoList = useSelector((state) => state.schoolInfoReducer.getSchoolInfoList);
    const getSchoolInfoFailure = useSelector((state) => state.schoolInfoReducer.getSchoolInfoFailure);
    const schoolInfoErrorMessage = useSelector((state) => state.schoolInfoReducer.errorMessage);

    const getCircularSuccess = useSelector((state) => state.circularReducer.getCircularSuccess);
    const getCircularList = useSelector((state) => state.circularReducer.getCircularList);
    const getCircularFailure = useSelector((state) => state.circularReducer.getCircularFailure);
    const circularErrorMessage = useSelector((state) => state.circularReducer.errorMessage);

    const getAssignTypeSuccess = useSelector((state) => state.assignTypeReducer.getAssignTypeSuccess);
    const getAssignTypeList = useSelector((state) => state.assignTypeReducer.getAssignTypeList);
    const getAssignTypeFailure = useSelector((state) => state.assignTypeReducer.getAssignTypeFailure);
    const assignTypeErrorMessage = useSelector((state) => state.assignTypeReducer.errorMessage);

    const createCircularSuccess = useSelector((state) => state.circularReducer.createCircularSuccess);
    const createCircularData = useSelector((state) => state.circularReducer.createCircularData);
    const createCircularFailure = useSelector((state) => state.circularReducer.createCircularFailure);

    const updateCircularSuccess = useSelector((state) => state.circularReducer.updateCircularSuccess);
    const updateCircularData = useSelector((state) => state.circularReducer.updateCircularData);
    const updateCircularFailure = useSelector((state) => state.circularReducer.updateCircularFailure);

    const getEnrollmentDetailsSuccess = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsSuccess);
    const getEnrollmentDetailsList = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsList);
    const getEnrollmentDetailsFailure = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsFailure);
    const enrollmentDetailsErrorMessage = useSelector((state) => state.enrollmentDetailsReducer.errorMessage);

    const deleteCircularDetailsSuccess = useSelector((state) => state.circularReducer.deleteCircularDetailsSuccess);
    const deleteCircularDetailsFailure = useSelector((state) => state.circularReducer.deleteCircularDetailsFailure);
    const deleteCircularDetailsErrorMessage = useSelector((state) => state.circularReducer.errorMessage);

    const getUnitFilterSuccess = useSelector((state) => state.unitReducer.getUnitFilterSuccess);
    const getUnitFilterList = useSelector((state) => state.unitReducer.getUnitFilterList);
    const getUnitFilterFailure = useSelector((state) => state.unitReducer.getUnitFilterFailure);

    const unitErrorMessage = useSelector((state) => state.unitReducer.errorMessage);

    const [pageItems, setpageItems] = useState({
        "viewOption": false,
        "addOption": true,
        "updateOption": false,
        "deleteOption": false
    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Administrator", "Circular")
        setpageItems(data)

    }, [navigation]);


    const column = [
        {
            "dataField": "title",
            "text": "Title",
            "sort": true
        },
        /* {
            "dataField": "description",
            "text": "Description",
            "sort": true
        }, */
        {
            "dataField": "name",
            "text": "Category",
            "sort": true
        },
        {
            "dataField": "startDate",
            "text": "Start Date",
            "sort": true,
            formatter: (cellContent, row, index) => (
                dateConversion(cellContent, "DD-MMM-YYYY")
            )
        },
        {
            "dataField": "endDate",
            "text": "End Date",
            "sort": true,
            formatter: (cellContent, row, index) => (
                dateConversion(cellContent, "DD-MMM-YYYY")
            )
        }
    ]

    if (pageItems.delete || pageItems.update || pageItems.view) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.view && <MdRemoveRedEye
                        className="text-success cursor-pointer"
                        size={18}
                        onClick={() => onViewForm(row, index)}
                    ></MdRemoveRedEye>}
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {
                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></FiTrash2>}


                </div>
            )
        })
    }
    const [state, setState] = useState({
        enrollId: [],
        description: ""
    });

    const [createModule, setCreateModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [list, setList] = useState([]);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [removeModule, setRemoveModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [circularItemList, setCircularItemList] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [downloadPdf, setDownloadPdf] = useState(false);
    const [content, setContent] = useState("");

    let userInfo = window.localStorage.getItem("unitId");
    // let parsedData = JSON.parse(unitId);
    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }
        let categoryList = await asyncGetAssignTypeInfo(requestData)
        let schoolInfoList = await asyncGetSchoolInfo(requestData)
        let unitFilterList = await asyncGetUnitFilterInfo(requestData)
        let enrollmentDetailsList = await asyncGetEnrollmenetDetailsInfo(requestData)

        setState({
            ...state,
            categoryList: categoryList?.data || [],
            unitFilterList: (unitFilterList?.data || []).filter(ele => userInfo == ele.unitId),
            schoolList: schoolInfoList?.data || [],
            enrollmentDetailsList: enrollmentDetailsList?.data || [],
        })
    }




    useEffect(() => {
        const requestData = {
            isActive: "true"
        }
        setLoaderModule(true)
        dispatch(getCircular(requestData))
        dispatch(getUnitFilter())
        listFetching()



    }, [navigation]);

    useEffect(() => {
        if (getAssignTypeSuccess) {
            setState({
                ...state,
                categoryList: getAssignTypeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ASSIGN_TYPE" })
        } else if (getAssignTypeFailure) {
            setItemList([])
            showMessage("warning", assignTypeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ASSIGN_TYPE" })
        }
    }, [getAssignTypeSuccess, getAssignTypeFailure]);

    useEffect(() => {
        if (getSchoolInfoSuccess) {

            setState({
                ...state,
                schoolList: getSchoolInfoList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SCHOOL_INFO" })
        } else if (getSchoolInfoFailure) {
            setItemList([])
            showMessage("warning", schoolInfoErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SCHOOL_INFO" })
        }
    }, [getSchoolInfoSuccess, getSchoolInfoFailure]);

    useEffect(() => {
        if (getUnitFilterSuccess) {
            let data = getUnitFilterList.filter(ele => userInfo == ele.unitId)

            setState({
                ...state,
                unitFilterList: data
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_FILTER_UNIT" })
        } else if (getUnitFilterFailure) {
            setState({
                ...state,
                unitFilterList: []
            })
            showMessage("warning", unitErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_FILTER_UNIT" })
        }
    }, [getUnitFilterSuccess, getUnitFilterFailure]);

    useEffect(() => {
        if (getEnrollmentDetailsSuccess) {
            setState({
                ...state,
                enrollmentDetailsList: [...getEnrollmentDetailsList]
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })

        } else if (getEnrollmentDetailsFailure) {
            setState({
                ...state,
                enrollmentDetailsList: []
            })
            showMessage("warning", enrollmentDetailsErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
        }
    }, [getEnrollmentDetailsSuccess, getEnrollmentDetailsFailure]);

    useEffect(() => {
        if (getCircularSuccess) {
            setItemList(getCircularList)
            // console.log(itemList);
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CIRCULAR" })
        } else if (getCircularFailure) {
            setItemList([])
            showMessage("warning", circularErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CIRCULAR" })
        }
    }, [getCircularSuccess, getCircularFailure]);

    useEffect(() => {
        if (createCircularSuccess) {
            const temp_state = [...itemList, createCircularData[0]];

            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_CIRCULAR" })
        } else if (createCircularFailure) {
            setLoaderModule(false)
            showMessage("warning", circularErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_CIRCULAR" })
        }
    }, [createCircularSuccess, createCircularFailure]);

    useEffect(() => {
        if (deleteCircularDetailsSuccess) {
            showMessage("success", "Deleted Successfully")
            dispatch({ type: "RESET_DELETE_CIRCULAR" })
        } else if (deleteCircularDetailsFailure) {
            showMessage("warning", deleteCircularDetailsErrorMessage?.message)
            dispatch({ type: "RESET_DELETE_CIRCULAR" })
        }
    }, [deleteCircularDetailsSuccess, deleteCircularDetailsFailure]);

    useEffect(() => {
        if (updateCircularSuccess) {
            updateTable(updateCircularData[0])
            dispatch({ type: "RESET_UPDATE_CIRCULAR" })
        } else if (updateCircularFailure) {
            setLoaderModule(false)
            showMessage("warning", circularErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_CIRCULAR" })
        }
    }, [updateCircularSuccess, updateCircularFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.circularId != updatedItem.circularId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({  description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)

    }

    const resetState = () => {
        setContent()

        setState({
            ...state,
            name: "",
            title: "",
            description: "",

            // startDate: new Date(),
            // endDate: new Date(),
            enrollmentIds: "",

        })
        setCircularItemList([])

    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setDeleteModule(false)
        setCreateModule(true)
    }
    let editForm = circularEditForm
    const onEditForm = (data, index) => {
        const locationIds = data?.enrollmentIds?.split(",");


        let temp_state = [...state.enrollmentDetailsList]
        const sel_loc = _.filter(temp_state, (item) => {
            return locationIds?.includes(item.enrollmentId.toString());
        })
        const fiterEnrollmentData = data?.enrollmentIds?.split(",").map(Number) || [];

        const request = {
            "circularId": data?.circularId || ""
        }
        let category = data?.name;

        editData = true;
        setState({
            ...state,
            description: data?.description,
            name: data.assignTypeId,
            title: data.title,
            startDate: data.startDate ? moment(data.startDate, 'YYYY-MM-DD') : "",
            endDate: data.endDate ? moment(data.endDate, 'YYYY-MM-DD') : "",
            enrollmentIds: fiterEnrollmentData
        });
        if (data.assignTypeId === 2) {
            editForm[0].formBody[3].formField[0].disabled = true;
        }
        else {
            editForm[0].formBody[3].formField[0].disabled = false
        } /* Ś */
        // alert(category)
        // Check if category is '1' and update enrollmentIds accordingly
        if (category === '1') {
            setState((prevState) => ({
                ...prevState,
                enrollmentIds: state?.enrollmentIds || ""
            }));
        }
        const descriptionString = Array.isArray(data.description) ? data.description.join(' ') : data.description;
        setContent(descriptionString)
        // setContent(data?.description)
        setSelectedItem(data)
        setSelectedIndex(index)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const onDownloadPdf = (data, index) => {

        setState({
            ...state,
            pdfFile: data

        })
        setDownloadPdf(true)
    }

    const onClosePdf = () => {
        setDownloadPdf(false)
    }

    const handleContentChange = (content) => {
        // const strippedContent = content.replace(/(<([^>]+)>)/gi, '');
        // const strippedContent = content.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ').replace(/\\/g, '').replace(/'/g, '');

        setContent(content)

        setState({
            ...state,
            description: content
        });
    };

    const onSelectClass = (event, field) => {

        const value = event;

        setState({
            ...state,
            enrollmentIds: value,
        })

    };

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        let category = state?.name
        let value = state?.enrollmentIds || []

        // const ids = value.map(item => item.enrollmentId.toString());


        const requestData = {
            assignTypeId: state?.name || "",
            startDate: dateConversion(state?.startDate || "", "YYYY-MM-DD"),
            endDate: dateConversion(state?.endDate || "", "YYYY-MM-DD"),
            title: state?.title || "",
            description: JSON.stringify(state?.description || "")
        };
        if (category == 1) {
            requestData.enrollmentIds = value
        }
        function validateFormData(requestData) {
            for (let key in requestData) {
                if (requestData.hasOwnProperty(key)) {
                    if (
                        requestData[key] === null ||
                        requestData[key] === undefined ||
                        requestData[key] === "" ||
                        requestData[key] === "0" ||
                        requestData[key] === 0 ||
                        (typeof requestData[key] === "string" && requestData[key].trim() === "") ||
                        (typeof requestData[key] === "number" && isNaN(requestData[key]))
                    ) {
                        return false;
                    }
                }
            }
            return true;
        }

        if (!deleteModule && !validateFormData(requestData)) {
            Alert({ description: 'Fill all the details.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });


        } else {
            if (editData) {
                dispatch(updateCircular(requestData, selectedItem.circularId))
            } else if (deleteModule) {
                // alert("delete")
                const deleteRequest = {
                    isActive: false
                }
                // setLoaderModule(true)
                dispatch(updateCircular(deleteRequest, selectedItem.circularId))
            } else {
                setLoaderModule(true)
                dispatch(createCircular(requestData))
            }
        }
    }




    let value = circularForm;
    const handleChange = (event, name) => {
        setState({
            ...state,
            [name]: event
        });
        circularForm[0].formBody[3].formField[0].disabled = false;
        circularForm[0].formBody[3].formField[0].disabled = false;
        if (event === '2') {
            circularForm[0].formBody[3].formField[0].disabled = true;
        } else if (event === '3') {
            circularForm[0].formBody[3].formField[0].disabled = true;
        }
    };

    const onViewForm = (data, index) => {
        editData = false;
        setViewModule(true)
        setState({
            ...state,
            viewData: data,
        })

    }

    const onSelectStudent = (event) => {
        setShowForm(true)
    }

    const {
        submissionDate,
        employeeId = null,
        typeId = null
    } = state;
    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const checkTitle = !checkData ? "class " : "student ";
    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const { sidebarOpen } = useSidebar();
    return (
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginTop: "-50px" }}>

            {/* <SmallLoader value={loaderModule} /> */}

            <View isOpen={createModule} size="lg" savetitle={modelButtonLbl} onClose={setCreateModule} isLoading={loaderModule} onSubmit={onSubmitForm} title={`${modelTitle} Circular`} >

                <FormLayout defaultState={state} onChangeCallback={{ "onSelectClass": onSelectClass, "handleChange": handleChange }} setDefaultState={setState} dynamicForm={editData ? editForm : value} noOfColumn={4} onSearch={onSelectStudent} onSubmit={validateForm} />
                <RichTextEditor
                    content={content}
                    placeholder="Enter text here..."
                    handleContentChange={handleContentChange}
                    onSubmit={validateForm}
                    value={content}
                />
            </View>

            <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" isLoading={loaderModule} onSubmit={onSubmitForm} title="Delete Circular">
                <h6 className="text-capitalize mb-1">Are you sure ?</h6>
            </View>
            <View centered={true} size={'lg'} isOpen={downloadPdf} onClose={setDownloadPdf} savetitle="Yes" title="Download Circular">
                <CircularDetailsPDF data={state.pdfFile} unitDetails={state?.unitFilterList || []} schoolInfo={state?.schoolList || []} onClick={onClosePdf} />
            </View>


            <View centered={true} size={'lg'} isOpen={viewModule} onClose={setViewModule} savetitle="Close" onSearch={validateForm} title="Circular Details">
                <CircularDetails data={state.viewData} unitDetails={state?.unitFilterList || []} schoolInfo={state.schoolList} />
            </View>
            <SimpleCard>
                <CustomTable
                    columns={column}
                    data={itemList}
                    initialPage={1}
                    buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick}
                    tableName={"Circular"} />
            </SimpleCard>

        </div>
    )
};

export default Circular;
