export const AppMenuForm = [
    {
        formBody: [
            {
                formField: [
                    {
                        label: "Upload Icon",
                        inputType: "imageUploader",
                        placeholder: "Enter Admission No",
                        fieldName: "profilePic",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Title",
                        inputType: "text",
                        placeholder: "Enter Your Title",
                        fieldName: "title",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Navigate",
                        inputType: "text",
                        placeholder: "Enter Identification",
                        fieldName: "navigate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    }
                   

                ]
            },
           
           
           
        ]
    },
  

];
