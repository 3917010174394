import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import FormLayout from 'app/components/render/formlayout';
import { studentAttendanceForm, attendanceFilterForm } from "./formData";
import _ from "lodash";
import formRender from "app/components/render/formcomponent";
import { getStudentAttendance, createStudentAttendance, updateStudentAttendance } from "app/api/StudentAttendanceApi";
import { getEnrollmentDetails } from "app/api/EnrollementDetailsApi";
import { getAttendanceType } from "app/api/AttendanceTypeApi";
import { getBatch } from "app/api/BatchApi";
import { dateConversion, pagePermission, showMessage, todayDate } from "app/utils/app-functions";
import { getStudentClassList, getAsyncStudentClassList } from "app/api/StudentListApi";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import View from "app/components/atoms/modalView/Modalview";
import moment from "moment";
import SimpleCard from "app/components/atoms/simple-card/SimpleCard";
import { asyncGetBatchInfo } from "app/api/BatchApi";
import { asyncGetAttendanceInfo } from "app/api/AttendanceTypeApi";
import Alert from "app/components/atoms/alert";
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { lightTheme } from "styles/theme";

let editData = false;
let buttonArr = []

const StudentAttendance = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getStudentAttendanceSuccess = useSelector((state) => state.studentAttendanceReducer.getStudentAttendanceSuccess);
    const getStudentAttendanceList = useSelector((state) => state.studentAttendanceReducer.getStudentAttendanceList);
    const getStudentAttendanceFailure = useSelector((state) => state.studentAttendanceReducer.getStudentAttendanceFailure);
    const studentAttendanceErrorMessage = useSelector((state) => state.studentAttendanceReducer.errorMessage);

    const getEnrollmentDetailsSuccess = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsSuccess);
    const getEnrollmentDetailsList = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsList);
    const getEnrollmentDetailsFailure = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsFailure);
    const enrollmentDetailsErrorMessage = useSelector((state) => state.enrollmentDetailsReducer.errorMessage);

    const getAttendanceTypeSuccess = useSelector((state) => state.attendanceTypeReducer.getAttendanceTypeSuccess);
    const getAttendanceTypeList = useSelector((state) => state.attendanceTypeReducer.getAttendanceTypeList);
    const getAttendanceTypeFailure = useSelector((state) => state.attendanceTypeReducer.getAttendanceTypeFailure);
    const attendanceTypeErrorMessage = useSelector((state) => state.attendanceTypeReducer.errorMessage);

    const getBatchSuccess = useSelector((state) => state.batchReducer.getBatchSuccess);
    const getBatchList = useSelector((state) => state.batchReducer.getBatchList);
    const getBatchFailure = useSelector((state) => state.batchReducer.getBatchFailure);
    const batchErrorMessage = useSelector((state) => state.batchReducer.errorMessage);

    const createStudentAttendanceSuccess = useSelector((state) => state.studentAttendanceReducer.createStudentAttendanceSuccess);
    const createStudentAttendanceData = useSelector((state) => state.studentAttendanceReducer.createStudentAttendanceData);
    const createStudentAttendanceFailure = useSelector((state) => state.studentAttendanceReducer.createStudentAttendanceFailure);

    const getStudentClassListSuccess = useSelector((state) => state.studentListReducer.getStudentClassListSuccess);
    const getStudentClassListList = useSelector((state) => state.studentListReducer.getStudentClassListList);
    const getStudentClassListFailure = useSelector((state) => state.studentListReducer.getStudentClassListFailure);
    const studentClassListErrorMessage = useSelector((state) => state.studentListReducer.errorMessage);

    const departmentErrorMessage = useSelector((state) => state.departmentReducer.errorMessage);

    const [pageItems, setpageItems] = useState({
        "view": false,
        "create": true,
        "update": false,
        "deleteOption": false
    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Student", "Student Attendance")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "index",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return index + 1
            }
        },
        {
            "dataField": "firstName",
            "text": "Student Name",
            "sort": true,
            formatter: (cellContent, row, index) => (
                row.firstName + " " + row.middleName + " " + row.lastName)
        },

    ]

    if (pageItems.create || pageItems.view) {
        column.push({
            "dataField": "status",
            "text": "Status",
            "sort": true,
            formatter: (cellContent, row, index, extraData) => {
                const conInfo = { inputType: "checkbox", status: cellContent };

                return (
                    <div className="icon-container" style={{ position: "relative", left: "-50px" }}>
                        {formRender(extraData, null, conInfo, null, null, statusChange, index)}
                    </div>
                );
            }

        })
    }
    const [state, setState] = useState({
        batchList: [],
        enrollmentDetailsList: [],
        attendanceTypeList: [],
        studentAttendList: [],
        attendanceDate: moment(new Date(), 'YYYY-MM-DD'),
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    let userInfo = window.localStorage.getItem("userInfo");

    let parsedData = JSON.parse(userInfo);
    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }
        const req = {
        };
        const reqq = {
            examVariantId: 1
        };
        if (parsedData[0].roleId == 5) {
            req.empCode = parsedData[0]?.userCode || '';
        }

        let batchList = await asyncGetBatchInfo(requestData)
        let attendanceTypeList = await asyncGetAttendanceInfo(requestData)
        let enrollData = await getAsyncStudentClassList(req);

        setState({
            ...state,
            enrollmentDetailsList: enrollData?.data || [],
            batchList: batchList?.data || [],
            attendanceTypeList: attendanceTypeList?.data || [],
        })

    }



    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        const req = {
            isActive: "true",
        };
        if (global.roleId == 5) {
            req.empCode = global.UserInfo?.userCode || '';
        }
        // dispatch(getStudentClassList(req))
        // dispatch(getEnrollmentDetails(requestData))
        // dispatch(getAttendanceType(requestData))
        // dispatch(getBatch(requestData))
        listFetching()
    }, [navigation]);

    useEffect(() => {
        if (getStudentAttendanceSuccess) {
            const attenList = getStudentAttendanceList.map(v => ({ ...v, status: check(v?.attendanceStatusName || "", v) }))
            
            setState({
                ...state,
                studentAttendList: attenList.sort((a, b) => a.firstName.localeCompare(b.firstName))
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_ATTENDANCE" })
        } else if (getStudentAttendanceFailure) {
            setState({
                ...state,
                studentAttendList: []
            })
            showMessage("warning", departmentErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_ATTENDANCE" })
        }
    }, [getStudentAttendanceSuccess, getStudentAttendanceFailure]);

    /* useEffect(() => {
        if (getEnrollmentDetailsSuccess) {
            setState({
                ...state,
                enrollmentDetailsList: getEnrollmentDetailsList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
        } else if (getEnrollmentDetailsFailure) {
            setState({
                ...state,
                enrollmentDetailsList: []
            })
            showMessage("warning", departmentErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
        }
    }, [getEnrollmentDetailsSuccess, getEnrollmentDetailsFailure]); */

    useEffect(() => {
        if (getStudentClassListSuccess) {
            setState({
                ...state,
                enrollmentDetailsList: getStudentClassListList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
        } else if (getStudentClassListFailure) {
            setState({
                ...state,
                enrollmentDetailsList: []
            })
            showMessage("warning", studentClassListErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
        }
    }, [getStudentClassListSuccess, getStudentClassListFailure]);

    useEffect(() => {
        if (getBatchSuccess) {
            setState({
                ...state,
                batchList: getBatchList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BATCH" })
        } else if (getBatchFailure) {
            setState({
                ...state,
                batchList: []
            })
            showMessage("warning", departmentErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BATCH" })
        }
    }, [getBatchSuccess, getBatchFailure]);

    useEffect(() => {
        if (getAttendanceTypeSuccess) {
            setState({
                ...state,
                attendanceTypeList: getAttendanceTypeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ATTENDANCE_TYPE" })
        } else if (getAttendanceTypeFailure) {
            setState({
                ...state,
                attendanceTypeList: []
            })
            showMessage("warning", departmentErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ATTENDANCE_TYPE" })
        }
    }, [getAttendanceTypeSuccess, getAttendanceTypeFailure]);

    useEffect(() => {
        if (createStudentAttendanceSuccess) {
            setLoaderModule(false)
            setState({
                ...state,
                studentAttendList: []

            })
            const status  =  editData ? 'Updated Successfully.' : 'Created Successfully.'
            Alert({ description: status, type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            editData = false
            dispatch({ type: "RESET_CREATE_STUDENT_ATTENDANCE" })
        } else if (createStudentAttendanceFailure) {
            showMessage('warning', "Please Search Your Data");
            Alert({ description: 'Please Search Your Data.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            dispatch({ type: "RESET_CREATE_STUDENT_ATTENDANCE" })
        }
    }, [createStudentAttendanceSuccess, createStudentAttendanceFailure]);


    const check = (typeStatus, items) => {
        if (items.attendanceId && items.attendanceStatusName) {
            editData = true
        }

        if (typeStatus === "Present") {
            return true
        } else if (typeStatus === "Absent") {
            return false
        } else {
            return true

        }
    }

    const statusChange = (event, index, extraData) => {
        let items = [...extraData.studentAttendList];

        if(!pageItems.update && editData){
            Alert({ description: 'Access Denied !', type: 'error'});
            return false
        }
        if(items[index].statusId == 1){
            Alert({ description: 'Student Admission is still pending. !', type: 'error'});
            return false
        }
        else if(items[index].statusId == 3){
            Alert({ description: 'Student is De-activated. !', type: 'error'});
            return false
        }

        if (pageItems.create) {
            const updatedStatusId = items[index].attendanceStatusId === 1 ? 2 : 1;
            let key = items[index].attendanceStatusId === 1 ? 2 : 1;
            items[index].attendanceStatusId = key
            items[index].status = event.target.checked;

            setState({
                ...extraData,
                studentAttendList: items
            })
        }
    }
    const onCreateForm = () => {
        console.log(JSON.stringify(studentAttendList));
        
        if(!pageItems.update && editData){
            Alert({ description: 'Access Denied !', type: 'error'});
            return false
        }

        // const filteredStudents = studentAttendList.filter(student => student.statusId !== 1 && student.statusId !== 3);


       /*  if(items[index].statusId == 1){
            Alert({ description: 'Student Admission is still pending. !', type: 'error'});
            return false
        }
        else if(items[index].statusId == 3){
            Alert({ description: 'Student is De-activated. !', type: 'error'});
            return false
        } */
        var present = studentAttendList.filter(itm => itm.statusId !=1 && itm.statusId !=3 && itm.attendanceStatusId === 1)
            .map(item => item.studentEnrollmentId);
        var absent = studentAttendList.filter(itm => itm.statusId !=1 && itm.statusId !=3 && itm.attendanceStatusId === 2)
            .map(item => item.studentEnrollmentId);
        let requestData = [];
        if (present.length) {
            requestData.push({
                "attendanceTypeId": state.attendTypeId,
                "attendanceDate": dateConversion(state.attendanceDate, "YYYY-MM-DD"),
                "studentEnrollmentIds": present.toString(),
                "attendanceStatusId": 1,
                "createdBy": 1,
                "enrollmentId": state.enrollmentId,
                "batchId": state.batchName,

            })
        }
        if (absent.length) {
            requestData.push({
                "attendanceTypeId": state.attendTypeId,
                "attendanceDate": dateConversion(state.attendanceDate, "YYYY-MM-DD"),
                "studentEnrollmentIds": absent.toString(),
                "attendanceStatusId": 2,
                "createdBy": 1,
                "enrollmentId": state.enrollmentId,
                "batchId": state.batchName
            })
        }
   console.log(JSON.stringify(requestData));
   
        // return
        if (requestData.length) {
            setLoaderModule(true)
            dispatch(createStudentAttendance(requestData));
        }
        else{
            Alert({ description: 'There is no data to create.', type: 'error'});
            return false

        }
    }

    // Function to check if there are duplicate attendance status IDs in the requestData array



    const onSearch = () => {
        const { attendanceDate } = state;
        const dateFilter = attendanceDate ? attendanceDate : new Date(todayDate());
        if (!attendanceDate) {
            setState({
                ...state,
                attendanceDate: dateFilter
            })
        }
        let filterEnrollment = _.filter(state.enrollmentDetailsList, { enrollmentId: parseInt(state.enrollmentId) })
        const enrollementDetails = filterEnrollment.length ? filterEnrollment[0] : {}
        if (!state.batchName || !enrollementDetails.classId || state.batchName == 0) {
            // showMessage('warning', "Please Select The Batch and Class")
            Alert({ description: 'Please Select The Batch and Class.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            setState({
                ...state,
                studentAttendList: []
            })
        }
        else {
            const requestData = {
                batchId: parseInt(state?.batchName || ""),
                attendanceTypeId: parseInt(state?.attendTypeId || ""),
                classId: parseInt(enrollementDetails?.classId || ""),
                sectionId: parseInt(enrollementDetails?.sectionId || ""),
                attendanceDate: dateConversion(dateFilter, "YYYY-MM-DD"),
                // statusId: 2
            }
            if (requestData.attendanceTypeId == 0 || requestData.attendanceTypeId == "" || isNaN(requestData.attendanceTypeId) || requestData.attendanceDate == "Invalid date") {
                // showMessage('warning', "Select All fields")
                Alert({ description: 'Select All fields.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

                setState({
                    ...state,
                    studentAttendList: []
                })
            }
            else {
                setLoaderModule(true)
                editData = false
                dispatch(getStudentAttendance(requestData))
            }
        }

    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            departmentName: "",
            description: ""
        })
    }

    /* const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    } */

    const onEditForm = (data, index) => {
        setState({
            ...state,
            departmentName: data?.departmentName || "",
            description: data?.description || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onClear = () => {
        // alert("Cleared")
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            studentAttendanceTitle: studentAttendanceTitle
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateStudentAttendance(requestDate, selectedItem.studentAttendanceId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: false
            }
            setLoaderModule(true)
            dispatch(updateStudentAttendance(deleteRequest, selectedItem.studentAttendanceId))
        } else {
            setLoaderModule(true)
            dispatch(createStudentAttendance(requestDate))
        }
    }

    const { studentAttendanceTitle, studentAttendList } = state;

    const {
        departmentName,
        description,
    } = state;

    const { sidebarOpen } = useSidebar();


    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const handleClick = (buttonName) => {
        if (buttonName == 'Submit') {
            onCreateForm();
        }
    };



    return (
        <div>
            <View isOpen={createModule} size="md" savetitle={modelButtonLbl} onClose={setCreateModule} isLoading={loaderModule} onSubmit={validateForm} title={`${modelTitle} Department`}>
                <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={studentAttendanceForm} noOfColumn={1} onSubmit={onSubmitForm} />
            </View>

            <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule}  savetitle="Yes" onSubmit={onSubmitForm} title="Delete Department">
                <h6 className="text-capitalize mb-1">Are you sure ?</h6>
            </View>

            <div style={sidebarOpen ? { marginLeft: "40px", marginTop: "50px", marginBottom: "-30px" } : { marginLeft: "90px", marginTop: "50px", marginBottom: "-50px" }}>
                <FormLayout defaultState={state} setDefaultState={setState} dynamicForm={attendanceFilterForm} noOfColumn={6} />
                <Button onClick={onSearch} className='mr-2' style={{ backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px", marginTop: "-20px" }}>Search</Button>
                {/* <Button onClick={onClear} style={{ backgroundColor: "white", color: "gray", border: "1px solid gray", fontSize: "12px", marginTop: "-20px" }}>Clear</Button> */}
            </div>


            {/* <Button className="mx-2 mb-2"onClick={onClear}>Clear</Button> */}

            <SimpleCard>
                <CustomTable buttonData={pageItems.create ? ['submit'] : []} handleClick={handleClick} tableName={"Student Attendance"} columns={column} data={studentAttendList} state={state} />
            </SimpleCard>
        </div>
    )
};

export default StudentAttendance;
