import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import FormLayout from 'app/components/render/formlayout';
import { MdEdit, MdDelete, MdVisibility, MdNearMe, MdDownload } from "react-icons/md";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { lightTheme } from "styles/theme";

import swal from "sweetalert2";
import _, { set } from "lodash";
import formRender from "app/components/render/formcomponent";
import { dateConversion, pagePermission, showMessage, validateEmail, validatePhoneNo } from "app/utils/app-functions";
import { getStudentClassList, getStudentList, asyncGetStudentListInfo, getAsyncStudentClassList } from "app/api/StudentListApi";
import TableLayout from "app/components/render/formlayout/tablelayout";

import { createStudent, getStudent, getStudentDetails, updateStudent } from "app/api/StudentApi";
import { getBloodGroup } from "app/api/BloodGroupApi";
import { getGender } from "app/api/GenderApi";
import { getMotherTongue } from "app/api/MotherTongueApi";
import { getQualification } from "app/api/QualificationApi";
import { getState } from "app/api/StateApi";
import { getCaste } from "app/api/CasteApi";
import { getSubCaste } from "app/api/SubCasteApi";
import { getReligion } from "app/api/ReligionApi";
import { docPath, profileImages, imagePath, studentPath } from "app/utils/constant";
import { getCountry } from "app/api/CountryApi";
import { getNationality } from "app/api/NationalityApi";
import { getClass } from "app/api/ClassApi";
import { getSection } from "app/api/SectionApi";
import { getBatch } from "app/api/BatchApi";
import { getEnrollmentDetails } from "app/api/EnrollementDetailsApi";
import { getStudentStatus } from "app/api/StudentStatusApi";
import { uploadDocument, uploadFiles } from "app/api/DocumentApi";
import { getSchoolInfo } from "app/api/SchoolInfoApi ";
import { getUnitFilter } from "app/api/UnitApi";
import View from "app/components/atoms/modalView/Modalview";
import { studentBasicInfoForm, studentParentForm, statusForm, approvalForm, homeWorkForm, siblingsForm, siblingsDetailsForm } from "./formData";
import MultiSlider from "app/components/atoms/multi-slider/Multislider";
import SimpleCard from "app/components/atoms/simple-card/SimpleCard";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import AppImageCropper from "app/components/AppImageCropper";
import ProfileDetails from "./DetailsView";
import { asyncGetSchoolInfo } from "app/api/SchoolInfoApi ";
import moment, { duration } from "moment";
import { asyncGetBloodGroupInfo } from "app/api/BloodGroupApi";
import { asyncGetGenderInfo } from "app/api/GenderApi";
import { asyncGetMotherTongueInfo } from "app/api/MotherTongueApi";
import { asyncQualificationInfo } from "app/api/QualificationApi";
import { asyncgetCaste } from "app/api/CasteApi";
import { asyncgetReligion } from "app/api/ReligionApi";
import { asyncGetSubCaste } from "app/api/SubCasteApi";
import { asyncGetNationalityInfo } from "app/api/NationalityApi";
import { asyncGetClassInfo } from "app/api/ClassApi";
import { asyncGetSectionInfo } from "app/api/SectionApi";
import { asyncGetBatchInfo } from "app/api/BatchApi";
import { asyncGetStateInfo } from "app/api/StateApi";
import { asyncGetCountryInfo } from "app/api/CountryApi";
import Alert from "app/components/atoms/alert";
import { asyncGetStudentStatusInfo } from "app/api/StudentStatusApi";
import noProfile from "app/pages/images/no_profile.png";
import { asyncGetEnrollmenetDetailsInfo } from "app/api/EnrollementDetailsApi";
import { useSidebar } from "app/components/sidebar/sidebarContext";
import Loading from "app/components/atoms/loading";

const { v4: uuidv4 } = require('uuid');


let editData = false;
let studToken = false
let studentArr = [];


const StudentAdmission = ({ navigation }) => {
    const dispatch = useDispatch();
    const admissionModel = useRef();
    const customerFormRef = useRef();

    const getStudentSuccess = useSelector((state) => state.studentReducer.getStudentSuccess);
    const getStudentList = useSelector((state) => state.studentReducer.getStudentList);
    const getStudentFailure = useSelector((state) => state.studentReducer.getStudentFailure);
    const studentErrorMessage = useSelector((state) => state.studentReducer.errorMessage);

    const getBatchSuccess = useSelector((state) => state.batchReducer.getBatchSuccess);
    const getBatchList = useSelector((state) => state.batchReducer.getBatchList);
    const getBatchFailure = useSelector((state) => state.batchReducer.getBatchFailure);
    const batchErrorMessage = useSelector((state) => state.batchReducer.errorMessage);

    const getStudentStatusSuccess = useSelector((state) => state.studentStatusReducer.getStudentStatusSuccess);
    const getStudentStatusList = useSelector((state) => state.studentStatusReducer.getStudentStatusList);
    const getStudentStatusFailure = useSelector((state) => state.studentStatusReducer.getStudentStatusFailure);
    const studentStatusErrorMessage = useSelector((state) => state.studentStatusReducer.errorMessage);

    const getStudentDetailsSuccess = useSelector((state) => state.studentReducer.getStudentDetailsSuccess);
    const getStudentDetailsList = useSelector((state) => state.studentReducer.getStudentDetailsList);
    const getStudentDetailsFailure = useSelector((state) => state.studentReducer.getStudentDetailsFailure);
    const studentDetailsErrorMessage = useSelector((state) => state.studentReducer.errorMessage);

    const getClassSuccess = useSelector((state) => state.classReducer.getClassSuccess);
    const getClassList = useSelector((state) => state.classReducer.getClassList);
    const getClassFailure = useSelector((state) => state.classReducer.getClassFailure);
    const classErrorMessage = useSelector((state) => state.classReducer.errorMessage);

    const getSectionSuccess = useSelector((state) => state.sectionReducer.getSectionSuccess);
    const getSectionList = useSelector((state) => state.sectionReducer.getSectionList);
    const getSectionFailure = useSelector((state) => state.sectionReducer.getSectionFailure);
    const sectionErrorMessage = useSelector((state) => state.sectionReducer.errorMessage);

    const getNationalitySuccess = useSelector((state) => state.nationalityReducer.getNationalitySuccess);
    const getNationalityList = useSelector((state) => state.nationalityReducer.getNationalityList);
    const getNationalityFailure = useSelector((state) => state.nationalityReducer.getNationalityFailure);
    const nationalityErrorMessage = useSelector((state) => state.nationalityReducer.errorMessage);

    const getCountrySuccess = useSelector((state) => state.countryReducer.getCountrySuccess);
    const getCountryList = useSelector((state) => state.countryReducer.getCountryList);
    const getCountryFailure = useSelector((state) => state.countryReducer.getCountryFailure);
    const countryErrorMessage = useSelector((state) => state.countryReducer.errorMessage);

    const getStateSuccess = useSelector((state) => state.stateReducer.getStateSuccess);
    const getStateList = useSelector((state) => state.stateReducer.getStateList);
    const getStateFailure = useSelector((state) => state.stateReducer.getStateFailure);
    const stateErrorMessage = useSelector((state) => state.stateReducer.errorMessage);

    const getReligionSuccess = useSelector((state) => state.religionReducer.getReligionSuccess);
    const getReligionList = useSelector((state) => state.religionReducer.getReligionList);
    const getReligionFailure = useSelector((state) => state.religionReducer.getReligionFailure);
    const religionErrorMessage = useSelector((state) => state.religionReducer.errorMessage);

    const getBloodGroupSuccess = useSelector((state) => state.bloodGroupReducer.getBloodGroupSuccess);
    const getBloodGroupList = useSelector((state) => state.bloodGroupReducer.getBloodGroupList);
    const getBloodGroupFailure = useSelector((state) => state.bloodGroupReducer.getBloodGroupFailure);
    const bloodGroupErrorMessage = useSelector((state) => state.bloodGroupReducer.errorMessage);

    const getMotherTongueSuccess = useSelector((state) => state.motherTongueReducer.getMotherTongueSuccess);
    const getMotherTongueList = useSelector((state) => state.motherTongueReducer.getMotherTongueList);
    const getMotherTongueFailure = useSelector((state) => state.motherTongueReducer.getMotherTongueFailure);
    const motherTongueErrorMessage = useSelector((state) => state.motherTongueReducer.errorMessage);

    const getSubCasteSuccess = useSelector((state) => state.subCasteReducer.getSubCasteSuccess);
    const getSubCasteList = useSelector((state) => state.subCasteReducer.getSubCasteList);
    const getSubCasteFailure = useSelector((state) => state.subCasteReducer.getSubCasteFailure);
    const subCasteErrorMessage = useSelector((state) => state.subCasteReducer.errorMessage);

    const getQualificationSuccess = useSelector((state) => state.qualificationReducer.getQualificationSuccess);
    const getQualificationList = useSelector((state) => state.qualificationReducer.getQualificationList);
    const getQualificationFailure = useSelector((state) => state.qualificationReducer.getQualificationFailure);
    const qualificationErrorMessage = useSelector((state) => state.qualificationReducer.errorMessage);

    const getCasteSuccess = useSelector((state) => state.casteReducer.getCasteSuccess);
    const getCasteList = useSelector((state) => state.casteReducer.getCasteList);
    const getCasteFailure = useSelector((state) => state.casteReducer.getCasteFailure);
    const casteErrorMessage = useSelector((state) => state.casteReducer.errorMessage);

    const getGenderSuccess = useSelector((state) => state.genderReducer.getGenderSuccess);
    const getGenderList = useSelector((state) => state.genderReducer.getGenderList);
    const getGenderFailure = useSelector((state) => state.genderReducer.getGenderFailure);
    const genderErrorMessage = useSelector((state) => state.genderReducer.errorMessage);

    const getEnrollmentDetailsSuccess = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsSuccess);
    const getEnrollmentDetailsList = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsList);
    const getEnrollmentDetailsFailure = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsFailure);
    const enrollmentDetailsErrorMessage = useSelector((state) => state.enrollmentDetailsReducer.errorMessage);

    const createStudentSuccess = useSelector((state) => state.studentReducer.createStudentSuccess);
    const createStudentData = useSelector((state) => state.studentReducer.createStudentData);
    const createStudentFailure = useSelector((state) => state.studentReducer.createStudentFailure);

    const getUnitFilterSuccess = useSelector((state) => state.unitReducer.getUnitFilterSuccess);
    const getUnitFilterList = useSelector((state) => state.unitReducer.getUnitFilterList);
    const getUnitFilterFailure = useSelector((state) => state.unitReducer.getUnitFilterFailure);

    const updateStudentSuccess = useSelector((state) => state.studentReducer.updateStudentSuccess);
    const updateStudentData = useSelector((state) => state.studentReducer.updateStudentData);
    const updateStudentFailure = useSelector((state) => state.studentReducer.updateStudentFailure);

    const getSchoolInfoSuccess = useSelector((state) => state.schoolInfoReducer.getSchoolInfoSuccess);
    const getSchoolInfoList = useSelector((state) => state.schoolInfoReducer.getSchoolInfoList);
    const getSchoolInfoFailure = useSelector((state) => state.schoolInfoReducer.getSchoolInfoFailure);
    const schoolInfoErrorMessage = useSelector((state) => state.schoolInfoReducer.errorMessage);

    const unitErrorMessage = useSelector((state) => state.unitReducer.errorMessage);


    const [pageItems, setpageItems] = useState({
        "view": false,
        "create": false,
        "update": false,
        "delete": false
    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Administrator", "Admission")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "studentName",
            "text": "Student Name",
            "sort": true
        },
        {
            "dataField": "dob",
            "text": "Date Of Birth",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return dateConversion(cellContent, "DD-MMM-YYYY")
            }
        },
        {
            "dataField": "genderName",
            "text": "Gender",
            "sort": true
        },
        {
            "dataField": "classTitle",
            "text": "Class",
            "sort": true
        },
        {
            "dataField": "batch",
            "text": "Batch",
            "sort": true,
            formatter: (cellContent, row, index) => (
                row.batchStart + "-" + row.batchEnd
            )
        },
        {
            dataField: "studentStatusName",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const data = cellContent;

                const status = data === "Pending" ? false : true;
                const conInfo = { inputType: "status", status: status, succesName: data == "Deactive" ? "Deactived" : data, failureName: data };
                return formRender(null, null, conInfo, null);
            }
        },

        /* {
            "dataField": "studentStatusName",
            "text": "Status",
            "sort": true,
        }, */


    ]



    if (pageItems.update || pageItems.view || pageItems.create) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container" style={{ position: "relative", left: "10px" }}>
                    {pageItems.update && (
                        <FiEdit
                            className="text-primary cursor-pointer"
                            size={18}
                            onClick={() => onEdit(row, index)}
                        />
                    )}
                    {pageItems.view && <MdVisibility
                        className="text-success cursor-pointer"
                        size={18}
                        onClick={() => onview(row, index)}
                    />}
                    {pageItems.create && <MdNearMe
                        className="text-info cursor-pointer"
                        size={18}
                        onClick={() => onStatus(row, index)}
                    />}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    text: "You Want Delete to the Student And You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {

                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></FiTrash2>}


                </div>
            )
        });
    }

    const itemHeader = [
        {
            "dataField": "status",
            "text": "#",
            formatter: (cellContent, row, index, extraData) => {
                const conInfo = { inputType: "checkbox", status: cellContent }
                return formRender(extraData, null, conInfo, null, null, statusChange, index);
            }
        },
        {
            "dataField": "studentName",
            "text": "Student Name",
            "sort": true
        },
        {
            "dataField": "class-section",
            "text": "Class - Section",
            "sort": true
        },
    ]

    const [state, setState] = useState({
        initialParentDetails: studentParentForm,
        profilePic: noProfile,
        filterStudentEnrollment: [],
        deletedItems: []


    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [studentDetails, setStudentDetails] = useState([]);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [statusModule, setStatusModule] = useState(false);
    const [downloadPdf, setDownloadPdf] = useState(false);
    const [uploadModule, setUploadModule] = useState(false);
    const [detailsView, setdetailsView] = useState(false);
    const [isStep7, setIsStep7] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [siblingState, setsiblingState] = useState([]);
    const [checked, setChecked] = useState(true);
    const [deleteSiblingData, setdeleteSiblingData] = useState(false);
    const [deleteSibling, setdeleteSibling] = useState([]);
    const [deleteSiblingIndex, setdeleteSiblingIndex] = useState([]);
    const [isLoading, setisLoading] = useState(false);





    const listFetching = async () => {

        const requestData = {
            isActive: "true",
            statusId: 1
        }
        let schoolInfoList = await asyncGetEnrollmenetDetailsInfo(requestData)
        let bloodGroupInfo = await asyncGetBloodGroupInfo(requestData)
        let motherTongueInfo = await asyncGetMotherTongueInfo(requestData)
        let qualificationInfo = await asyncQualificationInfo(requestData)
        let religionList = await asyncgetReligion(requestData)
        let caste = await asyncgetCaste(requestData)
        let genderInfo = await asyncGetGenderInfo()
        let subCasteList = await asyncGetSubCaste(requestData)
        let nationalityList = await asyncGetNationalityInfo(requestData)
        let classList = await asyncGetClassInfo(requestData)
        let sectionList = await asyncGetSectionInfo(requestData)
        let batchList = await asyncGetBatchInfo(requestData)
        let stateList = await asyncGetStateInfo(requestData)
        let countryList = await asyncGetCountryInfo(requestData)
        let studentStatusList = await asyncGetStudentStatusInfo(requestData)

        setState({
            ...state,
            enrollmentDetailsList: schoolInfoList?.data || [],
            bloodGroupList: bloodGroupInfo?.data || [],
            genderList: genderInfo?.data || [],
            motherTongueList: motherTongueInfo?.data || [],
            qualificationList: qualificationInfo?.data || [],
            casteList: caste?.data || [],
            religionList: religionList?.data || [],
            subCasteList: subCasteList?.data || [],
            nationalityList: nationalityList?.data || [],
            classList: classList?.data || [],
            sectionList: sectionList?.data || [],
            batchList: batchList?.data || [],
            stateList: stateList?.data || [],
            countryList: countryList?.data || [],
            studentStatusList: studentStatusList?.data || [],
        })
        // setLoaderModule(false)

    }




    useEffect(() => {
        setLoaderModule(true)

        const requestData = {
            isActive: "true",
        }
        /*   dispatch(getBloodGroup(requestData))
          dispatch(getGender())
          dispatch(getMotherTongue(requestData))
          dispatch(getQualification(requestData))
          dispatch(getCaste(requestData))
          dispatch(getReligion(requestData))
          dispatch(getCountry(requestData))
          dispatch(getSubCaste(requestData))
          dispatch(getNationality(requestData))
          dispatch(getClass(requestData))
          dispatch(getSection(requestData)) */
        dispatch(getStudent(requestData))
        /*  dispatch(getState())
         dispatch(getBatch(requestData))
         dispatch(getStudentStatus())
         dispatch(getUnitFilter())
         dispatch(getSchoolInfo(requestData)) */
        dispatch(getEnrollmentDetails(requestData))

        listFetching()

    }, [navigation]);


    useEffect(() => {
        if (getSchoolInfoSuccess) {
            setState((prevState) => ({
                ...prevState,
                schoolList: getSchoolInfoList  // Ensure getSchoolInfoList is a fresh reference
            }));

            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SCHOOL_INFO" })
        } else if (getSchoolInfoFailure) {
            setState({
                ...state,
                schoolList: []
            })
            showMessage("warning", schoolInfoErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SCHOOL_INFO" })
        }
    }, [getSchoolInfoSuccess, getSchoolInfoFailure]);

    useEffect(() => {
        if (getStudentSuccess) {
            setItemList([...getStudentList])
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT" })
        } else if (getStudentFailure) {
            setItemList([])
            showMessage("warning", studentErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT" })
        }
    }, [getStudentSuccess, getStudentFailure, getStudentList]);

    useEffect(() => {
        if (getClassSuccess) {
            setState((prevState) => ({
                ...prevState,
                classList: getClassList
            }));

            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CLASS" })
        } else if (getClassFailure) {
            setState({
                ...state,
                classList: []
            })
            setLoaderModule(false)
            showMessage("warning", classErrorMessage?.message)
            dispatch({ type: "RESET_GET_CLASS" })
        }
    }, [getClassSuccess, getClassFailure]);

    useEffect(() => {
        if (getStudentStatusSuccess) {
            setState({
                ...state,
                studentStatusList: getStudentStatusList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_STATUS" })
        } else if (getStudentStatusFailure) {
            setState({
                ...state,
                studentStatusList: []
            })
            setLoaderModule(false)
            showMessage("warning", studentStatusErrorMessage?.message)
            dispatch({ type: "RESET_GET_STUDENT_STATUS" })
        }
    }, [getStudentStatusSuccess, getStudentStatusFailure]);

    useEffect(() => {
        if (getUnitFilterSuccess) {
            let data = getUnitFilterList.filter(ele => global.unitId == ele.unitId)
            setState({
                ...state,
                unitFilterList: data
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_FILTER_UNIT" })
        } else if (getUnitFilterFailure) {
            setState({
                ...state,
                unitFilterList: []
            })
            showMessage("warning", unitErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_FILTER_UNIT" })
        }
    }, [getUnitFilterSuccess, getUnitFilterFailure]);

    useEffect(() => {
        if (getStudentDetailsSuccess) {
            setState({
                ...state,
                studentDetailsList: getStudentDetailsList
            })
            setLoaderModule(false)
            if (editData === true) {
                onEditForm(getStudentDetailsList);
            }
            // editData === true ? onEditForm(getStudentDetailsList) : (downloadPdf ? setDownloadPdf()) setViewModule(true)
            dispatch({ type: "RESET_GET_STUDENT_DETAILS" })
        } else if (getStudentDetailsFailure) {
            setState({
                ...state,
                studentDetailsList: []
            })
            setLoaderModule(false)
            showMessage("warning", studentDetailsErrorMessage?.message)
            dispatch({ type: "RESET_GET_STUDENT_DETAILS" })
        }
    }, [getStudentDetailsSuccess, getStudentDetailsFailure]);

    useEffect(() => {
        if (getBatchSuccess) {
            setState({
                ...state,
                batchList: getBatchList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BATCH" })
        } else if (getBatchFailure) {
            setState({
                ...state,
                batchList: []
            })
            setLoaderModule(false)
            showMessage("warning", batchErrorMessage?.message)
            dispatch({ type: "RESET_GET_BATCH" })
        }
    }, [getBatchSuccess, getBatchFailure]);

    useEffect(() => {
        if (getEnrollmentDetailsSuccess) {
            setState({
                ...state,
                enrollmentDetailsList: getEnrollmentDetailsList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
        } else if (getEnrollmentDetailsFailure) {
            setState({
                ...state,
                enrollmentDetailsList: []
            })
            setLoaderModule(false)
            showMessage("warning", enrollmentDetailsErrorMessage?.message)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
        }
    }, [getEnrollmentDetailsSuccess, getEnrollmentDetailsFailure]);

    useEffect(() => {
        if (getSectionSuccess) {
            setState({
                ...state,
                sectionList: getSectionList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SECTION" })
        } else if (getSectionFailure) {
            setState({
                ...state,
                sectionList: []
            })
            setLoaderModule(false)
            showMessage("warning", sectionErrorMessage?.message)
            dispatch({ type: "RESET_GET_SECTION" })
        }
    }, [getSectionSuccess, getSectionFailure]);

    useEffect(() => {
        if (getGenderSuccess) {
            setState({
                ...state,
                genderList: getGenderList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_GENDER" })
        } else if (getGenderFailure) {
            setState({
                ...state,
                genderList: []
            })
            setLoaderModule(false)
            showMessage("warning", genderErrorMessage?.message)
            dispatch({ type: "RESET_GET_GENDER" })
        }
    }, [getGenderSuccess, getGenderFailure]);

    useEffect(() => {
        if (getStateSuccess) {
            setState({
                ...state,
                stateList: getStateList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STATE" })
        } else if (getStateFailure) {
            setState({
                ...state,
                stateList: []
            })
            setLoaderModule(false)
            showMessage("warning", stateErrorMessage?.message)
            dispatch({ type: "RESET_GET_STATE" })
        }
    }, [getStateSuccess, getStateFailure]);

    useEffect(() => {
        if (getCountrySuccess) {

            setState({
                ...state,
                countryList: getCountryList,
                countryId: getCountryList.map((item) => item.countryId),
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_COUNTRY" })
        } else if (getCountryFailure) {
            setState({
                ...state,
                countryList: []
            })
            setLoaderModule(false)
            showMessage("warning", countryErrorMessage?.message)
            dispatch({ type: "RESET_GET_COUNTRY" })
        }
    }, [getCountrySuccess, getCountryFailure]);

    useEffect(() => {
        if (getNationalitySuccess) {
            setState((prevState) => ({
                ...prevState,
                nationalityList: getNationalityList,
                nationalityId: getNationalityList.map((item) => item.nationalityId),
            }));

            setLoaderModule(false)
            dispatch({ type: "RESET_GET_NATIONALITY" })
        } else if (getNationalityFailure) {
            setState({
                ...state,
                nationalityList: []
            })
            setLoaderModule(false)
            showMessage("warning", nationalityErrorMessage?.message)
            dispatch({ type: "RESET_GET_NATIONALITY" })
        }
    }, [getNationalitySuccess, getNationalityFailure]);

    useEffect(() => {
        if (getReligionSuccess) {
            setState({
                ...state,
                religionList: getReligionList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_RELIGION" })
        } else if (getReligionFailure) {
            setState({
                ...state,
                religionList: []
            })
            setLoaderModule(false)
            showMessage("warning", religionErrorMessage?.message)
            dispatch({ type: "RESET_GET_RELIGION" })
        }
    }, [getReligionSuccess, getReligionFailure]);

    useEffect(() => {
        if (getBloodGroupSuccess) {
            setState({
                ...state,
                bloodGroupList: getBloodGroupList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BLOOD_GROUP" })
        } else if (getBloodGroupFailure) {
            setState({
                ...state,
                bloodGroupList: []
            })
            setLoaderModule(false)
            showMessage("warning", bloodGroupErrorMessage?.message)
            dispatch({ type: "RESET_GET_BLOOD_GROUP" })
        }
    }, [getBloodGroupSuccess, getBloodGroupFailure]);

    useEffect(() => {
        if (getQualificationSuccess) {
            setState({
                ...state,
                qualificationList: getQualificationList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_QUALIFICATION" })
        } else if (getQualificationFailure) {
            setState({
                ...state,
                qualificationList: []
            })
            setLoaderModule(false)
            showMessage("warning", qualificationErrorMessage?.message)
            dispatch({ type: "RESET_GET_QUALIFICATION" })
        }
    }, [getQualificationSuccess, getQualificationFailure]);

    useEffect(() => {
        if (getCasteSuccess) {
            setState({
                ...state,
                casteList: getCasteList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CASTE" })
        } else if (getCasteFailure) {
            setState({
                ...state,
                casteList: []
            })
            setLoaderModule(false)
            showMessage("warning", casteErrorMessage?.message)
            dispatch({ type: "RESET_GET_CASTE" })
        }
    }, [getCasteSuccess, getCasteFailure]);

    useEffect(() => {
        if (getSubCasteSuccess) {
            setState({
                ...state,
                subCasteList: getSubCasteList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUB_CASTE" })
        } else if (getSubCasteFailure) {
            setState({
                ...state,
                subCasteList: []
            })
            setLoaderModule(false)
            showMessage("warning", subCasteErrorMessage?.message)
            dispatch({ type: "RESET_GET_SUB_CASTE" })
        }
    }, [getSubCasteSuccess, getSubCasteFailure]);

    useEffect(() => {
        if (getMotherTongueSuccess) {
            setState({
                ...state,
                motherTongueList: getMotherTongueList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MOTHER_TONGUE" })
        } else if (getMotherTongueFailure) {
            setState({
                ...state,
                motherTongueList: []
            })
            setLoaderModule(false)
            showMessage("warning", motherTongueErrorMessage?.message)
            dispatch({ type: "RESET_GET_MOTHER_TONGUE" })
        }
    }, [getMotherTongueSuccess, getMotherTongueFailure]);

    useEffect(() => {
        if (createStudentSuccess) {
            if (createStudentData && createStudentData.length > 0 && createStudentData[0].studentName) {
                setItemList([...itemList, createStudentData[0]]);
                const reques_tData = {
                    isActive: "true",
                }
                dispatch(getStudent(reques_tData))
                closeModule();
                Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
                dispatch({ type: "RESET_CREATE_STUDENT" });
            } else {
                console.error("createStudentData is empty or missing 'studentName' property.");
            }
        } else if (createStudentFailure) {
            setLoaderModule(false);
            showMessage("warning", studentErrorMessage?.message);
            dispatch({ type: "RESET_CREATE_STUDENT" });
        }
    }, [createStudentSuccess, createStudentFailure]);


    useEffect(() => {
        if (updateStudentSuccess) {

            updateTable(updateStudentData[0])
            dispatch({ type: "RESET_UPDATE_STUDENT" })
        } else if (updateStudentFailure) {
            setLoaderModule(false)
            showMessage("warning", studentErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_STUDENT" })
        }
    }, [updateStudentSuccess, updateStudentFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.studentId != updatedItem.studentId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;

            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch(getStudent({ "isActive": true }))

            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
        setStatusModule(false)

    }

    const addUploadFile = () => {
        const uploadfileItem = {
            "class": "",
            "studentName": "",
            "admissionNo": ""
        }
        setsiblingState([...siblingState, uploadfileItem])
    }


    const resetState = () => {
        setState({
            ...state,
            // admissionNo: "",
            firstName: "",
            renamedFile: "",
            studentToken: "",
            batchId: "",
            // countryCode: "+91",
            phone: "+91 ",
            address: "",
            profilePic: '',
            bloodGroupId: "",
            disabled: true,
            // registrationNumber: "",
            dob: moment(new Date(), 'YYYY-MM-DD'),
            middleName: "",
            email: "",
            postalCode: "",
            genderId: "",
            lastName: "",
            motherTongueId: "",
            fatherName: "",
            fatherIlliterate: false,
            fatherQualificationId: "",
            fatherOccupation: "",
            annualIncome: "",
            motherName: "",
            motherIlliterate: false,
            motherQualificationId: "",
            motherOccupation: "",
            stateId: "",
            casteId: "",
            religionId: "",
            // countryId: "",
            subCasteId: "",
            // nationalityId: "",
            classId: "",
            sectionId: "",
            schoolTransport: "",
            identification: "",
            dateOfJoin: moment(new Date(), 'YYYY-MM-DD'),
            emisNumber: "",
            filterStudentEnrollment: [],
            siblingData: "",
            noOfYoungers: "",
            noOfElders: "",
            deletedItems: [],
            countryId: "",
            isNew: false



        })
        setChecked(true)
        setsiblingState([])
        studentParentForm[0].formBody[0].formField[2].disabled = false;
        studentParentForm[0].formBody[1].formField[2].disabled = false;
    }


    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEdit = (data, index) => {
        editData = true;

        setSelectedItem(data)
        setSelectedIndex(index)
        dispatch(getStudentDetails(null, data.studentId))

    }

    const onview = (row, index) => {

        const profile = `${imagePath}${studentPath}${row[0]?.studentToken || ""}/${encodeURI(row[0]?.profilePic || "")}`;
        setState({
            ...state,
            profilePic: profile
        })
        setdetailsView(true)
        setStudentDetails(row)
        dispatch(getStudentDetails(null, row.studentId))
        editData = false
        setLoaderModule(true)


    }

    const onDownloadPdf = (data, index) => {
        editData = false
        dispatch(getStudentDetails(null, data.studentId))
        setDownloadPdf(true)

    }

    const onStatus = (data) => {
        // alert(JSON.stringify(data.statusId))
        // return
        setState({
            ...state,
            admissionStatusId: data.statusId
        })
        setSelectedItem(data)
        setStatusModule(true)

    }


    const onEditForm = (data, index) => {
        console.log(data)
        studentParentForm[0].formBody[0].formField[2].disabled = data[0].fatherIlliterate ? true : false;
        studentParentForm[0].formBody[1].formField[2].disabled = data[0].motherIlliterate ? true : false;
        const profile = `${imagePath}${studentPath}${data[0]?.studentToken || ""}/${encodeURI(data[0]?.profilePic || "")}`;
        const concatenatedPhone = `${data[0]?.countryCode || ""}${data[0]?.phoneNo || ""}`;

        setState({
            ...state,
            deletedItems: [],
            countryCode: data[0]?.countryCode || "",
            phoneNo: concatenatedPhone || "",
            renamedFile: "",
            firstName: data[0]?.firstName || "",
            phone: concatenatedPhone || "",
            address: data[0]?.address || "",
            bloodGroupId: data[0]?.bloodGroupId || "",
            // registrationNumber: data[0]?.registrationNumber || "",
            emisNumber: data[0]?.emisNumber || "",
            studentToken: data[0]?.studentToken || "",
            disabled: true,
            dob: data[0] ? moment(new Date(data[0].dob), 'YYYY-MM-DD') : "",
            // dob: data[0] ? dateConversion(new Date(data[0].dob), "YYYY-MM-DD") : "",
            middleName: data[0]?.middleName || "",
            batchId: data[0]?.batchId || "",
            admissionStatusId: data[0]?.statusId || 0,
            email: data[0]?.email || "",
            postalCode: data[0]?.postalCode || "",
            genderId: data[0]?.genderId || "",
            lastName: data[0]?.lastName || "",
            profilePic: profile,
            profilePicture: data[0]?.profilePic || '',
            motherTongueId: data[0]?.motherTongueId || "",
            fatherName: data[0]?.fatherName || "",
            fatherIlliterate: data[0]?.fatherIlliterate || false,
            fatherQualificationId: data[0]?.fatherQualificationId || "",
            fatherOccupation: data[0]?.fatherOccupation || "",
            annualIncome: data[0]?.annualIncome || "",
            motherName: data[0]?.motherName || "",
            motherIlliterate: data[0]?.motherIlliterate || false,
            motherQualificationId: data[0]?.motherQualificationId || "",
            motherOccupation: data[0]?.motherOccupation || "",
            stateId: data[0]?.stateId || "",
            casteId: data[0]?.casteId || "",
            religionId: data[0]?.religionId || "",
            countryId: data[0]?.countryId || "",
            subCasteId: data[0]?.subCasteId || "",
            nationalityId: data[0]?.nationalityId || "",
            classId: parseInt(data[0]?.classId) || "",
            sectionId: parseInt(data[0]?.sectionId) || "",
            schoolTransport: data[0]?.schoolTransport || "",
            identification: data[0]?.identification || "",
            dateOfJoin: data[0] ? moment(new Date(data[0].dateOfJoin), 'YYYY-MM-DD') : "",
            noOfElders: data[0]?.noOfElders || "",
            noOfYoungers: data[0]?.noOfYoungers || "",
            siblingData: Array.isArray(data[0]?.siblingDetails) && data[0]?.siblingDetails.length > 0 ? true : false,
            isNew: false



        });
        let sibling_State = data[0]?.siblingDetails || []
        if (sibling_State.length > 0) {
            setChecked(false)
        } else {
            setChecked(true)
        }
        if (siblingState) {
            const newSiblingData = sibling_State.map((item, index) => ({

                admissionNo: item?.admissionNo || null,
                class: item?.classSection || null,

                studentName: item?.studentName || null,
                studentId: item?.studentId || null,
                siblingDetailId: item?.siblingDetailId || null,
            }));

            setsiblingState(newSiblingData);
        }
        setCreateModule(true);

        editData = true
        setSelectedItem(data)
        setSelectedIndex(index)
        setCreateModule(true)
    };

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }


    const onRemove = (index) => {

        let temp_state = [...siblingState];
        if (temp_state[index].siblingDetailId) {
            setdeleteSibling(temp_state[index])
            setdeleteSiblingData(true)
            setdeleteSiblingIndex(index)
        } else {
            temp_state.splice(index, 1);
            setsiblingState(temp_state)

        }

    }

    console.log('====================================');
    console.log(siblingState);
    console.log('====================================');
    const onDeleteForm = () => {
        let temp_state = [...siblingState];
        let deletedObject = temp_state.splice(deleteSiblingIndex, 1);
        let remainingList = _.remove(temp_state, function (n, index) {
            return index != deleteSiblingIndex
        });
        console.log('====================================');
        console.log(remainingList);
        console.log('====================================');
        setsiblingState(remainingList)
        let temp_arr = { ...state }
        setState({
            ...state,
            deletedItems: [...temp_arr.deletedItems, ...deletedObject]
        })
        setdeleteSiblingData(false)
        Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });




    }




    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const formDataAppend = (data) => {
        const formData = new FormData();
        let fileName = data.name;
        const now = new Date();
        const uid = uuidv4();
        const fileExtension = fileName?.split('.').pop() || "";
        const year = now.getFullYear().toString();
        const uniqueIdentifier = Math.floor(1000 + Math.random() * 9000).toString();
        const day = now.getDate().toString().padStart(2, '0');
        const docName = `${year}${day}${uniqueIdentifier}.${fileExtension}`;
        const renamedFile = new File(
            [data],
            docName,
            {
                type: data?.type,
                name: docName,
                lastModified: data?.lastModified,
            }
        );

        studToken = state?.studentToken || uid
        setState(prevState => ({
            ...prevState,
            renamedFile: renamedFile,
            profilePicture: docName,
            // profilePic: docName ? docName : state.profilePicture,
            studentToken: state?.studentToken || uid,
            isNew: true
        }));

        formData.append('document', renamedFile);
        // return
        dispatch(uploadFiles(formData, `${studentPath}${state?.studentToken || uid}`));

        return renamedFile
    }

    const {
        /* admissionNo */ firstName, phone, address, bloodGroupId,/* registrationNumber , */ emisNumber, dob, middleName, email, studentStatusId,
        batchId, postalCode, genderId, lastName, motherTongueId, fatherName, fatherIlliterate, fatherQualificationId, fatherOccupation,
        annualIncome, motherName, motherIlliterate, motherQualificationId, motherOccupation, stateId, casteId, religionId,
        countryId, subCasteId, nationalityId, classId, sectionId, schoolTransport, identification, dateOfJoin, countryCode
    } = state;

    const validateFields = (fields, data, context) => {
        for (let key of fields) {
            const keys = key.split("?."); // Split for nested object access
            let value = data;
            for (let k of keys) {
                value = value?.[k];
                if (value === undefined) break; // Stop if key doesn't exist
            }

            if (!value || value === "") {
                Alert({
                    title: "Warning!",
                    description: `PLEASE FILL IN THE ${key.replace(/\?\./g, ".").toUpperCase()} FIELD${context ? ` IN ${context}` : ""}.`,
                    type: "warning",
                    placement: "topRight",
                    duration: 3,
                });
                return false;
            }
        }
        return true;
    };

    const onHandleSiblings = (event) => {
        // setShowForm(true)
        let isChecked = event.target.checked
        setChecked(!isChecked)
        if (!isChecked) {
            setsiblingState([])
        }
        setState({
            ...state,
            siblingData: event.target.checked,

        })
    }
    const onAddStudents = (index) => {
        setShowForm(true)
        setState({
            ...state,
            studentRowIndex: index,
            batch_Id: "",
            enrollment_Id: "",
            filterStudentEnrollment: []



        })

    }



    const onSubmitForm = () => {
        try {
            if (statusModule) {
                if (state.admissionStatusId !== 0) {
                    const statusRequest = { statusId: state.admissionStatusId };
                    dispatch(updateStudent(statusRequest, selectedItem.studentId));
                    return true;
                } else {
                    Alert({ type: 'warning', description: "Select Status", duration: 3 })
                    return false;
                }
            }
            if (deleteModule) {
                const deleteRequest = { isActive: false };
                setLoaderModule(true);
                dispatch(updateStudent(deleteRequest, selectedItem.studentId));
                return;
            }

            const temp_state = state?.enrollmentDetailsList || [];
            const filterEnrollmentId = temp_state.filter((n) => {
                return n.classId == state.classId && n.sectionId == state.sectionId;
            });

            let picture = '';
            if (state?.isNew) {
                picture = formDataAppend(state.profilePicFileType);
            }
            let phone = state?.phoneNum || state.phoneNo
            const phoneNoWithoutCountryCode = phone.replace(/^(\+\d{1,2})/, '') || "";

            const requestData = {
                firstName: firstName || "",
                phone: phoneNoWithoutCountryCode,
                countryCode: state?.countryCode || '',
                address: address,
                bloodGroupId: parseInt(bloodGroupId),
                emisNumber: emisNumber,
                dob: dateConversion(dob, "YYYY-MM-DD"),
                middleName: middleName || "",
                email: email,
                postalCode: postalCode,
                genderId: parseInt(genderId),
                lastName: lastName || "",
                motherTongueId: parseInt(motherTongueId),
                fatherName: fatherName,
                fatherQualificationId: parseInt(fatherQualificationId) || 0,
                fatherIlliterate: fatherIlliterate || false,
                fatherOccupation: fatherOccupation,
                annualIncome: annualIncome,
                motherName: motherName,
                motherIlliterate: motherIlliterate || false,
                motherQualificationId: parseInt(motherQualificationId) || 0,
                motherOccupation: motherOccupation,
                stateId: parseInt(stateId),
                casteId: parseInt(casteId),
                religionId: parseInt(religionId),
                countryId: parseInt(countryId),
                subCasteId: subCasteId,
                nationalityId: nationalityId,
                classId: classId,
                sectionId: parseInt(sectionId),
                schoolTransport: !!schoolTransport,
                identification: identification,
                studentToken: state?.studentToken || studToken,
                profilePic: picture?.name || state?.profilePicture,
                dateOfJoin: dateConversion(dateOfJoin, "YYYY-MM-DD"),
                statusId: editData ? state.admissionStatusId : 1,
                studentEnrollmentDetails: {
                    enrollmentId: parseInt(filterEnrollmentId[0]?.enrollmentId || 0),
                    batchId: batchId || ""
                },
                siblingDetails: siblingState,
                noOfYoungers: state?.noOfYoungers || "",
                noOfElders: state?.noOfElders || "",

            };
            console.log('====================================');
            console.log(requestData);
            console.log('====================================');
            // return
            let Batch = requestData.studentEnrollmentDetails.batchId || ""


            const requiredFields = ["dateOfJoin", "classId", "sectionId", "firstName", "lastName", "dob", "phone", "address", "stateId", "bloodGroupId",
                "subCasteId", "email", "countryId", "motherTongueId", "genderId", "casteId", "nationalityId", "religionId", "postalCode", "fatherName", "motherName",
            ];
            if (!validateFields(requiredFields, requestData)) return;

            if (requestData.fatherIlliterate || requestData.motherIlliterate) {
                const illiterateField = requestData.fatherIlliterate
                    ? "fatherIlliterate"
                    : "motherIlliterate";

                if (!validateFields([illiterateField], requestData)) {
                    return; // Exit if specific validation fails
                }
            }

            const phoneRegex = /^[+]?[0-9]{10,15}$/;
            if (!phoneRegex.test(requestData.phone)) {
                Alert({
                    title: "Invalid Phone Number",
                    description: "Please enter a valid phone number (10-digits).",
                    type: "error",
                    placement: "topRight",
                    duration: 3,
                });
                return;
            }

            if (requestData.studentEnrollmentDetails.batchId === "") {
                Alert({ description: 'Select Batch Id', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            }

            if (editData) {
                let data = state.deletedItems
                let deletingRow = data.map((ele) => ({
                    ...ele,
                    isActive: false,
                }));

                requestData.siblingDetails = [...requestData.siblingDetails, ...deletingRow]

                setLoaderModule(true);
                const siblingDetailId = deleteSibling?.siblingDetailId || ""


                // return


                setLoaderModule(true);

                dispatch(updateStudent(requestData, selectedItem[0]?.studentId || ""));
            } else {
                setLoaderModule(true);
                dispatch(createStudent(requestData));
            }
        } catch {
            console.log("error occurred")
        }
    };

    const {
        unitTypeCode,
        unitTypeName
    } = state;

    const buttonHoverStyle = {
        backgroundColor: '#45a049',
    };
    const buttonStyle = {
        backgroundColor: 'rgba(147,112,219)', // Fixed background color
        color: 'white', // White text color
        padding: '7px 7px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '12px',
        transition: 'background-color 0.3s',
        float: 'right',
    };

    const handleAddButtonClick = () => {
        setCreateModule(true);
    };

    const handleCloseModal = () => {
        setCreateModule(false);
    };

    const onHandleCheckBox = (event, name) => {
        const isCheckedState = event.target.checked;
        const dd = name === "fatherIlliterate" ? 0 : 1;

        if (name === "fatherIlliterate") {
            studentParentForm[0].formBody[dd].formField[2].disabled = isCheckedState;
        } else {
            studentParentForm[0].formBody[dd].formField[2].disabled = isCheckedState;
        }
        setState({
            ...state,
            [name]: isCheckedState,
        });
    };


    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const statusChange = (event, index, extraData) => {
        const temp_state = [...state.filterStudentEnrollment];

        // Check if any other row is already selected
        const alreadySelectedIndex = temp_state.findIndex(item => item.status === true);

        if (alreadySelectedIndex !== -1 && alreadySelectedIndex !== index) {
            // If another row is already selected and it's not the current row
            Alert({ description: 'You can select only one row at a time.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            return;
        }

        // Update the selected status of the current row
        temp_state.forEach((item, i) => {
            item.status = i === index ? event.target.checked : false;
        });

        setState({
            ...state,
            filterStudentEnrollment: temp_state
        });
    };


    const addButton = (
        <button onClick={() => handleAddButtonClick()} className="add-button">
            Add New Row
        </button>
    );

    const onFilterStudent = async () => {
        const enrollment_Id = state?.enrollment_Id || '';
        const batch_Id = state?.batch_Id || '';
        let request = {
            "batchId": batch_Id,
            "enrollmentId": enrollment_Id,
            isActive: true
        }
        let studentData = await asyncGetStudentListInfo(request);
        setState({
            ...state,
            filterStudentEnrollment: studentData?.data || [],

        })


    }


    const sliderData = [
        {
            title: "Admission & Education Info",
            slides: [
                {
                    content: (
                        <FormLayout
                            defaultState={state}
                            setDefaultState={setState}
                            dynamicForm={statusForm}
                            noOfColumn={6}
                            onSearch={() => onFind()}
                        />
                    ),
                    title: "Admission & Education Info",
                    description: "Description for Slide 1-2",
                    marginTop: "50px",
                    width: "80%",

                },
                {
                    content: (
                        <div>
                            <FormLayout
                                defaultState={state}
                                setDefaultState={setState}
                                dynamicForm={studentBasicInfoForm}
                                noOfColumn={4}
                            />
                        </div>
                    ),
                    title: "Student Info & Student Details",
                    marginTop: "100px",
                },
                {
                    content: (
                        <FormLayout
                            defaultState={state}
                            setDefaultState={setState}
                            dynamicForm={studentParentForm}
                            noOfColumn={2}
                            onChangeCallback={{ "onHandleCheckBox": onHandleCheckBox, "onHandleSiblings": onHandleSiblings }}

                        />
                    ),
                    title: "Parents Info",
                    marginTop: "80px",

                },
                {
                    content: (
                        <div>
                            {/* Siblings Toggle */}
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    marginBottom: "10px",
                                    padding: "0 1rem",
                                }}
                            >
                                <label style={{ marginRight: "10px", color: "gray" }}>Siblings in the same school</label>
                                <input
                                    type="checkbox"
                                    style={{ height: "1rem", width: "1rem" }}
                                    onChange={onHandleSiblings}
                                    checked={state?.siblingData || false}
                                />
                            </div>

                            {/* Form Layout */}
                            <FormLayout
                                defaultState={state}
                                setDefaultState={setState}
                                dynamicForm={siblingsForm}
                                noOfColumn={6}
                                onChangeCallback={{ onHandleCheckBox }}
                            />

                            {/* Siblings Table Section */}
                            <div style={{ marginTop: "1rem" }}>
                                <div
                                    style={{
                                        maxWidth: "100%",
                                        height: "40vh",
                                        overflowY: "scroll",
                                    }}
                                >
                                    <style>
                                        {`
                        ::-webkit-scrollbar {
                            display: none; /* Hide scrollbar in WebKit browsers */
                        }
                    `}
                                    </style>

                                    {/* Add Upload File Button */}
                                    <div className="px-2">
                                        <div className="py-2">
                                            <Button
                                                className="float-end"
                                                variant="primary"
                                                onClick={() => addUploadFile()}
                                                disabled={checked}
                                            >
                                                {"+"}
                                            </Button>
                                        </div>

                                        {/* Siblings Table */}
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="table-responsive mt-3">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Class</th>
                                                                <th scope="col">Student Name</th>
                                                                <th scope="col">Select Student</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(siblingState || []).map((item, index) => (
                                                                <TableLayout
                                                                    key={index}
                                                                    defaultState={state}
                                                                    setDefaultState={setsiblingState}
                                                                    index={index}
                                                                    layoutType="table"
                                                                    dataList={siblingState}
                                                                    dynamicForm={siblingsDetailsForm}
                                                                    iconPress={{
                                                                        onRemove,
                                                                        onAddStudents,
                                                                    }}
                                                                />
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ),
                    title: "Siblings Info",
                    marginTop: "100px",


                },
            ],
        },
    ];


    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };


    const onFind = () => {
        setUploadModule(true)
    }

    const { sidebarOpen } = useSidebar();
    const handleStep7 = (value) => {
        setIsStep7(value);
    };

    const onSelectStudentForm = () => {
        let temp_state = [...state.filterStudentEnrollment]
        temp_state.map(item => {
            item.status = true
        })
        setState({
            ...state,
            isCheck: false,
            filterStudentEnrollment: temp_state
        })
    }

    const confirmForm = () => {
        let temp_state = [...state.filterStudentEnrollment]
        let tempState = [...siblingState]
        let filteredData = _.filter(temp_state, function (item) {
            return item.status === true;
        });
        let index = state?.studentRowIndex
        if (index >= 0) {
            // Use bracket notation to access 'class-section'
            tempState[index].class = filteredData[0]?.['class-section'] || "";
            tempState[index].studentName = filteredData[0]?.studentName || "";
            tempState[index].admissionNo = filteredData[0]?.admissionNo || "";
        }
        setsiblingState(tempState)
        setShowForm(false)




    }


    return (
        <div >
            <Loading status={loaderModule} />

            <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>

                <View isOpen={createModule} size="fullscreen" savetitle={modelButtonLbl} onClose={handleCloseModal} onSubmit={onSubmitForm} title={`${modelTitle} Student Admission`} disable={isStep7} isLoading={loaderModule}>
                    <MultiSlider sliderData={sliderData} onReachStep7={handleStep7} />
                </View>

                <View size={'md'} isOpen={uploadModule} savetitle="upload" onClose={setUploadModule} title="Upload Image">
                    <AppImageCropper state={state} setState={setState} close={setUploadModule}></AppImageCropper>
                </View>

                <View size={'lg'} isOpen={detailsView} onClose={setdetailsView} savetitle="Yes" title="Student Details">
                    <ProfileDetails data={studentDetails} pageAccess={pageItems} studentDetails={state?.studentDetailsList || []} />
                </View>

                <View centered={true} size={'sm'} isOpen={statusModule} onClose={setStatusModule} savetitle="Yes" onSubmit={onSubmitForm} title="Admission Status">
                    <FormLayout defaultState={state} setDefaultState={setState} dynamicForm={approvalForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>

                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Batch">
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>
                </View>

                <View isOpen={showForm} size="lg" onClose={setShowForm} title={`Select `} onSubmit={confirmForm} /* onClose={onCloseForm} */ >
                    <FormLayout defaultState={state} setDefaultState={setState} dynamicForm={homeWorkForm} noOfColumn={4} onSubmit={onSubmitForm} />

                    {/* <CustomAlignButton alignName={"start"} onClick={onSelectStudentForm} btnName={"Select All"}></CustomAlignButton> */}

                    <Button className="me-3" style={{ backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px" }} onClick={() => onFilterStudent()}>
                        {"Search"}
                    </Button>

                    <CustomTable columns={itemHeader} data={state?.filterStudentEnrollment || []} tableName={""} state={state} />
                </View>
                <View centered={true} size={'sm'} isOpen={deleteSiblingData} onClose={setdeleteSiblingData} savetitle="Yes" onSubmit={onDeleteForm} title="Delete Sibling History" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                    <h6 className="text-capitalize mb-1">Are you sure ? You won't be able to revert this!</h6>
                </View>

                <SimpleCard >
                    <CustomTable
                        columns={column}
                        data={itemList}
                        initialPage={1}
                        buttonData={pageItems.create ? ['add'] : []}
                        handleClick={handleClick}
                        tableName={"Admission"} />
                </SimpleCard>

            </div>
        </div>
    )
};

export default StudentAdmission;
