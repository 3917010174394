
import { returnApiCallPromise } from './ApiContent';
import { homeworkSubmit } from './APIContainer';

//GET
export function getSubmitHomework(request) {
  const requestObj = request ? { params: request } : "";
  return (dispatch) => {
    returnApiCallPromise("GET", homeworkSubmit, requestObj)
      .then(response => {
        dispatch({
          type: 'GET_SUBMIT_HOMEWORK_SUCCESS',
          payload: response.data
        })
      }).catch(error => {

        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'GET_SUBMIT_HOMEWORK_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function asyncGetHomeworkInfo(request) {
  const requestObj = request ? { params: request } : "";
  return returnApiCallPromise("GET", homeworkSubmit, requestObj)
    .then(response => {
      return {
        error: false,
        message: "Succsss",
        data: response?.data?.data || []
      }
    }).catch(error => {
      let errorMessage = error;
      if (error.response) {
        if (error.response.data.error) {
          errorMessage = error.response.data.message;
        }
      }
      return {
        error: true,
        data: [],
        message: errorMessage
      }
    })
}

//POST
export function createSubmitHomework(request) {

  return (dispatch) => {
    returnApiCallPromise("POST", homeworkSubmit, request)
      .then(response => {
        if (response.data.error) {
          dispatch({
            type: 'CREATE_SUBMIT_HOMEWORK_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'CREATE_SUBMIT_HOMEWORK_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'CREATE_SUBMIT_HOMEWORK_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}

export function updateSubmitHomework(request, submitHomeworkId) {
  return (dispatch) => {
    returnApiCallPromise("PUT", homeworkSubmit + "/" + submitHomeworkId, request)
      .then(response => {

        if (response.data.error) {
          dispatch({
            type: 'UPDATE_SUBMIT_HOMEWORK_FAILURE',
            errorMessage: response.data.message
          })
        } else {
          dispatch({
            type: 'UPDATE_SUBMIT_HOMEWORK_SUCCESS',
            payload: response.data
          })
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        dispatch({
          type: 'UPDATE_SUBMIT_HOMEWORK_FAILURE',
          errorMessage: errorMessage
        })
      })
  }
}