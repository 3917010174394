import React, { useState, useEffect, useRef } from 'react';
import './style/header.css';
import { FaHome, FaInfoCircle, FaServicestack, FaEnvelope, FaBell, FaCogs, FaUser, FaSignOutAlt, FaRegQuestionCircle, FaLock, FaLanguage, FaShieldAlt } from 'react-icons/fa';
import { CiSearch } from "react-icons/ci";
import { IoChevronForwardSharp } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { useSidebar } from '../sidebar/sidebarContext';
import styled from 'styled-components';
import TreeViewNew from '../atoms/new-tree-view/TreeViewNew';
import UnitMenu from '../atoms/unit-name/UnitMenu';
import { useDispatch, useSelector } from "react-redux";
import { getUnit } from "app/api/UnitApi";
import { MdKeyboardArrowRight } from 'react-icons/md';
import { employeePath, imagePath, unitPath } from "app/utils/constant";
import noProfileImage from "app/pages/images/no_profile.png";
import adminImage from "app/pages/images/superadmin.png";
import { Dropdown } from "react-bootstrap";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { logoutJWTUser } from "app/redux/auth/authSlice";
import { useAuth } from 'app/contexts/AuthContext';
// import { adminImage } from 'app/pages/images/superadmin.png';

import ReactFlagsSelect from "react-flags-select";


function HeaderNavbar() {
    const [isCardVisible, setIsCardVisible] = useState(false);
    const [isUnitVisible, setIsUnitVisible] = useState(false);
    const [isCardHidden, setIsCardHidden] = useState(false); // State for slide-out animation
    const [notificationVisible, setNotificationVisible] = useState(false); // State for notification visibility
    const cardRef = useRef(null); // Reference to the card for detecting outside clicks
    const { sidebarOpen, toggleSidebar } = useSidebar();
    const notificationRef = useRef(null); // Reference to the notification card
    const [isClosing, setIsClosing] = useState(false); // State to track the closing animation
    const [isTreeVisible, setIsTreeVisible] = useState(false); // State for tree view visibility
    const dispatch = useDispatch();
    const { settings } = useSelector((state) => state.layout);
    const customerFormRef = useRef();
    let userInfo = window.localStorage.getItem("userInfo");

    let parsedData = JSON.parse(userInfo);

    const [selectedUnit, setSelectedUnit] = useState(false);
    const [isDropDown, setIsDropDown] = useState(false);
    const [image, setImage] = useState();
    const [token, setToken] = useState();
    const [selected, setSelected] = useState("IN");

    const [state, setState] = useState({
        showSearchBox: false,
        unitList: [],
        tempData: false
    });

    const toggleCard = () => {
        if (isCardVisible) {
            setIsClosing(true); // Trigger closing animation
            setIsCardVisible(false);
            setTimeout(() => {
                setIsCardVisible(false);
                setIsClosing(false);
            }, 1000);
        } else {
            setIsCardVisible(true);
        }
    };

    const handleClickOutside = (event) => {
        // Close profile card if clicked outside
        if (cardRef.current && !cardRef.current.contains(event.target)) {
            console.log(event.target);
            console.log("isCardVisible --- > " + isCardVisible);
            if (isCardVisible) {
                setIsClosing(true);
            }
            setIsCardVisible(false);

        }
    };

    useEffect(() => {
        // Check if the user clicks outside the profile card
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Clean up the event listener when the component unmounts
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []); // Empty dependency array means this effect runs only once on mount

    const Navbar = styled.nav`
                 background-color:white;
                 height: 71px;
                 padding-left:${({ sidebarOpen }) => (sidebarOpen ? '-30px' : '30px')};
                 margin-left:${({ sidebarOpen }) => (sidebarOpen ? '0px' : '0')};
                 border-bottom:0.2px dashed #D3D3D3;


                `;

    const getUnitSuccess = useSelector((state) => state.unitReducer.getUnitSuccess);
    const getUnitList = useSelector((state) => state.unitReducer.getUnitList);
    const getUnitFailure = useSelector((state) => state.unitReducer.getUnitFailure);

    useEffect(() => {
        const req = { "treeView": true }
        dispatch(getUnit(req))
    }, [])

    let tempState = ''

    useEffect(() => {
        if (getUnitSuccess) {
            setState({
                ...state,
                unitList: getUnitList
            })
            tempState = getUnitList
            const unitId = window.localStorage.getItem("unitId")
            if (tempState.length) {
                let unit_id = ''
                let unitCode = ''
                let unitToken = ''
                let logo = ''
                let arr = tempState
                let check = true
                arr = arr.filter(ele => ele.unitId == unitId)
                if (arr.length == 0) {
                    tempState.map(parentUnit => {
                        if (parentUnit?.branches) {
                            setIsDropDown(true)
                        }

                        let branchUnit = (parentUnit?.branches || []).filter(branch => branch.unitId == unitId);
                        if (branchUnit.length) {

                            let unitSelect = branchUnit;
                            check = false
                            logo = unitSelect[0]?.image || ''
                            setSelectedUnit(unitSelect[0]?.unitName || '');
                            unit_id = unitSelect[0]?.unitId || '';
                            unitToken = unitSelect[0]?.unitToken || '';
                            setToken(unitSelect[0]?.unitToken || '');
                            return;
                        } else {
                            unit_id = parentUnit?.unitId || '';
                            unitToken = parentUnit?.unitToken || '';
                            setSelectedUnit(parentUnit?.unitName || '');
                            setToken(parentUnit?.unitToken || '');

                        }
                    });
                }
                else if (arr.length != 0) {
                    tempState.map(parentUnit => {
                        if (parentUnit?.branches) {
                            setIsDropDown(true);
                        }
                    });
                    unit_id = arr[0]?.unitId || '';
                    unitCode = arr[0]?.unitCode || '';
                    unitToken = arr[0]?.unitToken || '';
                    setToken(arr[0]?.unitToken || '');
                    logo = arr[0]?.image || '';
                    setSelectedUnit(arr[0]?.unitName || '');
                }
                else {
                    setSelectedUnit(tempState[0]?.unitName || '');

                }
                setImage(logo);
                handleUnitId(unit_id, unitCode, unitToken);
            }

            dispatch({ type: "RESET_GET_UNIT" })
        } else if (getUnitFailure) {
            setState({
                ...state,
                unitList: []
            })
            dispatch({ type: "RESET_GET_UNIT" })
        }
    }, [getUnitSuccess, getUnitFailure]);
    const handleUnitId = (unitId, unitCode, unitToken) => {
        global.unitId = unitId
        global.unitCode = unitCode
        global.unitToken = unitToken

        localStorage.setItem('unitId', JSON.stringify(unitId));
        localStorage.setItem('unitCode', JSON.stringify(unitCode));
        localStorage.setItem('unitToken', JSON.stringify(unitToken));
    };

    const [searchQuery, setSearchQuery] = useState('');

    const { logout } = useAuth()

    const handleUnitClick = (data) => {
        handleUnitId(data.unitId, data.unitCode, data.unitToken)
        setSelectedUnit(data.unitName);
        window.location.reload();
    };

    const unitData = `units/${token}/employee/`
    console.log(token);

    // const unitTok = (unitData != null || unitData != undefined) ? unitData.replace(/"/g, '') : '';
    const imageUrl = parsedData?.[0]?.roleId === 1
        ? adminImage
        : (
            parsedData?.[0]?.profile && parsedData?.[0]?.employeeToken
                ? `${imagePath}${unitData}${parsedData?.[0]?.employeeToken}/${parsedData?.[0]?.profile}`
                : noProfileImage
        );
    console.log('====================================');
    console.log(imageUrl);
    console.log('====================================');
    return (
        <Navbar className="navbar" isOpen={sidebarOpen}>
            <div className="navbar-left">

                <div className={`menu-icon-container ${sidebarOpen ? 'open' : 'close'}`} onClick={toggleSidebar}>

                    <div className={`burger-icon ${sidebarOpen ? 'open' : 'close'}`}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>

                <UnitMenu state={state} units={state?.unitList || []} onUnitClick={handleUnitClick} selectedUnit={selectedUnit} isDropDown={isDropDown} />
            </div>

            <div className="navbar-right flex items-center ml-auto ">
                {/*  <div className="relative mr-4">
                    <ReactFlagsSelect
                        className="menu-flags"
                        searchable={true}
                        selected={selected}
                        showSelectedLabel={false}
                        placeholder={selected}
                        onSelect={(code) => setSelected(code)}
                        fullWidth={false}
                        id="flags-select"
                        // rfsKey="app-lang"

                    />
                </div> */}

                <div className=" profile-details" onClick={toggleCard}>
                    <div
                        className={`flex items-center cursor-pointer  p-2 transition-colors duration-300 ease-in-out 
                        
                         `}
                    >
                        <img
                            src={imageUrl}
                            style={{ objectFit: "cover" }}  // or "contain"
                            alt={`'s profile`}
                            className="w-8 h-8 rounded-full mr-2"
                        />

                    </div>

                    {(isCardVisible || isClosing) && (
                        <div
                            ref={cardRef}
                            className={`absolute right-0 mt-2 w-64 bg-white border rounded-lg shadow-lg z-50 ${isCardVisible ? 'profile-card' : 'profile-card-hidden'
                                }`}
                        >
                            <div className="flex flex-col items-center p-4 border-b">
                                <img
                                    src={imageUrl}
                                    style={{ objectFit: "cover" }}  // or "contain"

                                    alt={`'s profile`}
                                    className="w-16 h-16 rounded-full mb-2"
                                />
                                <div className="text-sm font-medium text-gray-700">
                                    {parsedData?.[0]?.firstName || 'N/A'}
                                </div>
                                <div className="text-xs text-gray-500">
                                    {parsedData?.[0]?.email || 'N/A'}
                                </div>
                            </div>
                            <div className="px-4 py-2 hover:bg-gray-200 cursor-pointer text-sm text-gray-700 flex items-center rounded-lg">
                                <FaUser className="mr-2 text-gray-600" />
                                Profile
                            </div>
                            <div
                                className="px-4 py-2 hover:bg-red-600 hover:text-white cursor-pointer text-sm text-gray-700 flex items-center rounded-lg hover:bg-opacity-90"
                                onClick={logout}
                            >
                                <FaSignOutAlt className="mr-2 text-gray-600 group-hover:text-white" />
                                Logout
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Navbar>

    );
}

export default HeaderNavbar;
