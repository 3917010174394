import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { bookForm } from "./formData";
import { createBooks, getBooks, updateBooks } from "app/api/BookApi";
import { getBookType } from "app/api/BokkTypeApi";
import { getKnowledgeMaterialType } from "app/api/KnowledgeMaterialTypeApi";
import { uploadFiles } from "app/api/DocumentApi";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import Alert from "app/components/atoms/alert";
import { asyncGetKnowledgeMaterialTypeInfo } from "app/api/KnowledgeMaterialTypeApi";
import { asyncGetKnowledgeBookInfo } from "app/api/BokkTypeApi";
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false;

const Book = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getBookSuccess = useSelector((state) => state.bookReducer.getBookSuccess);
    const getBookList = useSelector((state) => state.bookReducer.getBookList);
    const getBookFailure = useSelector((state) => state.bookReducer.getBookFailure);

    const getBookTypeSuccess = useSelector((state) => state.bookTypeReducer.getBookTypeSuccess);
    const getBookTypeList = useSelector((state) => state.bookTypeReducer.getBookTypeList);
    const getBookTypeFailure = useSelector((state) => state.bookTypeReducer.getBookTypeFailure);

    const createBookSuccess = useSelector((state) => state.bookReducer.createBookSuccess);
    const createBookData = useSelector((state) => state.bookReducer.createBookData);
    const createBookFailure = useSelector((state) => state.bookReducer.createBookFailure);

    const updateBookSuccess = useSelector((state) => state.bookReducer.updateBookSuccess);
    const updateBookData = useSelector((state) => state.bookReducer.updateBookData);
    const updateBookFailure = useSelector((state) => state.bookReducer.updateBookFailure);

    const getKnowledgeMaterialTypeSuccess = useSelector((state) => state.knowledgeMaterialTypeReducer.getKnowledgeMaterialTypeSuccess);
    const getKnowledgeMaterialTypeList = useSelector((state) => state.knowledgeMaterialTypeReducer.getKnowledgeMaterialTypeList);
    const getKnowledgeMaterialTypeFailure = useSelector((state) => state.knowledgeMaterialTypeReducer.getKnowledgeMaterialTypeFailure);
    const knowledgeMaterialTypeErrorMessage = useSelector((state) => state.knowledgeMaterialTypeReducer.errorMessage);

    const bookErrorMessage = useSelector((state) => state.bookReducer.errorMessage);
    const bookTypeErrorMessage = useSelector((state) => state.bookReducer.errorMessage);

    const [pageItems, setpageItems] = useState({

    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Super Admin", "Book")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "title",
            "text": "Title",
            "sort": true,
            /* formatter: (cellContent, row, index) => (
                index + 1
            ) */
        },
        {
            "dataField": "name",
            "text": "Name",
            "sort": true
        },
        /*  {
             "dataField": "name",
             "text": "name",
             "sort": true
         }, */
        {
            "dataField": "edition",
            "text": "Edition",
            "sort": true,
        },
        {
            "dataField": "authorName",
            "text": "Author Name",
            "sort": true,
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
    ]

    if (pageItems.delete || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index)

                        }}
                    ></FiTrash2>}
                </div>
            )
        })
    }

    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }
        const req = {
            isActive: "true",
        };
        const reqq = {
            examVariantId: 1
        };
        if (global.roleId == 5) {
            req.empCode = global.UserInfo?.userCode || '';
        }

        let knowledgeMaterialType = await asyncGetKnowledgeMaterialTypeInfo(requestData);
        let bookTypeList = await asyncGetKnowledgeBookInfo(requestData);

        setState({
            ...state,
            // schoolList:schoolInfoList?.getSchoolInfoList || [],
            // batchList: batchList?.data || [],
            materialTypeList: knowledgeMaterialType?.data || [],
            bookTypeList: bookTypeList?.data || [],



        })

    }
    
    const [state, setState] = useState({
        batchName: "",
        batchStart: "",
        batchEnd: ""
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getKnowledgeMaterialType())
        dispatch(getBooks(requestData))
        // dispatch(getBookType(requestData))
        listFetching()
    }, [navigation]);

    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])
    
    useEffect(() => {
        if (getKnowledgeMaterialTypeSuccess) {
            setState({
                ...state,
                materialTypeList: [...getKnowledgeMaterialTypeList]
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_KNOWLEDGE_TYPE_MATERIAL" })
        } else if (getKnowledgeMaterialTypeFailure) {
            setState({
                ...state,
                unitTypeList: []
            })
            showMessage("warning", knowledgeMaterialTypeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_KNOWLEDGE_TYPE_MATERIAL" })
        }
    }, [getKnowledgeMaterialTypeSuccess, getKnowledgeMaterialTypeFailure]);

    useEffect(() => {
        if (getBookSuccess) {
            setItemList(getBookList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BOOKS" })
        } else if (getBookFailure) {
            setItemList([])
            showMessage("warning", bookErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BOOKS" })
        }
    }, [getBookSuccess, getBookFailure]);

    useEffect(() => {
        if (getBookTypeSuccess) {
            setState({
                ...state,
                bookTypeList: getBookTypeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BOOK_TYPE" })
        } else if (getBookTypeFailure) {
            setState({
                ...state,
                bookTypeList: []
            })
            showMessage("warning", bookTypeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BOOK_TYPE" })
        }
    }, [getBookTypeSuccess, getBookTypeFailure]);

    useEffect(() => {
        if (createBookSuccess) {
            const temp_state = [...itemList, createBookData[0]];
            setItemList(temp_state)
            closeModule()
            setLoaderModule(false)
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_BOOKS" })
        } else if (createBookFailure) {
            setLoaderModule(false)
            showMessage("warning", bookErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_BOOKS" })
        }
    }, [createBookSuccess, createBookFailure]);

    useEffect(() => {
        if (updateBookSuccess) {
            updateTable(updateBookData[0])
            dispatch({ type: "RESET_UPDATE_BOOKS" })
        } else if (updateBookFailure) {
            setLoaderModule(false)
            showMessage("warning", bookErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_BOOKS" })
        }
    }, [updateBookSuccess, updateBookFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.booksId != updatedItem.booksId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            bookName: "",
            url: '',
            title: '',
            shortDescription: '',
            authorName: "",
            edition: "",
            material: "",
            bookTypeId: "",
            knowledgeMaterialTypeId: "",
            file: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    let isurl = ''
    function isValidURL(string) {
        try {
            isurl = new URL(string);
            return true;
        } catch (err) {
            return false;
        }
    }

    const onEditForm = (data, index) => {
        let id = data?.knowledgeMaterialTypeId || 0
        if (id == '1') {
            bookForm[0].formBody[2].formField[2].disabled = true;
            bookForm[0].formBody[2].formField[1].disabled = false;
            // name = "url"
        } else if (id == '2') {
            bookForm[0].formBody[2].formField[2].disabled = false;
            bookForm[0].formBody[2].formField[1].disabled = true;
            // name = "imageName"
        }
        setState({
            ...state,
            isUrl: id === '2' ? true : false,
        })
        let isUrl = isValidURL(data.material)

        setState({
            ...state,
            bookName: data?.name || '',
            authorName: data?.authorName || '',
            edition: data?.edition || '',
            title: data?.title || '',
            url: isUrl ? data?.material || '' : '',
            imageName: !isUrl ? data?.material || '' : '',
            knowledgeMaterialTypeId: data?.knowledgeMaterialTypeId || '',
            bookTypeId: data?.bookTypeId || '',
            shortDescription: data?.shortDescription || '',
            /*  batchName: data?.batchName || "",
             batchStart: data?.batchStart || "",
             batchEnd: data?.batchEnd || "" */
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            name: state?.bookName || '',
            title: state?.title || '',
            authorName: state?.authorName || '',
            shortDescription: state?.shortDescription || '',
            edition: state?.edition || '',
            "material": state.isUrl ? (state?.url || state.imageName) : (state?.imageName || state.url),
            bookTypeId: state?.bookTypeId || 0,
            materialTypeId: state?.knowledgeMaterialTypeId || 0,

        }
        let current = state.file || ""
        console.log(current)
        let selectedMaterial = selectedItem?.material || ""



        if (current.name === selectedMaterial) {
            setState({
                ...state,
                file: ""
            })
        } else if (current == "") {
            setState({
                ...state,
                file: ""
            })



        } else {

            formDataAppend(state?.file || "")

        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateBooks(requestDate, selectedItem.booksId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: false
            }
            setLoaderModule(true)
            dispatch(updateBooks(deleteRequest, selectedItem.booksId))
        } else {
            setLoaderModule(true)
            dispatch(createBooks(requestDate))
        }
    }

    const data = bookForm

    const onSelectMaterialType = (event ,fieldname) => {
        let name = ''
        if (event === 1 || event === 3) {
            data[0].formBody[2].formField[2].disabled = true;
            data[0].formBody[2].formField[1].disabled = false;
            name = "url"
        } else if (event === 2) {
            data[0].formBody[2].formField[2].disabled = false;
            data[0].formBody[2].formField[1].disabled = true;
            name = "imageName"
        }
        setState({
            ...state,
            [name]: '',
            isUrl: event === 2 ? true : false,
            [fieldname]: event
        })
    };

    const onWriteDocument = (event) => {
        let file = event.target.files[0];
        let fileName = file.name;
        const now = new Date();
        const year = now.getFullYear().toString();
        const fileExtension = fileName?.split('.').pop() || "";
        const uniqueIdentifier = Math.floor(1000 + Math.random() * 9000).toString();
        const day = now.getDate().toString().padStart(2, '0');
        const docName = `${year}${day}${uniqueIdentifier}.${fileExtension}`;
        const renamedFile = new File(
            [file],
            docName,
            {
                type: file?.type,
                name: docName,
                lastModified: file?.lastModified,
            }
        );
        // formDataAppend(renamedFile)
        setState({
            ...state,
            [event.target.name]: docName,
            file: renamedFile

        })
    };


    const formDataAppend = (data) => {
        const formData = new FormData();
        formData.append('document', data);
        console.log(data.name);
        // return
        dispatch(uploadFiles(formData, "books"));
    }
    const {
        batchName,
        batchStart,
        batchEnd
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const handleCloseModal = () => {
        setCreateModule(false);
    };

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const { sidebarOpen } = useSidebar();

    return (
        <div>
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
                <View isOpen={createModule} size="lg" savetitle={modelButtonLbl} onClose={handleCloseModal} onSubmit={validateForm} isLoading={loaderModule} title={`${modelTitle} Book`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={bookForm} noOfColumn={3} onSubmit={onSubmitForm} onChangeCallback={{ "onSelectMaterialType": onSelectMaterialType, "onWriteDocument": onWriteDocument }} />
                </View>


                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} isLoading={loaderModule} title="Delete Book">
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>
                </View>

                <SimpleCard >
                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Book"} columns={column} data={itemList} initialPage={1} />
                </SimpleCard>

            </div>
        </div>
    )
};

export default Book;
