import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import TableLayout from "app/components/render/formlayout/tablelayout";
import CollapseView from "app/components/atoms/collapse-view/CollapseView";
import { Table } from "react-bootstrap";
import { dateConversion } from "app/utils/app-functions";
import { getExamVariant } from "app/api/ExamVariant";
import { getQuiz, createQuiz, updateQuiz } from "app/api/QuizApi";
import { getStudentClassList } from "app/api/StudentListApi";
import Alert from "app/components/atoms/alert";
import { timeConversion } from "app/utils/app-functions";
import { FiEdit } from "react-icons/fi";
import moment from "moment";
import dayjs from 'dayjs';
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { getAsyncStudentClassList } from "app/api/StudentListApi";
import ChatNew from "app/components/atoms/chat-new/ChatNew";
import { getAppMenu } from "app/api/AppMenuApi";
import { AppMenuForm } from "./formData";
import AppImageCropper from "app/components/AppImageCropper";
import { createAppMenu } from "app/api/AppMenuApi";
import { uploadDocument, uploadFiles } from "app/api/DocumentApi";
import { docPath, profileImages, imagePath, studentPath } from "app/utils/constant";
import { updateAppMenu } from "app/api/AppMenuApi";

let editData = false;
const { v4: uuidv4 } = require('uuid');

const Appmenu = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();
    const purchaseFormRef = useRef()
    const jobHistoryModel = useRef();

    const getAppMenuSuccess = useSelector((state) => state.appMenuReducer.getAppMenuSuccess);
    const getAppMenuList = useSelector((state) => state.appMenuReducer.getAppMenuList);
    const getAppMenuFailure = useSelector((state) => state.appMenuReducer.getAppMenuFailure);





    const createAppMenuSuccess = useSelector((state) => state.appMenuReducer.createAppMenuSuccess);
    const createAppMenuData = useSelector((state) => state.appMenuReducer.createAppMenuData);
    const createAppMenuFailure = useSelector((state) => state.appMenuReducer.createAppMenuFailure);

    const updateAppMenuSuccess = useSelector((state) => state.appMenuReducer.updateAppMenuSuccess);
    const updateAppMenuData = useSelector((state) => state.appMenuReducer.updateAppMenuData);
    const updateAppMenuFailure = useSelector((state) => state.appMenuReducer.updateAppMenuFailure);


    const getStudentClassListSuccess = useSelector((state) => state.studentListReducer.getStudentClassListSuccess);
    const getStudentClassListList = useSelector((state) => state.studentListReducer.getStudentClassListList);
    const getStudentClassListFailure = useSelector((state) => state.studentListReducer.getStudentClassListFailure);
    const studentClassListErrorMessage = useSelector((state) => state.studentListReducer.errorMessage);


    const getEnrollmentDetailsSuccess = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsSuccess);
    const getEnrollmentDetailsList = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsList);
    const getEnrollmentDetailsFailure = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsFailure);
    const enrollmentDetailsErrorMessage = useSelector((state) => state.enrollmentDetailsReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Super Admin", "App Menu")
        setpageItems(data)
    }, [navigation]);


    const column = [
        {
            "dataField": "title",
            "text": "Title",
            "sort": true
        },

        {
            "dataField": "navigate",
            "text": "Navigate",
            "sort": true,

        },

    ]


    if (pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}


                </div>
            )
        },)
    }




    const [state, setState] = useState({
        deletedItems: [],
        purchaseName: "",
        description: "",
        customerList: [],
        /*   radioList:[{
                      option: 1,
                      name: "name",
                  },
              ], */
        currencyList: [],
        venderList: [],
        leadSourceTypeList: [],
        supplierList: [],
        warehouseList: [],
        locationList: [],
        materialTypeList: [],
        materialCodeList: [],
        deliveryList: [],
        unitsList: [],
        taxCode: [],
        contactPerson: '',
        qty: '',
        UnitId: '',
        unitPrice: '',
        totalId: '',
        totalTax: '',
        purchaseOrderDate: new Date(),
        deliveryOrderDate: new Date(),
        deliveryId: '',
        phoneNumber: '',
        emailId: '',
        deliveryAddress: '',
        contactPersonName: '',
        inVoiceData: '',
        permitId: '',
        shipVia: '',
        fob: '',
        debitTerm: '',
        memo: "",
        logisticId: '',
        supplierId: '',
        materialCodeId: '',
        currencySymbol: '',
        deliveryOrderDate: '',
        postCode: '',
        supplierCode: '',
        primaryNumber: '',
        emailCode: '',
        addressCode: '',
        suplierNameValue: '',
        secondaryPhone: '',
        contact: '',
        materialId: '',
        comment: '',
        footerData: [
            { label: 'SubTotal :', value: '0' },
            { label: 'Tax:', value: '0' },
            { label: 'Total:', value: '0' }
        ],
        insuranceAmount: "",
        logisticAmount: "",
        handlingCost: "",
        otherCost: "",
        checkCount: '',
        filterData: [],
        deletedItems: [],
        radioList: []



    });




    const [tableRows, setTableRows] = useState([]);
    const [createModule, setCreateModule] = useState(false);
    const [createForm, setCreateForm] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemAdded, setItemAdded] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [uploadModule, setUploadModule] = useState(false);
    const [list, setList] = useState([])
    const [id, setId] = useState([])
    const [currencySymbol, setCurrencySymbol] = useState('')
    const [showForm, setShowForm] = useState(false)
    const [selectedmaterial, setSelectMaterial] = useState([])
    const [results, setResults] = useState([]);
    const [filteredObjects, setFilteredObjects] = useState([]);
    const [deletedRows, setDeletedRows] = useState([]);


    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }

        let enrollData = await getAsyncStudentClassList();
        setState({
            ...state,
            enrollmentDetailsList: enrollData?.data || [],
        })

    }



    useEffect(() => {

        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getAppMenu())




    }, [navigation]);


    useEffect(() => {
        if (getAppMenuSuccess) {
            setItemList(getAppMenuList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_APP_MENU" })
        } else if (getAppMenuFailure) {
            setState({
                ...state,
                examVariantList: []
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_APP_MENU" })
        }
    }, [getAppMenuSuccess, getAppMenuFailure]);


    useEffect(() => {
        if (createAppMenuSuccess) {
            const temp_state = [...itemList, createAppMenuData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_APP_MENU" })
        } else if (createAppMenuFailure) {
            setLoaderModule(false)
            //   showMessage("warning", purchaseErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_APP_MENU" })
        }
    }, [createAppMenuSuccess, createAppMenuFailure]);


    useEffect(() => {
        if (updateAppMenuSuccess) {
            updateTable(updateAppMenuData[0])
            dispatch({ type: "RESET_UPDATE_APP_MENU" })
        } else if (updateAppMenuFailure) {
            setLoaderModule(false)
            //   showMessage("warning", purchaseErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_APP_MENU" })
        }
    }, [updateAppMenuSuccess, updateAppMenuFailure]);



    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.appMenuId != selectedItem.appMenuId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const handleCloseModal = () => {
        setCreateModule(false);
    };

    const resetState = () => {
        setState({
            ...state,
            profilePic: "",
            title: "",
            navigate: "",
            isNew: false,
            profilePicture: ""

        });

        setCurrencySymbol([])



        setTableRows([]);

    };



    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        const profile = `${imagePath}app/icons/${(data?.icon || "")}`;
        console.log(profile)



        const purchaseOrderItem = data?.quizDetails || [];
        setState({
            ...state,
            title: data?.title || "",
            profilePic: profile,
            navigate: data?.navigate || "",
            isNew: false,
            profilePicture: data?.icon



        })

        editData = true;
        setSelectedIndex(index);
        setSelectedItem(data);
        setCreateModule(true);
        setItemAdded(true)
    };


    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedItem(data)
        setSelectedIndex(index)
        setDeleteModule(true)
    }

    const itemHeader = [
        {
            "dataField": "index",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return index + 1
            }
        },

        {
            "dataField": "status",
            "text": "",
            formatter: (cellContent, row, index, extraData) => {
                const conInfo = { inputType: "checkbox", status: cellContent }
                return formRender(extraData, null, conInfo, null, null, statusChange, index);
            }
        },

        {
            "dataField": "materialId",
            "text": "Material Id",
            "sort": true
        },
        {
            "dataField": "materialCode",
            "text": "Material Code",
            "sort": true
        },
        {
            "dataField": "materialName",
            "text": "Material Name",
            "sort": true
        },



    ]

    const confirmForm = (index) => {
        setShowForm(false)

    }


    const onDeletePress = (itemIndex) => {
        let temp_state = [...tableRows];
        let addd = tableRows.splice(itemIndex, 1);
        let remainingList = _.remove(temp_state, function (n, index) {
            return index != itemIndex
        });
        setTableRows(remainingList);
        let temp_arr = { ...state }
        setState({
            ...state,
            deletedItems: [...temp_arr.deletedItems, ...addd]
        })
    };


    const modelTitle = editData ? "Edit " : "Create ";
    const modelTitle2 = editData ? "Edit " : "Create New ";
    const modelButtonLbl = editData ? "" : "Save";

    var itemCount = 0;
    const addRow = () => {

        const defaultNewOrderObject = {
            itemCount: 0,
            question: '',
            answer: '',


            quizDetails: [

            ]
        };
        itemCount++;
        setTableRows([...tableRows, defaultNewOrderObject]);
        setItemAdded(false)
    };

    const statusChange = (event, index, extraData) => {
        const newSelectedMaterial = [...selectedmaterial];
        let tempState = [...tableRows];

        const isChecked = event.target.checked;

        if (isChecked && newSelectedMaterial.some((material, i) => material.status && i !== index)) {
            Alert({ description: 'You Cannot Select Multiple Objects.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            event.preventDefault();
            return;
        }

        // Update the status of selected material
        newSelectedMaterial.forEach((material, i) => {
            material.status = (i === index) ? isChecked : false;
        });

        const updatedObject = newSelectedMaterial[index];

        const materialObject = state?.materialCodeList || [];
        const foundObjects = [];

        const foundObject = materialObject.find(item => item.materialId === updatedObject.materialId);
        if (foundObject) {
            foundObjects.push(foundObject);
        }

        setState((prevState) => ({
            ...prevState,
            updatedObject: updatedObject,
            foundObjects: foundObjects
        }));

        setSelectMaterial(newSelectedMaterial);

        // Update the table data
        setTableRows((prevTableRows) => {
            const updatedRows = [...prevTableRows];
            const indexx = state.index || 0;
            if (indexx < updatedRows.length && foundObject) {
                updatedRows[indexx] = {
                    ...updatedRows[indexx],
                    materialCodeId: foundObjects,
                    materialName: updatedObject.materialName,
                    materialId: updatedObject.materialId
                };
            }
            return updatedRows;
        });
    };



    const onSubmitItemDetails = (index) => {
        const temp_state = [...tableRows];
        const selectedRow = temp_state[index];

        if (selectedRow) {
            const quizDetails = selectedRow.quizDetails || [];
            const answer = selectedRow.answer || 0;

            let updatedItemDetails = quizDetails.map((item) => ({
                ...item,
                answer: answer,
            }));

            temp_state[index].quizDetails = updatedItemDetails;
            setTableRows(temp_state);
        }
    }


    const onDeleteInner = (detailIndex, parentIndex) => {
        const updatedTableRows = [...tableRows];
        updatedTableRows[parentIndex].quizDetails.splice(detailIndex, 1);
        setTableRows(updatedTableRows);
    }



    const handleChange = (event, name, index, parentIndex) => {

        event.persist()
        const Value = event.target.value
        let temp_state = [...tableRows]
        temp_state[parentIndex]["quizDetails"][index][name] = Value;

        setTableRows(temp_state)

        // console.log(JSON.stringify(state.radioList))
    }

    const handleTypeChange = (event, name, index, parentIndex) => {
        // console.log(event + " ==== " + index + " ---- " + stateName + " === " + name + " ==== " + parentIndex)
        let temp_state = [...tableRows];
        let checked = event.target.checked;

        temp_state[parentIndex]["quizDetails"].forEach((item, i) => {
            if (i !== index) {
                item[name] = false;
            }
        });

        temp_state[parentIndex]["quizDetails"][index][name] = checked;

        if (checked == true) {
            temp_state[parentIndex].answer = temp_state[parentIndex]["quizDetails"][index].answer;
        } else if (checked == false) {
            temp_state[parentIndex].answer = ""

        }

        setTableRows(temp_state);
    };



    const handlelocationchange = (event, index, name) => {
        let temp_state = [...tableRows]
        temp_state[index][event.target.name] = event.target.value
        setTableRows(temp_state)

    }


    const onAddItem = (index, parentIndex) => {
        const temp_state = [...tableRows];
        let itemIndex = temp_state[index].quizDetails[index]
        const qtyVal = temp_state[index].qty;
        const prevQty = temp_state[index].quizDetails.length || 0;
        let newItemDetails = [];
        const diff = qtyVal - prevQty;
        if (diff > 0) {
            for (let i = 0; i < diff; i++) {
                const defaultItem = {
                    option: "",
                    answer: "",

                };
                newItemDetails.push(defaultItem);
            }
            /*  setState({
                 ...state,radioList:[{
                     option: 1,
                     name: "name",
                 }]
 
             }) */
            temp_state[index].quizDetails = [...temp_state[index].quizDetails, ...newItemDetails];
            temp_state[index].prevQty = qtyVal;
            setTableRows(temp_state)
            setItemAdded(true);
        } else {
            Alert({ description: 'New quantity must be greater than the previous ones.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

        }
    };






    const convertToPurchaseOrderItem = (value) => {
        const updateData = selectedItem ? selectedItem : {};

        let temp_arr = [];
        let quizMeta = [];

        try {
            if (!Array.isArray(value)) {
                throw new Error("Expected 'value' to be an array.");
            }

            value.forEach((row, index) => {
                console.log(JSON.stringify(row));

                const req = {
                    question: row?.question || null,
                    qty: row.qty || null,
                    answer: row.answer || null,
                    quizDetailsId: row.quizDetailsId || null,

                    quizMeta: (row.quizDetails || []).map((data, ind) => ({
                        id: ind + 1,
                        title: data?.answer || "",
                        option: data?.option || "",
                    }))
                }
                temp_arr.push(req);
                quizMeta.push(req.quizMeta);
            });

            setState((prevState) => ({
                ...prevState,
                radioList: quizMeta
            }));

            console.log(JSON.stringify(temp_arr));
        } catch (error) {
            console.error("Error occurred while converting to purchase order item:", error.message);
        }

        return temp_arr;
    };

    const formDataAppend = (data) => {
        const formData = new FormData();
        let fileName = data.name;
        const now = new Date();
        const uid = uuidv4();
        const fileExtension = fileName?.split('.').pop() || "";
        const year = now.getFullYear().toString();
        const uniqueIdentifier = Math.floor(1000 + Math.random() * 9000).toString();
        const day = now.getDate().toString().padStart(2, '0');
        const docName = `${year}${day}${uniqueIdentifier}.${fileExtension}`;
        const renamedFile = new File(
            [data],
            docName,
            {
                type: data?.type,
                name: docName,
                lastModified: data?.lastModified,
            }
        );

        setState(prevState => ({
            ...prevState,
            renamedFile: renamedFile,
            profilePicture: docName,
            isNew: true
        }));

        formData.append('document', renamedFile);
        // return
        dispatch(uploadFiles(formData, `app/icons`));

        return renamedFile
    }


    const onSubmitForm = () => {
        let picture = '';
        console.log(state.profilePicFileType)
        if (state?.isNew) {
            picture = formDataAppend(state.profilePicFileType);
        }

        const requestData = {
            "title": state?.title || "",
            "icon": picture?.name || state?.profilePicture,
            "navigate": state?.navigate || "",

        };

        console.log(JSON.stringify(requestData));
        // return


        if (editData) {
            setLoaderModule(true);
            dispatch(updateAppMenu(requestData, selectedItem.appMenuId));

        } else if (deleteModule) {
            const deleteRequest = { isActive: "false" };
            setLoaderModule(true);
            dispatch(updateQuiz(deleteRequest, selectedItem.quizId));

        } else {
            setLoaderModule(true);
            dispatch(createAppMenu(requestData));
        }

    };


    const materialListView = (index) => {

        setShowForm(true);
        setState({
            ...state,
            index: index
        })
    }

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const { sidebarOpen } = useSidebar();

    const dummyBotData = {
        initialMessages: [
            { type: 'text', content: 'Hi there! How can I help you today?' },
        ],
        config: {
            botName: 'MyChatBot',
            initialMessages: [{ type: 'text', content: 'Welcome!' }],
        },
        actionProvider: {
            handleMessage: (message) => {
                console.log("User message:", message);
            }
        },
        messageParser: {
            parse: (message) => {
                console.log("Parsing message:", message);
            }
        }
    };
    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onFind = () => {
        setUploadModule(true)
    }

    return (
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "40px", overflowY: "hidden" } : { marginLeft: "10px", marginTop: "40px", marginBottom: "70px", overflowY: "hidden" }}>
            <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={handleCloseModal} onSubmit={validateForm} isLoading={loaderModule} title={`${modelTitle} App Menu`}>
                <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={AppMenuForm} noOfColumn={1} onSubmit={onSubmitForm} onSearch={() => onFind()} />
            </View>

            <View size={'md'} isOpen={uploadModule} savetitle="upload" onClose={setUploadModule} title="Upload Image">
                <AppImageCropper state={state} setState={setState} close={setUploadModule}></AppImageCropper>
            </View>

            <SimpleCard >
                <CustomTable
                    columns={column}
                    data={itemList}
                    initialPage={1}
                    buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick}
                    tableName={"App Menu"}
                />
            </SimpleCard>

        </div>
    )
};

export default Appmenu;
