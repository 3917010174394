import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Styled components for the container, select dropdowns, and labels
const TimePickerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  height:40px;
`;

const TimeSelect = styled.select`
  padding: 3px 3px;
  border-radius: 5px;
  width: 70px;
  text-align: center;

  &::-webkit-scrollbar {
    display: none;
  }

  &:focus {
    border-color: #4c90e0;
    outline: none;
  }
`;

const DurationDisplay = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  color: #333;
  text-align: center;
`;

const DurationPicker = ({ initialHours, initialMinutes, initialSeconds, onChangeDuration,disabled  }) => {
  console.log(initialHours)
  const [hours, setHours] = useState(Number(initialHours) || 0);
  const [minutes, setMinutes] = useState(Number(initialMinutes) || 0);
  const [seconds, setSeconds] = useState(Number(initialSeconds) || 0);

  // Update state when props change
  useEffect(() => {
    setHours(Number(initialHours) || 0);
    setMinutes(Number(initialMinutes) || 0);
    setSeconds(Number(initialSeconds) || 0);
  }, [initialHours, initialMinutes, initialSeconds]);


  // Handle change for hours, minutes, and seconds
  const handleHoursChange = (e) => {
    const newHours = Number(e.target.value);
    setHours(newHours);
    onChangeDuration(newHours, minutes, seconds);
  };

  const handleMinutesChange = (e) => {
    const newMinutes = parseInt(e.target.value, 10);
    setMinutes(newMinutes);
    onChangeDuration(hours, newMinutes, seconds);
  };

  const handleSecondsChange = (e) => {
    const newSeconds = parseInt(e.target.value, 10);
    setSeconds(newSeconds);
    onChangeDuration(hours, minutes, newSeconds);
  };


  // Display the selected duration

  return (
    <div>
      <TimePickerContainer>
        <TimeSelect value={Number(hours)} onChange={handleHoursChange} disabled={disabled}>
          {[...Array(24).keys()].map((hour) => (
            <option key={hour} value={hour}>
              {hour}hr
            </option>
          ))}
        </TimeSelect>
        <span>:</span>
        <TimeSelect value={minutes} onChange={handleMinutesChange} disabled={disabled}>
          {[...Array(60).keys()].map((minute) => (
            <option key={minute} value={minute}>
              {minute}mm
            </option>
          ))}
        </TimeSelect>
        <span>:</span>
        <TimeSelect value={seconds} onChange={handleSecondsChange} disabled={disabled}>
          {[...Array(60).keys()].map((second) => (
            <option key={second} value={second}>
              {second}ss
            </option>
          ))}
        </TimeSelect>
      </TimePickerContainer>
    </div>
  );
};

export default DurationPicker;
