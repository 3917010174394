import React from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { dateConversion } from "app/utils/app-functions";
import { imagePath, unitPath } from "app/utils/constant";

const CircularDetails = ({ data = {}, unitDetails = [], schoolInfo = [] }) => {
  const circularData = data;
  const unitImage = `${imagePath}${unitPath}${unitDetails[0]?.unitToken}/unitinfo/${encodeURI(unitDetails[0]?.image)}`;

  if (!circularData || !Object.keys(circularData).length) {
    return <div>No circular data available</div>;
  }

  const handleExportPDF = async () => {
    const element = document.querySelector(".circular-container"); // Select the container
    if (!element) return;

    // Capture the container as an image, ignoring specific elements
    const canvas = await html2canvas(element, {
      scale: 2, // High-quality scale
      useCORS: true, // Enable cross-origin images
      ignoreElements: (el) => el.classList.contains("export-icon"), // Exclude elements with the 'export-icon' class
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("circular-details.pdf");
  };


  return (
    <div className="circular-container">
      {/* Header Section */}
      <header className="header">
        <div className="school-logo">
          <img
            style={{
              width: "50px",
              height: "50px",
              transform: "scale(2.0)",
              marginLeft: "10px",
              marginTop:"-20px"
            }}
            src={unitImage || "/default-logo.png"}
            alt="School Logo"
          />
        </div>
        <div className="school-info">
          <h1>{schoolInfo[0]?.schoolName || "School Name"}</h1>
          <p>
            {schoolInfo[0]?.phoneNumber || "N/A"} | {schoolInfo[0]?.email || "N/A"}
          </p>
        </div>
        <div className="export">
          <FaCloudDownloadAlt
            onClick={handleExportPDF}
            className="export-icon"
            title="Download PDF"
          />
        </div>
      </header>

      {/* Body Section */}
      <main className="body">
        <section className="profile-section">
          <h2>{circularData.title || "Circular Title"}</h2>
          <p>{dateConversion(circularData.startDate, "DD-MMM-YYYY") || "Not provided"}</p>
        </section>

        <section className="content-section">
          <div dangerouslySetInnerHTML={{ __html: circularData.description || "No details available" }} />
        </section>
      </main>

      {/* Footer Section */}
      <footer className="footer">
        <p>Signature of Principal / In-charge</p>
      </footer>

      {/* Inline Styles */}
      <style jsx>{`
        .circular-container {
          font-family: Arial, sans-serif;
          color: #333;
          background-color: #fff;
          padding: 20px;
          border-radius: 8px;
          max-width: 900px;
          margin: 20px auto;
        }

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #ddd;
          padding-bottom: 10px;
          margin-bottom: 20px;
        }

        .school-logo img {
          width: 60px;
          height: 60px;
        }

        .school-info {
          flex: 1;
          text-align: center;
        }

        .school-info h1 {
          font-size: 24px;
          margin: 0;
          color: #1e3d58;
          margin-right:50px;

        }

        .school-info p {
          margin: 5px 0 0;
          font-size: 14px;
          color: #555;
          margin-right:50px;

        }

        .export {
          text-align: right;
        }

        .export-icon {
          font-size: 24px;
          color: #007BFF;
          cursor: pointer;
        }

        .profile-section {
          text-align: center;
          margin-bottom: 20px;
        }

        .profile-section h2 {
          margin: 0;
          color: #1e3d58;
        }

        .profile-section p {
          color: #555;
          font-size: 14px;
        }

        .content-section {
          margin-bottom: 20px;
          padding-left: 20px; /* Add space to the left */
        }

        .content-section div {
          line-height: 1.6;
          text-align: justify; /* Optional for better readability */
        }

        .footer {
          text-align: right;
          margin-top: 30px;
          font-weight: bold;
          color: #333;
        }
      `}</style>
    </div>
  );
};

export default CircularDetails;
