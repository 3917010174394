const initialState = {
    chatMessageList: []  
  }
  
  const ChatMessageReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_CHAT_MESSAGE_SUCCESS": {             
        return {
          ...state,
          getChatMessageSuccess: true,
          getChatMessageList: action.payload.data,
        };
      }
      case "GET_CHAT_MESSAGE_FAILURE": {
        return {
          ...state,
          getChatMessageFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_CHAT_MESSAGE": {
        return {
          ...state,
          getChatMessageSuccess: false,
          getChatMessageFailure: false,
          getChatMessageList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_CHAT_MESSAGE_SUCCESS": {             
        return {
          ...state,
          createChatMessageSuccess: true,
          createChatMessageData: action.payload.data,
        };
      }
      case "CREATE_CHAT_MESSAGE_FAILURE": {
        return {
          ...state,
          createChatMessageFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_CHAT_MESSAGE": {
        return {
          ...state,
          createChatMessageSuccess: false,
          createChatMessageFailure: false,
          createChatMessageData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_CHAT_MESSAGE_SUCCESS": {             
        return {
          ...state,
          updateChatMessageSuccess: true,
          updateChatMessageData: action.payload.data,
        };
      }
      case "UPDATE_CHAT_MESSAGE_FAILURE": {
        return {
          ...state,
          updateChatMessageFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_CHAT_MESSAGE": {
        return {
          ...state,
          updateChatMessageSuccess: false,
          updateChatMessageFailure: false,
          updateChatMessageData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ChatMessageReducer
  
  