import { lightTheme } from "styles/theme";

export const buttons = {
  add: {
    name: 'Add',
    color: lightTheme.primaryColor, // Assuming lightTheme.bg is a valid color
    size: 'md', // default size
  },
  rejoin: {
    name: 'Rejoin',
    color: lightTheme.primaryColor, // Assuming lightTheme.bg is a valid color
    size: 'md', // default size
  },
  submit: {
    name: 'Submit',
    color: lightTheme.primaryColor, // Assuming lightTheme.bg is a valid color
  },
  create: {
    name: 'Create',
    color: lightTheme.primaryColor, // Assuming lightTheme.bg is a valid color
  },
  update: {
    name: 'Update',
    color: lightTheme.primaryColor, // Assuming lightTheme.bg is a valid color
  },
  excel: {
    name: 'Excel',
    color: lightTheme.primaryColor, // Assuming lightTheme.bg is a valid color
    size: 'md',
  },
  delete: {
    name: 'Delete',
    color: lightTheme.primaryColor, // Assuming lightTheme.bg is a valid color
  },
  checkIn: {
    name: 'CheckIn',
    color: lightTheme.primaryColor, // Assuming lightTheme.bg is a valid color
    textColor: 'white',
    size: 'md',
  },
  checkOut: {
    name: 'CheckOut',
    color: 'white', // Assuming lightTheme.bg is a valid color
    textColor: 'grey',
    border: '1px solid gray',
    size: 'md',
  },
  selectedStudents: {
    name: 'Select Student',
    color: lightTheme.primaryColor, // Assuming lightTheme.bg is a valid color
    textColor: 'white',
    border: '1px solid gray',
    size: 'md',
  },
};
