import React from 'react';
import './styles.css';
import { Select as AntdSelect } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SelectProps from './props';

const classNames = require('classnames');

const { Option, OptGroup } = AntdSelect;

const Select = ({ options, suggested, optionValKey, info, name, position, optionLabelKey, optionLabelFn, size, height, items, value, placeholder, ...props }) => {
    const [t] = useTranslation('common');
    const className = classNames({
        'ax-select': true,
        [position]: position,
        [size]: size,
        [height]: height,
        [items]: items
    });
    const allProps = { className, ...props };

    const suggestedOptions = [];
    const otherOptions = [];

    if (suggested) {
        options.forEach(option => {
            if (option.suggested === true) {
                suggestedOptions.push(option);
            }
        });
        options.forEach(option => {
            if (option.suggested === false) {
                otherOptions.push(option);
            }
        });
    }
    // console.log("value ---" + value);

    return (

        <AntdSelect
            {...allProps}
            style={{
                width: '100%',
                minWidth: '90px',
                marginBottom: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            }}

            placeholder={placeholder || t('-select-')}
            maxTagCount={info?.limit || "responsive"}
            maxCount={info?.limit || "responsive"}
            value={value == '' ? [] : value}
            name={name}
            disabled={info?.disabled || false}
            mode={info?.multiple ? 'multiple' : ''}
            // disabled={info?.disabled || false}
            allowClear
            filterOption={(input, option) => {
                const children = option.props.children || []; // Default to empty array if null or undefined
                return children.some(child =>
                    typeof child === 'string' && child.toLowerCase().includes(input.toLowerCase())
                );
            }}
            dropdownRender={menu => (
                <div title={value}> {/* Tooltip effect */}
                    {menu}
                </div>
            )}


        >
            {
                suggested ?
                    <>
                        <OptGroup label="Suggested">
                            {suggestedOptions.map((val, index) => {
                                let label = val[optionLabelKey];
                                if (optionLabelFn) {
                                    label = optionLabelFn(val);
                                }
                                const optionProps = { key: val[optionValKey], value: val[optionValKey], name: name };
                                if (val.disabled) optionProps.disabled = val.disabled;
                                return <Option {...optionProps}>{label}</Option>;
                            })}
                        </OptGroup>
                        <OptGroup label="Others">
                            {otherOptions.map((val, index) => {
                                let label = val[optionLabelKey];
                                if (optionLabelFn) {
                                    label = optionLabelFn(val);
                                }
                                const optionProps = { key: val[optionValKey], value: val[optionValKey] };
                                if (val.disabled) optionProps.disabled = val.disabled;
                                return <Option {...optionProps}>{label}</Option>;
                            })}
                        </OptGroup>
                    </>
                    :
                    <>
                        {options && options.map((val, index) => {
                            let label = val[optionLabelKey];
                            if (optionLabelFn) {
                                label = optionLabelFn(val);
                            }
                            const optionProps = { key: index, value: val[optionValKey] };
                            if (val.disabled) optionProps.disabled = val.disabled;
                            return <Option {...optionProps}>{label}{info.subDisplayKey && " - " + val[info.subDisplayKey]}</Option>;
                        })}
                    </>
            }
        </AntdSelect>




    );
};

Select.propTypes = {
    options: PropTypes.array.isRequired,
    size: PropTypes.oneOf([SelectProps.size.sm, SelectProps.size.default, SelectProps.size.md, SelectProps.size.lg, SelectProps.size.auto, SelectProps.size.xl]),
    position: PropTypes.oneOf([SelectProps.position.center, SelectProps.position.left, SelectProps.position.right]),
    optionValKey: PropTypes.string,
    optionLabelKey: PropTypes.string,
    optionLabelFn: PropTypes.func,
    showSearch: PropTypes.bool,
};
Select.defaultProps = {
    size: SelectProps.size.default,
    optionValKey: 'value',
    optionLabelKey: 'label',
    optionLabelFn: null,
    showSearch: true,
    position: SelectProps.position.left,
};

export default Select;
