const initialState = {
    homeworkSubmissionList: []  
  }
  
  const HomeworkSubmissionReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_HOMEWORK_SUBMISSION_SUCCESS": {             
        return {
          ...state,
          getHomeworkSubmissionSuccess: true,
          getHomeworkSubmissionList: action.payload.data,
        };
      }
      case "GET_HOMEWORK_SUBMISSION_FAILURE": {
        return {
          ...state,
          getHomeworkSubmissionFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_HOMEWORK_SUBMISSION": {
        return {
          ...state,
          getHomeworkSubmissionSuccess: false,
          getHomeworkSubmissionFailure: false,
          getHomeworkSubmissionList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_HOMEWORK_SUBMISSION_SUCCESS": {             
        return {
          ...state,
          createHomeworkSubmissionSuccess: true,
          createHomeworkSubmissionData: action.payload.data,
        };
      }
      case "CREATE_HOMEWORK_SUBMISSION_FAILURE": {
        return {
          ...state,
          createHomeworkSubmissionFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_HOMEWORK_SUBMISSION": {
        return {
          ...state,
          createHomeworkSubmissionSuccess: false,
          createHomeworkSubmissionFailure: false,
          createHomeworkSubmissionData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_HOMEWORK_SUBMISSION_SUCCESS": {             
        return {
          ...state,
          updateHomeworkSubmissionSuccess: true,
          updateHomeworkSubmissionData: action.payload.data,
        };
      }
      case "UPDATE_HOMEWORK_SUBMISSION_FAILURE": {
        return {
          ...state,
          updateHomeworkSubmissionFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_HOMEWORK_SUBMISSION": {
        return {
          ...state,
          updateHomeworkSubmissionSuccess: false,
          updateHomeworkSubmissionFailure: false,
          updateHomeworkSubmissionData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default HomeworkSubmissionReducer
  
  