import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import FormLayout from "app/views/formlayout";
import { timeTableFilterForm, filterForm } from "./formData";
import { MdEdit, MdVisibility, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import formRender from "app/utils/formcomponent";
import { createTimeTable, getTimeTable, getTimeTableInfo, updateTimeTable } from "app/api/TimeTableApi";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FilterComponent from "app/components/FilterComponent";
import { getBatch } from "app/api/BatchApi";
import { getEnrollmentDetails } from "app/api/EnrollementDetailsApi";
import { getSubject } from "app/api/SubjectApi";
import CustomAlignButton from "app/components/CustomAlignButton";
import { getPeriodTime, getAsyncPeriodTime } from "app/api/PeriodTimeApi";
import { getPeriodDays, getAsyncPeriodDays } from "app/api/PeriodDays";
import { getSubjectTeachers } from "app/api/SubjectTeachersApi";
import { getSubjectEnrollment, asyncgetSubjectEnrollment } from "app/api/SubjectEnrollmentApi";
import SimpleCard from "app/components/atoms/simple-card/SimpleCard";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import TimeTable from "app/components/atoms/timetable/TimeTable";
import SimpleBorderTable from "app/components/atoms/simple-border-table/SimpleTable";
import { asyncGetBatchInfo } from "app/api/BatchApi";
import { getAsyncStudentClassList } from "app/api/StudentListApi";
import Alert from "app/components/atoms/alert";
import { FiEdit, FiEye } from "react-icons/fi";
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { lightTheme } from "styles/theme";

let editData = false;
let viewData = false;

const Timetable = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();
    const timeTableModel = useRef();

    const getTimeTableSuccess = useSelector((state) => state.timeTableReducer.getTimeTableSuccess);
    const getTimeTableList = useSelector((state) => state.timeTableReducer.getTimeTableList);
    const getTimeTableFailure = useSelector((state) => state.timeTableReducer.getTimeTableFailure);

    const getTimeTableInfoSuccess = useSelector((state) => state.timeTableReducer.getTimeTableInfoSuccess);
    const getTimeTableInfoList = useSelector((state) => state.timeTableReducer.getTimeTableInfoList);
    const getTimeTableInfoFailure = useSelector((state) => state.timeTableReducer.getTimeTableInfoFailure);

    const getBatchSuccess = useSelector((state) => state.batchReducer.getBatchSuccess);
    const getBatchList = useSelector((state) => state.batchReducer.getBatchList);
    const getBatchFailure = useSelector((state) => state.batchReducer.getBatchFailure);
    const batchErrorMessage = useSelector((state) => state.batchReducer.errorMessage);

    const getPeriodDaysSuccess = useSelector((state) => state.periodDaysReducer.getPeriodDaysSuccess);
    const getPeriodDaysList = useSelector((state) => state.periodDaysReducer.getPeriodDaysList);
    const getPeriodDaysFailure = useSelector((state) => state.periodDaysReducer.getPeriodDaysFailure);
    const periodDaysErrorMessage = useSelector((state) => state.periodDaysReducer.errorMessage);

    const getPeriodTimeSuccess = useSelector((state) => state.periodTimeReducer.getPeriodTimeSuccess);
    const getPeriodTimeList = useSelector((state) => state.periodTimeReducer.getPeriodTimeList);
    const getPeriodTimeFailure = useSelector((state) => state.periodTimeReducer.getPeriodTimeFailure);
    const periodTimeErrorMessage = useSelector((state) => state.periodTimeReducer.errorMessage);

    const getSubjectEnrollmentSuccess = useSelector((state) => state.subjectEnrollmentReducer.getSubjectEnrollmentSuccess);
    const getSubjectEnrollmentList = useSelector((state) => state.subjectEnrollmentReducer.getSubjectEnrollmentList);
    const getSubjectEnrollmentFailure = useSelector((state) => state.subjectEnrollmentReducer.getSubjectEnrollmentFailure);
    const subjectEnrollmentErrorMessage = useSelector((state) => state.subjectEnrollmentReducer.errorMessage);

    const getEnrollmentDetailsSuccess = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsSuccess);
    const getEnrollmentDetailsList = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsList);
    const getEnrollmentDetailsFailure = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsFailure);
    const enrollmentDetailsErrorMessage = useSelector((state) => state.enrollmentDetailsReducer.errorMessage);


    const createTimeTableSuccess = useSelector((state) => state.timeTableReducer.createTimeTableSuccess);
    const createTimeTableData = useSelector((state) => state.timeTableReducer.createTimeTableData);
    const createTimeTableFailure = useSelector((state) => state.timeTableReducer.createTimeTableFailure);

    const updateTimeTableSuccess = useSelector((state) => state.timeTableReducer.updateTimeTableSuccess);
    const updateTimeTableData = useSelector((state) => state.timeTableReducer.updateTimeTableData);
    const updateTimeTableFailure = useSelector((state) => state.timeTableReducer.updateTimeTableFailure);

    const timeTableErrorMessage = useSelector((state) => state.timeTableReducer.errorMessage);

    const [pageItems, setpageItems] = useState({
        "view": false,
        "create": true,
        "update": false,
        "deleteOption": false
    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Student", "Time Table")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "index",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => (
                index + 1
            )
        },
        {
            "dataField": "batchName",
            "text": "Batch",
            "sort": true,
        },
        {
            "dataField": "",
            "text": "Class-Section",
            "sort": true,
            formatter: (cellContent, row, index) => (
                row.className + " - " + row.sectionName
            )
        }
    ]

    if (pageItems.update || pageItems.view) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.view && <MdVisibility
                        className="text-success cursor-pointer"
                        size={18}
                        onClick={() => onView(row, index)}
                    ></MdVisibility>}

                </div>
            )
        })
    }
    const [state, setState] = useState({
        timeTableName: "",
        timeTableStart: "",
        timeTableEnd: "",
        periodTimeList: [],
        periodDaysList: [],
        timeTableInfo: []
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [timeTableModule, setTimeTableModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);


    let userInfo = window.localStorage.getItem("userInfo");
    const req1 = {
        isActive: "true",
    };
    let parsedData = JSON.parse(userInfo);

    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }
        const req = {
        };
        const reqq = {
            examVariantId: 1
        };
        if (parsedData[0].roleId == 5) {
            req.empCode = parsedData[0]?.userCode || '';
        }

        let batchList = await asyncGetBatchInfo(requestData)
        let enrollData = await getAsyncStudentClassList();
        let timeData = await getAsyncPeriodTime(req);
        let periodData = await getAsyncPeriodDays(req);

        setState({
            ...state,
            // schoolList:schoolInfoList?.getSchoolInfoList || [],
            batchList: batchList?.data || [],
            enrollmentDetailsList: enrollData?.data || [],
            periodTimeList: timeData?.data || [],
            periodDaysList: periodData?.data || [],


        })

    }


    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        /* dispatch(getBatch(requestData))
        dispatch(getEnrollmentDetails(requestData)) */
        // dispatch(getPeriodTime(requestData))
        listFetching()
    }, [navigation]);

    useEffect(() => {
        if (getTimeTableSuccess) {
            setItemList(getTimeTableList)
            setSelectedItem(getTimeTableList)
            if (editData == false && getTimeTableList.length > 0) {
                Alert({ description: 'TIMETABLE  ALREADY CREATED.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

                setCreateModule(false);

            } else if (editData) {
                setTimeTableModule(true);
            }
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_TIME_TABLE" })
        } else if (getTimeTableFailure) {
            setItemList([])
            showMessage("warning", timeTableErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_TIME_TABLE" })
        }
    }, [getTimeTableSuccess, getTimeTableFailure]);


    useEffect(() => {
        if (getBatchSuccess) {
            setState({
                ...state,
                batchList: getBatchList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BATCH" })
        } else if (getBatchFailure) {
            setState({
                ...state,
                batchList: []
            })
            showMessage("warning", batchErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BATCH" })
        }
    }, [getBatchSuccess, getBatchFailure]);

    useEffect(() => {
        if (getTimeTableInfoSuccess) {
            setState({
                ...state,
                timeTableInfo: [...getTimeTableInfoList]
            })

            setLoaderModule(false)
            dispatch({ type: "RESET_GET_TIME_INFO_TABLE" })
        } else if (getTimeTableInfoFailure) {
            setState({
                ...state,
                timeTableInfo: []
            })
            showMessage("warning", timeTableErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_TIME_INFO_TABLE" })
        }
    }, [getTimeTableInfoSuccess, getTimeTableInfoFailure]);

    useEffect(() => {
        if (getPeriodTimeSuccess) {
            state.periodTimeList = [{
                periodTimeId: 8,
                periodTitle: "Days / Time"
            }]
            setState({
                ...state,
                periodTimeList: [...state.periodTimeList, ...getPeriodTimeList]
            })
            dispatch(getPeriodDays())
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_PERIOD_TIME" })
        } else if (getPeriodTimeFailure) {
            setState({
                ...state,
                periodTimeList: []
            })
            showMessage("warning", periodTimeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_PERIOD_TIME" })
        }
    }, [getPeriodTimeSuccess, getPeriodTimeFailure, getPeriodTimeList]);

    useEffect(() => {
        if (getPeriodDaysSuccess) {
            try {
                let filterData = []
                filterData = getPeriodDaysList.filter((v) => v.status == true)

                setState({
                    ...state,
                    periodDaysList: filterData
                })
            } catch {
                console.log("error");
            }
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_PERIOD_DAYS" })
        } else if (getPeriodDaysFailure) {
            setState({
                ...state,
                periodDaysList: []
            })
            showMessage("warning", periodDaysErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_PERIOD_DAYS" })
        }
    }, [getPeriodDaysSuccess, getPeriodDaysFailure]);

    useEffect(() => {
        if (getSubjectEnrollmentSuccess) {
            setState({
                ...state,
                subjectList: getSubjectEnrollmentList.length > 0 ? getSubjectEnrollmentList[0].subjectList : []
            });
            viewData === true && setViewModule(true)
            viewData = false

            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUBJECT_ENROLLMENT" })
        } else if (getSubjectEnrollmentFailure) {
            setState({
                ...state,
                subjectList: []
            })
            showMessage("warning", subjectEnrollmentErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUBJECT_ENROLLMENT" })
        }
    }, [getSubjectEnrollmentSuccess, getSubjectEnrollmentFailure, getSubjectEnrollmentList]);

    useEffect(() => {
        if (getEnrollmentDetailsSuccess) {
            setState({
                ...state,
                enrollmentDetailsList: getEnrollmentDetailsList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
        } else if (getEnrollmentDetailsFailure) {
            setState({
                ...state,
                enrollmentDetailsList: []
            })
            showMessage("warning", enrollmentDetailsErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
        }
    }, [getEnrollmentDetailsSuccess, getEnrollmentDetailsFailure]);

    useEffect(() => {
        if (createTimeTableSuccess) {
            const temp_state = createTimeTableData;
            setItemList(temp_state)
            const request = {
                batchId: state?.addBatchId || ''
            }
            //  console.log(request)
            // dispatch(getTimeTableInfo(request))
            setState({
                ...state,
                timeTableInfo: []
            })
            closeModule()
            setLoaderModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_TIME_TABLE" })
        } else if (createTimeTableFailure) {
            setLoaderModule(false)
            showMessage("warning", timeTableErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_TIME_TABLE" })
        }
    }, [createTimeTableSuccess, createTimeTableFailure]);


    useEffect(() => {
        if (updateTimeTableSuccess) {
            const selectedBatchId = state?.batchId || null;
            const selectedEnrollmentId = state?.enrollmentId && state.enrollmentId.length > 0 ? state.enrollmentId[0].enrollmentId : null;
            const check = state?.addBatchId || null;

            const request = {
                batchId: check
            };
            dispatch(getTimeTableInfo(request));
            updateTable(updateTimeTableData[0])
            dispatch({ type: "RESET_UPDATE_TIME_TABLE" })
        } else if (updateTimeTableFailure) {
            setLoaderModule(false)
            showMessage("warning", timeTableErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_TIME_TABLE" })
        }
    }, [updateTimeTableSuccess, updateTimeTableFailure, updateTimeTableData]);

    const updateTable = (updatedItem, data) => {

        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.timeTableId != updatedItem.timeTableId;
            });
            // showMessage("success", "Deleted Successfully")
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            //setItemList(remainingList)
        } else {
            const selectedBatchId = state?.batchId || null;
            const selectedEnrollmentId = state?.enrollmentId && state.enrollmentId.length > 0 ? state.enrollmentId[0].enrollmentId : null;

            const req = {
                batchId: selectedBatchId,
                enrollmentId: selectedEnrollmentId,
                unitId: 1,
            };
            dispatch(getTimeTable(req));
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            setItemList(temp_state)
        }

        //setState({...state, timeTableInfo : temp_state})
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        // editData = false;
        setCreateModule(false)
        setViewModule(false)
        setDeleteModule(false)
    }
    const getSubjectInfo = async (req) => {
        let list = await asyncgetSubjectEnrollment(req)
        let subjectEnrollmentList = list.data
        setState({
            ...state,
            subjectList: subjectEnrollmentList.length > 0 ? subjectEnrollmentList[0].subjectList : []
        });
        viewData === true && setViewModule(true)
        viewData = false
    }

    const onEditForm = async (data, index) => {
        let temp_state = state?.enrollmentDetailsList || []
        const filterData = temp_state
            .filter((item) => item.enrollmentId === data.enrollmentId)
            .map((item) => item.enrollmentId);
        const requestData = {
            isActive: true,
            batchId: data?.batchId || "",
            enrollmentId: data?.enrollmentId || "",
        }
        let list = await asyncgetSubjectEnrollment(requestData)
        let getSubjectEnrollmentList = list?.data || []



        setState({
            ...state,
            batchId: data.batchId,
            enrollmentId: filterData,
            isDataUpdated: true,
            subjectList: getSubjectEnrollmentList.length > 0 ? getSubjectEnrollmentList[0].subjectList : []

        })

        const selectedBatchId = state?.batchId || null;

        // let subject =  getSubjectInfo(requestData)
        // dispatch(getSubjectEnrollment(requestData))

        const req = {
            batchId: selectedBatchId,
            enrollmentId: data?.enrollmentId || '',
            unitId: 1,
        }
        dispatch(getTimeTable(req))
        const request = {
            batchId: state.addBatchId
        }
        dispatch(getTimeTableInfo(request))
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
        editData = true

    }

    const onView = async (data, index) => {
        // editData = false;
        try {
            viewData = true;
            const selectedBatchId = state?.batchId || null;
            const selectedEnrollmentId = state?.enrollmentId && state.enrollmentId.length > 0 ? state.enrollmentId[0].enrollmentId : null;
            const check = state?.addBatchId || null;
            if (check != null) {
                const requestData = {
                    isActive: true,
                    batchId: data?.batchId || "",
                    enrollmentId: data?.enrollmentId || "",
                };
                // dispatch(getSubjectEnrollment(requestData));
                let list = await asyncgetSubjectEnrollment(requestData)
                viewData === true && setViewModule(true)
                viewData = false
                let getSubjectEnrollmentList = list?.data || []


                setState({
                    ...state,
                    subjectList: getSubjectEnrollmentList.length > 0 ? getSubjectEnrollmentList[0].subjectList : []
                });
                const request = {
                    batchId: check
                };
                dispatch(getTimeTableInfo(request));
                setSelectedIndex(index);
                setSelectedItem(data);

            } else {
                Alert({ description: 'Please Select the Batch', type: 'warning', placement: 'topRight', duration: 3 });

                // showMessage('warning', "Please Select the Batch");
            }

        } catch (error) {
            console.error('An error occurred:', error);

        }
    };

    const resetState = () => {
        setState({
            ...state,
            enrollmentId: "",
        });
        setItemList([]);
    };

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
        setTimeTableModule(false)
    }


    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }
    const handleBatchName = (event, name) => {
        let selectedMaterial = event;
        let batchList = state.batchList;
        let selectedBatch = batchList.filter(item => item.batchId == selectedMaterial);
        if (selectedBatch.length > 0) {
            setState({
                ...state,
                [name]: event,
                batchId: selectedBatch[0].batchId || ""
            });
        } else {
            setState({
                ...state,
                [name]: event,
                batchId: ""
            });
            // showMessage('warning', "Selected batch not found");
            Alert({ description: 'Selected batch not found.', type: 'warning', placement: 'topRight', duration: 3 });

        }
    };


    const onFind = () => {
        const check = state?.addBatchId || null
        if (check != null && check != "0") {
            const request = {
                batchId: state.addBatchId
            }
            setLoaderModule(true);
            dispatch(getTimeTableInfo(request))
        } else {
            Alert({ description: 'Please Select the Batch.', type: 'warning', placement: 'topRight', duration: 3 });

            // showMessage('warning', "Please Select the Batch")
        }
    }


    const onSearch = () => {
        setTimeTableModule(true);
        const selectedBatchId = state?.batchId || null;
        // console.log(JSON.stringify(state?.enrollmentName));

        const selectedEnrollmentId = state?.enrollmentId || null;
        if (selectedEnrollmentId != null && selectedBatchId != null) {
            const req = {
                batchId: selectedBatchId,
                enrollmentId: selectedEnrollmentId,
                unitId: 1,
            };
            dispatch(getTimeTable(req));

            const requestData = {
                isActive: true,
                batchId: selectedBatchId,
                enrollmentId: selectedEnrollmentId,
            };
            dispatch(getSubjectEnrollment(requestData));
        } else {
            // showMessage("warning", "Please Select Batch And Class");
            Alert({ description: 'Please Select Batch And Class', type: 'warning', placement: 'topRight', duration: 3 });

            setTimeTableModule(false);
        }
    };


    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onCreateTimeTable = (data) => {
        try {
            const timeTable = timeTableModel.current?.timeTableStateInfo()?.timeTable;

            const request = {
                batchId: state.batchId,
                enrollmentId: state.enrollmentId || 0,
                periodMeta: JSON.stringify(timeTable)
            }
            console.log(JSON.stringify(request));

            // return
            if (editData) {
                setLoaderModule(true)
                dispatch(updateTimeTable(request, selectedItem[0].timeTableId))
            } else if (deleteModule) {
                const deleteRequest = {
                    isActive: false
                }
                setLoaderModule(true)
                dispatch(updateTimeTable(deleteRequest, selectedItem[0].timeTableId))
            } else {
                if (!request.periodMeta) {
                    Alert({ description: 'FILL ALL THE DATA.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
                    // showMessage("warning", "FILL ALL THE DATA")
                } else {
                    setLoaderModule(true)
                    dispatch(createTimeTable(request))

                }
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    const {
        timeTableName,
        timeTableStart,
        timeTableEnd
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";


    const { sidebarOpen } = useSidebar();


    return (
        <div>

            <View isOpen={createModule} size="xl" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={onCreateTimeTable} isLoading={loaderModule} title={`${modelTitle} TimeTable`}>
                <FilterComponent dataRef={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={timeTableFilterForm} noOfColumn={2} btnClickFun={() => onSearch()} />
                {timeTableModule &&
                    <div>
                        <TimeTable ref={timeTableModel} existing={editData} data={itemList} days={state?.periodDaysList || []} periodTime={state?.periodTimeList || []} optionList={state?.subjectList || []}></TimeTable>
                        {/* <CustomAlignButton btnName={"Submit"} onClick={onCreateTimeTable}></CustomAlignButton> */}
                    </div>
                }
            </View>
            <View isOpen={viewModule} size="xl" savetitle={"Close"} onClose={setViewModule} /* onSubmit={closeModule} */ title={`View Time Table`}>
                <SimpleBorderTable heading={state?.periodTimeList || []} data={selectedItem.periodMeta} subjectList={state?.subjectList || []} days={state?.periodDaysList || []}></SimpleBorderTable>
            </View>
            <div style={sidebarOpen ? { marginLeft: "40px", marginTop: "50px", marginBottom: "-30px" } : { marginLeft: "90px", marginTop: "50px", marginBottom: "-50px" }}>
                <FormLayout defaultState={state} setDefaultState={setState} dynamicForm={filterForm} noOfColumn={4} />
                <Button onClick={onFind} className='mr-2' style={{ backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px", marginTop: "-20px" }}>Search</Button>
                {/* <Button onClick={onClear} style={{ backgroundColor: "white", color: "gray", border: "1px solid gray", fontSize: "12px", marginTop: "-20px" }}>Clear</Button> */}
            </div>
            <SimpleCard  >
                <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick}
                    columns={column}/*  data={Array.isArray(getTimeTableInfoList) ? getTimeTableInfoList : []}  */ data={state?.timeTableInfo || []} tableName="Time Table" state={state} />
            </SimpleCard>
        </div>
    )
};

export default Timetable;
