import React, { useState, useRef, useEffect } from 'react';
import { Send, Menu, User } from 'lucide-react';
import { ChatMessage } from './ChatMessage';
import { StudentList } from './StudentList';
import { asyncGetStudentChatList } from 'app/api/StudentChatListApi';
import { useDispatch, useSelector } from 'react-redux';
import { getStudent } from 'app/api/StudentApi';
import { imagePath, studentPath } from "app/utils/constant";
import { getChat } from 'app/api/ChatApi';
import { createChat } from 'app/api/ChatApi';
import Alert from '../alert';
import { getChatMessage } from 'app/api/ChatMessageApi';
import { createChatMessage } from 'app/api/ChatMessageApi';
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { asyncGetChatInfo } from 'app/api/ChatApi';


function ChatNew() {
    const dispatch = useDispatch();

    const getStudentSuccess = useSelector((state) => state.studentReducer.getStudentSuccess);
    const getStudentList = useSelector((state) => state.studentReducer.getStudentList);
    const getStudentFailure = useSelector((state) => state.studentReducer.getStudentFailure);
    const studentErrorMessage = useSelector((state) => state.studentReducer.errorMessage);

    let userInfo = JSON.parse(window.localStorage.getItem("userInfo") || "{}");
    let staffId = userInfo[0]?.employeeId || "";

    const request = {
        staffId: staffId
    }

    const [state, setState] = useState([]);
    const [students, setStudents] = useState([

    ]);

    const [selectedStudent, setSelectedStudent] = useState(null);
    const [messages, setMessages] = useState([]);
    const [chatHistory, setchatHistory] = useState([]);
    const [loaderModule, setLoaderModule] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const { sidebarOpen } = useSidebar();
    const [warningMessage, setWarningMessage] = useState(false);



    const [newMessage, setNewMessage] = useState('');
    const messagesEndRef = useRef(null);
    

    const getChatSuccess = useSelector((state) => state.chatReducer.getChatSuccess);
    const getChatList = useSelector((state) => state.chatReducer.getChatList);
    const getChatFailure = useSelector((state) => state.chatReducer.getChatFailure);

    const getChatMessageSuccess = useSelector((state) => state.chatMessageReducer.getChatMessageSuccess);
    const getChatMessageList = useSelector((state) => state.chatMessageReducer.getChatMessageList);
    const getChatMessageFailure = useSelector((state) => state.chatMessageReducer.getChatMessageFailure);

    const createChatMessageSuccess = useSelector((state) => state.chatMessageReducer.createChatMessageSuccess);
    const createChatMessageData = useSelector((state) => state.chatMessageReducer.createChatMessageData);
    const createChatMessageFailure = useSelector((state) => state.chatMessageReducer.createChatMessageFailure);

    const createChatSuccess = useSelector((state) => state.chatReducer.createChatSuccess);
    const createChatData = useSelector((state) => state.chatReducer.createChatData);
    const createChatFailure = useSelector((state) => state.chatReducer.createChatFailure);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (selectedStudent?.chatId) {
                const request_Data = {
                    isActive: true,
                    senderType: "staff",
                    senderId: staffId,
                    receiverId: selectedStudent?.studentId || "",
                    receiverType: "student"
                };
                dispatch(getChatMessage(request_Data));  // Fetch new messages
            }
        }, 5000);

        return () => clearInterval(interval);

    }, [selectedStudent]);

    useEffect(() => {
        scrollToBottom();  // Scroll to top when chat is updated or selectedStudent is changed
    }, [messages, selectedStudent]);


    /* useEffect(() => {
        const fetchStudents = async () => {
            try {
            } catch (error) {
                console.error("Error fetching student list:", error);
            }
        };
        fetchStudents();
    }, []); */


    useEffect(() => {

        const requestData = {
            isActive: "true",
        }
        const request_Data = {
            isActive: true,
            staffId: staffId
        }
        dispatch(getChat(request_Data))
    }, []);




    useEffect(() => {
        if (getStudentSuccess) {
            setStudents([...getStudentList])
            setState(getStudentList)

            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT" })
        } else if (getStudentFailure) {
            setStudents([])
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT" })
        }
    }, [getStudentSuccess, getStudentFailure, getStudentList]);

    useEffect(() => {
        if (getChatMessageSuccess) {
            setchatHistory(getChatMessageList)
            console.log(chatHistory)
            // setStudents([...getChatList])
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CHAT_MESSAGE" })
        } else if (getChatMessageFailure) {
            // setStudents([])
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CHAT_MESSAGE" })
        }
    }, [getChatMessageSuccess, getChatMessageFailure]);


    useEffect(() => {
        if (getChatSuccess) {

            setStudents([...getChatList])
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CHAT" })
        } else if (getChatFailure) {
            setStudents([])
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CHAT" })
        }
    }, [getChatSuccess, getChatFailure]);

    useEffect(() => {
        if (createChatMessageSuccess) {
            const request_Data = {
                isActive: true,
                senderType: "staff",
                senderId: staffId,
                receiverId: selectedStudent?.studentId || "",
                receiverType: "student"
            };
            dispatch(getChatMessage(request_Data));
            const requestData = {
                isActive: true,
                staffId: staffId
            }
            dispatch(getChat(requestData))

            // const temp_state = [...itemList, createExamData[0]];
            // setItemList(temp_state)
            // closeModule()
            setLoaderModule(false)
            dispatch({ type: "RESET_CREATE_CHAT_MESSAGE" })
        } else if (createChatMessageFailure) {
            Alert({ description: 'your Message Has Failed To Send', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            // setLoaderModule(false)
            // showMessage("warning", examErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_CHAT_MESSAGE" })
        }
    }, [createChatMessageSuccess, createChatMessageFailure]);

    useEffect(() => {
        if (createChatSuccess) {
            const request_Data = {
                isActive: true,
                staffId: staffId
            }
            dispatch(getChat(request_Data))



            setLoaderModule(false)
            dispatch({ type: "RESET_CREATE_CHAT" })
        } else if (createChatFailure) {
            Alert({ description: 'your Message Has Failed To Send', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            // setLoaderModule(false)
            // showMessage("warning", examErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_CHAT" })
        }
    }, [createChatSuccess, createChatFailure]);





    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim() || !selectedStudent) return;

        const currentTime = new Date().toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
        });


        const chatNewMsg = {
            "chatId": selectedStudent?.chatId || "",
            "senderId": selectedStudent?.staffId || staffId,
            "senderType": "staff",
            "receiverId": selectedStudent?.studentId,
            "receiverType": "student",
            "message": newMessage,
            "messageType": "text",
            "messageTime": currentTime
        };
        if (!selectedStudent?.chatId) {
            delete chatNewMsg.chatId;
            chatNewMsg.receiverId = selectedStudent?.studentEnrollmentId || "";



        }
        console.log(chatNewMsg)
        let chatData = await asyncGetChatInfo(chatNewMsg)
        let chatList = chatData?.data || []
        let studentId = selectedStudent?.studentEnrollmentId
        const chatExists = chatList.some(chat => chat.studentId === studentId);
        if (chatExists) {
            setNewMessage("This user has chart section already")
            setWarningMessage(true)
            return

        }
        // Dispatch action to send message
        dispatch(createChatMessage(chatNewMsg));

        setMessages((prev) => [...prev, { ...chatNewMsg, sender: 'employee', content: newMessage }]);

        setNewMessage('');
        // scrollToBottom()

        /* else {
           const request_Data = {
               isActive: "true",
               staffId: staffId
           }
           let chatData = await asyncGetChatInfo(request_Data)
           let chatList = chatData?.data || []
           let studentId = selectedStudent?.studentEnrollmentId
           const chatExists = chatList.some(chat => chat.studentId === studentId);

           const newMsg = {
               senderId: staffId,
               senderType: "staff",
               receiverId: selectedStudent?.studentEnrollmentId || "",
               receiverType: "student",
               message: newMessage,
               messageTime: currentTime
           };
           setMessages((prev) => [...prev, { ...newMsg, sender: 'employee', content: newMessage }]);
           if (chatExists) {
               setNewMessage("This user has chart section already")
               setWarningMessage(true)
               return

           }

           dispatch(createChat(newMsg));
           setNewMessage('');
           setWarningMessage(false)







       } */

    };




    const handleSearch = async (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        try {
            if (!isNaN(value)) {
                const response = await getStudent({ phoneNumber: value, isActive: true, });
                const requestData = {
                    phoneNo: value
                }

                dispatch(getStudent(requestData))
                setStudents(response.data || []);
                if (!value) {
                    const request_Data = {
                        isActive: true,
                        staffId: staffId
                    }
                    dispatch(getChat(request_Data))
                    setWarningMessage(false)
                    setNewMessage("")
                }
            } else {
                const request_Data = { isActive: true, staffId: staffId };
                let chatData = await asyncGetChatInfo(request_Data);
                let chatList = chatData?.data || [];

                let filteredStudents = chatList.filter(student =>
                    student.studentName.toLowerCase().includes(value.toLowerCase())
                );
                console.log(filteredStudents)

                setStudents(filteredStudents);
                if (!value) {
                    const request_Data = {
                        isActive: true,
                        staffId: staffId
                    }
                    dispatch(getChat(request_Data))
                    setWarningMessage(false)
                    setNewMessage("")
                }

            }



        } catch (error) {
            console.error('Error fetching students:', error);
        }

    };

    return (
        <div
            className={`bg-gray-100 flex overflow-hidden h-[100%] fixed mt-[-90px] transition-all duration-300 linear ${sidebarOpen ? "w-[80%]" : "w-[100%]"
                }`}
        >

            {/* Student List Sidebar */}
            <div style={{ overflowY: "scroll", paddingBottom: "100px" }}>
                <StudentList
                    students={students}
                    setStudents={setStudents}
                    selectedStudent={selectedStudent}
                    onSelectStudent={(student) => {
                        setSelectedStudent(student);
                        if (student?.chatId) {
                            const request_Data = {
                                isActive: true,
                                senderType: "staff",
                                senderId: staffId,
                                receiverId: student?.studentId || "",
                                receiverType: "student"
                            };
                            dispatch(getChatMessage(request_Data));
                        } else {
                            setchatHistory([])

                        }
                    }}
                    handleSearch={handleSearch}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    setNewMessage={setNewMessage}
                    setWarningMessage={setWarningMessage}
                />
            </div>

            {/* Chat Area */}
            <div className="flex-1 flex flex-col h-[76%] w-[100%] overflow-auto scrollbar scrollbar-thin pr-[80px] " >
                {selectedStudent ? (
                    <>
                        {/* Chat Header */}
                        <div className="bg-gray-50 border-b border-gray-200 px-6 py-4 flex items-center" >
                            <div className="flex items-center space-x-3">
                                {(() => {
                                    const profile = `${imagePath}${studentPath}${selectedStudent?.studentToken || ""}/${encodeURI(selectedStudent?.profilePic || "")}`;
                                    console.log(profile)
                                    return selectedStudent.profilePic ? (
                                        <img
                                            src={profile}
                                            alt={selectedStudent.studentName}
                                            className="w-10 h-10 rounded-full object-cover"
                                        />
                                    ) : (
                                        <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center">
                                            <User className="w-6 h-6 text-gray-600" />
                                        </div>
                                    );
                                })()}
                                <div>
                                    <h2 className="text-lg font-semibold text-gray-900">
                                        {selectedStudent.studentName}
                                    </h2>
                                    <p className="text-sm text-gray-500">
                                        {selectedStudent.status === 'online' ? 'Online' : 'Offline'}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Messages */}
                        <div className="flex-1 flex flex-col h-[76%] w-[100%] overflow-auto scrollbar scrollbar-thin pr-[80px]"
                            style={{
                                display: 'flex',
                                flexDirection: 'column-reverse', // This ensures messages stack from bottom
                                overflowY: 'auto',
                            }}>
                            <div style={{
                                marginTop: chatHistory.length === 0
                                    ? "250px"
                                    : chatHistory.length === 1
                                        ? "245px"
                                        : chatHistory.length === 2
                                            ? "195px"
                                            : chatHistory.length === 3
                                                ? "145px"
                                                : chatHistory.length === 4
                                                    ? "95px"
                                                    : "0px"
                            }}>
                                {chatHistory.slice().reverse().map((msg, index) => {
                                    const isCurrentUserSender = msg?.senderId === staffId;

                                    return (
                                        <div
                                            key={index}
                                            className={`flex ${isCurrentUserSender ? 'justify-end' : 'justify-start'} }`}
                                            style={{ margin: "10px" }}

                                        >
                                            <div
                                                className={`max-w-[70%] p-1 rounded-lg ${isCurrentUserSender ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
                                            >
                                                <p>{msg.message}</p>
                                                <span className="text-xs text-white-500">{msg.messageTime}</span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div ref={messagesEndRef} />
                            <div
                                className="chat-input-container "
                                style={{
                                    position: 'fixed',
                                    bottom: '10px',
                                    width: '60%',
                                    padding: '10px',
                                    backgroundColor: '#fff', // Make sure it stays visible
                                    zIndex: 1000,
                                    borderRadius: "20px"
                                }}
                            >
                                <form onSubmit={handleSendMessage} className="flex items-center space-x-2 ">
                                    <input
                                        type="text"
                                        value={newMessage}
                                        onChange={(e) => setNewMessage(e.target.value)}
                                        placeholder="Type your message"
                                        className="w-full px-4 py-2 border rounded"
                                        style={{ borderRadius: '25px', color: warningMessage ? "red" : "black" }}
                                    />
                                    <button
                                        type="submit"
                                        className="bg-blue-500 text-white px-2 py-2 rounded-full"
                                        style={{ borderRadius: '50%' }}
                                    >
                                        <Send className="w-6 h-6" />
                                    </button>
                                </form>
                            </div>
                        </div>


                        {/* Message Input */}

                    </>
                ) : (
                    <div className="flex-1 flex items-center justify-center bg-gray-50">
                        <div className="text-center">
                            <h2 className="text-xl font-semibold text-gray-700">
                                Select a student to start chatting
                            </h2>
                            <p className="text-gray-500 mt-2">
                                Choose from the list on the left to begin a conversation
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ChatNew;