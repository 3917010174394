import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { knowledgeBookForm } from "./formData";
import { createKnowledgeBookType, getKnowledgeBookType, updateKnowledgeBookType } from "app/api/KnowledgeBookTypeApi";
import Alert from "app/components/atoms/alert";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false;

const KnowledgeBookType = ({ navigation }) => {

    const dispatch = useDispatch();
    const knowledgeBookFormRef = useRef();


    const getKnowledgeBookTypeSuccess = useSelector((state) => state.knowledgeBookTypeReducer.getKnowledgeBookTypeSuccess);
    const getKnowledgeBookTypeList = useSelector((state) => state.knowledgeBookTypeReducer.getKnowledgeBookTypeList);
    const getKnowledgeBookTypeFailure = useSelector((state) => state.knowledgeBookTypeReducer.getKnowledgeBookTypeFailure);

    const createKnowledgeBookTypeSuccess = useSelector((state) => state.knowledgeBookTypeReducer.createKnowledgeBookTypeSuccess);
    const createKnowledgeBookTypeData = useSelector((state) => state.knowledgeBookTypeReducer.createKnowledgeBookTypeData);
    const createKnowledgeBookTypeFailure = useSelector((state) => state.knowledgeBookTypeReducer.createKnowledgeBookTypeFailure);

    const updateKnowledgeBookTypeSuccess = useSelector((state) => state.knowledgeBookTypeReducer.updateKnowledgeBookTypeSuccess);
    const updateKnowledgeBookTypeData = useSelector((state) => state.knowledgeBookTypeReducer.updateKnowledgeBookTypeData);
    const updateKnowledgeBookTypeFailure = useSelector((state) => state.knowledgeBookTypeReducer.updateKnowledgeBookTypeFailure);

    const errorMessage = useSelector((state) => state.knowledgeBookTypeReducer.errorMessage);

    const [pageItems, setpageItems] = useState({

    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Masters", "Knowledge Book Type")
        setpageItems(data)

    }, [navigation]);
    const column = [

        {
            "dataField": "",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return index +1
            }
        },
        {
            "dataField": "knowledgeBookName",
            "text": "Knowledge Book Name",
            "sort": true
        },
        // {
        //     text: "Action",
        //     sort: false,
        //     align: "center",
        //     headerAlign: "center",
        //     formatter: (cellContent, row, index) => (
        //         <div >
        //             <MdEdit
        //                 className="text-success cursor-pointer"
        //                 size={18}
        //                 onClick={() => onEditForm(row, index)}
        //             ></MdEdit>
        //             <MdDelete
        //                 className="text-danger cursor-pointer"
        //                 size={18}
        //                 onClick={() => {
        //                     onDeleteItem(row, index)
        //                 }}
        //             ></MdDelete>
        //             {/* <MdAddTask
        //                 className="text-success cursor-pointer"
        //                 size={18}
        //                 onClick={() => onEditForm(row, index)}
        //             ></MdAddTask> */}
        //         </div>
        //     )
        // },
    ]
    if (pageItems.delete || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {/*  {pageItems.view && <MdRemoveRedEye
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onDocView(row, index)}
                    ></MdRemoveRedEye>} */}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index)
                        }}
                    ></FiTrash2>}
                </div>
            )
        })
    }

    const [state, setState] = useState({
        customerCode: "",
        customerName: "",
        email: "",
        phoneNo: "",
        address: "",
        postalCode: "",
        remark: "",
        cityId: "",
        stateId: "",
        countryId: "",
        "materialTypeList": [],
        "taxList": []
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        let req = { "isActive": true }
        dispatch(getKnowledgeBookType(req))
        // dispatch(getMaterialType())
    }, [navigation]);


    useEffect(() => {
        if (getKnowledgeBookTypeSuccess) {
            setItemList(getKnowledgeBookTypeList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_KNOWLEDGE_BOOK_TYPE" })
        } else if (getKnowledgeBookTypeFailure) {
            setItemList([])
            showMessage("warning", errorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_KNOWLEDGE_BOOK_TYPE" })
        }
    }, [getKnowledgeBookTypeSuccess, getKnowledgeBookTypeFailure]);



    useEffect(() => {
        if (createKnowledgeBookTypeSuccess) {
            const temp_state = [...itemList, createKnowledgeBookTypeData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_KNOWLEDGE_BOOK_TYPE" })
        } else if (createKnowledgeBookTypeFailure) {
            setLoaderModule(false)
            showMessage("warning", errorMessage?.message)
            dispatch({ type: "RESET_CREATE_KNOWLEDGE_BOOK_TYPE" })
        }
    }, [createKnowledgeBookTypeSuccess, createKnowledgeBookTypeFailure]);


    useEffect(() => {
        if (updateKnowledgeBookTypeSuccess) {
            updateTable(updateKnowledgeBookTypeData[0])
            dispatch({ type: "RESET_UPDATE_KNOWLEDGE_BOOK_TYPE" })
        } else if (updateKnowledgeBookTypeFailure) {
            setLoaderModule(false)
            showMessage("warning", errorMessage?.message)
            dispatch({ type: "RESET_UPDATE_KNOWLEDGE_BOOK_TYPE" })
        }
    }, [updateKnowledgeBookTypeSuccess, updateKnowledgeBookTypeFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.knowledgeBookId != selectedItem.knowledgeBookId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            knowledgeBookName: "",
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            knowledgeBookName: data?.knowledgeBookName || "",

        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedItem(data)
        setSelectedIndex(index)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = knowledgeBookFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            knowledgeBookName: state?.knowledgeBookName || "",
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateKnowledgeBookType(requestDate, selectedItem.knowledgeBookId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            dispatch(updateKnowledgeBookType(deleteRequest, selectedItem.knowledgeBookId))
        } else {
            setLoaderModule(true)
            dispatch(createKnowledgeBookType(requestDate))
        }
    }

    const {
        materialTypeList,
        taxList
    } = state;

    const handleCloseModal = () => {
        setCreateModule(false);
    };

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const { sidebarOpen } = useSidebar();

    return (
        <div>
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
                <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={handleCloseModal} onSubmit={validateForm} isLoading={loaderModule} title={`${modelTitle} KnowledgeBookType`}>
                    <FormLayout ref={knowledgeBookFormRef} defaultState={state} setDefaultState={setState} dynamicForm={knowledgeBookForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>

                <SimpleCard >
                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"KnowledgeBookType"} columns={column} data={itemList} initialPage={1} />
                </SimpleCard>

            </div>
        </div>
    )
};

export default KnowledgeBookType;
