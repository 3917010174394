import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { classForm } from "./formData";
import { createClass, getClass, updateClass } from "app/api/ClassApi";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import Alert from "app/components/atoms/alert";

let editData = false;

const Class = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getClassSuccess = useSelector((state) => state.classReducer.getClassSuccess);
    const getClassList = useSelector((state) => state.classReducer.getClassList);
    const getClassFailure = useSelector((state) => state.classReducer.getClassFailure);

    const createClassSuccess = useSelector((state) => state.classReducer.createClassSuccess);
    const createClassData = useSelector((state) => state.classReducer.createClassData);
    const createClassFailure = useSelector((state) => state.classReducer.createClassFailure);

    const updateClassSuccess = useSelector((state) => state.classReducer.updateClassSuccess);
    const updateClassData = useSelector((state) => state.classReducer.updateClassData);
    const updateClassFailure = useSelector((state) => state.classReducer.updateClassFailure);

    const classErrorMessage = useSelector((state) => state.classReducer.errorMessage);

    const [pageItems, setpageItems] = useState({
    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Masters", "Class")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "sNo",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => (
                index + 1
            )
        },
        {
            "dataField": "classTitle",
            "text": "Class Title",
            "sort": true
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
    ]
    if (pageItems.delete || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index);
                        }}
                    ></FiTrash2>}
                </div>
            )
        })
    }

    const [state, setState] = useState({
        classTitle: ""
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getClass(requestData))
    }, [navigation]);

    useEffect(() => {
        if (getClassSuccess) {
            setItemList(getClassList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CLASS" })
        } else if (getClassFailure) {
            setItemList([])
            showMessage("warning", classErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CLASS" })
        }
    }, [getClassSuccess, getClassFailure]);

    useEffect(() => {
        if (createClassSuccess) {
            const temp_state = [...itemList, createClassData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_CLASS" })
        } else if (createClassFailure) {
            showMessage("warning", classErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_CREATE_CLASS" })
        }
    }, [createClassSuccess, createClassFailure]);

    useEffect(() => {
        if (updateClassSuccess) {
            updateTable(updateClassData[0])
            dispatch({ type: "RESET_UPDATE_CLASS" })
        } else if (updateClassFailure) {
            setLoaderModule(false)
            showMessage("warning", classErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_CLASS" })
        }
    }, [updateClassSuccess, updateClassFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.classId != updatedItem.classId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            classTitle: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            classTitle: data?.classTitle || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            classTitle: classTitle
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateClass(requestDate, selectedItem.classId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: false
            }
            setLoaderModule(true)
            dispatch(updateClass(deleteRequest, selectedItem.classId))
        } else {
            setLoaderModule(true)
            dispatch(createClass(requestDate))
        }
    }

    const handleCloseModal = () => {
        setCreateModule(false);
    };

    const {
        classTitle
    } = state;

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    return (
        <div>
            <div style={{ marginTop: "-10px" }}>
                {/* <SmallLoader value={loaderModule} /> */}
                <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={handleCloseModal} onSubmit={validateForm} isLoading={loaderModule} title={`${modelTitle} Class`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={classForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>


                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} isLoading={loaderModule} title="Delete Class">
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>
                </View>

                <SimpleCard >
                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Class"} columns={column} data={itemList} initialPage={1} />
                </SimpleCard>
            </div>
        </div>
    )
};

export default Class;
