const initialState = {
    batchList: []  
  }
  
  const BannerReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_BANNER_SUCCESS": {             
        return {
          ...state,
          getBannerSuccess: true,
          getBannerList: action.payload.data,
        };
      }
      case "GET_BANNER_FAILURE": {
        return {
          ...state,
          getBannerFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_BANNER": {
        return {
          ...state,
          getBannerSuccess: false,
          getBannerFailure: false,
          getBannerList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_BANNER_SUCCESS": {             
        return {
          ...state,
          createBannerSuccess: true,
          createBannerData: action.payload.data,
        };
      }
      case "CREATE_BANNER_FAILURE": {
        return {
          ...state,
          createBannerFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_BANNER": {
        return {
          ...state,
          createBannerSuccess: false,
          createBannerFailure: false,
          createBannerData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_BANNER_SUCCESS": {             
        return {
          ...state,
          updateBannerSuccess: true,
          updateBannerData: action.payload.data,
        };
      }
      case "UPDATE_BANNER_FAILURE": {
        return {
          ...state,
          updateBannerFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_BANNER": {
        return {
          ...state,
          updateBannerSuccess: false,
          updateBannerFailure: false,
          updateBannerData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default BannerReducer
  
  