const initialState = {
    chatList: []  
  }
  
  const ChatReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_CHAT_SUCCESS": {             
        return {
          ...state,
          getChatSuccess: true,
          getChatList: action.payload.data,
        };
      }
      case "GET_CHAT_FAILURE": {
        return {
          ...state,
          getChatFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_CHAT": {
        return {
          ...state,
          getChatSuccess: false,
          getChatFailure: false,
          getChatList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_CHAT_SUCCESS": {             
        return {
          ...state,
          createChatSuccess: true,
          createChatData: action.payload.data,
        };
      }
      case "CREATE_CHAT_FAILURE": {
        return {
          ...state,
          createChatFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_CHAT": {
        return {
          ...state,
          createChatSuccess: false,
          createChatFailure: false,
          createChatData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_CHAT_SUCCESS": {             
        return {
          ...state,
          updateChatSuccess: true,
          updateChatData: action.payload.data,
        };
      }
      case "UPDATE_CHAT_FAILURE": {
        return {
          ...state,
          updateChatFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_CHAT": {
        return {
          ...state,
          updateChatSuccess: false,
          updateChatFailure: false,
          updateChatData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ChatReducer
  
  