import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { holidaysForm } from "./formData";
import { createHolidays, getHolidays, updateHolidays } from "app/api/HolidaysApi";
import { dateConversion, pagePermission, showMessage } from "app/utils/app-functions";
import Alert from "app/components/atoms/alert";
import moment from "moment";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { getOtherLeave } from "app/api/OtherLeaveApi";
import { updateOtherLeave } from "app/api/OtherLeaveApi";
import { createOtherLeave } from "app/api/OtherLeaveApi";
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false;

const OtherLeaves = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getOtherLeaveSuccess = useSelector((state) => state.otherLeaveReducer.getOtherLeaveSuccess);
    const getOtherLeaveList = useSelector((state) => state.otherLeaveReducer.getOtherLeaveList);
    const getOtherLeaveFailure = useSelector((state) => state.otherLeaveReducer.getOtherLeaveFailure);

    const createOtherLeaveSuccess = useSelector((state) => state.otherLeaveReducer.createOtherLeaveSuccess);
    const createOtherLeaveData = useSelector((state) => state.otherLeaveReducer.createOtherLeaveData);
    const createOtherLeaveFailure = useSelector((state) => state.otherLeaveReducer.createOtherLeaveFailure);

    const updateOtherLeaveSuccess = useSelector((state) => state.otherLeaveReducer.updateOtherLeaveSuccess);
    const updateOtherLeaveData = useSelector((state) => state.otherLeaveReducer.updateOtherLeaveData);
    const updateOtherLeaveFailure = useSelector((state) => state.otherLeaveReducer.updateOtherLeaveFailure);

    const otherLeavesErrorMessage = useSelector((state) => state.otherLeaveReducer.errorMessage);

    const [pageItems, setpageItems] = useState({
    });

    useEffect(() => {
        let data = pagePermission(pageItems, "Settings", "Other Leave")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "leaveName",
            "text": "Leave Name",
            "sort": true
        },
        {
            "dataField": "startDate",
            "text": "Start Date",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return dateConversion(row.startDate, "DD-MMM-YYYY")
            }
        },
        {
            "dataField": "toDate",
            "text": "To Date",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return dateConversion(row.toDate, "DD-MMM-YYYY")
            }
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
        {
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index);
             
                        }}
                    ></FiTrash2>}
                </div>
            )
        },
    ]

    const [state, setState] = useState({});

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getOtherLeave(requestData))
    }, [navigation]);


   

    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }
    useEffect(() => {
        if (getOtherLeaveSuccess) {
            setItemList(getOtherLeaveList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_OTHER_LEAVE" })
        } else if (getOtherLeaveFailure) {
            setItemList([])
            showMessage("warning", otherLeavesErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_OTHER_LEAVE" })
        }
    }, [getOtherLeaveSuccess, getOtherLeaveFailure]);

    useEffect(() => {
        if (createOtherLeaveSuccess) {
            const temp_state = [...itemList, createOtherLeaveData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_OTHER_LEAVE" })
        } else if (createOtherLeaveFailure) {
            setLoaderModule(false)
            showMessage("warning", otherLeavesErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_OTHER_LEAVE" })
        }
    }, [createOtherLeaveSuccess, createOtherLeaveFailure]);


    useEffect(() => {
        if (updateOtherLeaveSuccess) {
            updateTable(updateOtherLeaveData[0])
            dispatch({ type: "RESET_UPDATE_OTHER_LEAVE" })
        } else if (updateOtherLeaveFailure) {
            setLoaderModule(false)
            showMessage("warning", otherLeavesErrorMessage)
            dispatch({ type: "RESET_UPDATE_OTHER_LEAVE" })
        }
    }, [updateOtherLeaveSuccess, updateOtherLeaveFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.otherLeaveId != updatedItem.otherLeaveId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            holidayName: "",
            holidayDate: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            holidayName: data?.leaveName || "",
            holidayDate: [data?.startDate , data?.toDate],
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            name: holidayName,
            startDate: holidayDate ?  holidayDate[0] : "" ,
            endDate: holidayDate ?  holidayDate[1] : "" 
        }
        
        // return
        if (editData) {
            setLoaderModule(true)
            dispatch(updateOtherLeave(requestDate, selectedItem.otherLeaveId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            dispatch(updateOtherLeave(deleteRequest, selectedItem.otherLeaveId))
        } else {
            setLoaderModule(true)
            dispatch(createOtherLeave(requestDate))
        }
    }

    const {
        holidayName,
        holidayDate,
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const { sidebarOpen } = useSidebar();

    return (
        <div>
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>

                <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} isLoading={loaderModule} title={`${modelTitle} Other Leaves`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={holidaysForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>

                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} isLoading={loaderModule} title="Delete Other Leaves">
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>
                </View>

                <SimpleCard >
                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Other Leave"} columns={column} data={itemList} state={state} />
                </SimpleCard>
            </div>
        </div>
    )
};

export default OtherLeaves;
