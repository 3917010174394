import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const View = ({ size = 'sm', isOpen, onClose, children, title, onSubmit,disable,isLoading }) => {
    const [closing, setClosing] = useState(false);
    const [showModal, setShowModal] = useState(isOpen);

    const handleClose = () => {
        setClosing(true);
        isLoading=false
    };

    useEffect(() => {
        if (isOpen) {
            setShowModal(true);
            setClosing(false);
        } else {
            setClosing(true);
        }
    }, [isOpen]);

    useEffect(() => {
        if (closing) {
            const timer = setTimeout(() => {
                setShowModal(false);
                onClose();
            }, 300);

            return () => clearTimeout(timer);
        }
    }, [closing, onClose]);

    if (!showModal) return null;

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            handleClose();
        }
    };

    return (
        <ModalOverlay closing={closing} >
            <ModalContent size={size} closing={closing}>
                <ModalHeader>
                    <ModalTitle>{title}</ModalTitle>
                    <Close_Button onClick={handleClose}>×</Close_Button>
                </ModalHeader>
                <ModalBody>{children}</ModalBody>
                <ModalFooter>
                    <CloseButton onClick={handleClose}>Close</CloseButton>
                    {onSubmit && <SubmitButton onClick={onSubmit}  disabled={disable? disable : isLoading}>Submit</SubmitButton>}
                </ModalFooter>
            </ModalContent>
        </ModalOverlay>
    );
};

const fadeIn = keyframes`
    to {
        opacity: 1;
        transform: scale(1);
    }
`;

const fadeOut = keyframes`
    to {
        opacity: 0;
        transform: scale(0.8);
    }
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
    transition: opacity 0.3s ease;
    opacity: ${(props) => (props.closing ? 0 : 1)};
    margin:0px;
`;

const ModalContent = styled.div`
    background: #fff;
    position: relative;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    opacity: 0;
    transform: scale(0.8);
    animation: ${(props) => (props.closing ? fadeOut : fadeIn)} 0.3s forwards;

    ${(props) => props.size === 'sm' && `width: 300px; height: auto;`}

    ${(props) => props.size === 'md' && `width: 50%; height: auto;`}

    ${(props) => props.size === 'nr' && `width: 65%; height: auto;`}

    ${(props) => props.size === 'lg' && `width: 80%; height: auto;`}

    ${(props) => props.size === 'xl' && `width: auto; height: auto;`}

    ${(props) => props.size === 'fullscreen' && `
        width: 100%;
        height: 100%;
        max-width: none;
        border-radius: 0;
        padding: 5px;
    `}

    overflow: hidden; /* Ensure footer stays in place */
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: ${({ theme }) => theme.primaryColor};
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    margin-top:-5px;
    
`;

const ModalTitle = styled.h4`
    font-size: 13.5px;
    font-weight: bold;
    color: white;
`;

const ModalBody = styled.div`
    padding: 20px;
    max-height: 80vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #4d6d9a;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items:center;
    padding: 10px;
    border-top: 1px solid #ddd;
    background: white;
    border-radius: 0 0 8px 8px;
    width: 100%;
    height:10%;
`;

const Button = styled.button`
    font-size: 14px;
    padding: 5px 20px;
    border-radius: 5px;
    cursor: pointer;
    height: 35px;
`;

const CloseButton = styled(Button)`
    background: transparent;
    color: black;
    border: 1px solid gray;
    height: 35px;

`;
const Close_Button = styled(Button)`
    background: transparent;
    color: white;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
    margin-left: auto;
    &:hover {
        color: #ddd;
    }
`;

const SubmitButton = styled(Button)`
    background-color: ${({ theme, disabled }) => (disabled ? '#ccc' : theme.primaryColor)};
    color: ${({ disabled }) => (disabled ? '#666' : 'white')};
    border: transparent;
    margin-left: 10px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export default View;
