import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import FormLayout from "app/views/formlayout";
import { feesEnrollmentForm, feesForm } from "./formData";
import { MdEdit, MdDelete, MdOutlineAddCircleOutline } from "react-icons/md";
import { FiEdit ,FiTrash2 } from "react-icons/fi";
import { lightTheme } from "styles/theme";
import swal from "sweetalert2";
import _ from "lodash";
import formRender from "app/utils/formcomponent";
import { createFeesEnrollment, getFeesEnrollment, updateFeesEnrollment } from "app/api/FeesEnrollmentApi";
import { additionvalues, dateConversion, pagePermission, showMessage } from "app/utils/app-functions";
import { getEnrollmentDetails } from "app/api/EnrollementDetailsApi";
import { getFeesType } from "app/api/FeesTypeApi";
import { getBatch } from "app/api/BatchApi";
import moment from "moment";
import View from "app/components/atoms/modalView/Modalview";
import SimpleCard from "app/components/atoms/simple-card/SimpleCard";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import TableLayout from "app/components/render/formlayout/tablelayout";
import { asyncGetEnrollmenetDetailsInfo } from "app/api/EnrollementDetailsApi";
import { asyncGetBatchInfo } from "app/api/BatchApi";
import { asyncGetFeesTypeInfo } from "app/api/FeesTypeApi";
import { useSidebar } from "app/components/sidebar/sidebarContext";

import Alert from "app/components/atoms/alert";
import Loading from "app/components/atoms/loading";
let editData = false;




const FeesEnrollment = ({ navigation }) => {

  const dispatch = useDispatch();
  const customerFormRef = useRef();

  const getFeesEnrollmentSuccess = useSelector((state) => state.feesEnrollmentReducer.getFeesEnrollmentSuccess);
  const getFeesEnrollmentList = useSelector((state) => state.feesEnrollmentReducer.getFeesEnrollmentList);
  const getFeesEnrollmentFailure = useSelector((state) => state.feesEnrollmentReducer.getFeesEnrollmentFailure);

  const getEnrollmentDetailsSuccess = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsSuccess);
  const getEnrollmentDetailsList = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsList);
  const getEnrollmentDetailsFailure = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsFailure);
  const enrollmentDetailsErrorMessage = useSelector((state) => state.enrollmentDetailsReducer.errorMessage);

  const getFeesTypeSuccess = useSelector((state) => state.feesTypeReducer.getFeesTypeSuccess);
  const getFeesTypeList = useSelector((state) => state.feesTypeReducer.getFeesTypeList);
  const getFeesTypeFailure = useSelector((state) => state.feesTypeReducer.getFeesTypeFailure);
  const feesTypeErrorMessage = useSelector((state) => state.feesTypeReducer.errorMessage);

  const getBatchSuccess = useSelector((state) => state.batchReducer.getBatchSuccess);
  const getBatchList = useSelector((state) => state.batchReducer.getBatchList);
  const getBatchFailure = useSelector((state) => state.batchReducer.getBatchFailure);
  const batchErrorMessage = useSelector((state) => state.batchReducer.errorMessage);

  const createFeesEnrollmentSuccess = useSelector((state) => state.feesEnrollmentReducer.createFeesEnrollmentSuccess);
  const createFeesEnrollmentData = useSelector((state) => state.feesEnrollmentReducer.createFeesEnrollmentData);
  const createFeesEnrollmentFailure = useSelector((state) => state.feesEnrollmentReducer.createFeesEnrollmentFailure);

  const updateFeesEnrollmentSuccess = useSelector((state) => state.feesEnrollmentReducer.updateFeesEnrollmentSuccess);
  const updateFeesEnrollmentData = useSelector((state) => state.feesEnrollmentReducer.updateFeesEnrollmentData);
  const updateFeesEnrollmentFailure = useSelector((state) => state.feesEnrollmentReducer.updateFeesEnrollmentFailure);

  const feesEnrollmentErrorMessage = useSelector((state) => state.feesEnrollmentReducer.errorMessage);



  const [pageItems, setpageItems] = useState({
    "viewOption": false,
    "create": true,
    "update": false,
    "delete": false
  }
  );

  useEffect(() => {
    let data = pagePermission(pageItems, "Administrator", "Create Fees")
    setpageItems(data)

  }, [navigation]);

  const column = [
    {
      "dataField": "index",
      "text": "S.No",
      "sort": true,
      formatter: (cellContent, row, index) => (
        index + 1
      )
    },
    {
      dataField: "",
      text: "className-Section",
      sort: true,
      formatter: (cellContent, row, index) => {
        console.log(row); // Correctly logs the row
        return (row?.classList || []).map((item, idx) => {
          // alert(item.className + " - " + item.sectionName); // Alerts the className and sectionName
          return idx === 0
            ? item.className + " - " + item.sectionName
            : ", " + item.className + " - " + item.sectionName;
        }).join(''); // Joins the strings for proper formatting
      }
    },

    {
      "dataField": "feesName",
      "text": "Fees Name",
      "sort": true,
    },
    {
      "dataField": "feesAmount",
      "text": "Amount",
      "sort": true,
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => {
        const conInfo = { inputType: "status", status: cellContent }
        return formRender(null, null, conInfo, null);
      }
    },
    /* {
        text: "Action",
        sort: false,
        align: "center",
        headerAlign: "center",
        formatter: (cellContent, row, index) => (
            <div >
                {pageItems.update && <FiEdit
                    className="text-success cursor-pointer"
                    size={18}
                    onClick={() => onEditForm(row, index)}
                ></FiEdit>}
                {pageItems.delete &&<MdDelete
                    className="text-danger cursor-pointer"
                    size={18}
                    onClick={() => {
                        swal
                            .fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                type: "question",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!",
                                cancelButtonText: "No",
                            })
                            .then((result) => {
                                if (result.value) {

                                    onDeleteItem(row, index);
                                } else {
                                    swal.fire({
                                        title: "Cancelled!",
                                        text: "Permission denied.",
                                        type: "error",
                                        icon: "error",
                                        timer: 1500,
                                    });
                                }
                            });
                    }}
                ></MdDelete>}
            </div>
        )
    }, */
  ]


  if (pageItems.update || pageItems.delete) {
    column.push({
      text: "Action",
      sort: false,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => (
        <div className="icon-container">
          {pageItems.update && <FiEdit
            className="text-primary cursor-pointer"
            size={18}
            onClick={() => onEditForm(row, index)}
          ></FiEdit>}
          {pageItems.delete && <FiTrash2
            className="text-danger cursor-pointer"
            size={18}
            onClick={() => {
              swal
                .fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  type: "question",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                  cancelButtonText: "No",
                })
                .then((result) => {
                  if (result.value) {

                    onDeleteItem(row, index);
                  } else {
                    swal.fire({
                      title: "Cancelled!",
                      text: "Permission denied.",
                      type: "error",
                      icon: "error",
                      timer: 1500,
                    });
                  }
                });
            }}
          ></FiTrash2>}
        </div>
      )
    });
  }

  const [state, setState] = useState({
    feesEnrollmentName: "",
    feesEnrollmentStart: "",
    feesEnrollmentEnd: "",
    feesAmount: 0,
    enrollmentData: []
  });

  const [createModule, setCreateModule] = useState(false);
  const [loaderModule, setLoaderModule] = useState(false);
  const [editModule, setEditModule] = useState(false);
  const [deleteModule, setDeleteModule] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [subFeesState, setSubFeesState] = useState([{
    subFees: "",
    feesTypeId: ""
  }]);


  const listFetching = async () => {
    const requestData = {
      isActive: "true",
    }
    let enrollmentDetailsList = await asyncGetEnrollmenetDetailsInfo(requestData)
    let feesTypeList = await asyncGetFeesTypeInfo(requestData)
    let batchList = await asyncGetBatchInfo(requestData)



    setState({
      ...state,
      // schoolList:schoolInfoList?.getSchoolInfoList || [],
      enrollmentDetailsList: enrollmentDetailsList?.data || [],
      batchList: batchList?.data || [],
      feesTypeList: feesTypeList?.data || [],

    })

  }


  useEffect(() => {
    setLoaderModule(true)
    const requestData = {
      isActive: "true"
    }
    dispatch(getFeesEnrollment(requestData))
    // dispatch(getEnrollmentDetails(requestData))
    // dispatch(getFeesType(requestData))
    // dispatch(getBatch(requestData))
    listFetching()
  }, [navigation]);

  useEffect(() => {
    if (getFeesEnrollmentSuccess) {
      setItemList(getFeesEnrollmentList)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_FEES_ENROLLMENT" })
    } else if (getFeesEnrollmentFailure) {
      setItemList([])
      showMessage("warning", feesEnrollmentErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_FEES_ENROLLMENT" })
    }
  }, [getFeesEnrollmentSuccess, getFeesEnrollmentFailure]);

  useEffect(() => {
    if (getBatchSuccess) {
      setState({
        ...state,
        batchList: getBatchList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_BATCH" })
    } else if (getBatchFailure) {
      setState({
        ...state,
        batchList: []
      })
      showMessage("warning", batchErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_BATCH" })
    }
  }, [getBatchSuccess, getBatchFailure]);

  useEffect(() => {
    if (getEnrollmentDetailsSuccess) {
      setState({
        ...state,
        enrollmentDetailsList: getEnrollmentDetailsList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
    } else if (getEnrollmentDetailsFailure) {
      setState({
        ...state,
        enrollmentDetailsList: []
      })
      showMessage("warning", enrollmentDetailsErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
    }
  }, [getEnrollmentDetailsSuccess, getEnrollmentDetailsFailure]);

  useEffect(() => {
    if (getFeesTypeSuccess) {
      setState({
        ...state,
        feesTypeList: getFeesTypeList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_FEES_TYPE" })
    } else if (getFeesTypeFailure) {
      setState({
        ...state,
        feesTypeList: []
      })
      showMessage("warning", feesTypeErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_FEES_TYPE" })
    }
  }, [getFeesTypeSuccess, getFeesTypeFailure]);

  useEffect(() => {
    if (createFeesEnrollmentSuccess) {
      const temp_state = [...itemList, createFeesEnrollmentData[0]];
      setItemList(temp_state)
      closeModule()
      setLoaderModule(false)
      Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      dispatch({ type: "RESET_CREATE_FEES_ENROLLMENT" })
    } else if (createFeesEnrollmentFailure) {
      setLoaderModule(false)
      showMessage("warning", feesEnrollmentErrorMessage?.message)
      dispatch({ type: "RESET_CREATE_FEES_ENROLLMENT" })
    }
  }, [createFeesEnrollmentSuccess, createFeesEnrollmentFailure]);

  useEffect(() => {
    if (updateFeesEnrollmentSuccess) {
      updateTable(updateFeesEnrollmentData[0])
      dispatch({ type: "RESET_UPDATE_FEES_ENROLLMENT" })
    } else if (updateFeesEnrollmentFailure) {
      setLoaderModule(false)
      showMessage("warning", feesEnrollmentErrorMessage?.message)
      dispatch({ type: "RESET_UPDATE_FEES_ENROLLMENT" })
    }
  }, [updateFeesEnrollmentSuccess, updateFeesEnrollmentFailure]);

  const updateTable = (updatedItem) => {
    const temp_state = [...itemList];
    if (deleteModule) {
      let remainingList = _.remove(temp_state, function (n) {
        return n.feesEnrollmentId != updatedItem.feesEnrollmentId;
      });
      Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(remainingList)
    } else {
      temp_state[selectedIndex] = updatedItem;
      Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(temp_state)
    }
    setLoaderModule(false)
    closeModule()
  }

  const closeModule = () => {
    editData = false;
    setCreateModule(false)
    setDeleteModule(false)
  }

  const resetState = () => {
    setState({
      ...state,
      enrollmentIds: "",
      feesTypeId: "",
      feesAmount: 0,
      feesName: '',
      batchId: "",
      startDate: moment(new Date(), 'YYYY-MM-DD'),
      dueDate: moment(new Date(), 'YYYY-MM-DD'),
    })
  }

  const onCreateForm = () => {
    setSubFeesState([])
    resetState()
    editData = false;
    setCreateModule(true)
  }

  const onEditForm = (data, index) => {
    const selectedIdsEnroll = state?.enrollmentDetailsList || []
    // const enrollmentIdsData = data?.enrollmentIds.split(',') || []
    const enrollmentIdsData = data?.enrollmentIds?.split(",").map(Number) || [];

    // const temp_enrollment_arr = selectedIdsEnroll.filter(obj => enrollmentIdsData.includes(obj.enrollmentId.toString()));

    setState({
      ...state,
      enrollmentIds: enrollmentIdsData,
      feesTypeId: data?.feesTypeId || "",
      feesAmount: data?.feesAmount || 0,
      feesName: data?.feesName || "",
      batchId: data?.batchId || "",
      startDate: data?.startDate ? moment(data?.startDate, 'YYYY-MM-DD') : "",
      dueDate: data?.dueDate ? moment(data?.dueDate, 'YYYY-MM-DD') : "",
    })
    editData = true;
    setSubFeesState(data.feesItem)
    setSelectedIndex(index)
    setSelectedItem(data)
    setCreateModule(true)
  }

  const onDeleteItem = (data, index) => {
    editData = false;
    setSelectedIndex(index)
    setSelectedItem(data)
    setDeleteModule(true)
  }

  const validateForm = async () => {
    const validatefom = customerFormRef.current.validateForm();
  }

  const validateFields = (fields, data, context) => {
    for (let key of fields) {
      const keys = key.split("?."); // Split for nested object access
      let value = data;
      for (let k of keys) {
        value = value?.[k];
        if (value === undefined) break; // Stop if key doesn't exist
      }

      if (!value || value === "") {
        Alert({
          title: "Warning!",
          description: `PLEASE FILL IN THE ${key.replace(/\?\./g, ".").toUpperCase()} FIELD${context ? ` IN ${context}` : ""}.`,
          type: "warning",
          placement: "topRight",
          duration: 3,
        });
        return false;
      }
    }
    return true;
  };


  const onSubmitForm = () => {
    const selectedEnrollmentId = state?.enrollmentIds || ""
    const selectArray = []; // Initialize the array to store the selected object.

    const list = state?.enrollmentDetailsList?.find(
      (item) => item.enrollmentId === parseInt(selectedEnrollmentId)
    );

    if (list) { // Ensure that `list` is not undefined or null before pushing.
      selectArray.push(list);
    }

    console.log(selectedEnrollmentId); // To verify the result.





    if (selectArray || deleteModule) {
      const enrollmentIds = selectArray.map((ele) => {
        return ele.enrollmentId
      })
      const feesTypeId = subFeesState.map((ele) => {
        return ele.feesTypeId
      })

      subFeesState.forEach(subFee => {
        let matchedFeeType = state.feesTypeList.find(feeType => feeType.feesTypeId == subFee.feesTypeId);
        if (matchedFeeType) {
          subFee.feesTypeName = matchedFeeType.feesTypeName;
        }
      });

      const requestDate = {
        enrollmentIds: selectedEnrollmentId ? selectedEnrollmentId.join(',') : '',
        feesTypeIds: feesTypeId.toString(),
        feesAmount: feesAmount,
        batchId: batchId || 1,
        feesName: feesName,
        startDate: startDate,
        dueDate: dueDate,
        feesItem: JSON.stringify(subFeesState)
      }
      console.log(requestDate);
      const requiredFields = ["enrollmentIds", "feesTypeIds", "feesAmount", "batchId", "feesName", "startDate", "dueDate"];
      if (!validateFields(requiredFields, requestDate)) return;

      const feesItem = JSON.parse(requestDate.feesItem);

      const hasUnfilledData = feesItem.some(
        (item) => !item.subFees || !item.feesTypeId
      );

      if (hasUnfilledData) {
        Alert({
          title: "Warning!",
          description: "Please remove unfilled data in feesItem.",
          type: "warning",
          placement: "topRight",
          duration: 3,
        });
        return;
      }

      if (editData) {
        setLoaderModule(true)
        dispatch(updateFeesEnrollment(requestDate, selectedItem.feesEnrollmentId))
      } else if (deleteModule) {
        const deleteRequest = {
          isActive: false
        }
        setLoaderModule(true)
        dispatch(updateFeesEnrollment(deleteRequest, selectedItem.feesEnrollmentId))
      } else {
        setLoaderModule(true)
        dispatch(createFeesEnrollment(requestDate))
      }
    }
    else {
      Alert({ description: 'Fill all the details.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
    }

  }

  const addproof = () => {
    const feesData = {
      "subFees": "",
      "feesTypeId": ""
    }
    setSubFeesState([...subFeesState, feesData])
  }

  const onSubFees = (event, name, index) => {
    let temp_state = [...subFeesState];
    temp_state[index][name] = event.target.value;
    let feesAmount = _.sumBy(temp_state, function (o) { return parseFloat(o?.subFees || 0); });
    let fees = { ...state }
    fees.feesAmount = feesAmount
    setState(fees);
    setSubFeesState(temp_state)

  }

  const onRemoveItem = (index) => {
    let temp_state = [...subFeesState];
    temp_state.splice(index, 1);
    setSubFeesState(temp_state)
    let feesAmount = _.sumBy(temp_state, function (o) { return parseFloat(o?.subFees || 0); });
    let fees = { ...state }
    fees.feesAmount = feesAmount
    setState(fees);
  }
  const {
    batchId,
    dueDate,
    feesName,
    startDate,
    feesTypeId,
    feesAmount
  } = state;

  const handleClick = (buttonName) => {
    if (buttonName == 'Add') {
      onCreateForm();
    }
  };

  const modelTitle = editData ? "Edit " : "Create ";
  const modelButtonLbl = editData ? "" : "Save";
  const { sidebarOpen } = useSidebar();

  return (
    <div >
      <Loading status={loaderModule} />

      <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>

        <View isOpen={createModule} size="md" savetitle={modelButtonLbl} onClose={setCreateModule} isLoading={loaderModule} onSubmit={onSubmitForm} title={`${modelTitle} Fees Enrollment`}>
          {/* <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={feesEnrollmentForm} noOfColumn={1} onSubmit={onSubmitForm} onSearch={() => addproof()} /> */}
          <div className="row">
            <div className="col-lg-12">
              <div >
                <div className="p-0 mh-50">
                  <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={feesEnrollmentForm} noOfColumn={3}  onSubmit={onSubmitForm} onSearch={() => addproof()} />
                </div>
              </div>
            </div>

            <div className="col-lg-12 mh-50">
              <div className="px-1 mb-3" >
                <div className="py-0 " >
                  <div className="d-flex justify-content-end">
                    <div style={{ zIndex: "999" }}>
                      <Button className="float-end" style={{ border: "none", backgroundColor: `${lightTheme.primaryColor}`, fontSize: "12px" }} onClick={() => addproof()} >
                        {/* {<MdOutlineAddCircleOutline size={24} />} */}Add Fees
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive mt-0">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Fees Type</th>
                      <th scope="col">Fees Amount</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(subFeesState ? subFeesState : []).map((item, index) => {
                      return (
                        <TableLayout key={index} defaultState={state} setDefaultState={setSubFeesState} onChangeCallback={{ "onSubFees": onSubFees }} index={index} dataList={subFeesState} layoutType='table' dynamicForm={feesForm} iconPress={{ "onRemove": onRemoveItem }} /* onSubmit={onSubmit} */ />
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </View>

        <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" isLoading={loaderModule} onSubmit={onSubmitForm} title="Delete Fees Enrollment">
          <h6 className="text-capitalize mb-1">Are you sure ?</h6>
        </View>

        <SimpleCard >
          <CustomTable columns={column} data={itemList} buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Fees Enrollment"} state={state} />

        </SimpleCard>


      </div>
    </div>
  )
}

export default FeesEnrollment;