const initialState = {
    submitHomeworkList: []  
  }
  
  const SubmitHomeworkReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SUBMIT_HOMEWORK_SUCCESS": {             
        return {
          ...state,
          getSubmitHomeworkSuccess: true,
          getSubmitHomeworkList: action.payload.data,
        };
      }
      case "GET_SUBMIT_HOMEWORK_FAILURE": {
        return {
          ...state,
          getSubmitHomeworkFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SUBMIT_HOMEWORK": {
        return {
          ...state,
          getSubmitHomeworkSuccess: false,
          getSubmitHomeworkFailure: false,
          getSubmitHomeworkList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SUBMIT_HOMEWORK_SUCCESS": {             
        return {
          ...state,
          createSubmitHomeworkSuccess: true,
          createSubmitHomeworkData: action.payload.data,
        };
      }
      case "CREATE_SUBMIT_HOMEWORK_FAILURE": {
        return {
          ...state,
          createSubmitHomeworkFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SUBMIT_HOMEWORK": {
        return {
          ...state,
          createSubmitHomeworkSuccess: false,
          createSubmitHomeworkFailure: false,
          createSubmitHomeworkData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SUBMIT_HOMEWORK_SUCCESS": {             
        return {
          ...state,
          updateSubmitHomeworkSuccess: true,
          updateSubmitHomeworkData: action.payload.data,
        };
      }
      case "UPDATE_SUBMIT_HOMEWORK_FAILURE": {
        return {
          ...state,
          updateSubmitHomeworkFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SUBMIT_HOMEWORK": {
        return {
          ...state,
          updateSubmitHomeworkSuccess: false,
          updateSubmitHomeworkFailure: false,
          updateSubmitHomeworkData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default SubmitHomeworkReducer
  
  