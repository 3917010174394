import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete, MdVisibility } from "react-icons/md";

import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { batchEditForm, batchForm } from "./formData";
import { createBanner, getBanner, updateBanner } from "app/api/BannerApi";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import Alert from "app/components/atoms/alert";
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { imagePath } from "app/utils/constant";
import { getUnitFilter } from "app/api/UnitApi";
import { asyncGetUnitFilterInfo } from "app/api/UnitApi";
import { uploadFiles } from "app/api/DocumentApi";
import { dateConversion } from "app/utils/app-functions";

let editData = false;

const Banner = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getBannerSuccess = useSelector((state) => state.bannerReducer.getBannerSuccess);
    const getBannerList = useSelector((state) => state.bannerReducer.getBannerList);
    const getBannerFailure = useSelector((state) => state.bannerReducer.getBannerFailure);

    const createBannerSuccess = useSelector((state) => state.bannerReducer.createBannerSuccess);
    const createBannerData = useSelector((state) => state.bannerReducer.createBannerData);
    const createBannerFailure = useSelector((state) => state.bannerReducer.createBannerFailure);

    const updateBannerSuccess = useSelector((state) => state.bannerReducer.updateBannerSuccess);
    const updateBannerData = useSelector((state) => state.bannerReducer.updateBannerData);
    const updateBannerFailure = useSelector((state) => state.bannerReducer.updateBannerFailure);

    const batchErrorMessage = useSelector((state) => state.bannerReducer.errorMessage);

    const [pageItems, setpageItems] = useState({

    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Super Admin", "Banner")
        setpageItems(data)

    }, [navigation]);

    const column = [
/* 
        {
            "dataField": "image",
            "text": "Image",
            "sort": true,
            formatter: (cellContent, row, index, extraData) => {


                const url = `${imagePath}units/${row.unitToken}/unitinfo/${encodeURI(row.image)}`;
                console.log(url);

                return <img
                    key={`${row.image}-${new Date().getTime()}`}
                    className="avatar-lg active"
                    src={url}
                    alt=""
                    style={{ objectFit: "contain", height: "70px", width: "100px", cursor: "pointer" }}
                    onClick={() => handleViewImage(url)}
                />
            }
        }, */
        {
            dataField: "unitName",
            text: "Unit Name",
            sort: false,
        },
        {
            dataField: "validTill",
            text: "Valid Till",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                return dateConversion(cellContent, 'DD-MMM-YYYY');
            }
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
    ]

    const handleViewImage = (image) => {
        window.open(image, "_blank");
    };

    if (pageItems.delete || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                let path = "app/banner"; // Default path
                if (row?.unitToken) {
                    path = `units/${row.unitToken}/banner`;
                }

                const url = `${imagePath}${path}/${encodeURI(row.image)}`;

                return (
                    <div className="icon-container">
                        {pageItems.view && (
                            <MdVisibility
                                className="text-primary cursor-pointer"
                                size={18}
                                onClick={() => handleViewImage(url)} // Handle view image click
                            />
                        )}
                        {pageItems.update && (
                            <FiEdit
                                className="text-primary cursor-pointer"
                                size={18}
                                onClick={() => onEditForm(row, index)} // Handle edit form click
                            />
                        )}
                        {pageItems.delete && (
                            <FiTrash2
                                className="text-danger cursor-pointer"
                                size={18}
                                onClick={() => onDeleteItem(row, index)} // Handle delete item click
                            />
                        )}
                    </div>
                );
            }

        })
    }
    const [state, setState] = useState({
        batchName: "",
        batchStart: "",
        batchEnd: ""
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getBanner(requestData))
        listFetching()
    }, [navigation]);

    const listFetching = async () => {
        let unitList = await asyncGetUnitFilterInfo()
        setState({
            ...state,
            unitList: unitList?.data || [],
        })
    }

    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])

    useEffect(() => {
        if (getBannerSuccess) {
            setItemList(getBannerList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BANNER" })
        } else if (getBannerFailure) {
            setItemList([])
            showMessage("warning", batchErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BANNER" })
        }
    }, [getBannerSuccess, getBannerFailure]);

    useEffect(() => {
        if (createBannerSuccess) {
            const temp_state = [...itemList, createBannerData[0]];
            setItemList(temp_state)
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            closeModule()
            setLoaderModule(false)
            dispatch({ type: "RESET_CREATE_BANNER" })
        } else if (createBannerFailure) {
            setLoaderModule(false)
            Alert({ type: "error", description: batchErrorMessage })
            dispatch({ type: "RESET_CREATE_BANNER" })
        }
    }, [createBannerSuccess, createBannerFailure]);

    useEffect(() => {
        if (updateBannerSuccess) {

            updateTable(updateBannerData[0])

            dispatch({ type: "RESET_UPDATE_BANNER" })
        } else if (updateBannerFailure) {
            setLoaderModule(false)
            Alert({ type: "warning", description: batchErrorMessage })
            dispatch({ type: "RESET_UPDATE_BANNER" })
        }
    }, [updateBannerSuccess, updateBannerFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.bannerId != updatedItem.bannerId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            image: "",
            unitId: "",
            validTill: '',
            isNew: false,
            isDefault: false,
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            image: data?.image || '',
            isDefault: data?.isDefault || '',
            unitId: data?.unitId || '',
            validTill: data?.validTill || '',
            unitToken: data?.unitToken || '',
            isNew: false,
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        /* if (!state.unitId && !state.isDefault) {
            Alert({ description: 'Select Unit', type: 'warning' });
            return true
        } */

        if (state.isNew) {
            formDataAppend(state.formData)
        }

        const requestDate = {
            image: state?.image || '',
            validTill: state?.validTill || '',
            isDefault: state?.isDefault || false,
        }
        // if (state?.unitId) {
            requestDate.unitId = state?.unitId || null;
        // }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateBanner(requestDate, selectedItem.bannerId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: false
            }
            setLoaderModule(true)
            dispatch(updateBanner(deleteRequest, selectedItem.bannerId))
        } else {
            setLoaderModule(true)
            dispatch(createBanner(requestDate))
        }
    }

    const {
        batchName,
        batchStart,
        batchEnd
    } = state;

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const handleCloseModal = () => {
        setCreateModule(false);
    };

    const onWriteDocument = (event) => {
        let file = event.target.files[0];
        let fileName = file?.name;
        const now = new Date();
        const fileExtension = fileName.split('.').pop();
        const year = now.getFullYear().toString();
        const uniqueIdentifier = Math.floor(1000 + Math.random() * 9000).toString();
        const day = now.getDate().toString().padStart(2, '0');
        const docName = `${year}${day}${uniqueIdentifier}.${fileExtension}`;

        const renamedFile = new File(
            [file],
            docName,
            {
                type: file?.type,
                name: docName,
                lastModified: file?.lastModified,
            }
        );
        setState({
            ...state,
            isNew: true,
            formData: renamedFile,
            [event.target.name]: docName,
        })
    };

    const formDataAppend = (data) => {
        const formData = new FormData();
        formData.append('document', data);
        let path = ''
        if (state?.unitToken) {
            path = "units/" + state.unitToken + "/banner"
        }
        else {
            path = "app/banner"
        }
        dispatch(uploadFiles(formData, path));
    }

    const onSelectUnit = (event, name) => {
        let unitList = state?.unitList || []
        let data = unitList.filter(ele => ele.unitId == event)
        setState({
            ...state,
            [name]: event,
            unitToken: data[0]?.unitToken || '',
        })
    }
    const onDefault = (event, name) => {
        setState({
            ...state,
            [name]: event.target.checked,
            unitId: '',
        })
    }

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const { sidebarOpen } = useSidebar();

    return (
        <div>
            <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
                {/* <SmallLoader value={loaderModule} /> */}
                <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={handleCloseModal} onSubmit={validateForm} isLoading={loaderModule} title={`${modelTitle} Banner`}>
                    {editData ? <FormLayout onChangeCallback={{ "onDocument": onWriteDocument, "onSelectUnit": onSelectUnit, "onDefault": onDefault }} ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={batchEditForm} noOfColumn={1} onSubmit={onSubmitForm} /> :
                    <FormLayout onChangeCallback={{ "onDocument": onWriteDocument, "onSelectUnit": onSelectUnit, "onDefault": onDefault }} ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={batchForm} noOfColumn={1} onSubmit={onSubmitForm} />
                    }
                </View>


             {/*    <View centered={true} size={'sm'} isOpen={viewModule} onClose={setviewModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Banner">
                </View> */}

                <SimpleCard >
                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Banner"} columns={column} data={itemList} initialPage={1} />
                </SimpleCard>

            </div>
        </div>
    )
};

export default Banner;
