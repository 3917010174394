import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { purchaseAddForm, homeWorkForm, fieldList, inner } from "./formData";
import TableLayout from "app/components/render/formlayout/tablelayout";
import CollapseView from "app/components/atoms/collapse-view/CollapseView";
import { Table } from "react-bootstrap";
import { dateConversion } from "app/utils/app-functions";
import { getExamVariant } from "app/api/ExamVariant";
import { getQuiz, createQuiz, updateQuiz } from "app/api/QuizApi";
import { getStudentClassList } from "app/api/StudentListApi";
import Alert from "app/components/atoms/alert";
import { timeConversion } from "app/utils/app-functions";
import { FiEdit } from "react-icons/fi";
import moment from "moment";
import dayjs from 'dayjs';
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { getAsyncStudentClassList } from "app/api/StudentListApi";
import DurationPicker from "app/components/Duration";
let editData = false;

const CreateQuiz = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();
    const purchaseFormRef = useRef()
    const jobHistoryModel = useRef();

    const getExamVariantSuccess = useSelector((state) => state.examVariantReducer.getExamVariantSuccess);
    const getExamVariantList = useSelector((state) => state.examVariantReducer.getExamVariantList);
    const getExamVariantFailure = useSelector((state) => state.examVariantReducer.getExamVariantFailure);

    const getQuizSuccess = useSelector((state) => state.quizReducer.getQuizSuccess);
    const getQuizList = useSelector((state) => state.quizReducer.getQuizList);
    const getQuizFailure = useSelector((state) => state.quizReducer.getQuizFailure);
    const quizErrorMessage = useSelector((state) => state.quizReducer.errorMessage);

    const createQuizSuccess = useSelector((state) => state.quizReducer.createQuizSuccess);
    const createQuizData = useSelector((state) => state.quizReducer.createQuizData);
    const createQuizFailure = useSelector((state) => state.quizReducer.createQuizFailure);

    const updateQuizSuccess = useSelector((state) => state.quizReducer.updateQuizSuccess);
    const updateQuizData = useSelector((state) => state.quizReducer.updateQuizData);
    const updateQuizFailure = useSelector((state) => state.quizReducer.updateQuizFailure);


    const getStudentClassListSuccess = useSelector((state) => state.studentListReducer.getStudentClassListSuccess);
    const getStudentClassListList = useSelector((state) => state.studentListReducer.getStudentClassListList);
    const getStudentClassListFailure = useSelector((state) => state.studentListReducer.getStudentClassListFailure);
    const studentClassListErrorMessage = useSelector((state) => state.studentListReducer.errorMessage);


    const getEnrollmentDetailsSuccess = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsSuccess);
    const getEnrollmentDetailsList = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsList);
    const getEnrollmentDetailsFailure = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsFailure);
    const enrollmentDetailsErrorMessage = useSelector((state) => state.enrollmentDetailsReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Unit", "Create Quiz")
        setpageItems(data)
    }, [navigation]);


    const column = [
        {
            "dataField": "title",
            "text": "Title",
            "sort": true
        },

        {
            "dataField": "description",
            "text": "Description",
            "sort": true,

        },
        {
            "dataField": "class-section",
            "text": "Class-Section",
            "sort": true
        },
        {
            "dataField": "startDate",
            "text": "Start Date",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return dateConversion(cellContent, "DD-MMM-YYYY")
            }
        },
        {
            "dataField": "endDate",
            "text": "End Date",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return dateConversion(cellContent, "DD-MMM-YYYY")
            }
        },
        {
            "dataField": "startTime",
            "text": "Start Time",
            "sort": false,
            formatter: (cellContent, row, index) => (

                row.startTime
            )
        },
        {
            "dataField": "endTime",
            "text": "End Time",
            "sort": false
        },

    ]


    if (pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}


                </div>
            )
        },)
    }

    const formColumn = [


        {
            "dataField": "materialName",
            "text": "Enter Your Question",
            "sort": true
        },
        {
            "dataField": "Qty",
            "text": "No of Option",
            "sort": true
        },

        {
            "dataField": "additem",
            "text": "Add Options",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "Action",
            "sort": false
        },
    ]

    const innerHeading = [
        {
            "dataField": "",
            "text": "Select Option",
            "sort": true
        },

        {
            "dataField": "",
            "text": "Enter Your Answer ",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "",
            "sort": false
        },
        {
            "dataField": "action",
            "text": "",
            "sort": false
        }


    ]

    const innerTableList = [
        {
            "dataField": "purchaseName",
            "text": "Warehouse",
            "sort": true
        },
        {
            "dataField": "purchaseName",
            "text": "Receipt Location",
            "sort": true
        },
        {
            "dataField": "purchaseName",
            "text": "Length(mm)",
            "sort": true
        },
        {
            "dataField": "purchaseName",
            "text": "Width(mm)",
            "sort": true
        },
        {
            "dataField": "description",
            "text": "Thickness ",
            "sort": false
        },
        {
            "dataField": "description",
            "text": "Action ",
            "sort": false
        },
    ]
    const [state, setState] = useState({
        deletedItems: [],
        purchaseName: "",
        description: "",
        customerList: [],
        /*   radioList:[{
                      option: 1,
                      name: "name",
                  },
              ], */
        currencyList: [],
        venderList: [],
        leadSourceTypeList: [],
        supplierList: [],
        warehouseList: [],
        locationList: [],
        materialTypeList: [],
        materialCodeList: [],
        deliveryList: [],
        unitsList: [],
        taxCode: [],
        contactPerson: '',
        qty: '',
        UnitId: '',
        unitPrice: '',
        totalId: '',
        totalTax: '',
        purchaseOrderDate: new Date(),
        deliveryOrderDate: new Date(),
        deliveryId: '',
        phoneNumber: '',
        emailId: '',
        deliveryAddress: '',
        contactPersonName: '',
        inVoiceData: '',
        permitId: '',
        shipVia: '',
        fob: '',
        debitTerm: '',
        memo: "",
        logisticId: '',
        supplierId: '',
        materialCodeId: '',
        currencySymbol: '',
        deliveryOrderDate: '',
        postCode: '',
        supplierCode: '',
        primaryNumber: '',
        emailCode: '',
        addressCode: '',
        suplierNameValue: '',
        secondaryPhone: '',
        contact: '',
        materialId: '',
        comment: '',
        footerData: [
            { label: 'SubTotal :', value: '0' },
            { label: 'Tax:', value: '0' },
            { label: 'Total:', value: '0' }
        ],
        insuranceAmount: "",
        logisticAmount: "",
        handlingCost: "",
        otherCost: "",
        checkCount: '',
        filterData: [],
        deletedItems: [],
        radioList: []



    });




    const [tableRows, setTableRows] = useState([]);
    const [createModule, setCreateModule] = useState(false);
    const [createForm, setCreateForm] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemAdded, setItemAdded] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [uploadModule, setUploadModule] = useState(false);
    const [formList, setFormlist] = useState(fieldList);
    const [list, setList] = useState([])
    const [id, setId] = useState([])
    const [currencySymbol, setCurrencySymbol] = useState('')
    const [showForm, setShowForm] = useState(false)
    const [selectedmaterial, setSelectMaterial] = useState([])
    const [results, setResults] = useState([]);
    const [filteredObjects, setFilteredObjects] = useState([]);
    const [deletedRows, setDeletedRows] = useState([]);


    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }

        let enrollData = await getAsyncStudentClassList();
        setState({
            ...state,
            enrollmentDetailsList: enrollData?.data || [],
        })

    }



    useEffect(() => {

        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        // dispatch(getStudentClassList(requestData))

        dispatch(getQuiz())
        // dispatch(getExamVariant())
        listFetching()



    }, [navigation]);


    useEffect(() => {
        if (getExamVariantSuccess) {
            setState({
                ...state,
                examVariantList: getExamVariantList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EXAM_VARIANT" })
        } else if (getExamVariantFailure) {
            setState({
                ...state,
                examVariantList: []
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EXAM_VARIANT" })
        }
    }, [getExamVariantSuccess, getExamVariantFailure]);



    useEffect(() => {
        if (getQuizSuccess) {
            setItemList(getQuizList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_QUIZ" })
        } else if (getQuizFailure) {
            setItemList([])
            showMessage("warning", quizErrorMessage)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_QUIZ" })
        }
    }, [getQuizSuccess, getQuizFailure]);

    useEffect(() => {
        if (getStudentClassListSuccess) {
            setState({
                ...state,
                enrollmentDetailsList: getStudentClassListList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
        } else if (getStudentClassListFailure) {
            setState({
                ...state,
                enrollmentDetailsList: []
            })
            showMessage("warning", studentClassListErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
        }
    }, [getStudentClassListSuccess, getStudentClassListFailure]);


    useEffect(() => {
        if (createQuizSuccess) {
            const temp_state = [...itemList, createQuizData[0]];
            setItemList(temp_state)

            setState({
                ...state,
                supplierList: temp_state

            })
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_QUIZ" })
        } else if (createQuizFailure) {
            setLoaderModule(false)
            //   showMessage("warning", purchaseErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_QUIZ" })
        }
    }, [createQuizSuccess, createQuizFailure]);


    useEffect(() => {
        if (updateQuizSuccess) {
            updateTable(updateQuizData[0])
            dispatch({ type: "RESET_UPDATE_QUIZ" })
        } else if (updateQuizFailure) {
            setLoaderModule(false)
            //   showMessage("warning", purchaseErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_QUIZ" })
        }
    }, [updateQuizSuccess, updateQuizFailure]);



    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.quizId != selectedItem.quizId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }



    const resetState = () => {
        setState({
            ...state,
            contactPerson: '',
            contactNumber: '',
            supplierAddress: '',
            productTax: '',
            priceGst: '',
            qty: '',
            UnitId: '',
            unitPrice: '',
            totalId: '',
            totalTax: '',
            deliveryName: '',
            phoneNumber: '',
            emailId: '',
            email: '',
            address: '',
            deliveryAddress: '',
            contactPersonName: '',
            insuranceAmount: '',
            inVoiceData: '',
            permitId: '',
            shipVia: '',
            fob: '',
            debitTerm: '',
            memo: '',
            name: '',
            purchaseOrderDate: new Date(),
            deliveryOrderDate: new Date(),

            logisticId: '',
            title: '',
            startDate: '',
            description: '',
            endDate: '',
            startTime: '',
            endTime: '',
            enrollmentIds: '',

            supplierId: '',
            materialCodeId: '',
            currencySymbol: '',
            postCode: '',
            supplierCode: '',
            primaryNumber: '',
            emailCode: '',
            addressCode: '',
            suplierNameValue: '',
            secondaryPhone: '',
            contact: '',
            materialType: '',
            comment: '',
            amount: 0,
            supplierName: '',
            countryName: '',
            insuranceId: '',
            updatedObject: "",
            logisticId: '',
            logisticAmount: '',
            duration: '',
            isDuration: false,
            handlingCost: '',
            otherCost: '',
            quizDetails: [],
            footerData: [
                { label: 'SubTotal :', value: '' },
                { label: 'Tax:', value: '' },
                { label: 'Total:', value: '' }
            ],
            radioList: [],
            deletedItems: [],
            dateRange: false,
            isRangeDate: "",
            duration: ""
        });
        purchaseAddForm[0].formBody[2].formField[1].disabled = true;
        purchaseAddForm[0].formBody[1].formField[1].disabled = false;
        purchaseAddForm[0].formBody[3].formField[2].disable = true;
        purchaseAddForm[0].formBody[3].formField[2].disabled = true;
        purchaseAddForm[0].formBody[3].formField[1].disable = false;
        purchaseAddForm[0].formBody[3].formField[0].disable = false;





        setCurrencySymbol([])



        setTableRows([]);

    };



    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        console.log(data)
        let isDateRange = data?.isDateRange || false
        let isDurationRange = data?.isDuration || false

        const purchaseOrderItem = data?.quizDetails || [];
        let hours = "00", minutes = "00", seconds = "00";
        if (data?.duration) {
            [hours, minutes, seconds] = data.duration.split(':').map(unit => String(unit).padStart(2, '0'));
        }

        // alert(JSON.stringify(data))
        setState({
            ...state,
            dateRange: data?.isDateRange || false,
            isDuration: isDurationRange,
            title: data?.title || "",
            description: data?.description || "",
            enrollmentIds: parseInt(data?.enrollmentIds) || "",
            startDate: moment(data?.startDate, "YYYY-MM-DD"),
            endDate: moment(data?.endDate, "YYYY-MM-DD"),
            startTime: data?.startTime || "",
            endTime: data?.endTime || "",
            isRangeDate: data?.startDate && data?.endDate ? [data.startDate, data.endDate] : [],
            quizDate: data?.startDate || "",
            duration: data?.duration || "",
            durationHours: hours,
            durationMinutes: minutes,
            durationSeconds: seconds,

            radioList: [{
                option: 1,
                // answer: "",
            }]



        })
        if (isDateRange) {

            if (purchaseAddForm[0]?.formBody[2]?.formField[1]) {
                purchaseAddForm[0].formBody[2].formField[1].disabled = false;
                purchaseAddForm[0].formBody[1].formField[1].disabled = true;
                purchaseAddForm[0].formBody[3].formField[1].disable = true;
                purchaseAddForm[0].formBody[3].formField[0].disable = true;
                setState(prevState => ({ ...prevState, quizDate: "", startTime: "", endTime: "" }));

            }
        } else {
            purchaseAddForm[0].formBody[1].formField[1].disabled = false;
            purchaseAddForm[0].formBody[2].formField[1].disabled = true;
            purchaseAddForm[0].formBody[3].formField[1].disable = false;
            purchaseAddForm[0].formBody[3].formField[0].disable = false;
            setState(prevState => ({ ...prevState, isRangeDate: "" }));
        }
        if (isDurationRange) {

            if (purchaseAddForm[0]?.formBody[3]?.formField[2]) {
                purchaseAddForm[0].formBody[3].formField[2].disabled = false;
            }
        } else {

            purchaseAddForm[0].formBody[3].formField[2].disabled = true;

        }





        if (purchaseOrderItem) {
            const newTableRows = purchaseOrderItem.map((item, index) => {
                console.log(JSON.stringify(item))
                if (item.quizMeta) {
                    const selectedAnswer = item.quizMeta.find(meta => meta.option === true);
                    return {
                        quizDetailsId: item?.quizDetailsId || "",
                        question: item?.question || "",
                        answer: selectedAnswer ? selectedAnswer.title : "", // Set the selected answer's title



                        quizDetails: item.quizMeta.map((detail, index) => ({
                            ...detail,

                            answer: detail.title ? detail.title : null,
                            // option:detail.title ? detail.title : null,

                        })),
                    };
                } else {
                    return {};
                }
            });

            setTableRows(newTableRows);
        }



        editData = true;
        setSelectedIndex(index);
        setSelectedItem(data);
        setCreateModule(true);
        setItemAdded(true)
    };


    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedItem(data)
        setSelectedIndex(index)
        setDeleteModule(true)
    }

    const itemHeader = [
        {
            "dataField": "index",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return index + 1
            }
        },

        {
            "dataField": "status",
            "text": "",
            formatter: (cellContent, row, index, extraData) => {
                const conInfo = { inputType: "checkbox", status: cellContent }
                return formRender(extraData, null, conInfo, null, null, statusChange, index);
            }
        },

        {
            "dataField": "materialId",
            "text": "Material Id",
            "sort": true
        },
        {
            "dataField": "materialCode",
            "text": "Material Code",
            "sort": true
        },
        {
            "dataField": "materialName",
            "text": "Material Name",
            "sort": true
        },



    ]

    const confirmForm = (index) => {
        setShowForm(false)

    }


    const onDeletePress = (itemIndex) => {
        let temp_state = [...tableRows];
        let addd = tableRows.splice(itemIndex, 1);
        let remainingList = _.remove(temp_state, function (n, index) {
            return index != itemIndex
        });
        setTableRows(remainingList);
        let temp_arr = { ...state }
        setState({
            ...state,
            deletedItems: [...temp_arr.deletedItems, ...addd]
        })
    };

    const onSearch = () => {
        setCreateForm(true);
    }
    const modelTitle = editData ? "Edit " : "Create ";
    const modelTitle2 = editData ? "Edit " : "Create New ";
    const modelButtonLbl = editData ? "" : "Save";

    var itemCount = 0;
    const addRow = () => {

        const defaultNewOrderObject = {
            itemCount: 0,
            question: '',
            answer: '',


            quizDetails: [

            ]
        };
        itemCount++;
        setTableRows([...tableRows, defaultNewOrderObject]);
        setItemAdded(false)
    };

    const statusChange = (event, index, extraData) => {
        const newSelectedMaterial = [...selectedmaterial];
        let tempState = [...tableRows];

        const isChecked = event.target.checked;

        if (isChecked && newSelectedMaterial.some((material, i) => material.status && i !== index)) {
            Alert({ description: 'You Cannot Select Multiple Objects.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            event.preventDefault();
            return;
        }

        // Update the status of selected material
        newSelectedMaterial.forEach((material, i) => {
            material.status = (i === index) ? isChecked : false;
        });

        const updatedObject = newSelectedMaterial[index];

        const materialObject = state?.materialCodeList || [];
        const foundObjects = [];

        const foundObject = materialObject.find(item => item.materialId === updatedObject.materialId);
        if (foundObject) {
            foundObjects.push(foundObject);
        }

        setState((prevState) => ({
            ...prevState,
            updatedObject: updatedObject,
            foundObjects: foundObjects
        }));

        setSelectMaterial(newSelectedMaterial);

        // Update the table data
        setTableRows((prevTableRows) => {
            const updatedRows = [...prevTableRows];
            const indexx = state.index || 0;
            if (indexx < updatedRows.length && foundObject) {
                updatedRows[indexx] = {
                    ...updatedRows[indexx],
                    materialCodeId: foundObjects,
                    materialName: updatedObject.materialName,
                    materialId: updatedObject.materialId
                };
            }
            return updatedRows;
        });
    };



    const onSubmitItemDetails = (index) => {
        const temp_state = [...tableRows];
        const selectedRow = temp_state[index];

        if (selectedRow) {
            const quizDetails = selectedRow.quizDetails || [];
            const answer = selectedRow.answer || 0;

            let updatedItemDetails = quizDetails.map((item) => ({
                ...item,
                answer: answer,
            }));

            temp_state[index].quizDetails = updatedItemDetails;
            setTableRows(temp_state);
        }
    }


    const onDeleteInner = (detailIndex, parentIndex) => {
        const updatedTableRows = [...tableRows];
        updatedTableRows[parentIndex].quizDetails.splice(detailIndex, 1);
        setTableRows(updatedTableRows);
    }



    const handleChange = (event, name, index, parentIndex) => {

        event.persist()
        const Value = event.target.value
        let temp_state = [...tableRows]
        temp_state[parentIndex]["quizDetails"][index][name] = Value;

        setTableRows(temp_state)

        // console.log(JSON.stringify(state.radioList))
    }

    const handleTypeChange = (event, name, index, parentIndex) => {
        // console.log(event + " ==== " + index + " ---- " + stateName + " === " + name + " ==== " + parentIndex)
        let temp_state = [...tableRows];
        let checked = event.target.checked;

        temp_state[parentIndex]["quizDetails"].forEach((item, i) => {
            if (i !== index) {
                item[name] = false;
            }
        });

        temp_state[parentIndex]["quizDetails"][index][name] = checked;

        if (checked == true) {
            temp_state[parentIndex].answer = temp_state[parentIndex]["quizDetails"][index].answer;
        } else if (checked == false) {
            temp_state[parentIndex].answer = ""

        }

        setTableRows(temp_state);
    };



    const handlelocationchange = (event, index, name) => {
        let temp_state = [...tableRows]
        temp_state[index][event.target.name] = event.target.value
        setTableRows(temp_state)

    }


    const onAddItem = (index, parentIndex) => {
        const temp_state = [...tableRows];
        let itemIndex = temp_state[index].quizDetails[index]
        const qtyVal = temp_state[index].qty;
        const prevQty = temp_state[index].quizDetails.length || 0;
        let newItemDetails = [];
        const diff = qtyVal - prevQty;
        if (diff > 0) {
            for (let i = 0; i < diff; i++) {
                const defaultItem = {
                    option: "",
                    answer: "",

                };
                newItemDetails.push(defaultItem);
            }
            /*  setState({
                 ...state,radioList:[{
                     option: 1,
                     name: "name",
                 }]
 
             }) */
            temp_state[index].quizDetails = [...temp_state[index].quizDetails, ...newItemDetails];
            temp_state[index].prevQty = qtyVal;
            setTableRows(temp_state)
            setItemAdded(true);
        } else {
            Alert({ description: 'New quantity must be greater than the previous ones.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

        }
    };





    const expandView = (index, setRows) => {
        const totalItem = tableRows[index]?.quizDetails || [];

        return (
            <div>
                <table >

                </table>
                <Table>
                    <thead>
                        <tr>
                            {innerHeading.map((data, ind) => (
                                <th key={ind}>{data.text}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {totalItem.map((data, itemIndex) => (
                            <tr key={itemIndex}>
                                {inner.map((item, idx) => (
                                    <td key={idx}>

                                        <TableLayout
                                            onSearch={(index, parentIndex) => onDeleteInner(index, parentIndex)}

                                            isField={true}
                                            onChangeCallback={{ "handleTypeChange": handleTypeChange, "handleChange": handleChange }}
                                            // iconPress={handleTypeChange}
                                            defaultState={state}
                                            setDefaultState={setTableRows}
                                            setDataList={setTableRows}
                                            parentIndex={index}
                                            subItemName="quizDetails"
                                            index={itemIndex}
                                            layoutType="table"
                                            dataList={tableRows[index]?.quizDetails || []}
                                            dynamicForm={item}
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    };

    const convertToPurchaseOrderItem = (value) => {
        const updateData = selectedItem ? selectedItem : {};

        let temp_arr = [];
        let quizMeta = [];

        try {
            if (!Array.isArray(value)) {
                throw new Error("Expected 'value' to be an array.");
            }

            value.forEach((row, index) => {
                console.log(JSON.stringify(row));

                const req = {
                    question: row?.question || null,
                    qty: row.qty || null,
                    answer: row.answer || null,
                    quizDetailsId: row.quizDetailsId || null,

                    quizMeta: (row.quizDetails || []).map((data, ind) => ({
                        id: ind + 1,
                        title: data?.answer || "",
                        option: data?.option || "",
                    }))
                }
                temp_arr.push(req);
                quizMeta.push(req.quizMeta);
            });

            setState((prevState) => ({
                ...prevState,
                radioList: quizMeta
            }));

            console.log(JSON.stringify(temp_arr));
        } catch (error) {
            console.error("Error occurred while converting to purchase order item:", error.message);
        }

        return temp_arr;
    };

    const onSubmitForm = () => {
        const updateData = selectedItem ? selectedItem : {};
        let selectedTimeValue = state?.duration || "";
        const formattedTimeValue = selectedTimeValue
            ? moment(selectedTimeValue).format("hh:mm:ss A")
            : '';
        console.log(formattedTimeValue);

        const requestData = {
            "title": state?.title || "",
            "description": state?.description || "",
            "isDuration": state?.isDuration || false,
            "duration": state?.duration || "",
            "enrollmentIds": state?.enrollmentIds || "",
            "isDateRange": state?.dateRange || false,
            "startDate": state.isRangeDate ? dateConversion(state.isRangeDate[0], "YYYY-MM-DD") : dateConversion(state.quizDate, "YYYY-MM-DD"),
            "endDate": state.isRangeDate ? dateConversion(state.isRangeDate[1], "YYYY-MM-DD") : dateConversion(state.quizDate, "YYYY-MM-DD"),
            "endTime": state?.endTime || null,
            "startTime": state?.startTime || null,
            "quizDetails": convertToPurchaseOrderItem(tableRows) || [],
        };
        if (requestData.startTime === null) {
            delete requestData.startTime;
        }
        if (requestData.endTime === null) {
            delete requestData.endTime;
        }

        console.log(JSON.stringify(requestData));
        // return

        function isValid(value) {
            return value !== '' &&
                !(typeof value === 'string' && value.trim() === '') &&
                !(typeof value === 'number' && isNaN(value));
        }

        function validateFormData(data) {
            const requiredFields = ["title", "description", "startDate", "endDate"];

            for (const field of requiredFields) {
                if (!isValid(data[field])) {
                    return false;
                }
            }

            if (!Array.isArray(data.quizDetails) || data.quizDetails.length === 0) {
                return false;
            }

            for (const item of data.quizDetails) {
                if (!isValid(item.question) || !isValid(item.qty) || !isValid(item.answer)) {
                    return false;
                }

                if (!Array.isArray(item.quizMeta) || item.quizMeta.length === 0) {
                    return false;
                }

                for (const detail of item.quizMeta) {
                    if (!isValid(detail.title)) {
                        return false;
                    }
                }
            }

            return true;
        }

        let answer = requestData.quizDetails.some(item => (item.answer === null));


        if (answer) {
            Alert({ description: 'Check you selected all the options', type: 'warning', placement: 'topRight', duration: 3 });
            return

        }

        if (!validateFormData(requestData)) {
            // showMessage('warning', "Fill all the required details");
            Alert({ description: 'Fill all the required details', type: 'warning', placement: 'topRight', duration: 3 });

        } else {
            if (editData) {
                setLoaderModule(true);
                requestData.quizDetails.forEach((item) => {
                    if (item.quizDetailsId == null) {
                        delete item.quizDetailsId;
                    }
                });

                const data = state?.deletedItems || [];

                data.forEach(ele => {
                    ele.isActive = false;
                    requestData.quizDetails.push(ele);
                });

                dispatch(updateQuiz(requestData, selectedItem.quizId));

            } else if (deleteModule) {
                const deleteRequest = { isActive: "false" };
                setLoaderModule(true);
                dispatch(updateQuiz(deleteRequest, selectedItem.quizId));

            } else {

                requestData.quizDetails.forEach((item) => {
                    console.log(JSON.stringify(item))
                    if (item.quizDetailsId === null) {
                        delete item.quizDetailsId;
                    }
                });

                setLoaderModule(true);
                dispatch(createQuiz(requestData));
            }
        }
    };


    const materialListView = (index) => {

        setShowForm(true);
        setState({
            ...state,
            index: index
        })
    }

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const { sidebarOpen } = useSidebar();
    const handleQuizDate = (event, name) => {
        console.log(name); // Debugging alert

        const isChecked = event.target.checked;

        // Ensure purchaseAddForm is defined and has the expected structure
        if (name === "dateRange") {

            if (purchaseAddForm[0]?.formBody[2]?.formField[1]) {
                purchaseAddForm[0].formBody[2].formField[1].disabled = !isChecked;
                purchaseAddForm[0].formBody[1].formField[1].disabled = isChecked;
                purchaseAddForm[0].formBody[3].formField[2].disabled = !isChecked;
                purchaseAddForm[0].formBody[3].formField[1].disable = isChecked;
                purchaseAddForm[0].formBody[3].formField[0].disable = isChecked;

            }
        }
        if (name === "isDuration") {

            if (purchaseAddForm[0]?.formBody[3]?.formField[2]) {
                purchaseAddForm[0].formBody[3].formField[2].disable = !isChecked;
            }
        }

        // Update state
        setState((prevState) => ({
            ...prevState,
            [event.target.name]: isChecked,
            isDuration: isChecked

        }));
        let hours = "00", minutes = "00", seconds = "00";
        if (!isChecked) {
            setState((prevState) => ({
                ...prevState,
                isRangeDate: "",
                duration: `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`,




            }));

        } else if (isChecked) {
            setState((prevState) => ({
                ...prevState,
                quizDate: "",
                startTime: "",
                endTime: "",




            }));

        }
    };
    const handleQuizDuration = (event, name) => {
        console.log(name); // Debugging alert

        const isChecked = event.target.checked;
        let hours = "00", minutes = "00", seconds = "00";


        if (name === "isDuration") {

            if (purchaseAddForm[0]?.formBody[3]?.formField[2]) {
                purchaseAddForm[0].formBody[3].formField[2].disabled = !isChecked;
                if (!isChecked) {
                    setState((prevState) => ({
                        ...prevState,
                        duration: `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
                    }));
                }
            }
        }

        setState((prevState) => ({
            ...prevState,
            [event.target.name]: isChecked,
        }));


    };
    const handleDuration = (event, newValue) => {
        console.log(event)
        setState((prevState) => ({
            ...prevState,
            [event.target.name]: event,
        }));


    };

    return (
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "40px" } : { marginLeft: "10px", marginTop: "40px", marginBottom: "70px" }}>
            {/* <SmallLoader value={loaderModule} /> */}
            <View isOpen={createModule} onClose={setCreateModule} fullscreen={true} size="fullscreen" savetitle={modelButtonLbl} onSubmit={onSubmitForm} title={`${modelTitle} Quiz`} >
                <FormLayout ref={purchaseFormRef} defaultState={state} setDefaultState={setState} dynamicForm={purchaseAddForm} noOfColumn={4} onSearch={() => { onSearch() }} onChangeCallback={{ "handleQuizDate": handleQuizDate, "handleQuizDuration": handleQuizDuration, "handleDuration": handleDuration }}

                    onSubmit={onSubmitForm} />
                {/* <p style={{ fontSize: '20px', fontWeight: '500' }}>Logistics and handling cost</p> */}
                <CollapseView iconPress={{ "onAddItem": onAddItem, "onDeletePress": onDeletePress, "materialListViews": materialListView }} /* onChangeCallback={{ }} */ state={state} btnName={"Add Item"} setState={setState} header={formColumn} fieldList={formList} onSearch={materialListView} expandView={expandView} tableRows={tableRows} setTableRows={setTableRows} addRow={addRow} currencySymbol={currencySymbol} calculationTitle={state.footerData}>
                </CollapseView>
            </View>

            <View size="xl" isOpen={showForm} onClose={setShowForm} title={`Select Product`} onSubmit={confirmForm} btnName={"Confirm"} count={true} savetitle={"Confirm"} checkCount={state?.checkCount || 0} quatityCount={selectedIndex === false ? 0 : tableRows[selectedIndex]?.qty || 0}>
                <CustomTable columns={itemHeader} data={selectedmaterial} tableName={""} state={state} />
            </View>
            <SimpleCard >
                <CustomTable
                    columns={column}
                    data={itemList}
                    initialPage={1}
                    buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick}
                    tableName={"Create Quiz"}
                />
            </SimpleCard>
        </div>
    )
};

export default CreateQuiz;
