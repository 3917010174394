import * as yup from "yup";
import { Formik } from "formik";
import { useState, Fragment } from "react";
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
// import { CodeViewer, Breadcrumb, ModalView } from "@gull";
import { DateRange } from "react-date-range";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import TimeKeeper from 'react-timekeeper';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Multiselect from 'multiselect-react-dropdown';
// import DatePicker from "react-datepicker";
import noProfile from "app/pages/images/no_profile.png";
import dayjs from 'dayjs';
import "react-datepicker/dist/react-datepicker.css";
// import { getStatusColor } from "./utils";
import { MdCancel, MdMoreTime, MdVisibility, MdOutlineHistory, MdSearch, MdDelete, MdAdd } from "react-icons/md";
// import { showMessage } from "./app-functions";
import moment from "moment";
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import { IoDocumentText } from "react-icons/io5";
import Select from "../atoms/select";
import Text from "../atoms/text-option/Text";
import Input from "../atoms/input";
import DynamicCheckBox from "../atoms/checkbox";
import DatePicker from "../atoms/date-picker";
import TimePicker from "../atoms/time-picker";
import './formcomponent.css';
import CustomTypeahead from "../atoms/typedropdown/CustomTypeahead";
import MultiSelect from "../atoms/multiselect";
import Radio from "../atoms/radio";
import RadioField from "../atoms/radioField";
import SearchableInput from "../atoms/searchableInput/SearchableInput";
import { getStatusColor } from "app/utils/utils";
import { FiTrash2 } from "react-icons/fi";
import { lightTheme } from "styles/theme";
import DurationPicker from "../Duration";



let pickedInfo = "";
// const [selectedValues, setSelectedValues] = useState([]); // To hold selected values for multiple select

const formRender = (state, setState, info, onSearch, onChangeCallback, iconPress, index,/* subIndex, */ layoutType = "form", dataList, setDataList, errorState = {}, setErrorState, parentIndex, /* onIconClick */) => {

    const checkErrors = Object.keys(errorState).length === 0 ? false : true;
    const { label, inputType, icon, fieldName, config, options, uniqueKey, displayKey, placeholder, multiple } = info;

    const iconMap = {
        "MdSearch": MdSearch,

    };
    const IconComponent = iconMap[icon];
    // const [value, setValue] = useState()

    const removeErrors = (event, type) => {
        if (checkErrors) {
            const fieldName = event.target.name;
            let state = { ...errorState };
            //delete state[fieldName];
            state[fieldName] = false;
            setErrorState(state);
        }
    }

    const handleChange = (event, type) => {
        event.persist()

        const numberValue = event.target.value
        let temp_state = "";
        if (layoutType === "table") {
            temp_state = [...dataList]
            temp_state[index][event.target.name] = event.target.value
        } else if (type === 'number') {
            if (numberValue.length < 11)
                temp_state = {
                    ...state,
                    [event.target.name]: event.target.value
                }
            else {
                temp_state = {
                    ...state,
                }
            }
        } else {
            temp_state = {
                ...state,
                [event.target.name]: event.target.value
            }
        }
        setState(temp_state)
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0]
        let temp_state = "";
        if (layoutType === "table") {
            temp_state = [...dataList]
            temp_state[index][event.target.name] = file.name

        } else {
            temp_state = {
                ...state,
                [event.target.name]: file.name
            }
        }
        setState(temp_state)
    }

    const onTime = (status) => {
        pickedInfo = info.fieldName;
        setState({ ...state, showTimePicker: status })
    }
    const onHandleTime = (val) => {
        setState({
            ...state,
            [info.fieldName]: val
        })
    }

    const handleTimeChange = (time) => {


        let formattedTime;
        const { hour, minute, meridiem } = time;

        if (hour === 12) {
            if (meridiem === "am") {
                formattedTime = `00:${minute < 10 ? '0' : ''}${minute}`;
            } else {
                formattedTime = `12:${minute < 10 ? '0' : ''}${minute}`;
            }
        } else {
            if (meridiem === "am") {
                formattedTime = `${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`;
            } else {
                formattedTime = `${hour + 12}:${minute < 10 ? '0' : ''}${minute}`;
            }
        }



        const parsedTime = moment(formattedTime, "HH:mm", true);
        if (!parsedTime.isValid()) {
            console.error('Invalid time format:', formattedTime);
            return;
        }

        setState({
            ...state,
            [pickedInfo]: parsedTime.format("HH:mm")
        });
    };



    const handleDurationChange = (hours, minutes, seconds, fieldName, index) => {
        console.log(`${hours}:${minutes}:${seconds}`);

        let temp_state = "";

        if (layoutType === "table") {
            temp_state = [...dataList];
            temp_state[index][fieldName] = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        } else {
            temp_state = {
                ...state,
                [fieldName]: `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
            };
        }

        setState(temp_state);
    };


    const handleDateChange = (stateName, date) => {
        let temp_state = "";
        if (layoutType === "table") {
            temp_state = [...dataList]
            temp_state[index][stateName] = date
        } else {
            temp_state = {
                ...state,
                [stateName]: date
            }
        }
        setState(temp_state)
    }


    const handleCheckbox = (event, type) => {
        // event.persist();
        // alert(event.target.name)
        let temp_state = "";
        if (layoutType === "table") {
            temp_state = [...dataList]
            temp_state[index][event.target.name] = event.target.checked

        } else {
            temp_state = {
                ...state,
                [event.target.name]: event.target.checked
            }
        }
        setState(temp_state)
    }

    const handleSelect = (event, name) => {
        let temp_state = "";
        if (layoutType === "table") {
            temp_state = [...dataList]
            temp_state[index][info.fieldName] = event

        } else {
            temp_state = {
                ...state,
                [info.fieldName]: event
            }
        }
        setState(temp_state)
    }

    const handleTypeChange = (val) => {
        // alert(JSON.stringify(val))
        // return
        setState((prevState) => ({
            ...prevState,
            [info.fieldName]: val, // Ensure val is an array for multiple selections
        }));
    }

    const handleTypeDropDown = (event) => {
        let temp_state = "";
        if (layoutType === "table") {
            temp_state = [...dataList]
            temp_state[index][info.fieldName] = event.label
            temp_state[index][info.fieldId] = event.value
        } else {
            temp_state = {
                ...state,
                [info.fieldName]: event.label,
                [info.fieldId]: event.value
            }
        }
        setState(temp_state)
    }

    const handleRadioChange = (e) => {
        const newValue = e.target.value;
        setState((prevState) => ({
            ...prevState,
            [info.fieldName]: newValue,
        }));
    };
    /*    const handleSelectChange = (val) => {
           let tempState = {...state}
           if(state?.[info.fieldName] && state?.[info.fieldName].length){
               tempState[info.fieldName].push(val)
           }
           else{
               tempState[info.fieldName] = []
               tempState[info.fieldName].push(val)
           }
           setState(tempState)
       } */

    const handleSub = () => {

    }

    const onSelectOption = (event, values, keyName, valueName, keyIds, callback) => {
        const existValues = state[keyName] ? state[keyName] : [];
        if (layoutType === "table") {
            let temp_state = [...dataList]
            //const existValue = temp_state[index][keyIds] ? temp_state[index][keyIds] : [];
            const existValueIds = temp_state[index][keyName] ? temp_state[index][keyName].split(',') : [];

            // temp_state[index][keyIds] = [...existValue,...[values]]
            temp_state[index][keyName] = [...existValueIds, ...[values[valueName]]].toString()
        }
        else {
            setState({
                ...state,
                [keyName]: [...existValues, ...[values]],
            })
        }
    }

    const onRemove = (selectedList, removedItem, keyName, valueName, keyIds, callback) => {
        if (layoutType === "table") {
            let temp_state = [...dataList];
            const removingId = removedItem[valueName];
            let existValueIds = temp_state[index][keyName] ? temp_state[index][keyName].split(',') : [];

            const idx = existValueIds.indexOf(removingId.toString());
            if (idx > -1) { existValueIds.splice(idx, 1); }
            //temp_state[index][keyName] = selectedList;
            temp_state[index][keyName] = existValueIds.toString()
        }
        else {
            const existValues = selectedList ? selectedList : [];
            setState({
                ...state,
                [keyName]: existValues,
            })
        }
    }

    const handleEmail = (event) => {
        const inputValue = event.target.value
        let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
        if (inputValue.length) {
            if (!emailRegex.test(inputValue)) {
                // showMessage("warning", "Invalid email.");
            }
        }
    }
    const handlePhone = (event) => {
        try {
            const inputValue = event.target.value;
            const code = inputValue?.match(/\+\d+/)?.[0];

            let spaceIndex = inputValue.indexOf(" ");
            let phoneNum = inputValue?.replace(/^\+\d+\s/, '');
            if (spaceIndex !== -1) {
                phoneNum = inputValue.substring(spaceIndex + 1).replace(/[^\d]/g, '');
            }
            setState(prevState => ({
                ...prevState,
                countryCode: code,
                phoneNum: phoneNum
            }));

            if (!isValidPhoneNumber(phoneNum)) {
                const fieldName = [info.fieldName];
                let state = { ...errorState };
                state[fieldName] = true;
                setErrorState(state);
            } else {
                const fieldName = [info.fieldName];
                let state = { ...errorState };
                state[fieldName] = false;
                setErrorState(state);
            }
        } catch {
            console.log("error");
        }
    };

    const isValidPhoneNumber = (phoneNum) => {
        return phoneNum.length === 10;
    };


    const selectedImage = (img, index) => {
        /* let temp_state = [...itemDetails];
        // 2. Make a shallow copy of the element you want to mutate
        let temp_element = { ...temp_state[index] };
        if (img) {
            const imageName = generateSerial() + ".jpg";
            const blobFile = dataURLtoFile(img, imageName)
            // 3. Update the property you're interested in
            temp_element['imageName'] = imageName;
            temp_element['image'] = blobFile;
            temp_element['imageStatus'] = false;
        }
   
        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        temp_state[index] = temp_element;
        // 5. Set the state to our new copy
        updateState(false, itemIndex, temp_state); */
    }

    const getUserStatusClass = (status) => {

        switch (status) {

            case "active":

                return "bg-success";

            case "inactive":

                return "bg-warning";
            case "De-Activated":

                return "bg-danger";

            case "pending":

                return "bg-primary";



            default:

                break;

        }

    };


    switch (info.inputType) {
        case 'header':
            // code block
            // return <CardTitle >{info?.label || ""}</CardTitle>
            break;
        case 'label':
            const labelVal = layoutType === "table" ? dataList[index] : state;
            return (
                <div key={index} className="col-md-12 form-group mb-0">
                    <label htmlFor="">{labelVal[info.fieldName]}</label>
                </div>
            )
            break;
        case 'checkInStatus':
            let checklabel = info.statusName;// ? "Check-Out" : "Check-In";
            console.log(info);

            //checklabel = info.label ? info.label : checklabel;
            return (
                <span key={index} className={`badge ${getStatusColor(checklabel)}`}>
                    {checklabel}
                </span>

            )

            break;

        case 'leaveStatus':
            let leaveLabel = info.statusName;// ? "Check-Out" : "Check-In";
            //checklabel = info.label ? info.label : checklabel;
            return (

                <span className="badge bg-danger">
                    {leaveLabel}
                </span>

            )

            break;

        case 'number':
            const numberField = layoutType === "table" ? dataList[index] : state;
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <input
                        type='number'
                        id={info.fieldName}
                        className="form-control"
                        name={info.fieldName}
                        onChange={(event) => handleChange(event, info.inputType)}
                        placeholder={info.placeholder || ""}
                        onFocus={removeErrors}
                        value={numberField[info.fieldName] || ''} />
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>
            )
            break;
        case 'inlineInput':
            // code block
            return (
                <div key={index} className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="input-group">
                                <input className="form-control border-secondary py-2" type="search" value="search" />
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button">
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            break;

        case 'imageUploader':
            const imageUploaderField = layoutType === "table" ? dataList[index] : state;

            return (
                <div key={index} className="col-md-12 form-group mb-4">
                    {
                        info.label && (
                            <label htmlFor="firstName" className="label-color"><b>{info.label || "text area"}<span style={{ color: "red" }}>{info.config.required ? "*" : ""}</span></b></label>
                        )
                    }
                    <div className="input-right-icon">
                        <img
                            src={imageUploaderField.profilePic || noProfile}
                            width="54%"
                            onClick={() => { onSearch() }}
                            alt="Profile Pic"
                            onChange={info.onChange || handleChange}
                        />
                        {/* <div className="d-flex justify-content-start" >
                            <i className="ul-form__icon i-Upload" style={{ fontSize: "18px" }} onClick={() => { onSearch() }}></i>
                        </div> */}


                        {checkErrors && errorState[info.fieldName] && (
                            <div className="text-danger mt-1 ml-2">
                                {info.errorMsg || "* required"}
                            </div>
                        )}
                        {/* <span className="span-right-input-icon" name={info.fieldName} onClick={() => { onSearch() }} >
                                <i className="ul-form__icon i-Magnifi-Glass1"></i>
                            </span> */}
                    </div>
                </div>
            )
            break;

        case 'searchtext':
            const textSearchField = layoutType === "table" ? dataList[index] : state;
            return (
                <div key={index} className="col-md-12 form-group mb-0">
                    {
                        info.label && (
                            <label htmlFor="firstName"><b>{info.label || "text area"}<span style={{ color: "red" }}>{info.config.required ? "*" : ""}</span></b></label>
                        )
                    }
                    <div className="input-right-icon">
                        <input
                            // id="firstName"
                            id={info.fieldName}
                            name={info.fieldName}
                            className="form-control"
                            placeholder={info.placeholder || ""}
                            type={info.type || "text"}
                            onChange={info.onChange || handleChange}
                            disabled={info.disable ? true : false}
                            // onBlur={handleBlur}
                            onFocus={removeErrors}
                            value={textSearchField[info.fieldName] || ''}
                        />
                        {checkErrors && errorState[info.fieldName] && (
                            <div className="text-danger mt-1 ml-2">
                                {info.errorMsg || "* required"}
                            </div>
                        )}
                        <span name={info.fieldName} onClick={() => { onSearch() }} >
                            <i className="ul-form__icon i-Magnifi-Glass1"></i>
                        </span>
                    </div>
                </div>
            )
            break;

        /*   case 'timeKeeper':
              // const pickedTimeValue = layoutType === "table" ? dataList[index][pickedInfo] || '' : state[pickedInfo] || '';
              const selectedTimeValue = layoutType === "table" ? dataList[index][info.fieldName] || '' : state[info.fieldName] || '';
              // const timeInp = pickedTimeValue ? pickedTimeValue : selectedTimeValue;
              return (
                  <div key={index} className="col-md-12 form-group mb-3">
                      <label >{info.label}</label>
                      <div className="ml-2 d-flex flex-row">
                          <input type="text" name={info.fieldName} value={selectedTimeValue} className="form-control" disabled={true} onClick={() => onTime(true)} />
                          <MdMoreTime className="text-black cursor-pointer"
                              size={28}
                              onClick={() => onTime(true)}></MdMoreTime>
                      </div>
  
                
                  </div>
              )
              break; */
        case 'time-picker':
            // const pickedTimeValue = layoutType === "table" ? dataList[index][pickedInfo] || '' : state[pickedInfo] || '';
            const selectedTimeValue = layoutType === "table" ? dataList[index][info.fieldName] || '' : state[info.fieldName] || '';
            // const timeInp = pickedTimeValue ? pickedTimeValue : selectedTimeValue;
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    <label className="label-color">{info.label}</label>
                    <TimePicker
                        value={selectedTimeValue}
                        name={info.fieldName}
                        // onChange={(event) => onHandleTime(event, layoutType)}
                        onChange={(time) => onChangeCallback?.[info.onChange] ? onChangeCallback?.[info.onChange](time, info.fieldName, index) : onHandleTime(time, info.fieldName)}

                    />
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>
            )
            break;

        case 'date-old':
            return (
                <div key={index} className="col-md-8 col-sm-6 col-xs-12" >
                    <div className="form-group">
                        {
                            info.label && (
                                <label htmlFor={info.fieldName} className="form-label" style={{ display: 'block' }}>
                                    {info.label} {info.config.required ? "*" : ""}
                                </label>
                            )
                        }
                        <DatePicker
                            showIcon
                            icon={
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 48 48"
                                    color="#003473"
                                >
                                    <mask id="ipSApplication0">
                                        <g fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="4">
                                            <path strokeLinecap="round" d="M40.04 22v20h-32V22"></path>
                                            <path
                                                fill="#fff"
                                                d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                                            ></path>
                                        </g>
                                    </mask>
                                    <path
                                        fill="currentColor"
                                        d="M0 0h48v48H0z"
                                        mask="url(#ipSApplication0)"
                                    ></path>
                                </svg>
                            }
                            dateFormat={info?.format || "dd-MM-yyyy"}
                            selected={state[info.fieldName] || ''}
                            label={info.fieldName}
                            disabled={info.disable ? true : false}
                            minDate={info.minimumDate === true ? new Date() : ""}
                            maxDate={info.dob === true ? new Date(moment().subtract(18, "years")) : ""}
                            onFocus={removeErrors}
                            name={info.fieldName}
                            isClearable
                            className="form-control"
                            onChange={(date) => onChangeCallback?.[info.onChange] ? onChangeCallback?.[info.onChange](date, info.fieldName, index) : handleDateChange(info.fieldName, date)}
                            fieldName={info.fieldName}
                        />
                        {checkErrors && errorState[info.fieldName] && (
                            <div className="text-danger mt-1 ml-2">
                                {info.errorMsg || "* required"}
                            </div>
                        )}
                    </div>
                </div>
            );
            break;

        case 'date':
            const date = layoutType === "table" ? dataList[index][info.fieldName] || '' : state[info.fieldName] || '';
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName" className="label-color">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <DatePicker
                        value={date}
                        info={info}
                        range={info?.range || false}
                        name={info.fieldName}
                        allowClear={true}

                        disabled={info?.disabled || false}
                        placeholder={info?.placeholder || 'Select Date'}
                        /*  onChange={(date, dateString) => {
                             console.log("dateString -- " + dateString)
                             console.log("date -- " + date)
                         }} */
                        onChange={(date, dateString) => onChangeCallback?.[info.onChange] ? onChangeCallback?.[info.onChange](dateString, info.fieldName, index, dateString) : handleDateChange(info.fieldName, dateString)}
                    />
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>
            )
            break;
        case 'phonenumber':
            // code block
            const phoneNum = layoutType === "table" ? dataList[index] : state;
            // alert(phoneNum[info.fieldName])
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName" className="label-color">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <PhoneInput
                        // style={}
                        placeholder="Enter phone number"
                        className="form-control"
                        // defaultCountry="US"
                        // phoneDigits="10"
                        value={phoneNum[info.fieldName]}
                        onChange={handleTypeChange}
                        onBlur={handlePhone}
                        onFocus={removeErrors}
                        defaultCountry="IN"
                        style={{ marginBottom: '8px', width: '100%' }}
                    />
                    {/* // error={phoneNum[info.fieldName] ? (isValidPhoneNumber(phoneNum[info.fieldName]) ? undefined : showMessage('warning', 'Invalid phone number')) : showMessage('danger', 'Phone number required')}  */}
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>
            )
            break;
        case 'text':
            // code block
            const valueField = layoutType === "table" ? dataList[index][info.fieldName] : state[info.fieldName] || '';

            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {info.label && (
                        <label
                            htmlFor="firstName"
                            className="label-color"
                        // style={{ marginBottom: '8px' }}
                        >
                            {info.label || "text area"}{info.config.required ? "*" : ""}
                        </label>
                    )}
                    <Input
                        type='text'
                        name={info.fieldName}
                        value={valueField}
                        info={info}
                        placeholder={info.placeholder}
                        onChange=/* {(event => handleChange(event, layoutType))} */
                        {(event) => onChangeCallback?.[info.onChange] ? onChangeCallback?.[info.onChange](event, info.fieldName, index, parentIndex) : handleChange(event, layoutType)} disabled={info.disable === true ? true : false}
                        // onBlur={handleBlur}
                        onFocus={removeErrors}
                    />
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                    {/*  <Text
                        info={info}
                        onChangeCallback={onChangeCallback}
                        index={index}
                        valueField={valueField}
                        handleChange={handleChange}
                        removeErrors={removeErrors}
                        checkErrors={checkErrors}
                        errorState={errorState}
                    /> */}
                </div>
            )
            /*const emailValueFiled = layoutType === "table" ? dataList[index] : state;
             return (
                <div className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="email">{info.label || "text area"}</label>
                        )
                    }
                    <input
                        name={info.fieldName}
                        id={info.fieldName}
                        className="form-control"
                        placeholder={info.placeholder || ""}
                        type="email"
                    //onChange={handleChange}
                    //onBlur={handleBlur}
                    // value={values.email}
                    />
                    {/* {errors.email && touched.email && (
                      <div className="text-danger mt-1 ml-2">
                        {errors.email}
                      </div>
                    )}
                    <small id="emailHelp" className="form-text text-muted">
                      We'll never share your email with anyone else.
                    </small>
                    </div>
                    ) */
            break;


        case 'time':
            // code block
            const valueFieldd = layoutType === "table" ? dataList[index] : state;

            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName" className="label-color">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <input
                        // id="firstName"
                        style={{ height: '30px', alignContent: 'center' }}
                        id={info.fieldName}
                        name={info.fieldName}
                        className="form-control"
                        placeholder={info.placeholder || `Enter ${info.label}`}
                        type={info.type || "text"}
                        onChange=
                        /* {handleChange} */
                        {(event) => onChangeCallback?.[info.onChange] ? onChangeCallback?.[info.onChange](event, info.fieldName, index, parentIndex) : handleChange(event)} disabled={info.disable === true ? true : false}
                        onBlur={info.fieldType === 'email' ? handleEmail : handleSub}
                        onFocus={removeErrors}
                        value={valueFieldd[info.fieldName] || ''}
                    />
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>
            )

            break;


        case 'durationPicker':
            // Get the data based on layoutType
            const durationFieldd = layoutType === "table" ? dataList[index] : state;
            let hours = "00", minutes = "00", seconds = "00";

            if (durationFieldd?.duration) {
                [hours, minutes, seconds] = durationFieldd.duration.split(':').map(unit => String(unit).padStart(2, '0'));
            }

           

           
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {info.label && (
                        <label htmlFor="firstName" className="label-color">
                            {info.label || "text area"}{info.config.required ? "*" : ""}
                        </label>
                    )}
                    <DurationPicker
                        initialHours={hours !== undefined ? hours : "00"}
                        initialMinutes={minutes !== undefined ? minutes : "00"}
                        initialSeconds={seconds !== undefined ? seconds : "00"}
                        onChangeDuration={(hours, minutes, seconds) => handleDurationChange(hours, minutes, seconds, info.fieldName, index)}
                        disabled={info?.disabled || false}
                    />
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>
            );


            break;



        case 'searchableInput':
            const value = layoutType === "table" ? dataList : state;
            const list = state[info.options] || [];
            const filteredData = state?.filteredObjects || []
            const selectValue = layoutType === "table" ? dataList[index][info.fieldName] || '' : state[info.fieldName] || '';
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    <div >
                        {
                            info.label && (
                                <label htmlFor="firstName" className="label-color">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                            )
                        }
                        <SearchableInput
                            setState={setState}
                            state={value}
                            index={index}
                            name={info.fieldName}
                            onChange={(event, name) => onChangeCallback?.[info.onChange] ? onChangeCallback?.[info.onChange](event, name, index) : handleTypeChange(event, info?.uniqueKey, info?.fieldName)}
                            displayKey={info.displayKey}
                            value={selectValue}
                        >
                        </SearchableInput>
                    </div>

                </div>
            )

            break;

        case 'label-row':

            // const custStyle = info?.style ? info.style : {}            

            const position = info?.position === "right" ? "justify-content-end" : "justify-content-start"

            return (
                <div key={index} className={`row mb-lg-2 ${info?.classStyle || ""}`} >
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-lg-10">
                                <span className="t-font-boldest">
                                    {info?.label}
                                </span>
                            </div>
                            <div className="col-lg-2">
                                <span className="t-font-boldest">
                                    :
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <span className={`d-flex ${position} `} >
                            {state[info.fieldName] || ''}
                        </span>
                    </div>
                </div>
            )

            break;

        case 'icon-table':
            //code block
            const icon_state = layoutType === "table" ? dataList[index] : state;
            const key = info.index ? [info.index] : icon_state[info.fieldName];
            return (
                <div key={index} className='d-flex justify-content-center mt-1'
                >
                    {key == 'file' ? (<IoDocumentText className="text-info cursor-pointer" size={24} textalign="center" />)
                        : (key == 'outline-clock' ? (<MdOutlineHistory onClick={() => { iconPress[info.onClick](index, parentIndex) }} className="text-success cursor-pointer" size={24} textalign="center" />) :
                            (key == 'delete' ? (<span style={{ paddingRight: `${info?.onStyle || '0px'}`, marginRight: `${info?.onMargin || '0px'}` }}><FiTrash2 className="text-danger cursor-pointer " size={24} onClick={() => { iconPress[info.onClick](index, parentIndex) }} /></span>) :
                                (key == 'add' ? (<span style={{ paddingRight: `${info?.onStyle || '0px'}`, marginRight: `${info?.onMargin || '0px'}` }}><MdAdd className="text-info cursor-pointer" size={24} textalign="center" onClick={() => { iconPress[info.onClick](index) }} /></span>) : null))
                        )}
                </div>)
            break


        case 'icon-new':
            //code block
            const icon_statee = layoutType === "table" ? dataList[index] : state;
            const keyy = info.index ? [info.index] : icon_statee[info.fieldName];
            return (
                <div key={index} className='d-flex justify-content-center mt-1'
                >
                    {keyy == 'file' ? (<IoDocumentText className="text-info cursor-pointer" size={24} textalign="center" />)
                        : (keyy == 'outline-clock' ? (<MdOutlineHistory onClick={() => { onSearch(index, parentIndex) }} className="text-danger cursor-pointer" size={24} textalign="center" />) :
                            (keyy == 'delete' ? (<span style={{ paddingRight: `${info?.onStyle || '0px'}`, marginRight: `${info?.onMargin || '0px'}` }}><MdDelete className="text-danger cursor-pointer " size={24} onClick={() => { onSearch(index, parentIndex) }} /></span>) :
                                (keyy == 'add' ? (<span style={{ paddingRight: `${info?.onStyle || '0px'}`, marginRight: `${info?.onMargin || '0px'}` }}><MdAdd className="text-info cursor-pointer" size={24} textalign="center" onClick={() => { onSearch(index, parentIndex) }} /></span>) : null))
                        )}
                </div>)
            break

        case 'multi-select':
            //code block
            const option = state[info.options] || [];
            // const keyy = info.index ? [info.index] : icon_statee[info.fieldName];
            return (
                <div key={index} className='d-flex justify-content-center mt-1'
                >
                    <MultiSelect
                        options={option}
                        placeholder="Choose fruits"
                        maxSelectable={10}
                        selectedItemStyle={{ backgroundColor: "#28a745", color: "#fff" }}
                        theme="light"
                    // onSelectionChange={handleSelectionChange}
                    />

                </div>)
            break


        case 'typedropdown':
            const optionsList = state[info.options] || [];
            const keyField = info.uniqueKey || "id";
            const displayField = info.displayKey || "name";
            const typeDropdownValidate = layoutType === "table" ? dataList[index] : state;

            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor={keyField} class="label-color">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <div >
                        <CustomTypeahead
                            info={info}
                            optionsList={optionsList}
                            typeDropdownValidate={typeDropdownValidate}
                            onChangeCallback={onChangeCallback}
                            handleTypeChange={handleTypeDropDown}
                            index={index}
                            onSearch={onSearch}
                            parentIndex={parentIndex}
                            style={{ width: '100%' }}
                        />





                    </div>


                    {info.icon ? (<span className="span-right-input-icon" name={info.fieldName} onClick={() => { onSearch() }} style={{ marginTop: info?.classStyle || '' }}>
                        <i className={`ul-form__icon ${info?.icon || "i-Magnifi-Glass1"}`}></i>
                    </span>) : ""}
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>

            )
            break;


        case 'textarea':
            // code block
            const textAreaValue = layoutType === "table" ? dataList[index][info.fieldName] : state[info.fieldName] || '';
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName" className="label-color">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <Input
                        type='textarea'
                        value={textAreaValue}
                        name={info.fieldName}
                        placeholder={info.placeholder}
                        onChange={(event, name) => onChangeCallback?.[info.onChange] ? onChangeCallback?.[info.onChange](event, name, index) : handleChange(event, layoutType)}
                    // onChange={(event) => handleChange(event, layoutType)}
                    />
                    {/*   {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )} */}
                    {/*   <textarea
                        id={info.fieldName}
                        name={info.fieldName}
                        className="form-control"
                        aria-label="With textarea"
                        placeholder={info.placeholder || `Enter ${info.label}`}
                        // type="textarea"
                        key={index}
                        onChange={handleChange}
                        onFocus={removeErrors}
                        // onBlur={handleBlur}
                        value={textAreaValue[info.fieldName] || ''}
                    /> */}
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>
            )
            break;
        case 'switch':
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <Form.Check
                        type="switch"
                        name={info.fieldName}
                        checked={info.status ? info.status : state[info.fieldName]}
                        onChange={(event) => { iconPress ? iconPress(event, index, state) : handleCheckbox(event, info.fieldName) }}
                        id="custom-switch 5"
                    />
                </div>
            )
            break;
        case 'checkbox':
            // const checkOptions = state[info?.options] || [];
            const checkField = info.uniqueKey || "id";
            const checkDisField = info.displayKey || "name";
            const selectedCheckbox =
                layoutType === "table"
                    ? dataList && dataList[index] && dataList[index][info.fieldName]
                        ? dataList[index][info.fieldName]
                        : ''
                    : info.fieldName && state[info.fieldName] || '';
            return (
                <div key={index} className="col-md-6 mb-3">
                    <DynamicCheckBox
                        // options={checkOptions}
                        info={info}
                        isChecked={info?.status || selectedCheckbox}
                        uniqueKey={checkField}
                        displayKey={checkDisField}
                        name={info.fieldName}
                        onChange={(event, name) => {
                            if (onChangeCallback?.[info.onChange]) {
                                return onChangeCallback[info.onChange](event, info.fieldName, index, parentIndex);
                            } else if (typeof iconPress === 'function') {
                                return iconPress(event, index, state);
                            } else {
                                return handleCheckbox(event, layoutType);
                            }
                        }}

                    // onChange={(e) => handleCheckbox(e,info.fieldName)}
                    />
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>


            )
            // code block
            /* return <FormControlLabel
                control={<Checkbox name={info.fieldName} checked={state[info.fieldName]} onChange={(e) => onSelectionChange(e, info.dupicateFromField, info.dupicateToField)} />}
                label={info.label || "checkbox"}
            /> */
            break;
        case 'checkbox-old':

            const selectedCheckbox1 = layoutType === "table" ? dataList[index][info.fieldName] || '' : state[info.fieldName] || '';
            return (
                <Fragment key={index} >
                    <label className="checkbox mb-3" >
                        <input
                            type="checkbox"
                            name={info.fieldName}
                            checked={info?.status || selectedCheckbox1}
                            key={index}
                            onChange={(event) => {
                                if (typeof iconPress === 'function') {
                                    iconPress(event, index, state);
                                } else {
                                    handleCheckbox(event, info.fieldName);
                                }
                            }}
                        />
                        {/* <span >{info.label || ""}</span> */}
                        <span className="checkmark"></span>
                    </label>
                    {
                        info.label && (
                            <label htmlFor="firstName" className="label-color">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                </Fragment>)

            break;
        case 'checkboxOld':
            const selected_Checkbox = layoutType === "table" ? dataList[index][info.fieldName] || '' : state[info.fieldName] || '';
            return (
                <Fragment key={index}  >
                    <div className="col-md-12 form-group mb-3">
                        <label></label>
                        <div style={{ height: '30px', alignContent: 'center' }}>
                            <input
                                type="checkbox"
                                id={info.fieldName}
                                name={info.fieldName}
                                className="form-check-input border border-secondary "
                                checked={info?.status || selected_Checkbox}
                                key={index}
                                onChange={(event) => {
                                    if (typeof iconPress === 'function') {
                                        iconPress(event, index, state);
                                    } else {
                                        handleCheckbox(event, info.fieldName);
                                    }
                                }}
                            />
                            <label className="pt-0.5" htmlFor={info.fieldName}>
                                {info.label || ""}
                            </label>
                        </div>
                    </div>
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}</Fragment>
            )
            // code block
            /* return <FormControlLabel
                control={<Checkbox name={info.fieldName} checked={state[info.fieldName]} onChange={(e) => handleCheckbox(e, info.dupicateFromField, info.dupicateToField)} />}
                label={info.label || "checkbox"}
            /> */
            break;
        case 'radio':
            // code block
            const opsList = state[info.options] || [];
            const radioValue = layoutType === "table" ? dataList[index][info.fieldName] || false : (state[info.fieldName] || false);
            const unqKey = info.uniqueKey || "id";
            const dispKey = info.displayKey || "name";
            return (
                <div key={index} style={{ marginTop: `${info?.marginTop || ''}` }} className="col-md-6  mb-3">
                    <RadioField
                        options={opsList}
                        info={info}
                        fieldName={info.fieldName}
                        value={radioValue}
                        unqKey={unqKey}
                        dispKey={dispKey}
                        // onChange={handleRadioChange}
                        onChange={(event, name) => onChangeCallback?.[info.onChange] ? onChangeCallback?.[info.onChange](event, name, index) : handleRadioChange(event, layoutType)}
                    />

                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>
            )
            break;
        // case 'radio':
        //     // code block
        //     const opsList = state[info.options] || [];
        //     const unqKey = info.uniqueKey || "id";
        //     const dispKey = info.displayKey || "name";
        //     return (
        //         <div key={index} className="col-md-6 form-group mb-3">
        //             {
        //                 info.label && (
        //                     <label htmlFor="firstName">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
        //                 )
        //             }
        //             <div className="ul-form__radio-inline">  {/* col-md-6 form-group mb-1 */}
        //                 {
        //                     opsList.length && (
        //                         opsList.map((item, index) => (
        //                             <label key={index} className=" ul-radio__position radio radio-primary form-check-inline">

        //                                 <input
        //                                     checked={state[info.fieldName] == item[unqKey]}
        //                                     key={index}
        //                                     onFocus={removeErrors}
        //                                     // onChange={handleChange} 
        //                                     onChange={(event) => info.onChange ? onChangeCallback?.[info.onChange](event, index) : handleChange(event)}
        //                                     type="radio" name={info.fieldName} value={item[unqKey]} />
        //                                 <label className="ul-form__radio-font">
        //                                     {item[dispKey]}
        //                                 </label>
        //                                 <span className="checkmark"></span>
        //                             </label>
        //                         ))
        //                     )
        //                 }
        //             </div>
        //             {checkErrors && errorState[info.fieldName] && (
        //                 <div className="text-danger mt-1 ml-2">
        //                     {info.errorMsg || "* required"}
        //                 </div>
        //             )}
        //         </div>
        //     )
        //     break;

        case 'icon':
            // code block
            return (
                <div key={index} className="mt-1">
                    <MdCancel
                        className="text-danger cursor-pointer"
                        size={24}
                        style={{ cursor: "pointer" }}
                        onClick={() => { iconPress[info.onClick](index, info.fieldName) }}
                    />
                </div>
            )
            break;
        case 'viewicon':
            // code block
            return (
                <div key={index} className="mt-1">
                    <MdVisibility className="text-success cursor-pointer" size={24} /* textAlign="center" */ onClick={() => { iconPress[info.onClick](index, info.fieldName) }}
                    ></MdVisibility>
                </div>

            )
            break;

        case 'file':
            // code block
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName" className="label-color">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <input
                        style={{ height: '30px' }}
                        type="file"
                        accept={info?.fileFormat}
                        name={info.fieldName}
                        className="form-control"
                        disabled={info?.disabled || false}
                        onChange={(event) => onChangeCallback?.[info.onChange] ? onChangeCallback?.[info.onChange](event, info.fieldName, index) : handleFileChange(event)}
                        onFocus={removeErrors}
                        id="inputGroupFile01"
                    />
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}

                </div>
            )
            /*  */
            break;
        case 'image':
            // code block            

            // return <ChooseImage selectedFile={itemRow.image} initialImage={imagePath + itemRow.imageName} index={index} onDrop={selectedImage} onProcess={onProcess} />            
            break;
        case 'multiselect':
            const multOptionList = state[info.options] || [];
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="firstName">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }

                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info?.errorMsg || '* required'}
                        </div>
                    )}
                </div>
            )
            break;
        case 'select':
            const optionList = state[info.options] || [];
            const uniqueKey = info.uniqueKey || "id";
            let displayKey = info.displayKey || "name";
            const subDisplayKey = info.subDisplayKey || "";
            let serviceTypeArr = []
            // const selectedValue = layoutType === "table" ? dataList[index][info.fieldName] || '' : state[info.fieldName] || '';
            const rawSelectedValue = layoutType === "table" ? dataList[index][info.fieldName] : state[info.fieldName];
            const selectedValue = Array.isArray(rawSelectedValue) ? rawSelectedValue[0] : rawSelectedValue || '';

            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {info.label && (
                        <label htmlFor="firstName" className="label-color"  /* style={{ marginBottom: '8px' }} */ >

                            {info.label || "text area"}{info.config.required ? "*" : ""}
                        </label>
                    )}
                    {/* <br /> */}
                    <Select
                        info={info}
                        options={optionList}
                        optionValKey={uniqueKey}
                        name={info.fieldName}
                        optionLabelKey={displayKey}
                        value={rawSelectedValue}
                        // onChange={handleTypeChange}
                        onChange={(event, name) => onChangeCallback?.[info.onChange] ? onChangeCallback?.[info.onChange](event, info.fieldName, index, parentIndex) : handleSelect(event, name)}
                        placeholder={info?.placeholder || "--select--"}
                    // size="xl"
                    // position="left"

                    />


                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>


            )
            break;
        case 'selectsearch':
            /* const optionList = state[info.options] || [];
            const uniqueKey = info.uniqueKey || "id";
            const displayKey = info.displayKey || "name";
            let serviceTypeArr = []
            if (info?.multiple) {
                serviceTypeArr = [];
            }
     
            const selectedItem = multiple ? optionList.filter((item, index) => serviceTypeArr.indexOf(item) == index) || null
                : optionList.find(v => v?.[uniqueKey]?.toString() === state[info.fieldName]?.toString());
            const multiple = info?.multiple || false */


            const items = [
                {
                    id: 0,
                    name: 'Cobol'
                },
                {
                    id: 1,
                    name: 'JavaScript'
                },
                {
                    id: 2,
                    name: 'Basic'
                },
                {
                    id: 3,
                    name: 'PHP'
                },
                {
                    id: 4,
                    name: 'Java'
                }
            ]

            const handleOnSearch = (string, results) => {
                // onSearch will have as the first callback parameter
                // the string searched and for the second the results.
                console.log(string, results)
            }

            const handleOnHover = (result) => {
                // the item hovered
                console.log(result)
            }

            const handleOnSelect = (item) => {
                // the item selected
                console.log(item)
            }

            const handleOnFocus = () => {
                console.log('Focused')
            }

            const formatResult = (item) => {
                return (
                    <>
                        <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span>
                        <span style={{ display: 'block', textAlign: 'left' }}>name: {item.name}</span>
                    </>
                )
            }

            return (
                <ReactSearchAutocomplete
                    items={items}
                    onSearch={handleOnSearch}
                    onHover={handleOnHover}
                    onSelect={handleOnSelect}
                    onFocus={handleOnFocus}
                    autoFocus
                    formatResult={formatResult}
                />
            )
            break;
        case 'email':
            const emailValueFiled = layoutType === "table" ? dataList[index] : state;
            return (
                <div key={index} className="col-md-12 form-group mb-3">
                    {
                        info.label && (
                            <label htmlFor="email">{info.label || "text area"}{info.config.required ? "*" : ""}</label>
                        )
                    }
                    <input
                        name={info.fieldName}
                        id={info.fieldName}
                        className="form-control"
                        placeholder={info.placeholder || ""}
                        onChange={handleChange}
                        type="email"
                        onFocus={removeErrors}
                        value={emailValueFiled[info.fieldName] || ''}
                    />
                    {checkErrors && errorState[info.fieldName] && (
                        <div className="text-danger mt-1 ml-2">
                            {info.errorMsg || "* required"}
                        </div>
                    )}
                </div>
            )

            // return <ChooseImage selectedFile={itemRow.image} initialImage={imagePath + itemRow.imageName} index={index} onDrop={selectedImage} onProcess={onProcess} />            
            break;

        case 'button':
            // alert(JSON.stringify(info))
            return (
                <div key={index} className={`col-md-12 form-group ${info?.className || `mb-3`}  `} style={{ marginTop: info?.position || '', }}
                >
                    <Button style={{ backgroundColor: lightTheme.primaryColor, border: "none", padding: "5px 10px", fontSize: "12px" }} onClick={() => onSearch()}>
                        {info?.label || "Save Changes"}
                    </Button>
                </div>
            )

            // return <ChooseImage selectedFile={itemRow.image} initialImage={imagePath + itemRow.imageName} index={index} onDrop={selectedImage} onProcess={onProcess} />            
            break;
        case 'status':

            return (

                <span className={`badge ${getUserStatusClass(info.succesName == "Deactived" ? "De-Activated" : info.status ? "active" : "inactive")}`}>
                    {info.status ? info?.succesName || "Active" : info?.failureName || "InActive"}
                </span>
            )

            break;
        default:
        // code block
    }

    /* switch (info.inputType) {
        case 'header':
            // code blockṣ
            return <CardTitle >{info?.label || ""}</CardTitle>
            break;
        case 'label':
            // code block
            return <DatePicker format={info?.format || "DD/MM/YYYY"} value={state[info?.fieldName] || new Date()} label={info?.label || "Date"} onChange={handleDateChange} fieldName={info?.fieldName} />
            break;
        case 'ṣ':
            // code block
            return <DatePicker format={info?.format || "DD/MM/YYYY"} value={state[info?.fieldName] || new Date()} label={info?.label || "Date"} onChange={handleDateChange} fieldName={info?.fieldName} />
            break;
        case 'text':
            // code block
            return <TextField
                type="text"
                size="small"
                name={info.fieldName}
                onChange={handleChange}
                value={state[info.fieldName] || ''}                
                label={info.label || "input text"}
                errorMessages={['this field is required']}
            />
            break;
        case 'textarea':
            // code block
            return <TextAreaField
                size="small"
                label={info.label || "text area"}
                name={info.fieldName}
                value={state[info.fieldName] || ''}
                onChange={handleChange}
                // onChange={(e) => handleChange("address", e)}
                // error={"errorObj.address"}
                placeholder=""
            // errorMsg="Please enter address."
            />
            break;
        case 'checkbox':
            // code block
            return <FormControlLabel
                control={<Checkbox name={info.fieldName} checked={state[info.fieldName]} onChange={(e) => handleCheckbox(e, info.dupicateFromField, info.dupicateToField)} />}
                label={info.label || "checkbox"}
            />
            break;
        case 'radio':
            // code block
             <Form.Check
                              type="radio"
                              label="first radio"
                              name="radio"
                              id="formHorizontalRadios1"
                              value="radio1"
                            />
            return <RowRadioButtonsGroup name={info.fieldName} value={state[info.fieldName]} title={info.label} items={info.options} handleChange={handleChange} />
            break;
        case 'file':
            // code block
            return (
                <>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <ChooseFile />
                    </Box>
                    {
                        info.label && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography align="center" variant="subtitle1" >{info.label}</Typography>
                            </Box>
                        )
                    }
                   

                </>
            )
            break;
        case 'image':
            // code block            
            return (
                <>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                       
                        <ChooseImage onDrop={selectedImage} />
                    </Box>
                    {
                        info.label && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography align="center" variant="subtitle1" >{info.label}</Typography>
                            </Box>
                        )
                    }
                   

                </>
            )
            // return <ChooseImage selectedFile={itemRow.image} initialImage={imagePath + itemRow.imageName} index={index} onDrop={selectedImage} onProcess={onProcess} />            
            break;
        case 'select':
            const optionList = state[info.options] || [];
            const uniqueKey = info.uniqueKey || "id";
            const displayKey = info.displayKey || "name";
            // alert(info.fieldName+"---"+state[info.fieldName])
            let serviceTypeArr = []
            if (info?.multiple) {
                serviceTypeArr = [];//
                state[info.fieldName] ? state[info.fieldName].split(",") : []
            }

            // const toFindDuplicates = optionList.filter((item, index) => state[info.fieldName].indexOf(item) !== index)
            const selectedItem = multiple ? optionList.filter((item, index) => serviceTypeArr.indexOf(item) == index) || null
                : optionList.find(v => v?.[uniqueKey]?.toString() === state[info.fieldName]?.toString());
            // alert(JSON.stringify(selectedItem))
            const multiple = info?.multiple || false
            return <AutoComplete
                options={optionList}
                disableClearable
                multiple={multiple}
                name={info.fieldName}
                getOptionLabel={(option) => option[displayKey]}
                onChange={(event, values) => onSelectOption(event, values, info.fieldName, info.uniqueKey, multiple, info?.subItem)}
                // value={selectedItem || (multiple ? [] : null)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        label={info.label || "options"}
                        variant="outlined"
                        fullWidth
                    />
                )}
                required={true}
                validators={[
                    'required'
                ]}
                errorMessages={['this field is required']}

            />
            break;
        default:
        // code block
    } */
}

export default formRender;
