const homeWorkDetailsForm = [
    {
        title: "Basic Information",
        formBody: [
            /*  {
                 formField: [
                     {
                         label: "Created By",
                         inputType: "select",
                         options: "employeeList",
                         uniqueKey: "employeeId",
                         displayKey: "employeeCode",
                         subDisplayKey: "employeeName",
                         multiple: false,
                         onChange: "handleSubjectTeachers",
 
                         // disabled:true,
                         // limit: "1",
                         fieldName: "employeeId",
                         defaultValue: "",
                         config: {
                             required: "required",
                         },
                     },
                 ]
             }, */
            {
                formField: [
                    {
                        label: "Class - Section",
                        inputType: "select",
                        options: "classList",
                        uniqueKey: "enrollmentId",
                        displayKey: "classTitle",
                        onChange: "onSelectClass",
                        subDisplayKey: "sectionTitle",
                        multiple: false,
                        fieldName: "enrollmentId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Select Homework",
                        inputType: "select",
                        options: "studentHomeWorkList",
                        uniqueKey: "homeWorkId",
                        // onChange: "handleHomeworkList",

                        displayKey: "title",
                        // onChange: "onSelectClass",
                        subDisplayKey: "sectionTitle",
                        multiple: false,
                        fieldName: "homeWorkId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
           
           

        ]
    }
];

const homeWorkForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey: "batchId",
                        onChange: "onSelectBatch",
                        displayKey: "batchName",
                        fieldName: "batchId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Created By",
                        inputType: "typedropdown",
                        options: "employeeList",
                        uniqueKey: "employeeId",
                        displayKey: "employeeCode",
                        subDisplayKey: "employeeName",
                        multiple: false,
                        onChange: "handleSubjectTeachers",

                        // disabled:true,
                        // limit: "1",
                        fieldName: "employeeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Class - Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey: "enrollmentId",
                        displayKey: "classTitle",
                        onChange: "onSelectClass",
                        subDisplayKey: "sectionTitle",
                        multiple: true,
                        fieldName: "enrollmentId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },


            {
                formField: [
                    {
                        label: "Type",
                        inputType: "select",
                        options: "homeWorkTypeList",
                        uniqueKey: "typeId",
                        displayKey: "typeName",
                        multiple: false,
                        fieldName: "typeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },

            {
                formField: [
                    {
                        label: "Submission Date",
                        inputType: "date",
                        fieldName: "submissionDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Home Work Date",
                        inputType: "date",
                        fieldName: "createdAt",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },

            // {
            //     formField: [
            //         {
            //             label: "Select Student",
            //             inputType: "button",
            //             // color: "info",
            //             /*  options: "filterStudentEnrollment",
            //              uniqueKey:"studentEnrollmentId",
            //              displayKey:"studentName",
            //              subDisplayKey:"lastName",
            //              onChange:"onSelectStudent",
            //              multiple:true, */
            //             position: '25px',
            //             fieldName: "studentEnrollmentId",
            //             defaultValue: "",
            //             config: {
            //                 required: "required",
            //             },
            //         }
            //     ]
            // }
        ]
    }
];

const homeWorkEditForm = [
    {
        title: "Basic Information",
        formBody: [
            {
                formField: [
                    {
                        label: "Batch Name",
                        inputType: "select",
                        options: "batchList",
                        uniqueKey: "batchId",
                        disabled: true,
                        onChange: "onSelectBatch",
                        displayKey: "batchName",
                        fieldName: "batchId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Type",
                        inputType: "select",
                        options: "homeWorkTypeList",
                        uniqueKey: "typeId",
                        disabled: true,
                        displayKey: "typeName",
                        multiple: false,
                        fieldName: "typeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Created By",
                        inputType: "typedropdown",
                        options: "employeeList",
                        uniqueKey: "employeeId",
                        displayKey: "employeeCode",
                        subDisplayKey: "employeeName",
                        onChange: "handleSubjectTeachers",
                        multiple: false,
                        limit: "1",
                        disabled: true,
                        fieldName: "employeeId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Submission Date",
                        inputType: "date",
                        fieldName: "submissionDate",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Home Work Date",
                        inputType: "date",
                        fieldName: "createdAt",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },
            {
                formField: [
                    {
                        label: "Class -Section",
                        inputType: "select",
                        options: "enrollmentDetailsList",
                        uniqueKey: "enrollmentId",
                        displayKey: "classTitle",
                        onChange: "onSelectClass",
                        subDisplayKey: "sectionTitle",
                        multiple: true,
                        fieldName: "enrollmentId",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                ]
            },

        ]
    }
];


export {
    homeWorkDetailsForm,
    homeWorkForm,
    homeWorkEditForm
};