import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// import FormLayout from "app/views/formlayout";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
// import { createBatch, getBatch, updateBatch } from "app/api/BatchApi";
import { pagePermission, showMessage } from "app/utils/app-functions";
// import SmallLoader from "@gull/components/SmallLoader";
// import { createUnitType, getUnitType, updateUnitType } from "app/api/UnitTypeApi";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { parentunitForm, unitForm } from "./formData";
import { imagePath, unitPath } from "app/utils/constant";
import { getSubjectEnrollment } from "app/api/SubjectEnrollmentApi";
import { createUnit, getUnitFilter, asyncGetUnitFilterInfo, getUnitUserDetails, updateUnit } from "app/api/UnitApi";
import { createUnitType, getUnitType } from "app/api/UnitTypeApi";
import { uploadFiles } from "app/api/DocumentApi";
import { getRole } from "app/api/RoleApi";
import Alert from "app/components/atoms/alert";
import { FiEdit } from "react-icons/fi";
import { AlertProps } from "app/components/atoms/alert";
import { asyncGetUnitTypeInfo } from "app/api/UnitTypeApi";
import { asyncGetRoleInfo } from "app/api/RoleApi";
import { useSidebar } from "app/components/sidebar/sidebarContext";
import Loading from "app/components/atoms/loading";

// import noProfile from "assests/";
const { v4: uuidv4 } = require('uuid');

let editData = false;
let unitToken = false;

const Unit = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();
    const timeTableModel = useRef();

    const getUnitSuccess = useSelector((state) => state.unitReducer.getUnitSuccess);
    const getUnitList = useSelector((state) => state.unitReducer.getUnitList);
    const getUnitFailure = useSelector((state) => state.unitReducer.getUnitFailure);

    const getUserDetailsSuccess = useSelector((state) => state.unitReducer.getUserDetailsSuccess);
    const getUserDetailsList = useSelector((state) => state.unitReducer.getUserDetailsList);
    const getUserDetailsFailure = useSelector((state) => state.unitReducer.getUserDetailsFailure);

    const getUnitFilterSuccess = useSelector((state) => state.unitReducer.getUnitFilterSuccess);
    const getUnitFilterList = useSelector((state) => state.unitReducer.getUnitFilterList);
    const getUnitFilterFailure = useSelector((state) => state.unitReducer.getUnitFilterFailure);

    const getUnitTypeSuccess = useSelector((state) => state.unitTypeReducer.getUnitTypeSuccess);
    const getUnitTypeList = useSelector((state) => state.unitTypeReducer.getUnitTypeList);
    const getUnitTypeFailure = useSelector((state) => state.unitTypeReducer.getUnitTypeFailure);

    const createUnitSuccess = useSelector((state) => state.unitReducer.createUnitSuccess);
    const createUnitData = useSelector((state) => state.unitReducer.createUnitData);
    const createUnitFailure = useSelector((state) => state.unitReducer.createUnitFailure);

    const createUnitTypeSuccess = useSelector((state) => state.unitTypeReducer.createUnitTypeSuccess);
    const createUnitTypeData = useSelector((state) => state.unitTypeReducer.createUnitTypeData);
    const createUnitTypeFailure = useSelector((state) => state.unitTypeReducer.createUnitTypeFailure);

    const updateUnitSuccess = useSelector((state) => state.unitReducer.updateUnitSuccess);
    const updateUnitData = useSelector((state) => state.unitReducer.updateUnitData);
    const updateUnitFailure = useSelector((state) => state.unitReducer.updateUnitFailure);

    const createFileSuccess = useSelector((state) => state.documentReducer.createFileSuccess);
    const createFileData = useSelector((state) => state.documentReducer.createFileData);
    const createFileFailure = useSelector((state) => state.documentReducer.createFileFailure);

    const getRoleSuccess = useSelector((state) => state.roleReducer.getRoleSuccess);
    const getRoleList = useSelector((state) => state.roleReducer.getRoleList);
    const getRoleFailure = useSelector((state) => state.roleReducer.getRoleFailure);
    const roleErrorMessage = useSelector((state) => state.roleReducer.errorMessage);

    const unitErrorMessage = useSelector((state) => state.unitReducer.errorMessage);
    const documentErrorMessage = useSelector((state) => state.documentReducer.errorMessage);

    const [pageItems, setpageItems] = useState({
    });

    useEffect(() => {
        let data = pagePermission(pageItems, "Unit", "Unit")
        setpageItems(data)
    }, [navigation]);

    const column = [
        /*  {
             "dataField": "index",
             "text": "S.No",
             "sort": true,
             formatter: (cellContent, row, index) => (
                 index + 1
             )
         }, */
        {
            "dataField": "image",
            "text": "Logo",
            "sort": true,
            formatter: (cellContent, row, index, extraData) => {
                console.log(`${imagePath}${unitPath}${row.unitToken}/${encodeURI(row.image)}`);
                return <img
                    className="avatar-lg active"
                    src={row.image && row.image !== "" ? `${imagePath}${unitPath}${row.unitToken}/unitinfo/${encodeURI(row.image)}` : "/assets/images/no-image.png"}
                    alt=""
                    style={{ objectFit: "contain", height: "40px", width: "40px" }}
                />
            }
        },
        {
            "dataField": "unitName",
            "text": "Unit Name",
            "sort": true,
        },
        {
            "dataField": "",
            "text": "Type",
            "sort": true,
            formatter: (cellContent, row, index) => (
                row.parentId != null ? "Sub-Branch" : "Main Branch"
            )
        },

    ]


    if (pageItems.update) {
        column.push(
            {
                text: "Action",
                sort: false,
                align: "center",
                headerAlign: "center",
                formatter: (cellContent, row, index) => (
                    <div className="icon-container">
                        <FiEdit
                            className="text-primary cursor-pointer"
                            size={18}
                            onClick={() => onEditForm(row, index)}
                        ></FiEdit>
                        {/* <MdDelete
                            className="text-danger cursor-pointer"
                            size={18}
                            onClick={() => {
                                swal
                                    .fire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        type: "question",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, delete it!",
                                        cancelButtonText: "No",
                                    })
                                    .then((result) => {
                                        if (result.value) {
    
                                            onDeleteItem(row, index);
                                        } else {
                                            swal.fire({
                                                title: "Cancelled!",
                                                text: "Permission denied.",
                                                type: "error",
                                                icon: "error",
                                                timer: 1500,
                                            });
                                        }
                                    });
                            }}
                        ></MdDelete> */}
                    </div>
                )
            },
        )
    }

    const [state, setState] = useState({
        isParent: false
    });

    const [createModule, setCreateModule] = useState(false);
    const [typeModule, setTypeModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [timeTableModule, setTimeTableModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }
        let unitTypeList = await asyncGetUnitTypeInfo(requestData)
        let unitFilterList = await asyncGetUnitFilterInfo(requestData)
        let roleList = await asyncGetRoleInfo(requestData)


        setItemList(unitFilterList?.data || [])
        setState({
            ...state,
            unitTypeList: unitTypeList?.data || [],
            roleList: roleList?.data || [],
            unitFilterList: unitFilterList?.data || [],
        })
        setLoaderModule(false)


    }


    useEffect(() => {
        setLoaderModule(true)
        // dispatch(getUnitFilter(requestData))
        // dispatch(getRole(requestData))
        /* dispatch(getUnitFilter(requestData))
        dispatch(getUnitType(requestData)) */
        listFetching()

    }, [navigation]);


    useEffect(() => {
        if (getRoleSuccess) {
            setState({
                ...state,
                roleList: getRoleList,
                roleId: 2
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ROLE" })
        } else if (getRoleFailure) {
            setState({
                ...state,
                roleList: []
            })
            showMessage("warning", roleErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ROLE" })
        }
    }, [getRoleSuccess, getRoleFailure]);

    useEffect(() => {
        if (getUserDetailsSuccess) {
            setState({
                ...state,
                userDetailsList: [...getUserDetailsList]
            })
            setEditForm(getUserDetailsList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_USER_DETAILS" })
        } else if (getUserDetailsFailure) {
            setState({
                ...state,
                userDetailsList: []
            })
            showMessage("warning", unitErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_USER_DETAILS" })
        }
    }, [getUserDetailsSuccess, getUserDetailsFailure]);

    useEffect(() => {
        if (getUnitFilterSuccess) {
            setState({
                ...state,
                unitFilterList: [...getUnitFilterList]
            })
            setItemList(getUnitFilterList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_FILTER_UNIT" })
        } else if (getUnitFilterFailure) {
            setItemList([])
            showMessage("warning", unitErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_FILTER_UNIT" })
        }
    }, [getUnitFilterSuccess, getUnitFilterFailure]);

    useEffect(() => {
        if (getUnitTypeSuccess) {
            setState({
                ...state,
                unitTypeList: [...getUnitTypeList]
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_UNIT_TYPE" })
        } else if (getUnitTypeFailure) {
            setState({
                ...state,
                unitTypeList: []
            })
            showMessage("warning", unitErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_UNIT_TYPE" })
        }
    }, [getUnitTypeSuccess, getUnitTypeFailure]);

    useEffect(() => {
        if (createUnitSuccess) {
            const temp_state = [...itemList, createUnitData[0]];
            setState({
                ...state,
                unitFilterList: temp_state
            })
            setItemList(temp_state)
            closeModule()
            setLoaderModule(false)
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_UNIT" })
        } else if (createUnitFailure) {
            setLoaderModule(false)
            showMessage("warning", unitErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_UNIT" })
        }
    }, [createUnitSuccess, createUnitFailure]);

    useEffect(() => {
        if (updateUnitSuccess) {
            updateTable(updateUnitData[0])
            dispatch({ type: "RESET_UPDATE_UNIT" })
        } else if (updateUnitFailure) {
            setLoaderModule(false)
            showMessage("warning", unitErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_UNIT" })
        }
    }, [updateUnitSuccess, updateUnitFailure]);

    useEffect(() => {
        if (createFileSuccess) {
            // const temp_state = [...itemList, createFileData[0]];
            // closeModule()
            // setLoaderModule(false)
            dispatch({ type: "RESET_CREATE_FILE" })
        } else if (createFileFailure) {
            setLoaderModule(false)
            showMessage("warning", documentErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_FILE" })
        }
    }, [createFileSuccess, createFileFailure]);

    /* useEffect(() => {

            unitForm[0].formBody[2].formField.map(ele => {
                return {...ele ,disable : state.isParent}
            })
    }, [state.isParent]); */

    // console.log(JSON.stringify(uni));
    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.timeTableId != updatedItem.timeTableId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setViewModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            unitTypeId: [],
            loginInfo: false,
            unitId: '',
            unitName: '',
            email: '',
            phone: '+91 ',
            address: '',
            pincode: '',
            unitCode: '',
            location: '',
            password: '',
            materEmail: '',
            // roleId: '',
            isParent: false,
            firstName: '',
            confirmPassword: '',
            masterEmail: "",
            isNew: false

        })
        const fieldsToRemove = ["roleId", "firstName", "masterEmail", "password", "confirmPassword", "imageName"];

        unitForm[0].formBody[0].formField = unitForm[0].formBody[0].formField.filter(
            (field) => !fieldsToRemove.includes(field.fieldName)
        );
        unitForm[0].formBody[1].formField = unitForm[0].formBody[1].formField.filter(
            (field) => !fieldsToRemove.includes(field.fieldName)
        );
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
        setTimeTableModule(false)
    }

    const onConfirmForm = (data) => {
        // let filterData = (state?.unitTypeList || []).filter(ele => ele.unitTypeId === data[0].unitTypeId)
        setState({
            ...state,
            unitId: data[0].unitId,
            unitTypeId: data[0].unitTypeId,
            email: data[0]?.email || '',
            phone: data[0]?.phone || '',
            address: data[0]?.address || '',
            pincode: data[0]?.pincode || '',
            location: data[0]?.location || '',
            image: data[0]?.image || '',
            unitCode: data[0]?.unitCode || '',
            isParent: true
        })
    }

    const onEditForm = (data, index) => {
        console.log(data)
        // let filterData = (state?.unitTypeList || []).filter(ele => (ele.unitTypeId).toString() === data.unitTypeId.toString())
        let req = { "unitId": data.unitId, "roleId": 2 }
        dispatch(getUnitUserDetails(req))
        setState({
            ...state,
            unitTypeId: data?.unitTypeId || "",
            unitName: data.unitName,
            unitCode: data.unitCode,
            unitId: data.parentId == null ? null : data.parentId,
            email: data.email || '',
            phone: data.phone || '',
            address: data.address || '',
            pincode: data.pincode || '',
            location: data.location || '',
            image: data.image || '',
            imageName: data.image || '',
            unitToken: data.unitToken || '',
            loginInfo: true,
            isParent: data.parentId == null ? false : true
            /*  password: setData.password,
             materEmail: setData.email,
             roleId: setData.roleId,
             firstName: setData.firstName,
             confirmPassword: setData.password, */
        })
        unitToken = data.unitToken || ''
        editData = true
        setCreateModule(true)
        setSelectedItem(data)
        setSelectedIndex(index)
    }

    const setEditForm = (data) => {
        let setData = data && data.length > 0 && data[0]
        if (data.length) {
            const fieldsToRemove = ["roleId", "firstName", "password", "masterEmail", "confirmPassword", "imageName"];

            unitForm[0].formBody[0].formField = unitForm[0].formBody[0].formField.filter(
                (field) => !fieldsToRemove.includes(field.fieldName)
            );
            unitForm[0].formBody[1].formField = unitForm[0].formBody[1].formField.filter(
                (field) => !fieldsToRemove.includes(field.fieldName)
            );
            let newFields = [
                {
                    "label": "Role",
                    "inputType": "select",
                    "options": "roleList",
                    "disable": false,
                    "uniqueKey": "roleId",
                    "displayKey": "roleName",
                    "fieldName": "roleId",
                    "defaultValue": "",
                    "config": {
                        "required": "required"
                    }
                },
                {
                    "label": "First Name",
                    "inputType": "text",
                    "fieldName": "firstName",
                    "placeholder": "Enter First Name",
                    "defaultValue": "",
                    "config": {
                        "required": "required"
                    }
                },
                {
                    "label": "Login Email",
                    "inputType": "text",
                    "placeholder": "Enter Email",
                    "fieldName": "masterEmail",
                    "disable": false,
                    "defaultValue": "",
                    "config": {
                        "required": "required"
                    }
                },

            ]
            let new_Fields = [

                {
                    "label": "Password",
                    "inputType": "text",
                    "type": "password",
                    "disable": false,
                    "placeholder": "Enter Password",
                    "fieldName": "password",
                    "defaultValue": "",
                    "config": {
                        "required": ""
                    }
                },
                {
                    "label": "Confirm Password",
                    "inputType": "text",
                    "type": "password",
                    "disable": false,
                    "placeholder": "Enter Confirm Password",
                    "fieldName": "confirmPassword",
                    "defaultValue": "",
                    "config": {
                        "required": ""
                    }
                },
                {
                    "label": "Unit Logo",
                    "inputType": "file",
                    "fieldName": "imageName",
                    "onChange": "onWriteDocument",
                    "defaultValue": "",
                    "config": {
                        "required": ""
                    }
                }
            ]
            unitForm[0].formBody[0].formField = [
                ...unitForm[0].formBody[0].formField, // Existing fields
                ...newFields                          // New fields to append
            ];
            unitForm[0].formBody[1].formField = [
                ...unitForm[0].formBody[1].formField, // Existing fields
                ...new_Fields                          // New fields to append
            ];
        }
        setState({
            ...state,
            loginInfo: true,
            password: setData.password,
            masterEmail: setData.email,
            roleId: setData.roleId,
            firstName: setData.firstName,
            confirmPassword: setData.password,
        })

    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onHandleUnit = (event, name) => {
        resetState()

        setState({
            ...state,
            [name]: event
        })
        swal
            .fire({
                title: "Are you sure?",
                text: "Do you want to fill Unit details",
                type: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            })
            .then((result) => {
                if (result.value && event != 0) {
                    let filterData = (state?.unitFilterList || []).filter(ele => ele.unitId == event)
                    onConfirmForm(filterData)
                }
                else {
                    resetState()
                }
            });
    }

    const onSubmitForm = () => {
        if (state.loginInfo && (state?.password !== state?.confirmPassword)) {
            Alert({ description: 'Password and Confirm Password do not match.', type: 'error', placement: 'topRight', duration: 3 });
            return false
        }

        if (state?.isNew) {
             formDataAppend(state.formData);
        }
        const requestDate = {
            "unitName": state?.unitName || '',
            "unitTypeId": state?.unitTypeId || '',
            "address": state?.address || '',
            "unitCode": state?.unitCode || '',
            "email": state?.email || '',
            "phone": state?.phone || '',
            "pincode": state?.pincode || '',
            "location": state?.location || '',
            "unitToken": state?.unitToken || unitToken,
            "parentId": state?.unitId || null,
            // "serviceId": null,
            "image": state?.imageName || state.image,
            "loginInfo": [{
                firstName: state?.firstName || "",
                roleId: state?.roleId || "",
                email: state?.masterEmail || '',
                ...(state?.password ? { password: state.password } : {}),
                ...(state?.confirmPassword ? { confirmPassword: state.confirmPassword } : {})
            }],
        }
        console.log(JSON.stringify(requestDate));
        console.log(JSON.stringify(unitToken));
        if (editData) {
            setLoaderModule(true)
            if (requestDate.parentId == '') {
                delete requestDate.parentId
            }
            dispatch(updateUnit(requestDate, selectedItem.unitId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: false
            }
            setLoaderModule(true)
            // dispatch(updateTimeTable(deleteRequest, selectedItem[0].timeTableId))
        } else {
            if (requestDate.image === '') {
                Alert({ description: 'Please upload an logo.', type: 'warning', placement: 'topRight', duration: 3 });
                // return false
            } else {
                setLoaderModule(true)
                dispatch(createUnit(requestDate))
            }

        }
    }

    const onWriteDocument = (event) => {
        let file = event.target.files[0];
        let fileName = file?.name;
        const now = new Date();
        const fileExtension = fileName.split('.').pop();
        const year = now.getFullYear().toString();
        const uniqueIdentifier = Math.floor(1000 + Math.random() * 9000).toString();
        const day = now.getDate().toString().padStart(2, '0');
        const docName = `${year}${day}${uniqueIdentifier}.${fileExtension}`;
        const uid = uuidv4();

        const renamedFile = new File(
            [file],
            docName,
            {
                type: file?.type,
                name: docName,
                lastModified: file?.lastModified,
            }
        );
        unitToken = state?.unitToken || uid
       /*  console.log('====================================');
        console.log(state?.unitToken);
        console.log(uid);
        console.log('===================================='); */
        // formDataAppend(renamedFile, uid)
        setState({
            ...state,
            [event.target.name]: docName,
            unitToken: unitToken,
            formData: renamedFile,
            isNew: true

        })
    };


    const formDataAppend = (data) => {
        const formData = new FormData();
        formData.append('document', data);

        dispatch(uploadFiles(formData, "units/" + `${unitToken}` + "/unitinfo"));
    }

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    const handleCheckboxLogin = (event) => {
        if (event.target.checked) {
            let newFields = [
                {
                    "label": "Role",
                    "inputType": "select",
                    "options": "roleList",
                    "disable": false,
                    "uniqueKey": "roleId",
                    "displayKey": "roleName",
                    "fieldName": "roleId",
                    "defaultValue": "",
                    "config": {
                        "required": "required"
                    }
                },
                {
                    "label": "First Name",
                    "inputType": "text",
                    "fieldName": "firstName",
                    "placeholder": "Enter First Name",
                    "defaultValue": "",
                    "config": {
                        "required": "required"
                    }
                },
                {
                    "label": "Login Email",
                    "inputType": "text",
                    "placeholder": "Enter Email",
                    "fieldName": "masterEmail",
                    "disable": false,
                    "defaultValue": "",
                    "config": {
                        "required": "required"
                    }
                },

            ]
            let new_Fields = [

                {
                    "label": "Password",
                    "inputType": "text",
                    "type": "password",
                    "disable": false,
                    "placeholder": "Enter Password",
                    "fieldName": "password",
                    "defaultValue": "",
                    "config": {
                        "required": ""
                    }
                },
                {
                    "label": "Confirm Password",
                    "inputType": "text",
                    "type": "password",
                    "disable": false,
                    "placeholder": "Enter Confirm Password",
                    "fieldName": "confirmPassword",
                    "defaultValue": "",
                    "config": {
                        "required": ""
                    }
                },
                {
                    "label": "Unit Logo",
                    "inputType": "file",
                    "fieldName": "imageName",
                    "onChange": "onWriteDocument",
                    "defaultValue": "",
                    "config": {
                        "required": ""
                    }
                }
            ]
            unitForm[0].formBody[0].formField = [
                ...unitForm[0].formBody[0].formField,
                ...newFields
            ];
            unitForm[0].formBody[1].formField = [
                ...unitForm[0].formBody[1].formField,
                ...new_Fields
            ];
        } else {
            const fieldsToRemove = ["roleId", "firstName", "password", "masterEmail", "confirmPassword", "imageName"];

            unitForm[0].formBody[0].formField = unitForm[0].formBody[0].formField.filter(
                (field) => !fieldsToRemove.includes(field.fieldName)
            );
            unitForm[0].formBody[1].formField = unitForm[0].formBody[1].formField.filter(
                (field) => !fieldsToRemove.includes(field.fieldName)
            );
        }

        setState({
            ...state,
            [event.target.name]: event.target.checked
        })

    };
    const { sidebarOpen } = useSidebar();

    return (
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
            <Loading status={loaderModule} />

            <View isOpen={createModule} size='lg' savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} title={`${modelTitle} Unit`}>
                {state?.isParent ? <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={parentunitForm} noOfColumn={2} onChangeCallback={{ "onHandleUnit": onHandleUnit, "onWriteDocument": onWriteDocument }} onSubmit={onSubmitForm} /> :
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={unitForm} noOfColumn={2} onChangeCallback={{ "onHandleUnit": onHandleUnit, "onWriteDocument": onWriteDocument, "handleCheckboxLogin": handleCheckboxLogin }} onSubmit={onSubmitForm} />}
            </View>
            <SimpleCard>
                <CustomTable
                    columns={column}
                    data={itemList}
                    initialPage={1}
                    buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick}
                    tableName={"Create Unit"} />
            </SimpleCard>
        </div>
    )
};

export default Unit;
