import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { examFilterForm, createExamForm, addItemForm, enterItem, statusForm, editExamForm } from "./formData";
import { MdEdit, MdDelete, MdRemoveRedEye, MdNearMe } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import formRender from "app/utils/formcomponent";
import { createExam, deleteExamDetails, getExam, getExamStatus, updateExam } from "app/api/ExamApi";
import { getBatch } from "app/api/BatchApi";
import { getSubject } from "app/api/SubjectApi";
import { getExamType } from "app/api/ExamTypeApi";
import { getEnrollmentDetails } from "app/api/EnrollementDetailsApi";
import { showMessage, dateConversion, timeConversion, pagePermission } from "app/utils/app-functions";
import { getEmployee } from "app/api/EmployeeApi";
import { getExamVariant } from "app/api/ExamVariant";
import { examStatus, examVariant } from "app/api/APIContainer";
import { getAsyncStudentClassList } from "app/api/StudentListApi";
import SimpleCard from "app/components/atoms/simple-card/SimpleCard";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import View from "app/components/atoms/modalView/Modalview";
import TableLayout from "app/components/render/formlayout/tablelayout";
import DataTable from "app/components/dataTable/DataTable";
import FormLayout from "app/components/render/formlayout";
import { asyncGetBatchInfo } from "app/api/BatchApi";
import { asyncGetEnrollmenetDetailsInfo } from "app/api/EnrollementDetailsApi";
import { asyncGetExamTypeInfo } from "app/api/EnquiryTypeApi";
import { asyncGetExamTypeListInfo } from "app/api/ExamTypeApi";
import { asyncGetSubjectInfo } from "app/api/SubjectApi";
import { asyncGetEmployeeInfo } from "app/api/EmployeeApi";
import moment from "moment";
import Alert from "app/components/atoms/alert";
import { asyncGetExamStatusInfo } from "app/api/ExamApi";
import { asyncGetExamListInfo } from "app/api/ExamApi";
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { lightTheme } from "styles/theme";

let editData = false;

const Exam = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();
    const examFormRef = useRef();

    const getExamSuccess = useSelector((state) => state.examReducer.getExamSuccess);
    const getExamList = useSelector((state) => state.examReducer.getExamList);
    const getExamFailure = useSelector((state) => state.examReducer.getExamFailure);
    const examErrorMessage = useSelector((state) => state.examReducer.errorMessage);

    const getEmployeeSuccess = useSelector((state) => state.employeeReducer.getEmployeeSuccess);
    const getEmployeeList = useSelector((state) => state.employeeReducer.getEmployeeList);
    const getEmployeeFailure = useSelector((state) => state.employeeReducer.getEmployeeFailure);
    const employeeErrorMessage = useSelector((state) => state.employeeReducer.errorMessage);

    const getBatchSuccess = useSelector((state) => state.batchReducer.getBatchSuccess);
    const getBatchList = useSelector((state) => state.batchReducer.getBatchList);
    const getBatchFailure = useSelector((state) => state.batchReducer.getBatchFailure);
    const batchErrorMessage = useSelector((state) => state.batchReducer.errorMessage);

    const getExamTypeSuccess = useSelector((state) => state.examTypeReducer.getExamTypeSuccess);
    const getExamTypeList = useSelector((state) => state.examTypeReducer.getExamTypeList);
    const getExamTypeFailure = useSelector((state) => state.examTypeReducer.getExamTypeFailure);
    const examTypeErrorMessage = useSelector((state) => state.examTypeReducer.errorMessage);

    const getSubjectSuccess = useSelector((state) => state.subjectReducer.getSubjectSuccess);
    const getSubjectList = useSelector((state) => state.subjectReducer.getSubjectList);
    const getSubjectFailure = useSelector((state) => state.subjectReducer.getSubjectFailure);
    const subjectErrorMessage = useSelector((state) => state.subjectReducer.errorMessage);

    const getEnrollmentDetailsSuccess = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsSuccess);
    const getEnrollmentDetailsList = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsList);
    const getEnrollmentDetailsFailure = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsFailure);
    const enrollmentDetailsErrorMessage = useSelector((state) => state.enrollmentDetailsReducer.errorMessage);

    const createExamSuccess = useSelector((state) => state.examReducer.createExamSuccess);
    const createExamData = useSelector((state) => state.examReducer.createExamData);
    const createExamFailure = useSelector((state) => state.examReducer.createExamFailure);

    const getExamStatusSuccess = useSelector((state) => state.examReducer.getExamStatusSuccess);
    const getExamStatusList = useSelector((state) => state.examReducer.getExamStatusList);
    const getExamStatusFailure = useSelector((state) => state.examReducer.getExamStatusFailure);

    const getExamVariantSuccess = useSelector((state) => state.examVariantReducer.getExamVariantSuccess);
    const getExamVariantList = useSelector((state) => state.examVariantReducer.getExamVariantList);
    const getExamVariantFailure = useSelector((state) => state.examVariantReducer.getExamVariantFailure);

    const getStudentClassListSuccess = useSelector((state) => state.studentListReducer.getStudentClassListSuccess);
    const getStudentClassListList = useSelector((state) => state.studentListReducer.getStudentClassListList);
    const getStudentClassListFailure = useSelector((state) => state.studentListReducer.getStudentClassListFailure);
    const studentClassListErrorMessage = useSelector((state) => state.studentListReducer.errorMessage);
    //update
    const updateExamSuccess = useSelector((state) => state.examReducer.updateExamSuccess);
    const updateExamData = useSelector((state) => state.examReducer.updateExamData);
    const updateExamFailure = useSelector((state) => state.examReducer.updateExamFailure);

    const deleteExamDetailsSuccess = useSelector((state) => state.examReducer.deleteExamDetailsSuccess);
    const deleteExamDetailsFailure = useSelector((state) => state.examReducer.deleteExamDetailsFailure);
    const deleteExamDetailsErrorMessage = useSelector((state) => state.examReducer.errorMessage);
    const examVariantErrorMessage = useSelector((state) => state.examVariantReducer.errorMessage);

    const [pageItems, setpageItems] = useState({
    });

    useEffect(() => {
        let data = pagePermission(pageItems, "Student", "Exam")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "index",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return index + 1
            }
        },
        {
            "dataField": "examName",
            "text": "Exam Name",
            "sort": true
        },
        {
            "dataField": "examTypeName",
            "text": "Exam Type Name",
            "sort": true
        },
        {
            "dataField": "",
            "text": "Class-Section",
            "sort": true,
            formatter: (cellContent, row, index) => {
                try {
                    let data = row.classList.map((item, idx) => {
                        return item.classTitle + " - " + item.sectionName
                    })
                    return data[0]
                } catch {
                    console.log("error")
                }
            }


        },
        {
            "dataField": "",
            "text": "Subject",
            "sort": true,
            formatter: (cellContent, row, index) => {
                try {
                    return row.examDetails.map((item, idx) => {
                        return idx === 0 ? item.subjectTitle : ", " + item.subjectTitle;
                    }).join('');
                } catch (error) {
                    console.error('Error formatting subject:', error);
                    return "";
                }
            }
        },

        {
            "dataField": "employeeCode",
            "text": "Assigned By",
            "sort": true
        },
        {
            "dataField": "description",
            "text": "Description",
            "sort": true
        }

    ];
    if (pageItems.view || pageItems.update || pageItems.create || pageItems.delete) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index, extraData) => (
                <div className="icon-container">
                    {pageItems.view && <MdRemoveRedEye
                        className="text-success cursor-pointer"
                        size={18}
                        onClick={() => { viewExamDetails(row) }}
                    ></MdRemoveRedEye>}
                    {pageItems.create && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index, extraData)}
                    ></FiEdit>}
                    {pageItems.create && pageItems.update && <MdNearMe
                        className="text-info cursor-pointer"
                        size={18}
                        onClick={() => onStatus(row, index)}
                    ></MdNearMe>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index);

                        }}
                    ></FiTrash2>}
                </div>
            )
        },)
    }
    const examColumn = [
        {
            "dataField": "examDate",
            "text": "Date",
            "sort": false,
            "formatter": (cellContent, row, index) => {
                return dateConversion(cellContent);
            }
        },
        {
            "dataField": "fromTime",
            "text": "Time",
            "sort": false,
            "formatter": (cellContent, row, index) => (
                row.fromTime + " - " + row.toTime
            )
        },
        {
            "dataField": "subjectTitle",
            "text": "Subject",
            "sort": false
        },
        {
            "dataField": "maximumMark",
            "text": "Max Mark",
            "sort": false
        },
        {
            "dataField": "minimumMark",
            "text": "Min Mark",
            "sort": false
        },
        {
            "dataField": "internalMark",
            "text": "InternalMark",
            "sort": false
        },
        {
            "dataField": "practicalMark",
            "text": "Practical Mark",
            "sort": false
        },

    ]

    const [state, setState] = useState({
        batchList: [],
        examType: [],
        subjectList: [],
        examDetails: [],
        enrollmentDetailsList: [],
        employeeList: [],
        description: "",
        examTypeName: "",
        batchName: "",
        className: "",
        sectionName: "",
        subjectName: "",
        maximumMark: "",
        addBatchId: "",
        addClassId: "",
        addExamTypeId: "",
        addStartDate: "",
        addEndDate: "",
        addDescription: "",
        enrollmentIds: ""
    });

    const [createModule, setCreateModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [statusModule, setStatusModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [examItemList, setExamItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [examItem, setExamItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [removeModule, setRemoveModule] = useState(false);
    const [checkboxStates, setCheckboxStates] = useState([false]);

    let userInfo = window.localStorage.getItem("userInfo");
    let parsedData = JSON.parse(userInfo);
    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }
        const req = {
            isActive: "true",
        };
        const reqq = {
            /*    isActive: "true",
               batchId: state?.batchId || 0 */
        };
        if (parsedData?.[0]?.roleId == 5) {
            reqq.empCode = parsedData?.[0]?.userCode || ''
        }


        let batchList = await asyncGetBatchInfo(requestData)
        let enrollmentDetailsList = await getAsyncStudentClassList(reqq)
        let examType = await asyncGetExamTypeListInfo(requestData)
        let subjectList = await asyncGetSubjectInfo(requestData)
        let employeeList = await asyncGetEmployeeInfo(req)
        let examStatusList = await asyncGetExamStatusInfo(requestData)
        console.log(subjectList)
        setState({
            ...state,
            // schoolList:schoolInfoList?.getSchoolInfoList || [],
            batchList: batchList?.data || [],

            enrollmentDetailsList: enrollmentDetailsList?.data || [],
            examType: examType?.data || [],
            subjectList: subjectList?.data || [],
            employeeList: employeeList?.data || [],
            examStatusList: examStatusList?.data || [],

        })

    }
    const deletDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            deletDialog()
        }
    }, [deleteModule])

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        const req = {
            isActive: "true",
            // examVariantId : 1
        }
        const req1 = {
            isActive: "true",
        };

        if (parsedData?.[0]?.roleId == 5) {
            req1.empCode = parsedData?.[0]?.userCode || ''
        }
        console.log(req1);

        // dispatch(getStudentClassList(req1))
        // dispatch(getExamStatus())
        // dispatch(getBatch(requestData))
        // dispatch(getExamType(req))
        // dispatch(getSubject(requestData))
        // dispatch(getEnrollmentDetails(requestData))
        // dispatch(getEmployee(req1))
        dispatch(getExamVariant())
        listFetching()
    }, [navigation]);

    useEffect(() => {
        if (getExamSuccess) {
            let temp_state = getExamList.filter(ele => ele.examDetails != null)
            setItemList(temp_state)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EXAM" })
        } else if (getExamFailure) {
            setItemList([])
            showMessage("warning", examErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EXAM" })
        }
    }, [getExamSuccess, getExamFailure]);

    useEffect(() => {
        if (getStudentClassListSuccess) {
            setState({
                ...state,
                enrollmentDetailsList: getStudentClassListList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
        } else if (getStudentClassListFailure) {
            setState({
                ...state,
                enrollmentDetailsList: []
            })
            showMessage("warning", studentClassListErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
        }
    }, [getStudentClassListSuccess, getStudentClassListFailure]);

    useEffect(() => {
        if (getEmployeeSuccess) {
            setState({
                ...state,
                employeeList: getEmployeeList,
                createdBy: global.roleId == 5 ? getEmployeeList.map((item) => item.employeeId) : '',
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        } else if (getEmployeeFailure) {
            setState({
                ...state,
                employeeList: []
            })
            showMessage("warning", employeeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        }
    }, [getEmployeeSuccess, getEmployeeFailure]);

    useEffect(() => {
        if (getExamStatusSuccess) {
            setState({
                ...state,
                examStatusList: getExamStatusList,
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EXAM_STATUS" })
        } else if (getExamStatusFailure) {
            setState({
                ...state,
                examStatusList: []
            })
            showMessage("warning", examErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EXAM_STATUS" })
        }
    }, [getExamStatusSuccess, getExamStatusFailure]);

    useEffect(() => {
        if (getEnrollmentDetailsSuccess) {
            setState({
                ...state,
                enrollmentDetailsList: getEnrollmentDetailsList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
        } else if (getEnrollmentDetailsFailure) {
            setState({
                ...state,
                enrollmentDetailsList: []
            })
            showMessage("warning", enrollmentDetailsErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
        }
    }, [getEnrollmentDetailsSuccess, getEnrollmentDetailsFailure]);

    useEffect(() => {
        if (getSubjectSuccess) {
            setState({
                ...state,
                subjectList: getSubjectList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUBJECT" })
        } else if (getSubjectFailure) {
            setState({
                ...state,
                subjectList: []
            })
            showMessage("warning", subjectErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUBJECT" })
        }
    }, [getSubjectSuccess, getSubjectFailure]);

    useEffect(() => {
        if (getBatchSuccess) {
            setState({
                ...state,
                batchList: getBatchList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BATCH" })
        } else if (getBatchFailure) {
            setState({
                ...state,
                batchList: []
            })
            showMessage("warning", batchErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BATCH" })
        }
    }, [getBatchSuccess, getBatchFailure]);

    useEffect(() => {
        if (getExamVariantSuccess) {
            setState({
                ...state,
                examVariantList: getExamVariantList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EXAM_VARIANT" })
        } else if (getExamVariantFailure) {
            setState({
                ...state,
                examVariantList: []
            })
            showMessage("warning", examVariantErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EXAM_VARIANT" })
        }
    }, [getExamVariantSuccess, getExamVariantFailure]);

    useEffect(() => {
        if (getExamTypeSuccess) {
            setState({
                ...state,
                examType: getExamTypeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EXAM_TYPE" })
        } else if (getExamTypeFailure) {
            setState({
                ...state,
                examType: []
            })
            showMessage("warning", examTypeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EXAM_TYPE" })
        }
    }, [getExamTypeSuccess, getExamTypeFailure]);

    useEffect(() => {
        if (createExamSuccess) {
            const temp_state = [...itemList, createExamData[0]];
            setItemList(temp_state)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setLoaderModule(false)
            dispatch({ type: "RESET_CREATE_EXAM" })
        } else if (createExamFailure) {
            setLoaderModule(false)
            showMessage("warning", examErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_EXAM" })
        }
    }, [createExamSuccess, createExamFailure]);

    useEffect(() => {
        if (updateExamSuccess) {
            if (updateExamData[0].examDetails == null) {
                dispatch(getExam(state?.updateExam || {}))
            }
            else {
                updateTable(updateExamData[0])
            }

            dispatch({ type: "RESET_UPDATE_EXAM" })
        } else if (updateExamFailure) {
            setLoaderModule(false)
            dispatch({ type: "RESET_UPDATE_EXAM" })
        }
    }, [updateExamSuccess, updateExamFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n?.examId != updatedItem?.examId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            if (updatedItem.examDetails != null) {
                temp_state[selectedIndex] = updatedItem
                setItemList([])
            }
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
        }
        setLoaderModule(false)
        closeModule()
    }

    useEffect(() => {
        if (deleteExamDetailsSuccess) {
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            dispatch({ type: "RESET_DELETE_EXAM_DETAILS" })
        } else if (deleteExamDetailsFailure) {
            showMessage("warning", deleteExamDetailsErrorMessage?.message)
            dispatch({ type: "RESET_DELETE_EXAM_DETAILS" })
        }
    }, [deleteExamDetailsSuccess, deleteExamDetailsFailure]);

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
        setStatusModule(false)

    }

    const resetState = () => {
        setState({
            ...state,
            addExamStartDate: "",
            addExamEndDate: "",
            enrollmentIds: "",
            addExamTypeId: "",
            addBatchId: "",
            examName: "",
            createdBy: "",
            addDescription: ""
        })
        setExamItemList([])
        // setCheckboxStates([])
    }

    const viewExamDetails = (row) => {
        setState({
            ...state,
            examDetails: row?.examDetails || [],
        })
        setViewModule(true)
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
        setCheckboxStates([])
    }
    const onEditForm = (data, index, state) => {
        console.log(data)
        setSelectedItem(data)
        const value = data
        const examDetails = (data?.examDetails || []).map((item) => ({
            ...item,
            examDate: item.examDate ? moment(new Date(item.examDate), 'YYYY-MM-DD') : "",
            subjectId: item?.subjectId || "",
            subject_Id: item?.subjectId || "",
            examDate: moment(new Date(item.examDate), 'YYYY-MM-DD'),
        }));
        if (data.examStatus !== 'Completed' && data.examStatus !== 'Cancelled') {
            const examDetailsIds = (value?.examDetails || []).map(detail => detail.examDetailsId);
            let matchingExamDetails = []
            itemList.forEach(obj => {
                (obj?.examDetails || []).forEach(detail => {
                    if (examDetailsIds.includes(detail.examDetailsId)) {
                        matchingExamDetails.push(detail);
                    }
                });
            });
            editData = true;
            setState({
                ...state,
                addBatchId: data?.batchId || "",
                enrollmentIds: parseInt(data?.enrollmentIds) || "",
                addExamTypeId: data?.examTypeId || "",
                addClassId: data?.classId || "",
                addExamStartDate: moment(new Date(data.examStartDate), 'YYYY-MM-DD'),
                addExamEndDate: moment(new Date(data.examEndDate), 'YYYY-MM-DD'),
                addDescription: data?.description || "",
                examName: data?.examName || "",
                createdBy: data?.employeeId || "",

            })
            const initialCheckboxStates = matchingExamDetails.map((item) => (
                item.internalMark !== null && item.internalMark !== '' && item.internalMark !== 0 ||
                item.practicalMark !== null && item.practicalMark !== '' && item.practicalMark !== 0
            ));

            setCheckboxStates(initialCheckboxStates)
            setSelectedIndex(index)
            setSelectedItem(data)
            setExamItemList(examDetails)
            setCreateModule(true)
        }
        else {
            let status = data.examStatus == 'Completed' ? "The exam has been completed" : "The exam has been cancelled"
            showMessage('warning', status)
            Alert({ description: status, type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            return false
        }
    }

    const onRemoveItem = (index) => {
        let temp_state = [...examItemList];
        if (temp_state[index].examDetailsId) {
            setExamItem(temp_state[index])
            setSelectedIndex(index)
            setRemoveModule(true)
        } else {
            temp_state.splice(index, 1);
            //setSelectedIndex(index)
            setExamItemList(temp_state)
        }
    }

    const onDeleteForm = () => {

        let temp_state = [...examItemList];
        temp_state.splice(selectedIndex, 1);
        setExamItemList(temp_state)
        setRemoveModule(false)
        const examDetailsId = examItem.examDetailsId
        const request = {
            "examDetailsId": examDetailsId
        }
        dispatch(deleteExamDetails(request))
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    /* 
  const onRemoveItem = (index) => {
    let temp_state = [...qualificationState];
    if (temp_state[index].empQualificationId) {
      setSelectedItem(temp_state[index])
      setSelectedIndex(index)
      setDeleteModule(true)
    } else {
      temp_state.splice(index, 1);
      //setSelectedIndex(index)
      setQualificationState(temp_state)
    }
  }

  const onDeleteForm = () => {
    let temp_state = [...qualificationState];
    temp_state.splice(selectedIndex, 1);
    setQualificationState(temp_state)
    setDeleteModule(false)
    const qualificationId = selectedItem.empQualificationId
    const result = onSearch(qualificationId, "qualification")
  } */

    const onSearch = () => {
        if (!state.batchName || state.batchName == "0" || state.batchName == 0) {
            Alert({ description: "Please Select The Batch", type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            setItemList([])
            return false;
        }
        else if (!state.examTypeName && !state.className || state.examTypeName == "0" || state.examTypeName == 0 || state.className == "0" || state.className == 0) {
            Alert({ description: "Please Select Class or Examtype", type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList([])
            return false;
        }
        else if (state.sectionName && !state.className || state.className == "0" || state.className == 0) {
            Alert({ description: "Please Select Class", type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList([])
            return false;
        }

        const requestData = {
            batchId: state?.batchName || "",
            examTypeId: state?.examTypeName || "",
            enrollmentId: state?.className || "",
            // examVariantId: state?.examVariantId || "",
            isActive: "true"
        }
        setState({
            ...state,
            updateExam: requestData
        })
        if (global.roleId == 5) {
            requestData.empCode = global.UserInfo?.userCode || '';
        }
        setLoaderModule(true);
        dispatch(getExam(requestData))
    }
    const handleCheckboxChange = (event, index, data) => {
        const newCheckboxStates = [...checkboxStates];
        newCheckboxStates[index] = event.target.checked;
        setCheckboxStates(newCheckboxStates);

        if (!event.target.checked) {
            // If unchecked, reset marks for only the specific item at the given index
            setExamItemList(prevList =>
                prevList.map((item, i) =>
                    i === index ? { ...item, internalMark: 0, practicalMark: 0 } : item
                )
            );
        }
    };


    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    function parseTime(timeStr) {
        const [hours, minutes] = timeStr.split(':').map(Number);
        return hours * 60 + minutes;
    }

    function validateExamSchedule(exams) {
        for (let i = 0; i < exams.length; i++) {
            const exam1 = exams[i];
            const date1 = exam1.examDate;
            let fromTime1 = parseTime(exam1.fromTime);
            let toTime1 = parseTime(exam1.toTime);
            if (toTime1 < fromTime1) toTime1 += 24 * 60;

            for (let j = i + 1; j < exams.length; j++) {
                const exam2 = exams[j];
                if (date1 === exam2.examDate) {
                    let fromTime2 = parseTime(exam2.fromTime);
                    let toTime2 = parseTime(exam2.toTime);
                    if (toTime2 < fromTime2) toTime2 += 24 * 60;

                    if (fromTime1 < toTime2 && toTime1 > fromTime2) {
                        return true;
                    }
                }
            }
        }
        return false;
    }


    const onSubmitForm = () => {
        let filteringData = state?.examType || [];

        let typeCheck = filteringData.filter(ele => ele.examTypeId == state.addExamTypeId)
        let examVariantId = typeCheck.length ? typeCheck[0].examVariantId : ''


        if ((examVariantId === 2 && examItemList.length > 1) && !deleteModule && !statusModule) {
            Alert({ description: 'For the chosen exam type, you can create a single subject test', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            return false;
        }
        if ((moment(state.addExamEndDate).isBefore(state.addExamStartDate))) {
            Alert({ description: 'End date cannot be earlier than the start date', type: 'warning', placement: 'topRight', duration: 3 });
            return false
        }
        const overlapFound = validateExamSchedule(examItemList);
        if (!deleteModule && !statusModule && overlapFound) {
            Alert({ description: 'Over Lapping on other subject Exam time', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });


            return false;
        }

        function validateExamTimes(examDetails) {
            for (let i = 0; i < examDetails.length; i++) {
                for (let j = i + 1; j < examDetails.length; j++) {
                    const exam1 = examDetails[i];
                    const exam2 = examDetails[j];

                    // Check if exams are on the same day and have overlapping times
                    if (exam1.examDate === exam2.examDate) {
                        const exam1Start = new Date(`${exam1.examDate}T${exam1.fromTime}`);
                        const exam1End = new Date(`${exam1.examDate}T${exam1.toTime}`);
                        const exam2Start = new Date(`${exam2.examDate}T${exam2.fromTime}`);
                        const exam2End = new Date(`${exam2.examDate}T${exam2.toTime}`);

                        // Check if the time slots overlap
                        if ((exam1Start < exam2End) && (exam1End > exam2Start)) {
                            return {
                                valid: false,
                                conflict: { exam1, exam2 },
                            }; // Return details of the conflicting exams
                        }
                    }
                }
            }
            return { valid: true }; // No conflicts
        }


        let requestData = {
            examStartDate: dateConversion(state.addExamStartDate, "YYYY-MM-DD"),
            examName: state.examName,
            examEndDate: dateConversion(state.addExamEndDate, "YYYY-MM-DD"),
            createdBy: state.createdBy,
            description: state.addDescription,
            examDetails: examItemList.map(item => ({
                ...item,
                subjectId: item?.subjectId || "",
                examDate: dateConversion(item.examDate, "YYYY-MM-DD"),
                description: null,
                internalMark:item?.internalMark || 0,
                practicalMark:item?.practicalMark || 0


            })),
            examTypeId: state.addExamTypeId,
            batchId: state.addBatchId,
            classId: state.enrollmentIds,
            sectionId: state.enrollmentIds,
            enrollmentIds: state.enrollmentIds,
        };

        console.log(requestData);
        const invalidMarks = requestData.examDetails.some(item =>
            parseInt(item.examMark) > parseInt(item.maximumMark)
        );

        if (invalidMarks) {
            Alert({ description: 'Exam mark cannot be greater than maximum mark', type: 'warning', placement: 'topRight', duration: 3 });
            return ;
        }
        const validationResult = validateExamTimes(requestData.examDetails);

        if (!validationResult.valid) {
            const { exam1, exam2 } = validationResult.conflict;
            Alert({ description: 'Dont schedule the exam at same time', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            return

        }

        // return

        function validateFormData(requestData) {
            for (let key in requestData) {
                if (requestData.hasOwnProperty(key) && key !== "description") {
                    if (
                        requestData[key] === null ||
                        requestData[key] === undefined ||
                        requestData[key] === "" ||
                        requestData[key] === "0" ||
                        requestData[key] === 0 ||
                        (typeof requestData[key] === "string" && requestData[key].trim() === "") ||
                        (typeof requestData[key] === "number" && isNaN(requestData[key]))
                    ) {
                        return false;
                    }
                }
            }
            return true;
        }

        function validateExamDates(examDetails, startDate, endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            // Ensure the end date includes the whole day by setting the time to the end of the day
            end.setHours(23, 59, 59, 999);

            for (let exam of examDetails) {
                const examDate = new Date(exam.examDate);
                if (examDate < start || examDate > end) {
                    return false;
                }
            }
            return true;
        }

        if (!deleteModule && !statusModule && !validateFormData(requestData)) {
            showMessage("warning", "ENTER ALL THE DATA");
            Alert({ description: 'ENTER ALL THE DATA', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });


        } else if (deleteModule) {
            const deleteRequest = {
                isActive: false,
                examDetails: [{ isActive: false }]
            };
            setLoaderModule(true);
            dispatch(updateExam(deleteRequest, selectedItem.examId));
        } else if (statusModule) {

            let tempState = state?.examStatusList || [];
            let exam = tempState.filter(ele => state.examStatusId == ele.examStatusId);
            const statusRequest = {
                examStatus: exam?.[0]?.examStatus || ''
            };
            if (statusRequest.examStatus != "") {
                setLoaderModule(true);
                dispatch(updateExam(statusRequest, selectedItem.examId));
            }
            else {
                showMessage('warning', "Select Status")
                return false
            }
            /*  setLoaderModule(true);
             dispatch(updateExam(statusRequest, selectedItem.examId)); */
        } else {



            if (requestData.examDetails.length === 0 || requestData.examDetails.some(item =>
                Object.entries(item).some(([key, value]) =>
                    (key !== 'internalMark' && key !== 'practicalMark') &&
                    (value === "" || value === undefined || value === "0" || value === 0)
                )
            )) {

                Alert({ description: 'ENTER ALL THE EXAM DETAILS', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            } else if (!validateExamDates(requestData.examDetails, requestData.examStartDate, requestData.examEndDate)) {
                Alert({ description: 'SELECT EXAM DATES BETWEEN START DATE AND END DATE', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });


            } else {
                setLoaderModule(true);
                if (editData) {
                    dispatch(updateExam(requestData, selectedItem.examId));
                } else {
                    dispatch(createExam(requestData));
                }
            }
        }
    };



    const onStatus = (data) => {

        let tempState = state?.examStatusList || []
        let exam = tempState.filter(ele => data.examStatus == ele.examStatus)
        setState({
            ...state,
            examStatusId: exam?.[0]?.examStatusId || 1
        })
        setSelectedItem(data)
        setStatusModule(true)
    }

    const onClear = () => {
        setState({
            ...state,
            batchName: "",
            examTypeName: "",
            className: "",
            examVariantId: "",
        })
        setItemList([])
    }

    const addItem = () => {
        let filteringData = state?.examType || []
        console.log(JSON.stringify(filteringData));
        let examTypeId = state?.addExamTypeId || ''
        let typeCheck = filteringData.filter(ele => ele.examTypeId == examTypeId)
        if (examTypeId == "" || examTypeId == 0) {
            Alert({ description: 'Choose exam type...!', type: 'warning', placement: 'topRight', duration: 3 });
            return false
        }
        else if (typeCheck && typeCheck.length && typeCheck[0].examVariantId == 2 && examItemList.length >= 1) {
            Alert({ description: 'For the chosen exam type, you can create a single subject test', type: 'warning', placement: 'topRight', duration: 3 });
            return false
        }


        const defaultItem = {
            "subjectId": "",
            "examDate": "",
            "fromTime": "",
            "toTime": "",
            "minimumMark": "",
            "maximumMark": "",
            "description": "",
            "internalMark": 0,
            "practicalMark": 0,

        }

        setExamItemList([...examItemList, defaultItem])
    }

    /*   const handleChange = (event, index) => {
          event.persist()
          const temp_state = [...examItemList];
          const itemObject = { ...temp_state[index] }
          itemObject[event.target.name] = event.target.value;
          temp_state[index] = itemObject;
          setExamItemList(temp_state)
      } */
    const handleExamChage = (event, index) => {
        /*  event.persist()
         const temp_state = [...examItemList];
         const itemObject = { ...temp_state[index] }
         itemObject[event.target.name] = event.target.value;
         temp_state[index] = itemObject;
         setExamItemList(temp_state) */
    }
    // const [isCheckboxChecked, setisCheckboxChecked] = useState([]);

    // Initialize the state with an array of appropriate length

    const {
        examDetails
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";


    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const { sidebarOpen } = useSidebar();

    return (
        <div>
            <View isOpen={createModule} size="fullscreen" fullscreen savetitle={modelButtonLbl} onClose={setCreateModule} isLoading={loaderModule} onSubmit={onSubmitForm} title={`${modelTitle} Exam`}>
                <View centered={true} size={'sm'} isOpen={removeModule} onClose={setRemoveModule} savetitle="Yes" onSubmit={onDeleteForm} title="Delete Exam">
                    <h6 className="text-capitalize mb-1">Are you sure ? You won't be able to revert this!</h6>
                </View>
                {!editData ? <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={createExamForm} noOfColumn={4} onSubmit={onSubmitForm} /> :
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={editExamForm} noOfColumn={4} onSubmit={onSubmitForm} />}
                <Button className="me-3" style={{ backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px" }} onClick={() => addItem()}>
                    {"Add Item"}
                </Button>
                <div className="table-responsive" >
                    <label

                        style={{
                            whiteSpace: "nowrap",
                            padding: "10px",
                            color: "gray",
                            margin: "0px",
                            fontSize: "16px",  // Example font size
                            fontWeight: "bold",
                            width: "100%",
                            textAlign: "center"


                        }}
                    >
                        Select Checkbox  for Internal Practical Marks
                    </label>
                    <table className="table" >

                        <thead>
                            <tr>
                                <th
                                    scope="col"
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "150px"
                                    }}
                                >
                                    Intern..& Prac..
                                </th>
                                <th scope="col">Subject</th>
                                <th scope="col">Exam Date</th>
                                <th scope="col">From Time</th>
                                <th scope="col">To Time</th>
                                <th scope="col">Minimum Mark</th>
                                <th scope="col">Maximum Mark</th>
                                <th scope="col">Exam Mark</th>
                                <th scope="col">Internal</th>
                                <th scope="col">Practical</th>
                                <th scope="col">Action</th>

                            </tr>
                        </thead>

                        <tbody >
                            {(examItemList ? examItemList : []).map((item, index) => {
                                try {
                                    return (
                                        <>

                                            <td className="mb-3">

                                                <input
                                                    index={index}
                                                    style={{ position: "relative", top: "35px", zIndex: "1000", left: "-10px" }}
                                                    type="checkbox"
                                                    name=""
                                                    onChange={(event) => handleCheckboxChange(event, index)}
                                                    checked={checkboxStates[index] || false}
                                                />

                                            </td>
                                            <TableLayout
                                                ref={examFormRef}
                                                defaultState={state}
                                                setDefaultState={setExamItemList}
                                                index={index}
                                                layoutType="table"
                                                dataList={examItemList}
                                                dynamicForm={checkboxStates[index] ? addItemForm : enterItem} // Use checkbox state of current row
                                                iconPress={{ "onRemove": onRemoveItem }}
                                            />
                                        </>
                                    );
                                } catch (error) {
                                    console.error('Error mapping over examItemList:', error);
                                    // Handle the error gracefully, for example, by returning a placeholder or null
                                    return null;
                                }
                            })}
                        </tbody>
                    </table>
                </div>
            </View>

            <View centered={true} size={'sm'} isOpen={statusModule} onClose={setStatusModule} savetitle="Yes" isLoading={loaderModule} onSubmit={onSubmitForm} /* onClose={validateForm} */ title="Exam Status">
                <FormLayout defaultState={state} setDefaultState={setState} dynamicForm={statusForm} noOfColumn={1} onSubmit={onSubmitForm} />
            </View>
            <View isOpen={viewModule} size="lg" saveTitle={"Close"} onClose={setViewModule} onSubmit={null} title={`View Exam`}>
                {/*  <SimpleCard title="Exam_Time_Table" onClick={null}> */}
                <DataTable column={examColumn} data={examDetails} />
            </View>

            <div style={sidebarOpen ? { marginLeft: "50px", marginTop: "50px", marginBottom: "-20px" } : { marginLeft: "90px", marginTop: "50px", marginBottom: "-50px" }}>
                <FormLayout defaultState={state} setDefaultState={setState} dynamicForm={examFilterForm} noOfColumn={4} />
                <Button onClick={onSearch} className='mr-2' style={{ backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px", marginTop: "-20px" }}>Search</Button>
                <Button onClick={onClear} style={{ backgroundColor: "white", color: "gray", border: "1px solid gray", fontSize: "12px", marginTop: "-20px" }}>Clear</Button>
            </div>


            <SimpleCard >
                <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Exam List"} columns={column} data={itemList} state={state} />
            </SimpleCard>

        </div>
    )
};

export default Exam;
