import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { homeWorkForm, homeWorkDetailsForm, homeWorkEditForm } from "./formData";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import formRender from "app/components/render/formcomponent";
import { createHomeWork, deleteHomeWorkDetails, getHomeWork, updateHomeWork } from "app/api/HomeWorkApi";
import { getSubject, getAsyncSubject } from "app/api/SubjectApi";
import { getHomeWorkType, getAsyncHomeWorkType } from "app/api/HomeWorkTypeApi";
import { getEmployee, asyncGetEmployeeInfo } from "app/api/EmployeeApi";
import { getEnrollmentDetails } from "app/api/EnrollementDetailsApi";
import { getStudent, getStudentDetails } from "app/api/StudentApi";
import { getStudentEnrollment } from "app/api/StudentEnrollmentApi";
import { dateConversion, pagePermission, showMessage } from "app/utils/app-functions";
import { getBatch, asyncGetBatchInfo } from "app/api/BatchApi";
import CustomAlignButton from "app/components/CustomAlignButton";
import { getStudentClassList, getStudentList, asyncGetStudentListInfo, getAsyncStudentClassList } from "app/api/StudentListApi";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import SimpleCard from "app/components/atoms/simple-card/SimpleCard";
import TableLayout from "app/components/render/formlayout/tablelayout";
import FormLayout from "app/components/render/formlayout";
import moment from "moment";
import Alert from "app/components/atoms/alert";
import { asyncGetStudentEnrollmentInfo } from 'app/api/StudentEnrollmentApi';
import { asyncGetHomeWorkTypeInfo } from 'app/api/HomeWorkTypeApi';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { lightTheme } from "styles/theme";
import { asyncgetSubjectTeachers } from "app/api/SubjectTeachersApi";
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { asyncgetStudentHomeworkList } from "app/api/HomeworkStudentListApi";
import { asyncGetSubmissionStatus } from "app/api/HomeworkSubmissionStatusApi";
import { createSubmitHomework } from "app/api/HomeworkSubmitApi";
import { updateSubmitHomework } from "app/api/HomeworkSubmitApi";
import { getHomeworkSubmission } from "app/api/HomeworkSubmissionListApi";
import { createHomeworkSubmission } from "app/api/HomeworkSubmissionListApi";
import { updateHomeworkSubmission } from "app/api/HomeworkSubmissionListApi";
import { asyncGetHomeworkInfo } from "app/api/HomeworkSubmitApi";

// import { asyncGetStudentListInfo } from 'app/api/StudentListApi';




let editData = false;
let filterEnrollmentIds = "";
let filterStudentEnrollmentIds = "";
let checkData = false;
let studentArr = [];
let enrollArr = [];
let userInfo = JSON.parse(window.localStorage.getItem("userInfo") || "{}");
let staffId = userInfo[0]?.employeeId || "";

const HomeworkSubmit = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getHomeWorkSuccess = useSelector((state) => state.homeWorkReducer.getHomeWorkSuccess);
    const getHomeWorkList = useSelector((state) => state.homeWorkReducer.getHomeWorkList);
    const getHomeWorkFailure = useSelector((state) => state.homeWorkReducer.getHomeWorkFailure);
    const homeWorkErrorMessage = useSelector((state) => state.homeWorkReducer.errorMessage);



    const getSubjectSuccess = useSelector((state) => state.subjectReducer.getSubjectSuccess);
    const getSubjectList = useSelector((state) => state.subjectReducer.getSubjectList);
    const getSubjectFailure = useSelector((state) => state.subjectReducer.getSubjectFailure);
    const subjectErrorMessage = useSelector((state) => state.subjectReducer.errorMessage);


    const getEnrollmentDetailsSuccess = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsSuccess);
    const getEnrollmentDetailsList = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsList);
    const getEnrollmentDetailsFailure = useSelector((state) => state.enrollmentDetailsReducer.getEnrollmentDetailsFailure);
    const enrollmentDetailsErrorMessage = useSelector((state) => state.enrollmentDetailsReducer.errorMessage);

    const getHomeWorkTypeSuccess = useSelector((state) => state.homeWorkTypeReducer.getHomeWorkTypeSuccess);
    const getHomeWorkTypeList = useSelector((state) => state.homeWorkTypeReducer.getHomeWorkTypeList);
    const getHomeWorkTypeFailure = useSelector((state) => state.homeWorkTypeReducer.getHomeWorkTypeFailure);
    const homeWorkTypeErrorMessage = useSelector((state) => state.homeWorkTypeReducer.errorMessage);

    const getEmployeeSuccess = useSelector((state) => state.employeeReducer.getEmployeeSuccess);
    const getEmployeeList = useSelector((state) => state.employeeReducer.getEmployeeList);
    const getEmployeeFailure = useSelector((state) => state.employeeReducer.getEmployeeFailure);
    const employeeErrorMessage = useSelector((state) => state.employeeReducer.errorMessage);

    const getStudentEnrollmentSuccess = useSelector((state) => state.studentEnrollmentReducer.getStudentEnrollmentSuccess);
    const getStudentEnrollmentList = useSelector((state) => state.studentEnrollmentReducer.getStudentEnrollmentList);
    const getStudentEnrollmentFailure = useSelector((state) => state.studentEnrollmentReducer.getStudentEnrollmentFailure);
    const studentEnrollmentErrorMessage = useSelector((state) => state.studentEnrollmentReducer.errorMessage);

    const getSubmitHomeworkSuccess = useSelector((state) => state.homeworkSubmissionReducer.getSubmitHomeworkSuccess);
    const getSubmitHomeworkList = useSelector((state) => state.homeworkSubmissionReducer.getSubmitHomeworkList);
    const getSubmitHomeworkFailure = useSelector((state) => state.homeworkSubmissionReducer.getSubmitHomeworkFailure);
    const homeWorkSubmitErrorMessage = useSelector((state) => state.homeWorkReducer.errorMessage);

    const createHomeworkSubmissionSuccess = useSelector((state) => state.homeworkSubmissionReducer.createHomeworkSubmissionSuccess);
    const createHomeworkSubmissionData = useSelector((state) => state.homeworkSubmissionReducer.createHomeworkSubmissionData);
    const createHomeworkSubmissionFailure = useSelector((state) => state.homeworkSubmissionReducer.createHomeworkSubmissionFailure);


    const updateHomeworkSubmissionSuccess = useSelector((state) => state.homeworkSubmissionReducer.updateHomeworkSubmissionSuccess);
    const updateHomeworkSubmissionData = useSelector((state) => state.homeworkSubmissionReducer.updateHomeworkSubmissionData);
    const updateHomeworkSubmissionFailure = useSelector((state) => state.homeworkSubmissionReducer.updateHomeworkSubmissionFailure);

    const getStudentListSuccess = useSelector((state) => state.studentListReducer.getStudentListSuccess);
    const getStudentListList = useSelector((state) => state.studentListReducer.getStudentListList);
    const getStudentListFailure = useSelector((state) => state.studentListReducer.getStudentListFailure);
    const studentListErrorMessage = useSelector((state) => state.studentListReducer.errorMessage);

    const getStudentClassListSuccess = useSelector((state) => state.studentListReducer.getStudentClassListSuccess);
    const getStudentClassListList = useSelector((state) => state.studentListReducer.getStudentClassListList);
    const getStudentClassListFailure = useSelector((state) => state.studentListReducer.getStudentClassListFailure);
    const studentClassListErrorMessage = useSelector((state) => state.studentListReducer.errorMessage);

    const getBatchSuccess = useSelector((state) => state.batchReducer.getBatchSuccess);
    const getBatchList = useSelector((state) => state.batchReducer.getBatchList);
    const getBatchFailure = useSelector((state) => state.batchReducer.getBatchFailure);
    const batchErrorMessage = useSelector((state) => state.batchReducer.errorMessage);

    const deleteHomeWorkDetailsSuccess = useSelector((state) => state.homeWorkReducer.deleteHomeWorkDetailsSuccess);
    const deleteHomeWorkDetailsFailure = useSelector((state) => state.homeWorkReducer.deleteHomeWorkDetailsFailure);
    const deleteHomeWorkDetailsErrorMessage = useSelector((state) => state.homeWorkReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Student", "Home Work")
        setpageItems(data)

    }, [navigation]);

    const column = [
        /*  {
             "dataField": "status",
             "text": "Status",
             "sort": true,
             formatter: (cellContent, row, index, extraData) => {
                 const conInfo = { inputType: "checkbox", status: cellContent };
     
                 return (
                     <div>
                         {formRender(extraData || {}, null, conInfo, null, null, statusChange, index)}
                     </div>
                 );
             }
         }, */
        {
            "dataField": "studentName",
            "text": "Student Name",
            "sort": true,
        },
        {
            "dataField": "status",
            "text": "Student Status",
            "sort": true,
            formatter: (cellContent, row, index) => {
                console.log(row);
                return (
                    <>
                        <select
                            name="status"
                            className="form-control"
                            value={cellContent}
                            onChange={(event) => onStatusHandle?.(event, index)}
                        >
                            <option value="">Select Status</option>
                            {state?.submissionStatus?.map((item) => (
                                <option key={item.homeWorkStatusId} value={item.homeWorkStatusId}>
                                    {item.status}
                                </option>
                            ))}
                        </select>
                    </>
                );
            }
        },
        {
            "dataField": "result",
            "text": "Result",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return (
                    <>
                        <input
                            name="result"
                            className="form-control"
                            placeholder="Enter Remarks"
                            type="text"
                            value={cellContent}

                            onChange={(event) => onResultHandle?.(event, index)}
                        />
                    </>
                );
            }
        },
        {
            "dataField": "submittedDate",
            "text": "Date",
            "sort": true,
            formatter: (cellContent, row, index) => {
                const formattedDate = row?.submittedDate ? new Date(row.submittedDate).toISOString().split('T')[0] : "";
                return (
                    <>
                        <input
                            name="submittedDate"
                            className="form-control"
                            placeholder="Enter Date"
                            type="date"
                            value={formattedDate || ""}
                            onChange={(event) => onDateChange?.(event, index)}
                        />
                    </>
                );
            }
        },
    ];

    const mainColumn = [

        {
            "dataField": "index",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return index + 1
            }
        },

        {
            "dataField": "studentName",
            "text": "Student Name",
            "sort": true,


        },
        {
            "dataField": "submissionDate",
            "text": "Submission Date",
            "sort": true,
            "formatter": (cellContent, row, index) => {
                return dateConversion(cellContent);
            }



        },
        {
            "dataField": "result",
            "text": "Result",
            "sort": true,




        }


    ]

    if (pageItems.update || pageItems.delete) {
        mainColumn.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && (
                        <FiEdit
                            className="text-primary cursor-pointer"
                            size={18}
                            onClick={() => onEditForm(row, index)}
                        />
                    )}
                    {pageItems.deleteOption && (
                        <MdDelete
                            className="text-danger cursor-pointer"
                            size={18}
                            onClick={() => {
                                swal
                                    .fire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        type: "question",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, delete it!",
                                        cancelButtonText: "No",
                                    })
                                    .then((result) => {
                                        if (result.value) {
                                            onDeleteItem(row, index);
                                        } else {
                                            swal.fire({
                                                title: "Cancelled!",
                                                text: "Permission denied.",
                                                type: "error",
                                                icon: "error",
                                                timer: 1500,
                                            });
                                        }
                                    });
                            }}
                        />
                    )}
                </div>
            )
        });
    }
    const [state, setState] = useState({
        subjectList: [],
        isCheck: true,
        isConfirm: true,
        homeWorkTypeList: [],
        employeeList: [],
        enrollmentDetailsList: [],
        studentEnrollmentList: [],
        // submissionDate: new Date(),
        filterStudentEnrollment: [],
        statusList: [
            {
                id: 1, statusName: "Late",
                id: 22, statusName: "On Time"

            }
        ]

    });

    const [createModule, setCreateModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [list, setList] = useState([]);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [removeModule, setRemoveModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [homeWorkItemList, setHomeWorkItemList] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [editIndex, setEditIndex] = useState(false);


    const deletDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            deletDialog()
        }
    }, [deleteModule])

    let userInfo = window.localStorage.getItem("userInfo");
    let parsedData = JSON.parse(userInfo);



    const listFetching = async () => {
        try {
            const requestData = { isActive: true };
            const req = { isActive: true };

            const [
                enrollmentDetailsList,
                submissionStatus,

            ] = await Promise.all([
                getAsyncStudentClassList(),
                asyncGetSubmissionStatus(),
            ]);

            setState(prevState => ({
                ...prevState,

                classList: enrollmentDetailsList?.data || [],
                submissionStatus: submissionStatus?.data || [],
            }));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {

        setLoaderModule(true)


        // dispatch(getHomeWork(req))
        dispatch(getHomeworkSubmission())
        listFetching()

    }, [navigation]);




    useEffect(() => {
        if (getSubmitHomeworkSuccess) {
            setState({
                ...state,
                homeWorkSubmissionList: getSubmitHomeworkList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUBMIT_HOMEWORK" })
        } else if (getSubmitHomeworkFailure) {
            setState({
                ...state,
                homeWorkSubmissionList: []
            })
            showMessage("warning", homeWorkTypeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SUBMIT_HOMEWORK" })
        }
    }, [getSubmitHomeworkSuccess, getSubmitHomeworkFailure]);

    useEffect(() => {
        if (getEnrollmentDetailsSuccess) {


            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })

        } else if (getEnrollmentDetailsFailure) {

            showMessage("warning", enrollmentDetailsErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ENROLLMENT_DETAILS" })
        }
    }, [getEnrollmentDetailsSuccess, getEnrollmentDetailsFailure]);

    useEffect(() => {
        if (getStudentEnrollmentSuccess) {
            setState({
                ...state,
                studentEnrollmentList: getStudentEnrollmentList,
            })
            setList(getStudentEnrollmentList)

            if (editData) {
                onEdit(state.editClickdata, getStudentEnrollmentList);
            }
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_ENROLLMENT" })
        } else if (getStudentEnrollmentFailure) {
            setState({
                ...state,
                studentEnrollmentList: []
            })
            showMessage("warning", studentEnrollmentErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_ENROLLMENT" })
        }
    }, [getStudentEnrollmentSuccess, getStudentEnrollmentFailure]);

    useEffect(() => {
        if (getEmployeeSuccess) {
            setState({
                ...state,
                employeeList: getEmployeeList,
                employeeId: getEmployeeList.map((item) => item.employeeId),

            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        } else if (getEmployeeFailure) {
            setState({
                ...state,
                employeeList: []
            })
            showMessage("warning", employeeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        }
    }, [getEmployeeSuccess, getEmployeeFailure]);

    useEffect(() => {
        if (getStudentClassListSuccess) {

            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
        } else if (getStudentClassListFailure) {
            setState({
                ...state,
                studentClassList: []
            })
            showMessage("warning", studentClassListErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_CLASS_LIST" })
        }
    }, [getStudentClassListSuccess, getStudentClassListFailure]);

    useEffect(() => {
        if (getStudentListSuccess) {
            setState({
                ...state,
                studentEnrollmentList: getStudentListList
            })
            setList(getStudentListList)

            if (editData) {
                onEdit(state.editClickdata, getStudentListList);
            }
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_LIST" })
        } else if (getStudentListFailure) {
            setState({
                ...state,
                studentList: []
            })
            showMessage("warning", studentListErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STUDENT_LIST" })
        }
    }, [getStudentListSuccess, getStudentListFailure]);

    useEffect(() => {
        if (getHomeWorkSuccess) {
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_HOME_WORK" })
        } else if (getHomeWorkFailure) {
            showMessage("warning", homeWorkErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_HOME_WORK" })
        }
    }, [getHomeWorkSuccess, getHomeWorkFailure]);

    useEffect(() => {
        if (createHomeworkSubmissionSuccess) {
            setItemList([])

            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_HOMEWORK_SUBMISSION" })
        } else if (createHomeworkSubmissionFailure) {
            setLoaderModule(false)
            showMessage("warning", homeWorkErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_HOMEWORK_SUBMISSION" })
        }
    }, [createHomeworkSubmissionSuccess, createHomeworkSubmissionFailure]);

    useEffect(() => {
        if (deleteHomeWorkDetailsSuccess) {
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_DELETE_HOME_WORK_DETAILS" })
        } else if (deleteHomeWorkDetailsFailure) {
            showMessage("warning", deleteHomeWorkDetailsErrorMessage?.message)
            dispatch({ type: "RESET_DELETE_HOME_WORK_DETAILS" })
        }
    }, [deleteHomeWorkDetailsSuccess, deleteHomeWorkDetailsFailure]);

    useEffect(() => {
        if (updateHomeworkSubmissionSuccess) {
            setItemList([])

            // updateTable(updateHomeworkSubmissionData[0])
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            dispatch({ type: "RESET_UPDATE_HOMEWORK_SUBMISSION" })
        } else if (updateHomeworkSubmissionFailure) {
            setLoaderModule(false)
            showMessage("warning", homeWorkErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_HOMEWORK_SUBMISSION" })
        }
    }, [updateHomeworkSubmissionSuccess, updateHomeworkSubmissionFailure]);

    useEffect(() => {
        let data = state?.employeeList || []
        console.log(data);
        if (parsedData[0].roleId === 5) {
            setState({
                ...state,
                employee_id: parsedData[0].roleId == 5 ? (data && data.length && data[0].employeeId) : null,
                employeeId: parsedData[0].roleId == 5 ? (data && data.length ? `${data[0].employeeName} ${data[0].employeeCode}` : "") : "",
                // subjectList: parsedData[0].roleId == 5 ? (data && data.length && data[0].subjectList) : "",
            })
        }

    }, []);


    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.submissionId != updatedItem.submissionId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setDeleteModule(false)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)

    }

    const resetState = () => {
        // alert('in')
        /* if(parsedData[0]?.roleId != 5){
          state.employee_id = ''  
          state.employeeCode = ''  
        } */
        setState({
            ...state,
            studentEnrollmentId: "",
            batchId: "",
            submissionDate: moment(new Date(), 'YYYY-MM-DD'),
            enrollmentId: "",
            employeeId: "",
            typeId: "",
            filterStudentEnrollment: [],
            enrollmentDetailsList: [],
            createdAt: moment(new Date(), 'YYYY-MM-DD'),
        })
        setHomeWorkItemList([])
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }


    const onEditForm = async (data, index) => {
        console.log(data)




        setState(prevState => ({
            ...prevState,
            batchId: data?.batchId || 0,
            isConfirm: true,
            isCheck: true,
            // studentEnrollmentList: studentData.data,
            // enrollmentDetailsList: enrollData.data,
            // filterStudentEnrollment: obj,
            submissionDate: data ? moment(data.submissionDate, "YYYY-MM-DD") : "",
            createdAt: data ? moment(data.createdAt, "YYYY-MM-DD") : "",
            // enrollmentId: fiterEnrollmentData,
            employeeId: (data.employeeCode || "") + " " + `(${(data.employeeName || "")})`,
            employee_id: data?.createdBy || "",
            typeId: data?.homeWorkTypeId || "",
        }));
        setHomeWorkItemList(data?.homeWorkDetails || [])
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onEdit = (data, studentData) => {
        const temp_state = state.enrollmentDetailsList;
        const temp_state_arr = studentData

        // const fiterEnrollmentData = (data?.enrollmentIds || "").split(",");
        // alert(JSON.stringify(fiterEnrollmentData))
        const fiterStudentEnrollmentData = (data?.studentEnrollmentIds || "").split(",");
        const fiterEnrollmentData = data?.enrollmentIds?.split(",").map(Number) || [];

        const enrollList = temp_state.filter((item) => { return data?.enrollmentIds.includes((item.enrollmentId).toString()) });
        const stdList = temp_state_arr.map((item) => {
            const status = fiterStudentEnrollmentData.includes(item.studentEnrollmentId.toString());
            return { ...item, status };
        });
        const obj = [];

        stdList.forEach(ele => {
            enrollList.forEach(o => {
                if (o.enrollmentId == ele.enrollmentId) {
                    obj.push(ele);
                }
            });
        });

        studentArr = obj
        setState(prevState => ({
            ...prevState,
            batchId: data?.batchId || 0,
            isConfirm: true,
            isCheck: true,
            filterStudentEnrollment: obj,
            /*  submissionDate: data ? moment(data.submissionDate,"YYYY-MM-DD") : "",
             createdAt: data ? moment(data.createdAt,"YYYY-MM-DD") : "", */
            enrollmentId: fiterEnrollmentData,
            employee_id: data?.createdBy || "",
            // employee_id: data?.createdBy || "",
            typeId: data?.homeWorkTypeId || "",
        }));
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }




    const onSelectBatch = async (event, name) => {
        let request = {
            "batchId": event, isActive: "true",
        }

        if (request.batchId != 0) {
            const req = {
                isActive: "true",
            };
            if (parsedData[0].roleId == 5) {
                req.empCode = parsedData[0]?.userCode || '';
            }
            // let enrollList = await getAsyncStudentClassList(req);
            // let studentList = await asyncGetStudentListInfo(request);
            // console.log(enrollList);
            /*  let newList = studentList.data
             setList(newList) */
            // console.log(newList);

            setState({
                ...state,
                // enrollmentDetailsList: enrollList.data,
                // studentEnrollmentList: studentList.data,
                [name]: event

            })

            // dispatch(getStudentClassList(req))
            // dispatch(getStudentList(request))
        }


    }

    const addItem = () => {
        const defaultItem = {
            "subjectId": "",
            "homeWorkTitle": "",
            "description": "",
        }
        if (state.employee_id == null) {
            Alert({ description: 'Select Created By.', type: 'warning', placement: 'topRight', duration: 3, });
            return false;
        }
        setHomeWorkItemList([...homeWorkItemList, defaultItem])
    }

    const onRemoveItem = (index) => {
        let temp_state = [...homeWorkItemList];
        console.log("studentArr ---- > " + JSON.stringify(temp_state[index]));

        if (temp_state[index].homeWorkDetailsId) {
            setSelectedItem(temp_state[index])
            setEditIndex(index)
            setRemoveModule(true)
        } else {
            temp_state.splice(index, 1);
            setHomeWorkItemList(temp_state)
        }
    }

    const onDeleteForm = () => {
        let temp_state = [...homeWorkItemList];
        let mainArray = [...itemList];
        temp_state.splice(editIndex, 1);
        mainArray[selectedIndex].homeWorkDetails.splice(editIndex, 1);
        setHomeWorkItemList(temp_state)
        setRemoveModule(false)
        const homeWorkDetailsId = selectedItem.homeWorkDetailsId
        const req = {
            "homeWorkDetailsId": homeWorkDetailsId
        }
        dispatch(deleteHomeWorkDetails(req, homeWorkDetailsId))
        // const result = onSearch(jobHistoryId, "jobHistory")
    }
    console.log(JSON.stringify(homeWorkItemList));



    const onCheck = async () => {
        let emptyTitle = false

        filterEnrollmentIds = (state.enrollmentId || []).map((itm) => {
            return itm
        })
        filterStudentEnrollmentIds = (state.studentEnrollmentId || []).map((itm) => {
            return itm.studentEnrollmentId
        })
        checkData = false
        if (filterStudentEnrollmentIds.length) {
            checkData = true
        }
        (homeWorkItemList).map((itm) => {
            if (itm.homeWorkTitle == "" || itm.subjectId == "" || itm.subjectId == 0 || itm.subjectId == null || itm.subjectId == undefined) {
                emptyTitle = true
            }
        })
        if (emptyTitle && filterEnrollmentIds != "" || homeWorkItemList.length == 0) {
            Alert({ description: 'Please Fill Home Work Details.', type: 'warning', placement: 'topRight', duration: 3, });

            // showMessage('warning', "Please Fill Home Work Details")
        } else {
            setViewModule(true)
        }
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSearch = async () => {
        try {
            let request = {
                homeWorkId: state?.homeWorkId, // Ensure state exists before accessing homeWorkId
            };

            let studentList = await asyncgetStudentHomeworkList(request);
            setItemList(studentList?.data || []);
            const hasSubmission = studentList?.data?.some((item) => item?.submissionId);

            if (hasSubmission) {
                editData = true
            } else {
                editData = false

            }
            // Update state correctly
            setState(prevState => ({
                ...prevState,
                studentList: studentList?.data || [],
            }));

            // Use the fresh studentList instead of stale state

        } catch (error) {
            console.error("Error fetching student homework list:", error);
        }
    };


    const onStatusHandle = (event, index) => {
        // Get the selected value from the dropdown
        const selectedStatusId = event.target.value;

        // Create a copy of the student list from the state
        let tempState = [...state?.studentList || []];

        // Update the status for the student at the specified index
        tempState[index].status = selectedStatusId;

        // Optionally, you can update any other related state here if needed
        setState({
            ...state,
            studentList: tempState, // Update the student list with the new status
        });

    };

    const onDateChange = (event, index) => {
        // Get the selected value from the dropdown
        const selectedDate = event.target.value;

        // Create a copy of the student list from the state
        let tempState = [...state?.studentList || []];

        // Update the status for the student at the specified index
        tempState[index].submittedDate = selectedDate;

        // Optionally, you can update any other related state here if needed
        setState({
            ...state,
            studentList: tempState, // Update the student list with the new status
        });

    };


    const onSubmitForm = () => {
        let filterStudentEnrollment = state?.studentList || [];
        console.log(filterStudentEnrollment);

        let requestData = filterStudentEnrollment.map((item) => ({
            studentEnrollmentId: item.studentEnrollmentId || 2,
            homeWorkId: state?.homeWorkId || "",
            status: parseInt(item?.status) || 1,
            submissionDate: dateConversion(item?.submittedDate || new Date(), "YYYY-MM-DD"),
            result: item.result || ""
        }));


        setLoaderModule(true);

        if (editData) {
            requestData = filterStudentEnrollment.map((item) => ({
                ...item,
                submissionId: item?.submissionId || "",
                submissionDate: dateConversion(item?.submittedDate || new Date(), "YYYY-MM-DD")
            }));
            console.log(JSON.stringify(requestData));

            dispatch(updateHomeworkSubmission(requestData));
        } else if (deleteModule) {
            const deleteRequest = { isActive: false };
            dispatch(updateHomeworkSubmission(deleteRequest, selectedItem.homeWorkId));
            return; // Ensure the function exits after dispatch
        } else {
            dispatch(createHomeworkSubmission(requestData));

        }



    };

    const onCloseForm = () => {
        setShowForm(false);
        setState({
            ...state,
            isCheck: true,
            isConfirm: true,
        })
    }

    const confirmForm = () => {
        // alert('in')
        let temp_state = [...state.filterStudentEnrollment]
        let filteredData = _.filter(temp_state, function (item) {
            return item.status === true && item.statusId !== 1 && item.statusId !== 3;
        });

        setState({
            ...state,
            isConfirm: false,
            studentEnrollmentId: filteredData
        })
        setShowForm(false);

    }

    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: `Please confirm that you are assigning homework to ${checkTitle} on the saved student list.!`,
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setViewModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (viewModule) {
            confromDialog()
        }
    }, [viewModule])

    const confromremoveDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: `Are you sure ? You won't be able to revert this!`,
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onDeleteForm()
                    } else {
                        setRemoveModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (removeModule) {
            confromremoveDialog()
        }
    }, [removeModule])

    const onSelectStudentForm = () => {
        let temp_state = [...state.filterStudentEnrollment]
        temp_state.map(item => {
            item.status = true && item.statusId !== 1 && item.statusId !== 3;
        })
        setState({
            ...state,
            isCheck: false,
            filterStudentEnrollment: temp_state
        })
    }

    const onSelectStudent = (event) => {
        setShowForm(true)
    }

    const {
        submissionDate,
        employee_id = null,
        employeeId = null,
        typeId = null
    } = state;
    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const checkTitle = !checkData ? "class " : "student ";



    const onSelectClass = async (event, name) => {
        let value = event;
        console.log(value);

        const req = {
            isActive: true,
            employeeId: staffId,
            enrollmentId: value,
        };
        console.log(req);

        // Await the async function to get the data
        const studentHomeWorkList = await asyncGetHomeworkInfo(req);

        setState({
            ...state,
            [name]: event,
            studentHomeWorkList: studentHomeWorkList?.data || [],
        });
    };

    const statusChange = (event, index, extraData) => {
        let temp_state = [...state?.studentList || []]
        temp_state[index].status = event.target.checked



        const listFilter = temp_state.filter(item => item.status === true);

        setState(prevState => ({
            ...prevState,
            filterStudentEnrollment: listFilter
        }));

        console.log(listFilter);
    };

    const onResultHandle = (event, index, extraData) => {
        let value = event.target.value;
        let temp_state = [...state?.studentList || []]
        temp_state[index].result = value
        setState(prevState => ({
            ...prevState,
            studentList: temp_state
        }));




    };








    const handleClick = (buttonName) => {
        onSubmitForm()

    };
    const { sidebarOpen } = useSidebar();

    return (
        <div>
            <div style={{ marginTop: "-10px" }}>

                <View isOpen={createModule} size="lg" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={onSubmitForm} title={`${modelTitle} Home Work`} >
                    <FormLayout defaultState={state} setDefaultState={setState} dynamicForm={homeWorkDetailsForm} onChangeCallback={{ "onSelectClass": onSelectClass }} noOfColumn={6} />
                    <Button onClick={onSearch} className='mr-2' style={{ backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px", marginTop: "-20px" }}>Search</Button>
                    <div>
                        <CustomTable columns={column} data={itemList} handleClick={handleClick} tableName={"Student List"} state={state} />

                    </div>

                </View>
                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Home Work">
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>
                </View>


                <SimpleCard >
                    <div style={{ marginLeft: "100px" }}>
                        <FormLayout defaultState={state} setDefaultState={setState} dynamicForm={homeWorkDetailsForm} onChangeCallback={{ "onSelectClass": onSelectClass }} noOfColumn={6} />
                        <Button onClick={onSearch} className='mr-2' style={{ backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px", marginTop: "-20px" }}>Search</Button>
                    </div>
                    <div>
                        <CustomTable columns={column} data={itemList} buttonData={[editData ? ['update'] : ['create']]} handleClick={handleClick} tableName={"Student List"} state={state} />

                    </div>
                </SimpleCard>
            </div>
        </div>
    )
};

export default HomeworkSubmit;