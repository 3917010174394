import React, { useState, useRef, useEffect } from 'react';
import imageCompression from 'browser-image-compression';
import styled from 'styled-components';
import Alert from 'app/components/atoms/alert';
import { imagePath, event } from "app/utils/constant";

// Styled components for improved styling
const Container = styled.div`
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: auto;
  margin: 0 auto;
`;

const Title = styled.h2`
  color: #333;
  margin-bottom: 20px;
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  display: inline-block;
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    background-color: #003f7f;
  }
`;

const ImagePreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
`;

const ImageItem = styled.div`
  position: relative;
  width: auto;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: scale(1.05);
  }
`;

const Image = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

const ImageSizeText = styled.p`
  margin: 5px 0;
  font-size: 14px;
  color: #555;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  background-color: #212B54;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 8px;
  font-size: 13px;
  display: flex;
  height:20px;
  width:20px;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
  
  &:hover {
    background-color: #e84118;
    transform: scale(1.1);
  }
  
  &:active {
    background-color: #d43f00;
  }
  
  &:focus {
    outline: none;
  }
`;


const ImageCount = styled.p`
  margin-top: 20px;
  font-size: 18px;
  color: #333;
`;

const DragDropText = styled.p`
  font-size: 16px;
  color: #555;
  margin-top: 10px;
  font-style: italic;
`;

function MultipleImage({ setImagesProp, initialImages = [] }) {
    const [images, setImages] = useState([]);
    const inputRef = useRef(null); // Create a reference for the input

    // Max image upload limit
    const MAX_IMAGES = 5;

    useEffect(() => {
        if (initialImages.length > 0) {
            console.log(`${imagePath}${event}/${encodeURI("download (15).jpeg")}`);
            const formattedImages = initialImages.map((img) => ({
                id: img.eventGalleryId,  // Use eventGalleryId as the unique identifier
                preview: `${imagePath}${event}/${encodeURI(img?.image || "")}`,  // Construct full image path
                image: `${imagePath}${event}/${encodeURI(img?.image || "")}`
            }));
            
            setImages(formattedImages);
        }
    }, [initialImages]);

    // Compression options
    const options = {
        maxSizeMB: 0.1, // Target size in MB (0.1MB = 100KB)
        maxWidthOrHeight: 800, // Resize to maintain aspect ratio
        useWebWorker: true,
    };

    // Handle image selection with compression
    const handleImageChange = async (e) => {
        const files = Array.from(e.target.files);

        // Check if adding the new files exceeds the maximum image limit
        if (images.length + files.length > MAX_IMAGES) {
            Alert({ description: `You can upload a maximum of ${MAX_IMAGES} images.`, type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            return;
        }

        const compressedImages = [];

        for (const file of files) {
            try {
                // Compress the image
                const compressedFile = await imageCompression(file, options);

                compressedImages.push({
                    eventGalleryId: Math.random(),
                    preview: URL.createObjectURL(compressedFile),
                    image: compressedFile.name
                });
            } catch (error) {
                console.error('Error in image compression:', error);
            }
        }

        const updatedImages = [...images, ...compressedImages];
        setImages(updatedImages);
        setImagesProp(updatedImages); // Passing images to the parent component

        // Reset the file input after files are successfully uploaded
        inputRef.current.value = '';
    };

    // Remove selected image
    const removeImage = (id) => {
        const updatedImages = images.filter((image) => image.id !== id);
        setImages(updatedImages);
        setImagesProp(updatedImages); // Update parent with the new images
    };

    return (
        <Container>
            <Title>Upload up to {MAX_IMAGES} images (compressed to 100KB each)</Title>

            {/* File Input Label */}
            <FileInputLabel htmlFor="fileInput">Select or Drag & Drop Images</FileInputLabel>
            <FileInput
                ref={inputRef}
                id="fileInput"
                type="file"
                multiple
                accept="image/*"
                onChange={handleImageChange}
            />

            <DragDropText>Or drag and drop your images here</DragDropText>

            {/* Image Preview Section */}
            <ImagePreviewContainer>
                {images.length > 0 &&
                    images.map((image) => (
                        <ImageItem key={image.id}>
                            <Image src={image.preview} alt="Preview" />
                            <RemoveButton onClick={() => removeImage(image.id)}>✕</RemoveButton>
                        </ImageItem>
                    ))}
            </ImagePreviewContainer>

            {/* Image Count */}
            <ImageCount>{images.length} image(s) uploaded</ImageCount>
        </Container>
    );
}

export default MultipleImage;

