const initialState = {
    appMenuList: []  
  }
  
  const AppMenuReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_APP_MENU_SUCCESS": {             
        return {
          ...state,
          getAppMenuSuccess: true,
          getAppMenuList: action.payload.data,
        };
      }
      case "GET_APP_MENU_FAILURE": {
        return {
          ...state,
          getAppMenuFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_APP_MENU": {
        return {
          ...state,
          getAppMenuSuccess: false,
          getAppMenuFailure: false,
          getAppMenuList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_APP_MENU_SUCCESS": {             
        return {
          ...state,
          createAppMenuSuccess: true,
          createAppMenuData: action.payload.data,
        };
      }
      case "CREATE_APP_MENU_FAILURE": {
        return {
          ...state,
          createAppMenuFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_APP_MENU": {
        return {
          ...state,
          createAppMenuSuccess: false,
          createAppMenuFailure: false,
          createAppMenuData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_APP_MENU_SUCCESS": {             
        return {
          ...state,
          updateAppMenuSuccess: true,
          updateAppMenuData: action.payload.data,
        };
      }
      case "UPDATE_APP_MENU_FAILURE": {
        return {
          ...state,
          updateAppMenuFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_APP_MENU": {
        return {
          ...state,
          updateAppMenuSuccess: false,
          updateAppMenuFailure: false,
          updateAppMenuData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default AppMenuReducer
  
  