import React, { createContext, useContext, useState } from 'react';
const SidebarContext = createContext();

// Create a provider component
export const SidebarProvider = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen((prev) => !prev);
    };

    return (
        <SidebarContext.Provider value={{ sidebarOpen, toggleSidebar,setSidebarOpen }}>
            {children}
        </SidebarContext.Provider>
    );
};

// Create a custom hook to use the SidebarContext
export const useSidebar = () => useContext(SidebarContext);
