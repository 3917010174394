import { returnApiCallPromise } from './ApiContent';
import { uploadFile, uploadDocuments ,uploadStreams , uploadFileS3} from './APIContainer';

//GET
/* export function getBatch(request) {
    const requestObj = request ? { params: request } : "";
    return (dispatch) => {
        returnApiCallPromise("GET", uploadStreams, requestObj)
            .then(response => {
                dispatch({
                    type: 'GET_BATCH_SUCCESS',
                    payload: response.data
                })
            }).catch(error => {

                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'GET_BATCH_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
} */

//POST
export function uploadFiles(request, path){
console.log(path);

    return (dispatch) => {
        returnApiCallPromise("POST", uploadFileS3, request ,{headers : {"Content-Type": 'multipart/form-data', path : path}})
            .then(response => {
                if (response.data.error) {
                    dispatch({
                        type: 'CREATE_FILE_FAILURE',
                        errorMessage: response.data.message
                    })
                } else {
                    dispatch({
                        type: 'CREATE_FILE_SUCCESS',
                        payload: response.data
                    })
                }
            }).catch(error => {
                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'CREATE_FILE_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

export function uploadDocument(request) {

    return (dispatch) => {
        returnApiCallPromise("POST", uploadDocuments, request)
            .then(response => {
                if (response.data.error) {
                    dispatch({
                        type: 'CREATE_DOCUMENT_FAILURE',
                        errorMessage: response.data.message
                    })
                } else {
                    dispatch({
                        type: 'CREATE_DOCUMENT_SUCCESS',
                        payload: response.data
                    })
                }
            }).catch(error => {
                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'CREATE_DOCUMENT_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

export function uploadStream(request) {

    return (dispatch) => {
        returnApiCallPromise("POST", uploadStreams, request)
            .then(response => {
                if (response.data.error) {
                    dispatch({
                        type: 'CREATE_STREAM_FAILURE',
                        errorMessage: response.data.message
                    })
                } else {
                    dispatch({
                        type: 'CREATE_STREAM_SUCCESS',
                        payload: response.data
                    })
                }
            }).catch(error => {
                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'CREATE_STREAM_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

