import React from 'react';
import { useState, useEffect, useRef } from "react";

import { statusForm, feesRecords } from './formData';
import { useDispatch, useSelector } from 'react-redux';
import View from 'app/components/atoms/modalView/Modalview';
import CustomTable from 'app/components/atoms/custom-table/CustomTable';
import FormLayout from 'app/components/render/formlayout';
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard';
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { createPettyCash, createPettyCashDetails, getPettyCash, getPettyCashDetails, getPettyCashType, updatePettyCash } from "app/api/PettyCashApi";
import { GiMoneyStack } from 'react-icons/gi';
import { MdMoneyOff, MdOutlineAddCircleOutline, MdRemoveRedEye } from 'react-icons/md';
import { pagePermission, showMessage } from "app/utils/app-functions";
import { amountFormat } from 'app/utils/app-functions';
import { validatesArray } from 'app/utils/utils';
import { dateConversion } from 'app/utils/app-functions';
import formRender from 'app/components/render/formcomponent';
import { getEmployee } from "app/api/EmployeeApi";
import _ from "lodash";
import { closeForm, eventForm, feesForm } from "./formData";
import swal from "sweetalert2";
import FormWizardBody from "app/components/atoms/formwizard/FormWizardBody";
import TableLayout from 'app/components/render/formlayout/tablelayout';
import { Button } from 'react-bootstrap';
import Alert from 'app/components/atoms/alert';
import { useSidebar } from "app/components/sidebar/sidebarContext";
import { lightTheme } from 'styles/theme';
import { asyncGetPettyCashType } from 'app/api/PettyCashApi';

let editData = false;

const PettyCash = ({ navigation }) => {

  const dispatch = useDispatch();
  const openingFormRef = useRef();
  const closingFormRef = useRef();

  const getPettyCashSuccess = useSelector((state) => state.pettyCashReducer.getPettyCashSuccess);
  const getPettyCashList = useSelector((state) => state.pettyCashReducer.getPettyCashList);
  const getPettyCashFailure = useSelector((state) => state.pettyCashReducer.getPettyCashFailure);

  const getPettyCashTypeSuccess = useSelector((state) => state.pettyCashReducer.getPettyCashTypeSuccess);
  const getPettyCashTypeList = useSelector((state) => state.pettyCashReducer.getPettyCashTypeList);
  const getPettyCashTypeFailure = useSelector((state) => state.pettyCashReducer.getPettyCashTypeFailure);

  const getEmployeeSuccess = useSelector((state) => state.employeeReducer.getEmployeeSuccess);
  const getEmployeeList = useSelector((state) => state.employeeReducer.getEmployeeList);
  const getEmployeeFailure = useSelector((state) => state.employeeReducer.getEmployeeFailure);
  const employeeErrorMessage = useSelector((state) => state.employeeReducer.errorMessage);

  const getPettyCashDetailsSuccess = useSelector((state) => state.pettyCashReducer.getPettyCashDetailsSuccess);
  const getPettyCashDetailsList = useSelector((state) => state.pettyCashReducer.getPettyCashDetailsList);
  const getPettyCashDetailsFailure = useSelector((state) => state.pettyCashReducer.getPettyCashDetailsFailure);

  const createPettyCashSuccess = useSelector((state) => state.pettyCashReducer.createPettyCashSuccess);
  const createPettyCashData = useSelector((state) => state.pettyCashReducer.createPettyCashData);
  const createPettyCashFailure = useSelector((state) => state.pettyCashReducer.createPettyCashFailure);

  const createPettyCashDetailsSuccess = useSelector((state) => state.pettyCashReducer.createPettyCashDetailsSuccess);
  const createPettyCashDetailsList = useSelector((state) => state.pettyCashReducer.createPettyCashDetailsList);
  const createPettyCashDetailsFailure = useSelector((state) => state.pettyCashReducer.createPettyCashDetailsFailure);

  const updatePettyCashSuccess = useSelector((state) => state.pettyCashReducer.updatePettyCashSuccess);
  const updatePettyCashData = useSelector((state) => state.pettyCashReducer.updatePettyCashData);
  const updatePettyCashFailure = useSelector((state) => state.pettyCashReducer.updatePettyCashFailure);

  const eventErrorMessage = useSelector((state) => state.pettyCashReducer.errorMessage);

  const [pageItems, setpageItems] = useState({
  });

  useEffect(() => {
    let data = pagePermission(pageItems, "Administrator", "Petty Cash")
    setpageItems(data)
  }, [navigation]);

  const column = [
    {
      "dataField": "pettyCashNo",
      "text": "Ticket Number",
      "sort": true
    },
    {
      "dataField": "openingBalance",
      "text": "Opening Balance",
      "sort": true
    },
    {
      "dataField": "createdOn",
      "text": "Created On",
      "sort": true,
      formatter: (cellContent, row, index) => (
        dateConversion(cellContent, "DD-MMM-YYYY")
      )
    },
    {
      "dataField": "closedDate",
      "text": "Closed On",
      "sort": true,
      formatter: (cellContent, row, index) => (
        cellContent ? dateConversion(cellContent, "DD-MMM-YYYY") : ""
      )
    },
    {
      "dataField": "isOpen",
      "text": "Status",
      "sort": true,
      formatter: (cellContent, row, index) => {
        let data = cellContent ? "Open" : "Closed"
        const conInfo = { inputType: "status", status: cellContent, succesName: data, failureName: data }
        return formRender(null, null, conInfo, null);
      }
    },
  ]

  const transactionColumn = [
    {
      "dataField": "pettyCashTypeKey",
      "text": "Transaction Type",
      "sort": true
    },
    {
      "dataField": "amount",
      "text": "Amount",
      "sort": true
    },
    {
      "dataField": "remarks",
      "text": "Remarks",
      "sort": true,
    }
  ]

  if (pageItems.view || pageItems.update) {
    column.push({
      text: "Action",
      sort: false,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index, extraData) => {
        const editClass = row.isOpen ? 'text-primary' : 'text-warning';
        return (
          <div className="icon-container">
            {pageItems.update && <GiMoneyStack
              className={`${editClass} cursor-pointer`}
              size={20}
              onClick={() => onEdit(row, index, extraData)}
            />}
            {pageItems.view && <MdRemoveRedEye
              className={`text-success cursor-pointer`}
              size={20}
              onClick={() => onView(row, index, extraData)}
            />}
            {pageItems.update && row.isOpen && <MdMoneyOff
              className={`${editClass} cursor-pointer`}
              size={20}
              onClick={() => {
                swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, Close it!",
                  cancelButtonText: "No",
                }).then((result) => {
                  if (result.value) {
                    onCloseForm(row, index);
                  } else {
                    swal.fire({
                      title: "Cancelled!",
                      text: "Permission denied.",
                      icon: "error",
                      timer: 1500,
                    });
                  }
                });
              }}
            />}
            {/* {pageItems.delete && <MdDelete
                          className="text-danger cursor-pointer"
                          size={18}
                          onClick={() => {
                              swal.fire({
                                  title: "Are you sure?",
                                  text: "You won't be able to revert this!",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#3085d6",
                                  cancelButtonColor: "#d33",
                                  confirmButtonText: "Yes, delete it!",
                                  cancelButtonText: "No",
                              }).then((result) => {
                                  if (result.value) {
                                      onDeleteItem(row, index);
                                  } else {
                                      swal.fire({
                                          title: "Cancelled!",
                                          text: "Permission denied.",
                                          icon: "error",
                                          timer: 1500,
                                      });
                                  }
                              });
                          }}
                      />} */}
          </div>
        );
      }

    },)
  }

  const [state, setState] = useState({});
  const [createModule, setCreateModule] = useState(false);
  const [loaderModule, setLoaderModule] = useState(false);
  const [deleteModule, setDeleteModule] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [viewModule, setViewModule] = useState(false);
  const [viewTransaction, setViewTransaction] = useState(false);
  const [close, setClose] = useState(false);
  const [details, setDetails] = useState([]);


  const listFetching=async()=>{
    let pettyCashTypeList=await asyncGetPettyCashType()
    setState({
      ...state,
      pettyCashTypeList:pettyCashTypeList?.data || []
      
    })

  }
  useEffect(() => {
    setLoaderModule(true)
    const requestData = {
      isActive: "true",
    }
    dispatch(getPettyCash(requestData))
    // dispatch(getPettyCashType())
    const empData = {
      isActive: "true",
      empCode: global.UserInfo?.userCode || '',
      leaveStatusId: 1
    }
    dispatch(getEmployee(empData))
    listFetching()
  }, [navigation]);

  useEffect(() => {
    if (getPettyCashSuccess) {
      setItemList(getPettyCashList)
      /*  setState({
           ...state,
           syllabusList: getPettyCashList
       }) */
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_PETTY_CASH" })
    } else if (getPettyCashFailure) {
      setItemList([])
      showMessage("warning", eventErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_PETTY_CASH" })
    }
  }, [getPettyCashSuccess, getPettyCashFailure]);

  useEffect(() => {
    if (getPettyCashTypeSuccess) {
      setState({
        ...state,
        pettyCashTypeList: getPettyCashTypeList
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_PETTY_CASH_TYPE" })
    } else if (getPettyCashTypeFailure) {
      setState({
        ...state,
        pettyCashTypeList: []
      })
      showMessage("warning", eventErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_PETTY_CASH_TYPE" })
    }
  }, [getPettyCashTypeSuccess, getPettyCashTypeFailure]);

  useEffect(() => {
    if (getPettyCashDetailsSuccess) {
      let addAmount = 0;
      let lessAmount = 0;

      // Ensure the amounts are correctly parsed as numbers
      getPettyCashDetailsList.forEach(ele => {
        const amount = parseFloat(ele?.amount) || 0;
        console.log('====================================');
        console.log(ele.operator);
        console.log('====================================');
        if (ele.operator === "(+)") {
          addAmount += amount;
        } else {
          lessAmount += amount;
        }

      });
      console.log("addAmount---"+addAmount)
      console.log("lessAmount---"+lessAmount)

      let totalBalance = parseFloat(state?.openingBalance) || 0;
      console.log(state?.openingBalance)

      totalBalance -= lessAmount;

      totalBalance += addAmount;



      setState(prevState => ({
        ...prevState,
        pettyCashDetailsList: getPettyCashDetailsList,
        closingBalance: amountFormat(totalBalance),
      }));
      setLoaderModule(false);
      dispatch({ type: "RESET_GET_PETTY_CASH_DETAILS" });
    } else if (getPettyCashDetailsFailure) {
      setState(prevState => ({
        ...prevState,
        pettyCashDetailsList: []
      }));
      showMessage("warning", eventErrorMessage?.message);
      setLoaderModule(false);
      dispatch({ type: "RESET_GET_PETTY_CASH_DETAILS" });
    }
  }, [getPettyCashDetailsSuccess, getPettyCashDetailsList, getPettyCashDetailsFailure]);

  useEffect(() => {
    if (getEmployeeSuccess) {
      setState({
        ...state,
        employeeList: getEmployeeList,
        employeeId: getEmployeeList.length ? getEmployeeList[0]?.employeeId : 0,
      })
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_EMPLOYEE" })
    } else if (getEmployeeFailure) {
      setState({
        ...state,
        employeeList: []
      })
      showMessage("warning", employeeErrorMessage?.message)
      setLoaderModule(false)
      dispatch({ type: "RESET_GET_EMPLOYEE" })
    }
  }, [getEmployeeSuccess, getEmployeeFailure]);

  useEffect(() => {
    if (createPettyCashDetailsSuccess) {
      const temp_state = [...itemList, createPettyCashDetailsList[0]];
      // setItemList(temp_state)
      setLoaderModule(false)
      closeModule()
      Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      dispatch({ type: "RESET_CREATE_PETTY_CASH_DETAILS" })
    } else if (createPettyCashDetailsFailure) {
      setLoaderModule(false)
      showMessage("warning", eventErrorMessage?.message)
      dispatch({ type: "RESET_CREATE_PETTY_CASH_DETAILS" })
    }
  }, [createPettyCashDetailsSuccess, createPettyCashDetailsFailure]);

  useEffect(() => {
    if (createPettyCashSuccess) {
      const temp_state = [...itemList, createPettyCashData[0]];
      setItemList(temp_state)
      setLoaderModule(false)
      closeModule()
      Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      dispatch({ type: "RESET_CREATE_PETTY_CASH" })
    } else if (createPettyCashFailure) {
      setLoaderModule(false)
      showMessage("warning", eventErrorMessage?.message)
      dispatch({ type: "RESET_CREATE_PETTY_CASH" })
    }
  }, [createPettyCashSuccess, createPettyCashFailure]);


  useEffect(() => {
    if (updatePettyCashSuccess) {
      updateTable(updatePettyCashData[0])
      dispatch({ type: "RESET_UPDATE_PETTY_CASH" })
    } else if (updatePettyCashFailure) {
      setLoaderModule(false)
      showMessage("warning", eventErrorMessage)
      dispatch({ type: "RESET_UPDATE_PETTY_CASH" })
    }
  }, [updatePettyCashSuccess, updatePettyCashFailure]);

  const updateTable = (updatedItem) => {
    const temp_state = [...itemList];
    if (deleteModule) {
      let remainingList = _.remove(temp_state, function (n) {
        return n.pettyCashId != updatedItem.pettyCashId;
      });
      Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(remainingList)
    } else {
      temp_state[selectedIndex] = updatedItem;
      Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
      setItemList(temp_state)
    }
    setLoaderModule(false)
    closeModule()
  }

  const closeModule = () => {
    editData = false;
    setCreateModule(false)
    setViewModule(false)
    setDeleteModule(false)
    setClose(false)
  }

  const resetState = () => {
    setDetails([])
    setState({
      ...state,
      openingBalance: "",
      closingBalance: 0,
      createdBy: "",
      // createdOn: new Date(),

    })
  }

  const onCreateForm = () => {
    resetState()
    editData = false;
    setViewModule(true)
  }

  const onCloseForm = (data, index) => {
    if (!data.isOpen) {
      showMessage('warning', "This Account is Closed")
      return false;
    }
    let request = {
      "pettyCashId": data?.pettyCashId || ''
    }
    setState({
      ...state,
      pettyCashId: data.pettyCashId,
      openingBalance: data.openingBalance,
      closedDate: new Date(),
    })
    setSelectedIndex(index)
    dispatch(getPettyCashDetails(request))
    setClose(true)
  }

  const onEdit = (data, index) => {
    if (!data.isOpen) {
      showMessage('warning', "This Account is Closed")
      return false;
    }
    setDetails([])
    setState({
      ...state,
      pettyCashId: data.pettyCashId,
      openingBalance: data?.openingBalance || 0,
    })
    let request = {
      "pettyCashId": data?.pettyCashId || ''
    }
    // editData = true;
    dispatch(getPettyCashDetails(request))
    setSelectedIndex(index)
    setSelectedItem(data)
    setCreateModule(true)
  }

  const onView = (data, index) => {
    setDetails([])
    setState({
      ...state,
      pettyCashId: data.pettyCashId,
      openingBalance: amountFormat(data.openingBalance),
    })
    let request = {
      "pettyCashId": data?.pettyCashId || ''
    }
    // editData = true;
    dispatch(getPettyCashDetails(request))
    setSelectedIndex(index)
    // setSelectedItem(data)
    setViewTransaction(true)
  }

  const onDeleteItem = (data, index) => {
    editData = false;
    setSelectedIndex(index)
    setSelectedItem(data)
    setDeleteModule(true)
  }

  const validateOpeningFormRef = async () => {
    const validatefom = openingFormRef.current.validateForm();
  }

  const onAddDetails = () => {
    const defaultItem = {
      pettyCashTypeId: 1,
      pettyCashTypeKey: "topup",
      amount: "",
      remarks: "",
    };
    setDetails([...details, defaultItem])
  }

  const onSubmitForm = () => {
    if (details.length == 0 || validatesArray(details)) {
      showMessage('warning', 'Fill The Transaction Details')
      return false
    }

    const requestDate = {
      pettyCashId: state?.pettyCashId || 0,
      pettyCashDetails: details
    }

    if (editData) {
      setLoaderModule(true)
      // dispatch(updateSyllabus(requestDate, selectedItem.syllabusId))
    } else if (deleteModule) {
      const deleteRequest = {
        isActive: "false"
      }
      setLoaderModule(true)
      // dispatch(updateSyllabus(deleteRequest, selectedItem.syllabusId))
    } else {
      setLoaderModule(true)
      dispatch(createPettyCashDetails(requestDate))
    }
  }

  const onSubmit = () => {
    const requestDate = {
      openingBalance: state?.openingBalance || 0,
      createdBy: state?.employeeId || 0,
      createdOn: state?.createdOn || new Date()
    }
    if (requestDate.createdOn == null) {
      showMessage('warning', 'Select Opening Date')
      Alert({  description: 'Select Opening Date.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

      return false;
    } else if (requestDate.openingBalance == 0) {
      showMessage('warning', 'Enter Opening balance')
      Alert({  description: 'Enter Opening balance.', type: 'warning', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

      return false;
    }
    setLoaderModule(true)
    dispatch(createPettyCash(requestDate))
  }

  const onSubmitClose = () => {
    const requestDate = {
      isOpen: false,
      closedDate: dateConversion(new Date(state?.closedDate || new Date()), 'YYYY-MM-DD'),
      closingBalance: state?.closingBalance || 0
    }
    if (requestDate.closedDate == null) {
      showMessage('warning', 'Select Closing Date')
      return false;
    }
    setLoaderModule(true)
    dispatch(updatePettyCash(requestDate, state?.pettyCashId || ''))
  }

  const onRemoveItem = (index) => {
    let temp_state = [...details];
    temp_state.splice(index, 1);
    setDetails(temp_state)
  }

  const amountCheck = (event, name, index) => {
    let temp_state = [...details]
    temp_state[index][name] = event.target.value;
    if (temp_state[index]["pettyCashTypeKey"] !== "topup") {
      let pettyAmount = _.sumBy(temp_state, function (o) { return parseFloat(o?.amount || 0); });
      if (state.closingBalance < pettyAmount) {
        temp_state[index][name] = ''
        setDetails(temp_state)
        showMessage('warning', 'Your Balance is low Please Top Up')
        return false;
      }
    }
    setDetails(temp_state)
  }

  const onCashType = (event, name, index) => {
    let temp_state = [...details]
    temp_state[index][name] = event;
    temp_state[index]["amount"] = ''
    let tempArr = { ...state }
    let key = _.filter(tempArr?.pettyCashTypeList || [], function (o) { return parseInt(event) === parseInt(o.pettyCashTypeId) });
    temp_state[index]["pettyCashTypeKey"] = key?.[0]?.typeKey || '';
    setDetails(temp_state)

  }

  const modelTitle = editData ? "Edit " : "Create ";
  const modelButtonLbl = editData ? "" : "Save";

  const handleClick = (buttonName) => {
    if (buttonName == 'Add') {
      onCreateForm();
    }
  };

  const { sidebarOpen } = useSidebar();


  return (
    <div >
      <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>

        <View isOpen={createModule} size="fullscreen" savetitle={modelButtonLbl} onClose={setCreateModule} isLoading={loaderModule} onSubmit={onSubmitForm} title={`${modelTitle} Petty Cash`}>
          <div className="row">
            <div className="col-lg-5 d-flex mb-2">
              <div>
                <h6>Opening Balance : {state?.openingBalance || 0}.</h6><br></br>
                <h6>Closing Balance : {state?.closingBalance || 0}</h6>
              </div>
            </div>
            <div className="col-lg-6">
            </div>
            <div style={{ margin: "20px", width: "40%" }}>
              <div className="card-body">
                <FormWizardBody>
                  <div className="p-4 mh-50">
                    <div className="row">

                      <div /* className="col-lg-6" */>

                        <div><h5 className="title-border" style={{ /* marginTop: "-40px", */ borderBottom: '3px solid #003473' }}>EXISTING TRANSACTION</h5></div>
                        <div className="table-responsive mt-0" style={{ height: "400px" }}>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Type</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Remarks</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(state?.pettyCashDetailsList || []).map((item, index) => {
                                return (
                                  <tr key={index}>
                                    {/* <th scope="col">Type</th> */}
                                    <td scope="col">{item.pettyCashTypeKey}</td>
                                    <td scope="col">{item.amount}</td>
                                    <td scope="col">{item.remarks}</td>
                                  </tr>)
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </FormWizardBody>
              </div>
            </div>
            <div style={{ margin: "20px", width: "50%" }}>
              <div className="card-body">
                <FormWizardBody>
                  <div className="p-4 mh-50">
                    <div className="row">

                      <div /* className="col-lg-6" */>
                        <div className="px-2 mb-3" >
                          <div className="py-2 " >
                            <div className="d-flex justify-content-end">
                              <div style={{ position: "absolute", zIndex: "999", marginTop: "-30px" }}>
                                <Button className="float-end" style={{ backgroundColor: lightTheme.primaryColor, border: "none", fontSize: "12px", marginTop: "-20px" }} onClick={() => onAddDetails()} >
                                  {<MdOutlineAddCircleOutline size={24} />}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div><h5 className="title-border" style={{ marginTop: "-40px", borderBottom: '3px solid #003473' }}>NEW TRANSACTION</h5></div>
                        <div className="table-responsive mt-0" style={{ height: "400px" }}>
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Type</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Remarks</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(details ? details : []).map((item, index) => {
                                return (
                                  <TableLayout key={index} defaultState={state} setDefaultState={setDetails} index={index} layoutType="table" dataList={details} dynamicForm={feesForm} iconPress={{ "onRemove": onRemoveItem }} onChangeCallback={{ "amountCheck": amountCheck, "onCashType": onCashType }} onSubmit={onSubmitForm} />
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </FormWizardBody>
              </div>
            </div>

          </div>
        </View>
        <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Book">
          <h6 className="text-capitalize mb-1">Are you sure ?</h6>
        </View>
        <View centered={true} size="fullscreen" isOpen={viewTransaction} onClose={setViewTransaction} savetitle="Yes"  title="Transaction History">
          <CustomTable columns={transactionColumn} data={state?.pettyCashDetailsList || []} tableName={"Transaction History"} state={state} />
        </View>
        <View centered={true} size={'sm'} isOpen={viewModule} onClose={setViewModule} savetitle="Yes" onSubmit={onSubmit} title="Create Petty Cash">
          <FormLayout ref={openingFormRef} defaultState={state} setDefaultState={setState} dynamicForm={eventForm} noOfColumn={1} />
        </View>
        <View centered={true} size={'sm'} isOpen={close} onClose={setClose} savetitle="Yes" onSubmit={onSubmitClose} title="Close Petty Cash">
          <FormLayout ref={closingFormRef} defaultState={state} setDefaultState={setState} dynamicForm={closeForm} noOfColumn={1} />
        </View>
        <SimpleCard >
          <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Petty Cash"} columns={column} data={itemList} state={state} />
        </SimpleCard>

      </div>
    </div>
  )
}

export default PettyCash;