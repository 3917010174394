import React, { useState } from "react";
import ImageCropper from "./ImageCropper/ImageCropper";
import noProfile from "app/pages/images/no_profile.png";

const AppImageCropper = (props) => {
  const { state, setState, close } = props;
  const [croppedImg, setCroppedImg] = useState(null);
  const [againCrop, setAgainCrop] = useState(false);

  const handleFileSelect = (event) => {
    let file = event.target.files[0];
    if (!file) return;

    // Check if file size is greater than or equal to 1MB (1048576 bytes)
    if (file.size >= 1048576) {
      // Convert file size to KB and log it
      const fileSizeInKB = (file.size / 1024).toFixed(2);
      console.log(`File size is more than 1MB, converted size: ${fileSizeInKB} KB`);

      // Set the file size to KB in state (you can use this in your UI if needed)
      setState({
        ...state,
        profilePicFileType: file,
        fileSizeInKB: fileSizeInKB,  // Store the converted file size in KB
        uploadData: true,
        isNew: true,
      });
    } else {
      // File size is under 1MB, log the size in KB
      const fileSizeInKB = (file.size / 1024).toFixed(2);
      console.log(`File size: ${fileSizeInKB} KB`);

      // Set the state with the original file size
      setState({
        ...state,
        profilePicFileType: file,
        fileSizeInKB: fileSizeInKB,  // Store the file size in KB
        uploadData: true,
        isNew: true,
      });
    }

    // Create object URL for the image
    let src = URL.createObjectURL(file);
    setCroppedImg(src);
  };


  return (
    <div>
      {againCrop ? (
        <ImageCropper
          image={croppedImg}
          croppedImg={croppedImg}
          setCroppedImg={setCroppedImg}
          againCrop={againCrop}
          setAgainCrop={setAgainCrop}
          handleFileSelect={handleFileSelect}
          closeModule={close}
          state={state}
          setState={setState}
        />
      ) : (
        <ImageCropper
          image={croppedImg || noProfile}
          croppedImg={croppedImg}
          setCroppedImg={setCroppedImg}
          againCrop={againCrop}
          setAgainCrop={setAgainCrop}
          handleFileSelect={handleFileSelect}
          closeModule={close}
          state={state}
          setState={setState}
        />
      )}
    </div>
  );
};

export default AppImageCropper;
