import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { holidaysForm } from "./formData";
import { createHolidays, getHolidays, updateHolidays } from "app/api/HolidaysApi";
import { dateConversion, pagePermission, showMessage } from "app/utils/app-functions";
import Alert from "app/components/atoms/alert";
import moment from "moment";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false;

const Holidays = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getHolidaysSuccess = useSelector((state) => state.holidaysReducer.getHolidaysSuccess);
    const getHolidaysList = useSelector((state) => state.holidaysReducer.getHolidaysList);
    const getHolidaysFailure = useSelector((state) => state.holidaysReducer.getHolidaysFailure);

    const createHolidaysSuccess = useSelector((state) => state.holidaysReducer.createHolidaysSuccess);
    const createHolidaysData = useSelector((state) => state.holidaysReducer.createHolidaysData);
    const createHolidaysFailure = useSelector((state) => state.holidaysReducer.createHolidaysFailure);

    const updateHolidaysSuccess = useSelector((state) => state.holidaysReducer.updateHolidaysSuccess);
    const updateHolidaysData = useSelector((state) => state.holidaysReducer.updateHolidaysData);
    const updateHolidaysFailure = useSelector((state) => state.holidaysReducer.updateHolidaysFailure);

    const holidaysErrorMessage = useSelector((state) => state.holidaysReducer.errorMessage);

    const [pageItems, setpageItems] = useState({
    });

    useEffect(() => {
        let data = pagePermission(pageItems, "Settings", "Holidays")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "holidayName",
            "text": "Holidays Name",
            "sort": true
        },
        {
            "dataField": "holidayDate",
            "text": "Holidays Date",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return dateConversion(row.holidayDate, "DD-MMM-YYYY")
            }
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
        {
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index);

                        }}
                    ></FiTrash2>}
                </div>
            )
        },
    ]

    const [state, setState] = useState({});

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getHolidays(requestData))
    }, [navigation]);


    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])

    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }
    useEffect(() => {
        if (getHolidaysSuccess) {
            setItemList(getHolidaysList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_HOLIDAYS" })
        } else if (getHolidaysFailure) {
            setItemList([])
            showMessage("warning", holidaysErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_HOLIDAYS" })
        }
    }, [getHolidaysSuccess, getHolidaysFailure]);

    useEffect(() => {
        if (createHolidaysSuccess) {
            const temp_state = [...itemList, createHolidaysData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_HOLIDAYS" })
        } else if (createHolidaysFailure) {
            setLoaderModule(false)
            showMessage("warning", holidaysErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_HOLIDAYS" })
        }
    }, [createHolidaysSuccess, createHolidaysFailure]);


    useEffect(() => {
        if (updateHolidaysSuccess) {
            updateTable(updateHolidaysData[0])
            dispatch({ type: "RESET_UPDATE_HOLIDAYS" })
        } else if (updateHolidaysFailure) {
            setLoaderModule(false)
            showMessage("warning", holidaysErrorMessage)
            dispatch({ type: "RESET_UPDATE_HOLIDAYS" })
        }
    }, [updateHolidaysSuccess, updateHolidaysFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.holidayId != updatedItem.holidayId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            holidayName: "",
            holidayDate: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            holidayName: data?.holidayName || "",
            holidayDate: moment(data?.holidayDate, "YYYY-MM-DD"),
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            holidayName: holidayName,
            holidayDate: dateConversion(new Date(holidayDate), "YYYY-MM-DD")
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateHolidays(requestDate, selectedItem.holidayId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            dispatch(updateHolidays(deleteRequest, selectedItem.holidayId))
        } else {
            setLoaderModule(true)
            dispatch(createHolidays(requestDate))
        }
    }

    const {
        holidayName,
        holidayDate,
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const { sidebarOpen } = useSidebar();


    return (
        <div>
            <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>

                <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} isLoading={loaderModule} title={`${modelTitle} Holidays`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={holidaysForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>
                <SimpleCard >
                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Holidays"} columns={column} data={itemList} state={state} />
                </SimpleCard>
            </div>
        </div>
    )
};

export default Holidays;
