import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { designationForm } from "./formData";
import { createDesignation, getDesignation, updateDesignation } from "app/api/Designation";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import Alert from "app/components/atoms/alert";
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false;

const Designation = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getDesignationSuccess = useSelector((state) => state.designationReducer.getDesignationSuccess);
    const getDesignationList = useSelector((state) => state.designationReducer.getDesignationList);
    const getDesignationFailure = useSelector((state) => state.designationReducer.getDesignationFailure);

    const createDesignationSuccess = useSelector((state) => state.designationReducer.createDesignationSuccess);
    const createDesignationData = useSelector((state) => state.designationReducer.createDesignationData);
    const createDesignationFailure = useSelector((state) => state.designationReducer.createDesignationFailure);

    const updateDesignationSuccess = useSelector((state) => state.designationReducer.updateDesignationSuccess);
    const updateDesignationData = useSelector((state) => state.designationReducer.updateDesignationData);
    const updateDesignationFailure = useSelector((state) => state.designationReducer.updateDesignationFailure);

    const designationErrorMessage = useSelector((state) => state.designationReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Masters", "Designation")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "sNo",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => (
                index + 1
            )
        },
        {
            "dataField": "designationName",
            "text": "Designation Name",
            "sort": true
        },
        {
            "dataField": "description",
            "text": "Description ",
            "sort": false
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },

    ]

    if (pageItems.delete || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index);

                        }}
                    ></FiTrash2>}
                </div>
            )
        })
    }

    const [state, setState] = useState({
        designationName: "",
        description: ""
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getDesignation(requestData))
    }, [navigation]);

    useEffect(() => {
        if (getDesignationSuccess) {
            setItemList(getDesignationList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DESIGNATION" })
        } else if (getDesignationFailure) {
            setItemList([])
            showMessage("warning", designationErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DESIGNATION" })
        }
    }, [getDesignationSuccess, getDesignationFailure]);

    useEffect(() => {
        if (createDesignationSuccess) {
            const temp_state = [...itemList, createDesignationData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_DESIGNATION" })
        } else if (createDesignationFailure) {
            setLoaderModule(false)
            showMessage("warning", designationErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_DESIGNATION" })
        }
    }, [createDesignationSuccess, createDesignationFailure]);


    useEffect(() => {
        if (updateDesignationSuccess) {
            updateTable(updateDesignationData[0])
            dispatch({ type: "RESET_UPDATE_DESIGNATION" })
        } else if (updateDesignationFailure) {
            setLoaderModule(false)
            showMessage("warning", designationErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_DESIGNATION" })
        }
    }, [updateDesignationSuccess, updateDesignationFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.designationId != updatedItem.designationId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            designationName: "",
            description: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            designationName: data?.designationName || "",
            description: data?.description || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            designationName: designationName,
            description: description
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateDesignation(requestDate, selectedItem.designationId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            dispatch(updateDesignation(deleteRequest, selectedItem.designationId))
        } else {
            setLoaderModule(true)
            dispatch(createDesignation(requestDate))
        }
    }

    const handleCloseModal = () => {
        setCreateModule(false);
    };


    const {
        designationName,
        description,
    } = state;

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const { sidebarOpen } = useSidebar();

    return (
        <div>
            <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
                {/* <SmallLoader value={loaderModule} /> */}
                <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={handleCloseModal} onSubmit={validateForm} isLoading={loaderModule} title={`${modelTitle} Designation`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={designationForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>


                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} isLoading={loaderModule} title="Delete Batch">
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>
                </View>

                <SimpleCard >
                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Designation"} columns={column} data={itemList} initialPage={1} />
                </SimpleCard>

            </div>
        </div>
    )
};

export default Designation;
