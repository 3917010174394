import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { leaveTypeForm } from "./formData";
import { createLeaveType, getLeaveType, updateLeaveType } from "app/api/LeaveTypeApi";
import { FiEdit, FiTrash, FiTrash2 } from "react-icons/fi";
import Alert from "app/components/atoms/alert";
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false;

const LeaveType = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getLeaveTypeSuccess = useSelector((state) => state.leaveTypeReducer.getLeaveTypeSuccess);
    const getLeaveTypeList = useSelector((state) => state.leaveTypeReducer.getLeaveTypeList);
    const getLeaveTypeFailure = useSelector((state) => state.leaveTypeReducer.getLeaveTypeFailure);

    const createLeaveTypeSuccess = useSelector((state) => state.leaveTypeReducer.createLeaveTypeSuccess);
    const createLeaveTypeData = useSelector((state) => state.leaveTypeReducer.createLeaveTypeData);
    const createLeaveTypeFailure = useSelector((state) => state.leaveTypeReducer.createLeaveTypeFailure);

    const updateLeaveTypeSuccess = useSelector((state) => state.leaveTypeReducer.updateLeaveTypeSuccess);
    const updateLeaveTypeData = useSelector((state) => state.leaveTypeReducer.updateLeaveTypeData);
    const updateLeaveTypeFailure = useSelector((state) => state.leaveTypeReducer.updateLeaveTypeFailure);

    const leaveTypeErrorMessage = useSelector((state) => state.leaveTypeReducer.errorMessage);  

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Masters", "Leave Type")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "sNo",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => (
                index + 1
            )
        },
        {
            "dataField": "leaveTypeName",
            "text": "Leave Type Name",
            "sort": true
        },
        {
            "dataField": "description",
            "text": "Description",
            "sort": true
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        }
    ]

    if (pageItems.delete || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {

                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></FiTrash2>}
                </div>
            )
        })
    }

    const [state, setState] = useState({
        leaveTypeTitle: ""
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getLeaveType(requestData))
    }, [navigation]);

    useEffect(() => {
        if (getLeaveTypeSuccess) {
            setItemList(getLeaveTypeList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_TYPE" })
        } else if (getLeaveTypeFailure) {        
            setItemList([])
            showMessage("warning",leaveTypeErrorMessage?.message) 
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_LEAVE_TYPE" })
        }
    }, [getLeaveTypeSuccess, getLeaveTypeFailure]);

    useEffect(() => {
        if (createLeaveTypeSuccess) {
            const temp_state = [...itemList, createLeaveTypeData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            Alert({  description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            closeModule()
            dispatch({ type: "RESET_CREATE_LEAVE_TYPE" })
        } else if (createLeaveTypeFailure) {
            setLoaderModule(false)
            showMessage("warning",leaveTypeErrorMessage?.message) 
            dispatch({ type: "RESET_CREATE_LEAVE_TYPE" })
        }
    }, [createLeaveTypeSuccess, createLeaveTypeFailure]);

    useEffect(() => {
        if (updateLeaveTypeSuccess) {         
            updateTable(updateLeaveTypeData[0])
            dispatch({ type: "RESET_UPDATE_LEAVE_TYPE" })
        } else if (updateLeaveTypeFailure) {      
            setLoaderModule(false)    
            showMessage("warning",leaveTypeErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_LEAVE_TYPE" })
        }
    }, [updateLeaveTypeSuccess, updateLeaveTypeFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if(deleteModule) {      
            let remainingList = _.remove(temp_state, function (n) {
                return n.leaveTypeId != updatedItem.leaveTypeId;
            });          
            Alert({  description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)            
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({  description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)        
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            leaveTypeName: "",
            description: "",
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {     
        setState({
            ...state,
            leaveTypeName: data?.leaveTypeName || "",
            description: data?.description || "",
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {     
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            leaveTypeName: leaveTypeName,
            description : description
        }
        if (editData) {
            setLoaderModule(true)  
            dispatch(updateLeaveType(requestDate, selectedItem.leaveTypeId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: false
            }
            setLoaderModule(true)  
            dispatch(updateLeaveType(deleteRequest, selectedItem.leaveTypeId))
        } else {
            setLoaderModule(true)  
            dispatch(createLeaveType(requestDate))
        }

    }


    const handleCloseModal = () => {
        setCreateModule(false);
    };

    const {
        leaveTypeName,
        description,
    } = state;

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    
    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const { sidebarOpen } = useSidebar();

    return (
        <div>
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
            <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={handleCloseModal} onSubmit={validateForm} isLoading={loaderModule} title={`${modelTitle} LeaveType`}>
                <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={leaveTypeForm} noOfColumn={1} onSubmit={onSubmitForm} />
            </View>
            <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} isLoading={loaderModule} title="Delete Batch">
                <h6 className="text-capitalize mb-1">Are you sure ?</h6>
            </View>

            <SimpleCard >
                <CustomTable  buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"LeaveType"}columns={column} data={itemList} initialPage={1}  />
            </SimpleCard>

        </div>
        </div>
    )
};

export default LeaveType;
