import * as yup from "yup";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import _, { forEach } from "lodash";
import { createEmployee, getEmployee, updateEmployee, getEmployeeDetails, getEmployeePromotion, getEmployeeReporting, getEmployeeReportingInfo } from "app/api/EmployeeApi";
import { MdEdit, MdDelete, MdRemoveRedEye, MdOutlineEditLocation } from "react-icons/md";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { deleteEmployeeJobHistory } from "app/api/UtilsApi";
import swal from "sweetalert2";
import { getCity } from "app/api/CityApi";
import { getDepartment } from "app/api/Department";
import { getDesignation } from "app/api/Designation";
import { getEmployeeSalaryType } from "app/api/EmployeeSalaryTypeApi";
import { dateConversion, pagePermission, showMessage, todayDate, uploadFileType, validateEmail, validatePhoneNo } from "app/utils/app-functions";
import noProfileImage from "app/pages/images/no_profile.png";
import { getNationality } from "app/api/NationalityApi";
import { getBloodGroup } from "app/api/BloodGroupApi";
import { deleteEmployeeQualification, getQualification } from "app/api/QualificationApi";
import { getMotherTongue } from "app/api/MotherTongueApi";
import { getShift } from "app/api/EmployeeShiftApi";
import moment from "moment";
import { getEmployeeType } from "app/api/EmployeeTypeApi";
import { getProofType } from "app/api/ProofTypeApi";
import { getState } from "app/api/StateApi";
import { getCountry } from "app/api/CountryApi";
import { Button } from "react-bootstrap";
import { employeePath, profileImages } from "app/utils/constant";
import { deleteEmployeeProof } from "app/api/EmployeeProofApi";
import { getRole } from "app/api/RoleApi";
import { createPermission, getPermission, getRoles, updatePermission } from "app/api/PermissionApi";
import { getUnitFilter } from "app/api/UnitApi";
import { uploadDocument, uploadFiles } from "app/api/DocumentApi";
import { imagePath } from "app/utils/constant";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import Alert from "app/components/atoms/alert";
import formRender from "app/components/render/formcomponent";
import cup from "app/pages/images/cup.png"
import {
    employeeForm, reJoinForm, employeeDetails, employeeStep2, personalDetails, addressForm, addressInfoForm, permanentAddressInfoForm, proofUploaderForm,
    qualificationForm, salaryForm, jobHistoryForm, fileUploaderForm, relivingForm

} from "./formData";
import MultiSlider from "app/components/atoms/multi-slider/Multislider";
import TableLayout from "app/components/render/formlayout/tablelayout";
import { BsDatabaseAdd } from "react-icons/bs";
import AppImageCropper from "app/components/AppImageCropper";
import SimpleCard from "app/components/atoms/simple-card/SimpleCard";
import { asyncGetShiftInfo } from "app/api/EmployeeShiftApi";
import { asyncGetEmployeTypeInfo } from "app/api/EmployeeTypeApi";
import { asyncGetUnitFilterInfo } from "app/api/UnitApi";
import { asyncGetRoleInfo } from "app/api/RoleApi";
import { asyncGetNationalityInfo } from "app/api/NationalityApi";
import { asyncGetBloodGroupInfo } from "app/api/BloodGroupApi";
import { asyncGetDepartmentInfo } from "app/api/Department";
import { asyncGetDesignationInfo } from "app/api/Designation";
import { asyncGetSalaryTypeInfo } from "app/api/EmployeeSalaryTypeApi";
import { asyncGetStateInfo } from "app/api/StateApi";
import { asyncQualificationInfo } from "app/api/QualificationApi";
import { asyncGetProofInfo } from "app/api/ProofTypeApi";
import { asyncGetMotherTongueInfo } from "app/api/MotherTongueApi";
import { asyncGetCityInfo } from "app/api/CityApi";
import { asyncGetCountryInfo } from "app/api/CountryApi";
import ProfileDetails from "./DetailsView";
import { useSidebar } from "app/components/sidebar/sidebarContext";
import Loader from "app/components/atoms/loader/Loader"
import { updateEmployeeDocument } from "app/api/EmployeeDocumentApi";

import { asyncGetEmployeeDetailsInfo } from "app/api/EmployeeApi";
import Loading from "app/components/atoms/loading";
const { v4: uuidv4 } = require('uuid');



let editData = false;
let searchData = false;
let pageData = false;
let empToken = '';
let unitData = [];

const Employee = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();
    const jobHistoryModel = useRef();
    // const role = useRef();

    const getEmployeeSuccess = useSelector((state) => state.employeeReducer.getEmployeeSuccess);
    const getEmployeeList = useSelector((state) => state.employeeReducer.getEmployeeList);
    const getEmployeeFailure = useSelector((state) => state.employeeReducer.getEmployeeFailure);
    const employeeErrorMessage = useSelector((state) => state.employeeReducer.errorMessage);

    const getEmployeeReportingSuccess = useSelector((state) => state.employeeReducer.getEmployeeReportingSuccess);
    const getEmployeeReportingList = useSelector((state) => state.employeeReducer.getEmployeeReportingList);
    const getEmployeeReportingFailure = useSelector((state) => state.employeeReducer.getEmployeeReportingFailure);
    // const employeeErrorMessage = useSelector((state) => state.employeeReducer.errorMessage);

    const deleteEmployeeJobHistorySuccess = useSelector((state) => state.employeeReducer.deleteEmployeeJobHistorySuccess);
    const deleteEmployeeJobHistoryFailure = useSelector((state) => state.employeeReducer.deleteEmployeeJobHistoryFailure);
    const deleteEmployeeJobHistoryErrorMessage = useSelector((state) => state.employeeReducer.errorMessage);

    const deleteEmployeeQualificationSuccess = useSelector((state) => state.qualificationReducer.deleteEmployeeQualificationSuccess);
    const deleteEmployeeQualificationFailure = useSelector((state) => state.qualificationReducer.deleteEmployeeQualificationFailure);
    const deleteEmployeeQualificationErrorMessage = useSelector((state) => state.qualificationReducer.errorMessage);

    const getEmployeeTypeSuccess = useSelector((state) => state.employeeTypeReducer.getEmployeeTypeSuccess);
    const getEmployeeTypeList = useSelector((state) => state.employeeTypeReducer.getEmployeeTypeList);
    const getEmployeeTypeFailure = useSelector((state) => state.employeeTypeReducer.getEmployeeTypeFailure);
    const employeeTypeErrorMessage = useSelector((state) => state.employeeTypeReducer.errorMessage);

    const getNationalitySuccess = useSelector((state) => state.nationalityReducer.getNationalitySuccess);
    const getNationalityList = useSelector((state) => state.nationalityReducer.getNationalityList);
    const getNationalityFailure = useSelector((state) => state.nationalityReducer.getNationalityFailure);
    const nationalityErrorMessage = useSelector((state) => state.nationalityReducer.errorMessage);

    const getBloodGroupSuccess = useSelector((state) => state.bloodGroupReducer.getBloodGroupSuccess);
    const getBloodGroupList = useSelector((state) => state.bloodGroupReducer.getBloodGroupList);
    const getBloodGroupFailure = useSelector((state) => state.bloodGroupReducer.getBloodGroupFailure);
    const bloodGroupErrorMessage = useSelector((state) => state.bloodGroupReducer.errorMessage);

    const getQualificationSuccess = useSelector((state) => state.qualificationReducer.getQualificationSuccess);
    const getQualificationList = useSelector((state) => state.qualificationReducer.getQualificationList);
    const getQualificationFailure = useSelector((state) => state.qualificationReducer.getQualificationFailure);
    const qualificationErrorMessage = useSelector((state) => state.qualificationReducer.errorMessage);

    const getMotherTongueSuccess = useSelector((state) => state.motherTongueReducer.getMotherTongueSuccess);
    const getMotherTongueList = useSelector((state) => state.motherTongueReducer.getMotherTongueList);
    const getMotherTongueFailure = useSelector((state) => state.motherTongueReducer.getMotherTongueFailure);
    const motherTongueErrorMessage = useSelector((state) => state.motherTongueReducer.errorMessage);

    const getEmployeeDetailsSuccess = useSelector((state) => state.employeeDetailsReducer.getEmployeeDetailsSuccess);
    const getEmployeeDetailsList = useSelector((state) => state.employeeDetailsReducer.getEmployeeDetailsList);
    const getEmployeeDetailsFailure = useSelector((state) => state.employeeDetailsReducer.getEmployeeDetailsFailure);
    const employeeDetailsErrorMessage = useSelector((state) => state.employeeDetailsReducer.errorMessage);

    const getCitySuccess = useSelector((state) => state.cityReducer.getCitySuccess);
    const getCityList = useSelector((state) => state.cityReducer.getCityList);
    const getCityFailure = useSelector((state) => state.cityReducer.getCityFailure);
    const cityErrorMessage = useSelector((state) => state.cityReducer.errorMessage);

    /* const getRoleSuccess = useSelector((state) => state.roleReducer.getRoleSuccess);
    const getRoleList = useSelector((state) => state.roleReducer.getRoleList);
    const getRoleFailure = useSelector((state) => state.roleReducer.getRoleFailure);
    const roleErrorMessage = useSelector((state) => state.roleReducer.errorMessage); */

    const getProofTypeSuccess = useSelector((state) => state.proofTypeReducer.getProofTypeSuccess);
    const getProofTypeList = useSelector((state) => state.proofTypeReducer.getProofTypeList);
    const getProofTypeFailure = useSelector((state) => state.proofTypeReducer.getProofTypeFailure);
    const proofTypeErrorMessage = useSelector((state) => state.proofTypeReducer.errorMessage);

    const getDepartmentSuccess = useSelector((state) => state.departmentReducer.getDepartmentSuccess);
    const getDepartmentList = useSelector((state) => state.departmentReducer.getDepartmentList);
    const getDepartmentFailure = useSelector((state) => state.departmentReducer.getDepartmentFailure);
    const departmentErrorMessage = useSelector((state) => state.departmentReducer.errorMessage);

    const getCountrySuccess = useSelector((state) => state.countryReducer.getCountrySuccess);
    const getCountryList = useSelector((state) => state.countryReducer.getCountryList);
    const getCountryFailure = useSelector((state) => state.countryReducer.getCountryFailure);
    const countryErrorMessage = useSelector((state) => state.countryReducer.errorMessage);

    const getStateSuccess = useSelector((state) => state.stateReducer.getStateSuccess);
    const getStateList = useSelector((state) => state.stateReducer.getStateList);
    const getStateFailure = useSelector((state) => state.stateReducer.getStateFailure);
    const stateErrorMessage = useSelector((state) => state.stateReducer.errorMessage);

    const getShiftSuccess = useSelector((state) => state.shiftReducer.getShiftSuccess);
    const getShiftList = useSelector((state) => state.shiftReducer.getShiftList);
    const getShiftFailure = useSelector((state) => state.shiftReducer.getShiftFailure);
    const shiftErrorMessage = useSelector((state) => state.shiftReducer.errorMessage);

    const getEmployeePromotionSuccess = useSelector((state) => state.employeeReducer.getEmployeePromotionSuccess);
    const getEmployeePromotionList = useSelector((state) => state.employeeReducer.getEmployeePromotionList);
    const getEmployeePromotionFailure = useSelector((state) => state.employeeReducer.getEmployeePromotionFailure);
    const employeePromotionErrorMessage = useSelector((state) => state.employeeReducer.errorMessage);

    const getDesignationSuccess = useSelector((state) => state.designationReducer.getDesignationSuccess);
    const getDesignationList = useSelector((state) => state.designationReducer.getDesignationList);
    const getDesignationFailure = useSelector((state) => state.designationReducer.getDesignationFailure);
    const designationErrorMessage = useSelector((state) => state.designationReducer.errorMessage);

    const getEmployeeSalaryTypeSuccess = useSelector((state) => state.employeeSalaryTypeReducer.getEmployeeSalaryTypeSuccess);
    const getEmployeeSalaryTypeList = useSelector((state) => state.employeeSalaryTypeReducer.getEmployeeSalaryTypeList);
    const getEmployeeSalaryTypeFailure = useSelector((state) => state.employeeSalaryTypeReducer.getEmployeeSalaryTypeFailure);
    const employeeSalaryTypeErrorMessage = useSelector((state) => state.employeeSalaryTypeReducer.errorMessage);

    const createEmployeeSuccess = useSelector((state) => state.employeeReducer.createEmployeeSuccess);
    const createEmployeeData = useSelector((state) => state.employeeReducer.createEmployeeData);
    const createEmployeeFailure = useSelector((state) => state.employeeReducer.createEmployeeFailure);

    const updateEmployeeSuccess = useSelector((state) => state.employeeReducer.updateEmployeeSuccess);
    const updateEmployeeData = useSelector((state) => state.employeeReducer.updateEmployeeData);
    const updateEmployeeFailure = useSelector((state) => state.employeeReducer.updateEmployeeFailure);

    const getPermissionSuccess = useSelector((state) => state.permissionReducer.getPermissionSuccess);
    const getPermissionList = useSelector((state) => state.permissionReducer.getPermissionList);
    const getPermissionFailure = useSelector((state) => state.permissionReducer.getPermissionFailure);

    const createPermissionSuccess = useSelector((state) => state.permissionReducer.createPermissionSuccess);
    const createPermissionData = useSelector((state) => state.permissionReducer.createPermissionData);
    const createPermissionFailure = useSelector((state) => state.permissionReducer.createPermissionFailure);

    const getUnitFilterSuccess = useSelector((state) => state.unitReducer.getUnitFilterSuccess);
    const getUnitFilterList = useSelector((state) => state.unitReducer.getUnitFilterList);
    const getUnitFilterFailure = useSelector((state) => state.unitReducer.getUnitFilterFailure);

    const updatePermissionSuccess = useSelector((state) => state.permissionReducer.updatePermissionSuccess);
    const updatePermissionData = useSelector((state) => state.permissionReducer.updatePermissionData);
    const updatePermissionFailure = useSelector((state) => state.permissionReducer.updatePermissionFailure);

    const getRolesSuccess = useSelector((state) => state.permissionReducer.getRolesSuccess);
    const getRolesList = useSelector((state) => state.permissionReducer.getRolesList);
    const getRolesFailure = useSelector((state) => state.permissionReducer.getRolesFailure);

    const createFileSuccess = useSelector((state) => state.documentReducer.createFileSuccess);
    const createFileData = useSelector((state) => state.documentReducer.createFileData);
    const createFileFailure = useSelector((state) => state.documentReducer.createFileFailure);

    const permissionErrorMessage = useSelector((state) => state.permissionReducer.errorMessage);
    const unitErrorMessage = useSelector((state) => state.unitReducer.errorMessage);
    const documentErrorMessage = useSelector((state) => state.documentReducer.errorMessage);


    // const errorMessage = useSelector((state) => state.employeeReducer.errorMessage);
    const [pageItems, setpageItems] = useState({
        "viewOption": false,
        "addOption": true,
        "updateOption": false,
        "deleteOption": false
    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Employee", "Employee")
        setpageItems(data)
    }, [navigation]);

    const column = [
        {
            "dataField": "employeeCode",
            "text": "Employee Code",
            "sort": true
        },
        {
            "dataField": "employeeName",
            "text": "Employee Name",
            "sort": true,
        },
        {
            "dataField": "email",
            "text": "Email",
            "sort": true
        },
        {
            "dataField": "phoneNo",
            "text": "Phone No",
            "sort": true
        },
        {
            "dataField": "dateOfJoining",
            "text": "Date Of Join",
            "sort": true,
            "formatter": (cellContent, row, index) => {
                return dateConversion(cellContent, "DD-MMM-YYYY");
            }
        },
        {
            "dataField": "dateOfRelieving",
            "text": "Date Of Relieving",
            "sort": true,
            "formatter": (cellContent, row, index) => {
                return dateConversion(cellContent, "DD-MMM-YYYY");
            }
        },

    ]

    if (pageItems.delete || pageItems.update || pageItems.view) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.view && <MdRemoveRedEye
                        className="text-success cursor-pointer"
                        size={18}
                        onClick={() => onview(row, index)}
                    ></MdRemoveRedEye>}
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEdit(row, index)}
                    ></FiEdit>}
                    {/*  <MdOutlineEditLocation
                        className="text-success cursor-pointer"
                        size={18}
                        onClick={() => onUpdate(row, 'pageEdit')}
                    ></MdOutlineEditLocation> */}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    text: "You Want Delete The Employee And You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {

                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></FiTrash2>}
                </div>
            )
        },)
    }


    const [state, setState] = useState({
        cityList: [],
        uploadData: false,
        departmentList: [],
        designationList: [],
        employeeSalaryTypeList: [],
        employeePromotionList: [],
        fileTypeList: [
            {
                "fileTypeId": 1,
                "fileTypeName": "Experience Letter"
            },
            {
                "fileTypeId": 2,
                "fileTypeName": "Offer Letter"
            },
            {
                "fileTypeId": 3,
                "fileTypeName": "Reliving Letter"
            },
            {
                "fileTypeId": 4,
                "fileTypeName": "Certificate"
            },
        ],
        employeeDetailsList: [],
        initialEmployeeDetails: employeeDetails,
        nationalityList: [],
        religionList: [],
        casteList: [],
        subCasteList: [],
        motherTongueList: [],
        bloodGroupList: [],
        qualificationList: [],
        shiftList: [],
        proofTypeList: [],
        salaryTypeList: [],
        countryList: [],
        stateList: [],
        fileNames: [],
        fileUploads: [],
        setFileUploadInfo: [],
        profilePic: noProfileImage,
        tree: true,
        martialStatusList: [{ "martialStatusId": 1, "martialStatusName": "Single" }, { "martialStatusId": 2, "martialStatusName": "Married" }],
        genderList: [{ "genderId": 1, "genderName": "Male" }, { "genderId": 2, "genderName": "Female" }, { "genderId": 3, "genderName": "Others" },]
    });

    const [createModule, setCreateModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [deleteProof, setDeleteProof] = useState(false);
    const [deleteJobHistory, setdeleteJobHistory] = useState(false);
    const [deleteQualicationHistory, setdeleteQualicationHistory] = useState(false);
    const [deleteFileUpload, setdeleteFileUpload] = useState(false);
    const [permissionModule, setPermissionModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [loading, setLoading] = useState(false);

    const [viewModule, setViewModule] = useState(false);
    const [viewProof, setviewProof] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [isStep7, setIsStep7] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [rejoinModule, setRejoinModule] = useState(false);
    const [uploadState, setUploadState] = useState([]);
    const [uploadModule, setUploadModule] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [loginChecked, setLoginChecked] = useState(false);
    const [detailsView, setdetailsView] = useState(false);
    const [employeDetails, setEmployeDetails] = useState([]);
    const [employeData, setemployeData] = useState([]);

    const [fileState, fileSetState] = useState({
        file: '',
        type: '',
        isImage: false,
    });

    const [employeeProofState, setEmployeeProofState] = useState([
    ]);

    let userInfo = window.localStorage.getItem("userInfo");
    let parsedData = JSON.parse(userInfo);

    useEffect(() => {
        const parentState = state;
        let qualification = [];
        let fileUpload = [];
        let jobs = [];
        let employeeProof = [];
        if (parentState?.setEmployeeProof?.length > 0) {
            (parentState.setEmployeeProof).map((item, index) => {
                employeeProof.push({ ...item })
            })
        }
        if (parentState?.setQualificationInfo?.length > 0) {
            (parentState.setQualificationInfo).map((item, index) => {
                qualification.push({ ...item, startDate: dateConversion(item.startDate, "YYYY-MM-DD"), endDate: dateConversion(item.endDate, "YYYY-MM-DD"), })
            })
        }
        if (parentState?.setFileUploadInfo?.length > 0) {
            (parentState.setFileUploadInfo).map((item, index) => {
                fileUpload.push({ item })
            })
        }
        if (parentState?.setJobInfo?.length > 0) {
            (parentState.setJobInfo).map((item, index) => {
                jobs.push({ ...item, startDate: dateConversion(item.startDate, "YYYY-MM-DD"), endDate: dateConversion(item.endDate, "YYYY-MM-DD"), })
            })
        }
        setQualificationState(qualification)
        setJobHistoryState(jobs)
        setUploadState(fileUpload)
        setEmployeeProofState(employeeProof)
    }, []);

    const [jobHistoryState, setJobHistoryState] = useState([
    ]);
    const addJobHistory = () => {
        const jobHistoryItem = {
        }
        setJobHistoryState([...jobHistoryState, jobHistoryItem])
    }
    const [qualificationState, setQualificationState] = useState([
    ]);

    const listFetching = async () => {
        const requestData = {
            isActive: "true",
        }
        let shiftList = await asyncGetShiftInfo(requestData)
        let employeeTypeList = await asyncGetEmployeTypeInfo(requestData)
        let unitFilterList = await asyncGetUnitFilterInfo(requestData)
        let roleList = await asyncGetRoleInfo(requestData)
        let nationalityList = await asyncGetNationalityInfo(requestData)
        let bloodGroupList = await asyncGetBloodGroupInfo(requestData)
        let departmentList = await asyncGetDepartmentInfo(requestData)
        let designationList = await asyncGetDesignationInfo(requestData)
        let employeeSalaryTypeList = await asyncGetSalaryTypeInfo(requestData)
        let stateList = await asyncGetStateInfo(requestData)
        let qualificationList = await asyncQualificationInfo(requestData)
        let proofTypeList = await asyncGetProofInfo(requestData)
        let motherTongueList = await asyncGetMotherTongueInfo(requestData)
        let cityList = await asyncGetCityInfo(requestData)
        let countryList = await asyncGetCountryInfo(requestData)
        let reportList = await getEmployeeReportingInfo(requestData)
        unitData = unitFilterList?.data || []
        let data = await onRoles(roleList.data)
        console.log(data);

        setState({
            ...state,
            employeeReportingList: reportList?.data || [],
            shiftList: shiftList?.data || [],
            employeeTypeList: employeeTypeList?.data || [],
            unitFilterList: unitFilterList?.data || [],
            roleList: data,
            nationalityList: nationalityList?.data || [],
            bloodGroupList: bloodGroupList?.data || [],
            departmentList: departmentList?.data || [],
            designationList: designationList?.data || [],
            employeeSalaryTypeList: employeeSalaryTypeList?.data || [],
            stateList: stateList?.data || [],
            qualificationList: qualificationList?.data || [],
            proofTypeList: proofTypeList?.data || [],
            motherTongueList: motherTongueList?.data || [],
            cityList: cityList?.data || [],
            countryList: countryList?.data || [],

        })

    }



    useEffect(() => {
        setLoaderModule(true)
        const req = {
            isActive: "true"
        }
        // dispatch(getShift(req))
        // dispatch(getEmployeeType(req))
        // dispatch(getProofType(req))
        // dispatch(getNationality(req))
        // dispatch(getBloodGroup(req))
        // dispatch(getQualification(req))
        // dispatch(getMotherTongue(req))
        // dispatch(getCity(req))
        // dispatch(getRole(req))
        // dispatch(getState())
        // dispatch(getCountry())
        // dispatch(getDepartment(req))
        // dispatch(getDesignation(req))
        dispatch(getEmployee(req))
        // dispatch(getEmployeeReporting(req))
        // dispatch(getEmployeeSalaryType(req))
        // dispatch(getUnitFilter(req))
        listFetching()
        // dispatch(getRoles({ "status": true }))
    }, [navigation]);

    useEffect(() => {
        if (getEmployeeSuccess) {
            setItemList(getEmployeeList)
            setState({
                ...state,
                employeeList: getEmployeeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        } else if (getEmployeeFailure) {
            showMessage("warning", employeeErrorMessage?.message)
            setLoaderModule(false)
            setItemList([])
            setState({
                ...state,
                employeeList: []
            })
            dispatch({ type: "RESET_GET_EMPLOYEE" })
        }
    }, [getEmployeeSuccess, getEmployeeFailure]);

    useEffect(() => {
        if (getEmployeeReportingSuccess) {
            setState({
                ...state,
                employeeReportingList: getEmployeeReportingList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_REPORTING" })
        } else if (getEmployeeReportingFailure) {
            showMessage("warning", employeeErrorMessage?.message)
            setLoaderModule(false)
            setState({
                ...state,
                employeeReportingList: []
            })
            dispatch({ type: "RESET_GET_EMPLOYEE_REPORTING" })
        }
    }, [getEmployeeReportingSuccess, getEmployeeReportingFailure]);

    useEffect(() => {
        if (getEmployeePromotionSuccess) {
            setState({
                ...state,
                employeePromotionList: getEmployeePromotionList,
            })
            setSelectedItem(getEmployeePromotionList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_PROMOTION" })
        } else if (getEmployeePromotionFailure) {
            setState({
                ...state,
                employeePromotionList: []
            })
            showMessage("warning", employeePromotionErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_PROMOTION" })
        }
    }, [getEmployeePromotionSuccess, getEmployeePromotionFailure]);

    /*     useEffect(() => {
            const uploadList = uploadFileType()
            setState({
                ...state,
                fileTypeList: uploadList
            })
        }, []); */

    useEffect(() => {
        if (getRolesSuccess) {
            onRoles(getRolesList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ROLES" })
        } else if (getRolesFailure) {
            setItemList([])
            showMessage("warning", unitErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_ROLES" })
        }
    }, [getRolesSuccess, getRolesFailure]);

    useEffect(() => {
        if (getUnitFilterSuccess) {
            setState({
                ...state,
                unitFilterList: getUnitFilterList,

            })
            unitData = getUnitFilterList
            /*  if (unitData.length == 1) {
                 setState({
                     ...state,
                     unit: unitData
                 })
             } */
            /* else{
                setState({
                    ...state,
                    unit: getUnitFilterList
                })
            } */

            setLoaderModule(false)
            dispatch({ type: "RESET_GET_FILTER_UNIT" })
        } else if (getUnitFilterFailure) {
            setState({
                ...state,
                unitFilterList: [],

            })
            showMessage("warning", unitErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_FILTER_UNIT" })
        }
    }, [getUnitFilterSuccess, getUnitFilterFailure]);

    useEffect(() => {
        if (getStateSuccess) {
            setState({
                ...state,
                stateList: getStateList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STATE" })
        } else if (getStateFailure) {
            setState({
                ...state,
                stateList: []
            })
            showMessage("warning", stateErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_STATE" })
        }
    }, [getStateSuccess, getStateFailure]);

    useEffect(() => {
        if (getCountrySuccess) {
            setState({
                ...state,
                countryList: getCountryList,
                countryId: getCountryList.map((item) => item.countryId),
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_COUNTRY" })
        } else if (getCountryFailure) {
            setState({
                ...state,
                countryList: []
            })
            showMessage("warning", countryErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_COUNTRY" })
        }
    }, [getCountrySuccess, getCountryFailure]);

    useEffect(() => {
        if (getEmployeeTypeSuccess) {
            setState({
                ...state,
                employeeTypeList: getEmployeeTypeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_TYPE" })
        } else if (getEmployeeTypeFailure) {
            setState({
                ...state,
                employeeTypeList: []
            })
            showMessage("warning", employeeTypeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_TYPE" })
        }
    }, [getEmployeeTypeSuccess, getEmployeeTypeFailure]);

    useEffect(() => {
        if (getCitySuccess) {
            setState({
                ...state,
                cityList: getCityList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CITY" })
        } else if (getCityFailure) {
            setState({
                ...state,
                cityList: []
            })
            showMessage("warning", cityErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_CITY" })
        }
    }, [getCitySuccess, getCityFailure]);

    /*  useEffect(() => {
         if (getRoleSuccess) {
             setState({
                 ...state,
                 roleList: getRoleList
             })
             setLoaderModule(false)
             dispatch({ type: "RESET_GET_ROLE" })
         } else if (getRoleFailure) {
             setState({
                 ...state,
                 roleList: []
             })
             showMessage("warning", roleErrorMessage?.message)
             setLoaderModule(false)
             dispatch({ type: "RESET_GET_ROLE" })
         }
     }, [getRoleSuccess, getRoleFailure]);
  */
    useEffect(() => {
        if (getNationalitySuccess) {
            setState({
                ...state,
                nationalityList: getNationalityList,
                nationalityId: getNationalityList.map((item) => item.nationalityId),

            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_NATIONALITY" })
        } else if (getNationalityFailure) {
            setState({
                ...state,
                nationalityList: []
            })
            showMessage("warning", nationalityErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_NATIONALITY" })
        }
    }, [getNationalitySuccess, getNationalityFailure]);

    useEffect(() => {
        if (getBloodGroupSuccess) {
            setState({
                ...state,
                bloodGroupList: getBloodGroupList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BLOOD_GROUP" })
        } else if (getBloodGroupFailure) {
            setState({
                ...state,
                bloodGroupList: []
            })
            showMessage("warning", bloodGroupErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BLOOD_GROUP" })
        }
    }, [getBloodGroupSuccess, getBloodGroupFailure]);

    useEffect(() => {
        if (getProofTypeSuccess) {
            setState({
                ...state,
                proofTypeList: getProofTypeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_PROOF_TYPE" })
        } else if (getProofTypeFailure) {
            setState({
                ...state,
                proofTypeList: []
            })
            showMessage("warning", proofTypeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_PROOF_TYPE" })
        }
    }, [getProofTypeSuccess, getProofTypeFailure]);

    useEffect(() => {
        if (getQualificationSuccess) {
            setState({
                ...state,
                qualificationList: getQualificationList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_QUALIFICATION" })
        } else if (getQualificationFailure) {
            setState({
                ...state,
                qualificationList: []
            })
            showMessage("warning", qualificationErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_QUALIFICATION" })
        }
    }, [getQualificationSuccess, getQualificationFailure]);

    useEffect(() => {
        if (getMotherTongueSuccess) {
            setState({
                ...state,
                motherTongueList: getMotherTongueList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MOTHER_TONGUE" })
        } else if (getMotherTongueFailure) {
            setState({
                ...state,
                motherTongueList: []
            })
            showMessage("warning", motherTongueErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_MOTHER_TONGUE" })
        }
    }, [getMotherTongueSuccess, getMotherTongueFailure]);

    useEffect(() => {
        if (getEmployeeDetailsSuccess) {
            setState({
                ...state,
                employeeDetailsList: getEmployeeDetailsList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_DETAILS" })
            /*  if (editData === true && pageData === false) {
                 onEditForm(getEmployeeDetailsList);
             } else if (pageData === false && editData === false) {
                 setViewModule(true);
             } else if (editData === false && pageData === true) {
                 onPageEdit(getEmployeeDetailsList);
             } */
            editData === true ? onEditForm(getEmployeeDetailsList) : setViewModule(true);
        } else if (getEmployeeDetailsFailure) {
            setState({
                ...state,
                employeeDetailsList: []
            })
            setLoaderModule(false)


            dispatch({ type: "RESET_GET_EMPLOYEE_DETAILS" })
        }
    }, [getEmployeeDetailsSuccess, getEmployeeDetailsFailure]);

    useEffect(() => {
        if (getEmployeeSalaryTypeSuccess) {
            setState({
                ...state,
                employeeSalaryTypeList: getEmployeeSalaryTypeList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_SALARY_TYPE" })
        } else if (getEmployeeSalaryTypeFailure) {
            setState({
                ...state,
                employeeSalaryTypeList: []
            })
            showMessage("warning", employeeSalaryTypeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_EMPLOYEE_SALARY_TYPE" })
        }
    }, [getEmployeeSalaryTypeSuccess, getEmployeeSalaryTypeFailure]);

    useEffect(() => {
        if (getDepartmentSuccess) {
            setState({
                ...state,
                departmentList: getDepartmentList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DEPARTMENT" })
        } else if (getDepartmentFailure) {
            setState({
                ...state,
                departmentList: []
            })
            showMessage("warning", departmentErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DEPARTMENT" })
        }
    }, [getDepartmentSuccess, getDepartmentFailure]);

    useEffect(() => {
        if (getShiftSuccess) {
            setState({
                ...state,
                shiftList: getShiftList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SHIFT" })
        } else if (getShiftFailure) {
            setState({
                ...state,
                shiftList: []
            })
            showMessage("warning", shiftErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_SHIFT" })
        }
    }, [getShiftSuccess, getShiftFailure]);

    useEffect(() => {
        if (getDesignationSuccess) {
            setState({
                ...state,
                designationList: getDesignationList
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DESIGNATION" })
        } else if (getDesignationFailure) {
            setState({
                ...state,
                designationList: []
            })
            showMessage("warning", designationErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_DESIGNATION" })
        }
    }, [getDesignationSuccess, getDesignationFailure]);

    useEffect(() => {
        if (createEmployeeSuccess) {
            const temp_state = [...itemList, createEmployeeData[0]];
            setLoaderModule(false)
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
            closeModule()
            dispatch({ type: "RESET_CREATE_EMPLOYEE" })
        } else if (createEmployeeFailure) {
            setLoaderModule(false)
            showMessage("warning", employeeErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_EMPLOYEE" })
        }
    }, [createEmployeeSuccess, createEmployeeFailure]);

    useEffect(() => {
        if (deleteEmployeeJobHistorySuccess) {
            showMessage("success", "Deleted Successfully")
            dispatch({ type: "RESET_DELETE_EMPLOYEE_JOB_HISTORY" })
        } else if (deleteEmployeeJobHistoryFailure) {
            showMessage("warning", deleteEmployeeJobHistoryErrorMessage?.message)
            dispatch({ type: "RESET_DELETE_EMPLOYEE_JOB_HISTORY" })
        }
    }, [deleteEmployeeJobHistorySuccess, deleteEmployeeJobHistoryFailure]);

    useEffect(() => {
        if (deleteEmployeeQualificationSuccess) {
            showMessage("success", "Deleted Successfully")
            dispatch({ type: "RESET_DELETE_EMPLOYEE_QUALIFICATION" })
        } else if (deleteEmployeeQualificationFailure) {
            showMessage("warning", deleteEmployeeQualificationErrorMessage?.message)
            dispatch({ type: "RESET_DELETE_EMPLOYEE_QUALIFICATION" })
        }
    }, [deleteEmployeeQualificationSuccess, deleteEmployeeQualificationFailure]);

    useEffect(() => {
        if (updateEmployeeSuccess) {
            updateTable(updateEmployeeData[0])
            dispatch(getEmployee({ isActive: 'true' }))

            setLoaderModule(false)
            dispatch({ type: "RESET_UPDATE_EMPLOYEE" })
        } else if (updateEmployeeFailure) {
            closeModule()

            setLoaderModule(false)
            dispatch({ type: "RESET_UPDATE_EMPLOYEE" })
        }
    }, [updateEmployeeSuccess, updateEmployeeFailure]);

    useEffect(() => {
        if (getPermissionSuccess) {
            setState({
                ...state,
                permissionList: [...getPermissionList]
            })
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_PERMISSION" })
        } else if (getPermissionFailure) {
            setItemList([])
            showMessage("warning", permissionErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_PERMISSION" })
        }
    }, [getPermissionSuccess, getPermissionFailure]);

    useEffect(() => {
        if (createPermissionSuccess) {
            const temp_state = [...itemList, createPermissionData[0]];
            closeModule()
            setLoaderModule(false)
            // pageData = false
            showMessage("success", "Updated Successfully")
            setPermissionModule(false)
            dispatch({ type: "RESET_CREATE_PERMISSION" })
        } else if (createPermissionFailure) {
            setLoaderModule(false)
            showMessage("warning", permissionErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_PERMISSION" })
        }
    }, [createPermissionSuccess, createPermissionFailure]);

    useEffect(() => {
        if (updatePermissionSuccess) {
            updateTable(updatePermissionData[0])
            dispatch({ type: "RESET_UPDATE_PERMISSION" })
        } else if (updatePermissionFailure) {
            setLoaderModule(false)
            showMessage("warning", permissionErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_PERMISSION" })
        }
    }, [updatePermissionSuccess, updatePermissionFailure]);

    useEffect(() => {
        if (createFileSuccess) {
            // const temp_state = [...itemList, createFileData[0]];
            // closeModule()
            // setLoaderModule(false)
            dispatch({ type: "RESET_CREATE_FILE" })
        } else if (createFileFailure) {
            setLoaderModule(false)
            showMessage("warning", documentErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_FILE" })
        }
    }, [createFileSuccess, createFileFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.employeeId != updatedItem.employeeId;
            });
            showMessage("success", "Deleted Successfully")
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            setLoaderModule(false)
            setItemList(remainingList)
        } else {
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            temp_state[selectedIndex] = updatedItem;
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        // pageData = false
        setCreateModule(false)
        setViewModule(false)
        setDeleteModule(false)
        // pageData = false
    }

    const onview = (data, index) => {
        setdetailsView(true)
        setEmployeDetails(data)

        editData = false;
        const request = {
            "employeeId": data.employeeId
        }
        dispatch(getEmployeeDetails(request))
        dispatch(getEmployeePromotion(request))




    }


    const onViewForm = (data, index) => {
        editData = false;
        // pageData = false;
        const request = {
            "employeeId": data.employeeId
        }
        dispatch(getEmployeeDetails(request))
        dispatch(getEmployeePromotion(request))

    }

    const onPageEdit = (data) => {
        if (data.length) {
            setState({
                ...state,
                pageEditList: data[0]?.pagePermission[0]?.pagePermission || [],
                check: data[0]?.pagePermission || []
            })
        }

    }

    const resetState = () => {
        let data = []

        if (unitData.length > 1) {
            data = [];
        } else if (unitData.length == 1) {

            data = unitData;
        }

        setState({
            ...state,
            //personal
            firstName: "",

            reportTo: "",
            tree: true,
            initialEmployeeDetails: JSON.parse(JSON.stringify(employeeDetails)),
            loginInfo: false,
            uploadData: false,
            lastName: "",
            email: "",
            searchEmployee: "",
            phoneNo: "+91 ",
            // unit: [],
            dateOfJoin: moment(new Date(), 'YYYY-MM-DD'),
            dateOfRelieving: moment(new Date(moment().add(2, 'years'))),
            currentStatus: "",
            shiftId: "",
            profilePic: "",
            employeeTypeId: "",
            userName: "",
            password: "",
            confirmPassword: "",
            //details
            dateOfBirth: "",
            fatherName: "",
            motherName: "",
            emergencyRelation: "",
            emergencyContactNumber: "+91 ",
            bloodGroupId: "",
            motherTongueId: "",
            genderId: "",
            age: "",
            // nationalityId: "",
            martialStatusId: "",
            noOfchildrens: '-',
            proofId: "",
            proofImage: "",
            proofDetails: "",
            qualificationId: "",
            startDate: "",
            endDate: "",
            //salary
            salaryAmt: "",
            commissionPct: "",
            grossPay: "",
            deduction: "",
            pfNumber: "",
            healthInsurance: "",
            netPay: "",
            salaryType: "",
            salaryTypeId: "",
            //job History
            companyName: "",
            startDate: "",
            endDate: "",
            jobTitle: "",
            //position
            designationId: "",
            empSalaryTypeId: "",
            /*  managerId: "", */
            departmentId: "",
            fileTypeId: "",
            fileName: "",
            //addressInfo
            address1: "",
            permanentAddress1: "",
            landmark: "",
            permanentLandmark: "",
            stateId: "",
            permanentStateId: "",
            roleId: "",
            cityId: "",
            empToken: "",
            permanentCityId: "",
            postalCode: "",
            PermantPostalCode: "",
            countryId: "",
            permanentCountryId: "",
            shiftId: "",
            setJobInfo: '',
            setEmployeeProof: [],
            setFileUploadInfo: [],
            setQualificationInfo: '',
            unit: data[0]?.unitId || ""
        })
        setIsChecked(false)
        setLoginChecked(false)
        setJobHistoryState([])
        setQualificationState([])
        setEmployeeProofState([])



    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)

    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onEdit = async (data) => {
        const request = {
            "employeeId": data?.employeeId || ""
        }
        setLoading(true)

        editData = true
        // let employeDetails = await asyncGetEmployeeDetailsInfo(request)
        // onEditForm(employeDetails?.data || [])

        /*  setState({
             ...state,
             employeeDetailsList: employeDetails?.data  || []
         })
         console.log(employeDetails) */

        dispatch(getEmployeeDetails(request))
        // pageData = false;
        setSelectedItem(data)
        // return clearTimeout(loader)

    }

    /* const onUpdate = (data, key) => {
        pageData = true
        const request = {
            "employeeId": data?.employeeId || ""
        }
        dispatch(getEmployeeDetails(request))
        setPermissionModule(true)

    } */


    const onRoles = async (data) => {
        try {
            let roleData = parsedData[0]
            /*    console.log(data);
               console.log(roleData); */

            const filteredData = (data ? data : []).filter(item => item.roleId > roleData.roleId && (item.roleId != 2 || roleData.roleId == 1));
            console.log(filteredData);
            return filteredData
            /*   setState({
                  ...state,
                  roleList: filteredData
              }) */
        } catch {
            console.log("error")
        }
    }

    const onEditForm = (data, index) => {

        const unitIds = data.flatMap(employee =>
            employee.unitDetails.flatMap(detail => {
                if (typeof detail.unitId === "string") {
                    return detail.unitId.split(',').map(Number);
                }
                return detail.unitId;
            })
        );
        let employeDetailsList = state?.employeeDetailsList || []
        console.log(data)



        // try {
        empToken = data[0]?.employeeToken || ""
        const picture = `${imagePath}${employeePath}${data[0]?.employeeToken || ""}/${encodeURI(data[0]?.profilePicture || "")}`;
        setState({
            ...state,
            //personal
            uploadData: false,
            firstName: data[0]?.firstName || "",
            reportTo: data[0]?.reportTo || "",
            lastName: data[0]?.lastName || "",
            email: data[0]?.email || "",
            phoneNo: data[0]?.phoneNo || "",
            healthInsurance: data[0]?.empPosition[0]?.healthInsuranceNo || "",
            pfNumber: data[0]?.empPosition[0]?.pf || "",
            dateOfJoin: data[0].dateOfJoin ? moment(new Date(data[0].dateOfJoin), 'YYYY-MM-DD') : "",
            dateOfRelieving: data[0].dateOfRelieving ? moment(new Date(data[0].dateOfRelieving), 'YYYY-MM-DD') : "",
            employeeTypeId: data[0]?.employeeTypeId || "",
            shiftId: data[0]?.shiftId || "",
            profilePic: picture,
            empToken: data[0]?.employeeToken || "",
            profilePicture: data[0]?.profilePicture || '',
            setQualificationInfo: data[0]?.qualificationInfo || [],
            tree: false,
            loginInfo: loginChecked,
            addressCheck: true,
            userName: data[0]?.email || "",
            password: data[0]?.loginInfo?.[0]?.password || "",
            roleId: data[0]?.loginInfo?.[0]?.roleId || "",
            confirmPassword: data[0]?.loginInfo?.[0]?.password || "",
            dateOfBirth: data[0].dateOfBirth ? moment(new Date(data[0].dateOfBirth), 'YYYY-MM-DD') : "",
            age: data[0]?.age || "",
            fatherName: data[0]?.fatherName || "",
            motherName: data[0]?.motherName || "",
            emergencyRelation: data[0]?.emergencyRelation || "",
            emergencyContactNumber: data[0]?.emergencyContactNumber || "",
            bloodGroupId: data[0]?.bloodGroupId || "",
            nationalityId: data[0]?.nationalityId || "",
            motherTongueId: data[0]?.motherTongueId || "",
            martialStatusId: data[0].martialStatus ? data[0].martialStatus === "single" ? 1 : 2 : "",
            genderId: data[0]?.genderId || "",
            noOfchildrens: data[0]?.noOfChildrens || "-",
            unitDetails: data[0]?.unitDetails || [],
            address1: data[0]?.address[0]?.address || "",
            cityId: data[0]?.address[0]?.cityId || "",
            stateId: data[0]?.address[0]?.stateId || "",
            landmark: data[0]?.address[0]?.landMark || "",
            postalCode: data[0]?.address[0]?.postalCode || "",
            countryId: data[0]?.address[0]?.countryId || "",
            permanentAddress1: data[0]?.address[1]?.address || "",
            permanentCityId: data[0]?.address[1]?.cityId || "",
            permanentStateId: data[0]?.address[1]?.stateId || "",
            permanentLandmark: data[0]?.address[1]?.landMark || "",
            PermantPostalCode: data[0]?.address[1]?.postalCode || "",
            permanentCountryId: data[0]?.address[1]?.countryId || "",
            unit: unitIds,
            salaryAmt: data[0]?.empPosition[0]?.salaryAmt || "",
            commissionPct: data[0]?.empPosition[0]?.commissionPct || "",
            grossPay: data[0]?.empPosition[0]?.grossPay || "",
            netPay: data[0]?.empPosition[0]?.netPay || "",
            deduction: data[0]?.empPosition[0]?.deduction || "",
            empSalaryTypeId: data[0]?.empPosition[0]?.salaryTypeId || "",
            designationId: data[0]?.empPosition[0]?.designationId || "",
            departmentId: data[0]?.empPosition[0]?.departmentId || "",
            setJobInfo: data[0]?.empJobHistory || [],
            setFileUploadInfo: data[0]?.empDocuments || [],
            setEmployeeProof: data[0]?.empProofDoc || [],
            fileName: data[0]?.empDocuments[0]?.fileName || '' || [],


        })
        let empJobHistoryState = data[0]?.empJobHistory || []
        let empQualicationState = data[0]?.qualificationInfo || []
        let empFileUploadState = data[0]?.empDocuments || []

        if (empJobHistoryState) {
            const newJobHistory = empJobHistoryState.map((item, index) => ({

                empJobHistoryId: item?.empJobHistoryId || null,
                companyName: item?.companyName || null,
                startDate: moment(new Date(item.startDate), 'YYYY-MM-DD'),
                endDate: moment(new Date(item.endDate), 'YYYY-MM-DD'),
                jobTitle: item?.jobTitle || null,
                employeeId: item?.employeeId || null,
            }));

            setJobHistoryState(newJobHistory);
        }
        if (empQualicationState) {
            const newQualicationState = empQualicationState.map((item, index) => ({

                empQualificationId: item?.empQualificationId || null,
                employeeId: item?.employeeId || null,
                qualificationId: item?.qualificationId || null,
                qualificationName: item?.qualificationName || null,
                startDate: moment(new Date(item.startDate), 'YYYY-MM-DD'),
                endDate: moment(new Date(item.endDate), 'YYYY-MM-DD'),
            }));

            setQualificationState(newQualicationState);
        }
        if (empFileUploadState) {
            const newFileUploadState = empFileUploadState.map((item, index) => ({

                employeeDocumentId: item?.employeeDocumentId || null,
                employeeId: item?.employeeId || null,
                fileName: item?.fileName || null,
                fileTypeId: item?.fileTypeId || null,
            }));

            setUploadState(newFileUploadState);
        }



        setCreateModule(true)


        editData = true
        setSelectedIndex(index)
        setSelectedItem(data)
        setemployeData(data)
        setEmployeeProofState(data[0]?.empProofDoc || [])

        console.log(selectedItem)
        // setCreateModule(true)

    }

    const onRejoin = () => {
        setState({
            ...state,
            employeeDetailsList: [],
        })
        resetState()
        setRejoinModule(true)
    }

    const onRejoinForm = () => {
        const data = state?.employeeDetailsList || []
        if (data.length <= 0) {
            Alert({
                description: "Please Search Before You Submit...!",
                type: "warning",
                placement: "topRight",
                duration: 5, // Duration is 5 seconds
            });
            return false
        } else {
            if (data.length) {
                data.map(item => { delete item.leaveBalanceInfo });
                (data[0]?.qualificationInfo).map(item => { delete item.empQualificationId && delete item.employeeId; });
                (data[0]?.empProofDoc).map(item => { delete item.employeeProofId; delete item.employeeId; });
                (data[0]?.empDocuments).map(item => { delete item.employeeDocumentId; delete item.employeeId });
                (data[0]?.empJobHistory).map(item => { delete item.empJobHistoryId; delete item.employeeId });
                (data[0]?.address).map(item => { delete item.addressInfoId; delete item.employeeId });
                (data[0]?.empPosition).map(item => { delete item.empPositionId; delete item.employeeId });
            }

            setState({
                ...state,
                //personal
                firstName: data[0]?.firstName || "",
                reportTo: data[0]?.reportTo || "",
                lastName: data[0]?.lastName || "",
                email: data[0]?.email || "",
                phoneNo: data[0]?.phoneNo || "",
                //qualification
                setQualificationInfo: data[0]?.qualificationInfo || [],
                //loginInfo
                dateOfBirth: data[0].dateOfBirth ? moment(new Date(data[0].dateOfBirth), 'YYYY-MM-DD') : "",

                age: data[0]?.age || "",
                fatherName: data[0]?.fatherName || "",
                motherName: data[0]?.motherName || "",
                emergencyRelation: data[0]?.emergencyRelation || "",
                emergencyContactNumber: data[0]?.emergencyContactNumber || "",
                bloodGroupId: data[0]?.bloodGroupId || "",
                nationalityId: data[0]?.nationalityId || "",
                motherTongueId: data[0]?.motherTongueId || "",
                proofId: data[0]?.proofId || "",
                proofDetails: data[0]?.proofDetails || "",
                genderId: data[0]?.genderId || "",
                //address
                address1: data[0]?.address[0].address || "",
                cityId: data[0]?.address[0].cityId || "",
                stateId: data[0]?.address[0].stateId || "",
                landmark: data[0]?.address[0].landMark || "",
                postalCode: data[0]?.address[0].postalCode || "",
                countryId: data[0]?.address[0].countryId || "",
                //Address Permanent
                permanentAddress1: data[0]?.address[1].address || "",
                permanentCityId: data[0]?.address[1].cityId || "",
                permanentStateId: data[0]?.address[1].stateId || "",
                permanentLandmark: data[0]?.address[1].landMark || "",
                PermantPostalCode: data[0]?.address[1].postalCode || "",
                permanentCountryId: data[0]?.address[1].countryId || "",
                setJobInfo: data[0]?.empJobHistory || []
            })
            setCreateModule(true)
            setRejoinModule(false)
        }
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setState({
            ...state,
            dateOfRelieving: moment(new Date(), 'YYYY-MM-DD'),
        })
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const handleAccess = (data) => {
        let acessArr = [];
        let pageIds = new Set();
        if (data.length) {
            data.forEach(ele => {
                ele = ele.toString()
                let parts = ele.split("-");
                let pageId = parts[0];
                let access = parts[1] ? parseInt(parts[1]) : null;
                pageIds.add(pageId);

                let existingPage = acessArr.find(item => item.pageId === pageId);
                if (!existingPage) {
                    acessArr.push({ pageId: pageId, access: access ? [access] : [] });
                } else if (access) {
                    existingPage.access.push(access);
                }
            });

            pageIds.forEach(pageId => {
                let existingPage = acessArr.find(item => item.pageId === pageId);
                if (existingPage.access.length === 0) {
                    let access = [1, 2, 3, 4];
                    if (pageId !== '') {
                        acessArr.push({ pageId: pageId, access: access });
                    }
                }
            });

            return acessArr.length ? acessArr : [];
        }

    }

    useEffect(() => {
        let file = state.profilePicFileType
        let fileName = file?.name || '';
        const fileExtension = fileName?.split('.').pop() || "";
        const now = new Date();
        const year = now.getFullYear().toString();
        const uniqueIdentifier = Math.floor(1000 + Math.random() * 9000).toString();
        const day = now.getDate().toString().padStart(2, '0');
        const docName = `${year}${day}${uniqueIdentifier}.${fileExtension}`;
        const uid = uuidv4();
        const renamedFile = new File(
            [file],
            docName,
            {
                type: file?.type,
                name: docName,
                lastModified: file?.lastModified,
            }
        );

        // localStorage.setItem('empToken', JSON.stringify(uid));
        if (state.uploadData) {
                    empToken = state?.empToken || uid

            setState({
                ...state,
                profile: renamedFile,
                empToken : empToken ,
            })
            formDataAppend(renamedFile, empToken)
        }
    }, [state.profilePicFileType])

    const onWriteDocument = (event, dta, index) => {
        /* if(empToken == '' ){
            empToken = uuidv4();
        } */
        const uid = uuidv4();
        setLoading(true);
        const timer = setTimeout(() => setLoading(false), 3000);
        let temp_state = { ...state };
        let file = event.target.files[0];
        let fileName = file?.name || '';
        const fileExtension = fileName?.split('.').pop() || "";
        const imageFormats = ["png", "jpg", "jpeg", "bmp", "svg", "webp"];
        /*   if (fileExtension != 'pdf' && !imageFormats.includes(fileExtension.toLowerCase())) {
              showMessage('warning', "Only Pdf and Images are Supported")
              setState({
                  ...temp_state,
                  [event.target.name]: ''
              });
              return false;
          } */
        const now = new Date();
        const year = now.getFullYear().toString();
        const uniqueIdentifier = Math.floor(1000 + Math.random() * 9000).toString();
        const day = now.getDate().toString().padStart(2, '0');
        const docName = `${year}${day}${uniqueIdentifier}.${fileExtension}`;
        const renamedFile = new File(
            [file],
            docName,
            {
                type: file?.type,
                name: docName,
                lastModified: file?.lastModified,
            }
        );
        empToken = state?.empToken || uid

        formDataAppend(renamedFile, empToken);
        fileName = docName
        let name = event.target.name === "proofDocument" ? "fileNames" : "fileUploads";

        if (name === "fileUploads") {
            if (!temp_state.setFileUploadInfo[index]) {
                temp_state.setFileUploadInfo[index] = {};
            }
            state.setFileUploadInfo[index]["fileName"] = fileName;

        } else {
            if (!temp_state.setEmployeeProof[index]) {
                temp_state.setEmployeeProof[index] = {};
            }
            state.setEmployeeProof[index]["proofDocument"] = fileName;
        }

        temp_state[name] = [...state[name], fileName];
        setState(temp_state)
        setState({
            ...temp_state,
            empToken :  empToken,
            [event.target.name]: event.target.value
        });
        return () => clearTimeout(timer);

    };

    const formDataAppend = (data, token) => {
        const formData = new FormData();
        formData.append('document', data);
        dispatch(uploadFiles(formData, `${employeePath}${token}`));
    }


    const onDelete = () => {
        if (!state.dateOfRelieving) {
            Alert({
                description: "please Select Relieving Date",
                type: "warning",
                placement: "topRight",
                duration: 5, // Duration is 5 seconds
            });
            return false;
        }
        const deleteRequest = {
            isActive: false,
            dateOfRelieving: dateConversion(state.dateOfRelieving, "YYYY-MM-DD")
        }
        setLoaderModule(true)
        dispatch(updateEmployee(deleteRequest, selectedItem.employeeId))
    }
    const {
        firstName,
        lastName,
        email,
        unit,
        age,
        roleId,
        martialStatusId,
        permanentCountryId,
        userName,
        password,
        profile,
        shiftId,
        emergencyContactNumber,
        permanentAddress1,
        motherName,
        phoneNo,
        dateOfBirth,
        healthInsurance,
        pfNumber,
        permanentStateId,
        emergencyRelation,
        genderId,
        permanentCityId,
        PermantPostalCode,
        fatherName,
        nationalityId,
        permanentLandmark,
        motherTongueId,
        employeeTypeId,
        bloodGroupId,
        /*  userId ,
         unitId,
         empCode, */
        salaryAmt,
        commissionPct,
        grossPay,
        deduction,
        empSalaryTypeId,
        netPay,
        reportTo,
        designationId,
        departmentId,
    } = state;

    const onFind = (val, name) => {
        if (name === "jobHistory") {
            const req = {
                "employeeJobHistoryId": val
            }
            dispatch(deleteEmployeeJobHistory(req))
        } else if (name === "qualification") {
            const req = {
                "empQualificationId": val
            }
            dispatch(deleteEmployeeQualification(req))
        }
        else if (name === "employeeProof") {
            const req = {
                "employeeProofId": val
            }
            dispatch(deleteEmployeeProof(req))
        }
    }

    const profileUpload = () => {
        setUploadModule(true)

    }

    const onEmployeSearch = () => {

        const val = state?.searchEmployee
        if (val != '') {
            const req = {
                "empCode": val,
                "isActive": false
            }
            dispatch(getEmployeeDetails(req))
            searchData = true;
        }
        else {
            showMessage("warning", 'Enter Employee code to search...')
            Alert({
                description: "Enter Employee code to search...",
                type: "warning",
                placement: "topRight",
                duration: 3,
            });
        }
    }

    const onHandleCheckBox = (event, index) => {
        const isChecked = event.target.checked;


        // Clone and update `initialEmployeeDetails`
        /*  const updatedEmployeeDetails = state.initialEmployeeDetails.map((section) => ({
             ...section,
             formBody: section.formBody.map((formField) => ({
                 ...formField,
                 formField: formField.formField.map((field) => ({
                     ...field,
                     disabled:
                         ["userName", "password", "confirmPassword"].includes(field.fieldName)
                             ? !isChecked
                             : field.disabled,
                 })),
             })),
         })); */

        // Update the state
        setState((prevState) => ({
            ...prevState,
            // initialEmployeeDetails: updatedEmployeeDetails,
            [event.target.name]: isChecked,
            ...(isChecked
                ? {} // No additional changes when checked
                : { userName: "", password: "", confirmPassword: "" }), // Reset fields when unchecked
        }));
        setLoginChecked(isChecked)

    };


    const handleUnit = (event, name) => {
        let value = state?.unit || []; // Ensure this is the array of IDs to filter by

        // Filter the data where unitId matches any value in the 'value' array


        // Update the state with the new data
        setState({
            ...state,
            [name]: event,
        });

        console.log("Value array:", value);

    };






    const displaySearchData = () => {
        if (state?.employeeDetailsList.length > 0) {
            return (
                <>
                    <p className="text-muted">Name: <b>{state?.employeeDetailsList[0]?.firstName || "" + " " + state?.employeeDetailsList[0]?.lastName || ""}</b></p>
                    <p className="text-muted">D.O.B: <b>{state?.employeeDetailsList[0]?.dateOfBirth ? dateConversion(state.employeeDetailsList[0].dateOfBirth, "DD-MM-YYYY") : ""}</b></p>
                    <p className="text-muted">Email: <b>{state?.employeeDetailsList[0]?.email}</b></p>
                </>
            )
        } else {
            return (
                <>
                    <p className="text-muted">Employee Not Found...!</p>
                </>
            )
        }
    }

    const addUploadFile = () => {
        const uploadfileItem = {
            "fileTypeId": "",
            "fileName": ""
        }
        setUploadState([...uploadState, uploadfileItem])
    }

    const onRemoveFileUpload = (index) => {
        let temp_state = [...uploadState];
        let temp_state1 = [...state.setFileUploadInfo];
        temp_state.splice(index, 1);
        let arr = []
        temp_state1.forEach(item => {
            (temp_state ? temp_state : []).map(ele => {
                if (ele.fileName == item.fileName) {
                    arr.push(item)
                }
            })
        })
        setUploadState(temp_state)
        state.setFileUploadInfo = arr

    }


    const addEducation = () => {
        const qualificationItem = {
            "qualificationId": "",
            "startDate": "",
            "endDate": "",
        }
        setQualificationState([...qualificationState, qualificationItem])
    }


    const onRemoveItem = (index) => {
        let temp_state = [...qualificationState];
        if (temp_state[index].empQualificationId) {
            setSelectedItem(temp_state[index])
            setSelectedIndex(index)
            setDeleteModule(true)
        } else {
            temp_state.splice(index, 1);
            //setSelectedIndex(index)
            setQualificationState(temp_state)
        }
    }

    const addproof = () => {
        if (employeeProofState.length >= 4) {
            showMessage("warning", "Maximum Proof Upload 4 only")
            return false;
        } else {
            const employeeProofItem = {
                "proofTypeId": "",
                "proofDocument": "",
                "proofNumber": "",
            }
            setEmployeeProofState([...employeeProofState, employeeProofItem])
        }
    }

    const onRemove = (index, name) => {
        if (name === "jobHistoryRemove") {
            let temp_state = [...jobHistoryState];
            if (temp_state[index].empJobHistoryId) {
                setSelectedItem(temp_state[index])
                setSelectedIndex(index)
                setdeleteJobHistory(true)

            } else {
                temp_state.splice(index, 1);
                setJobHistoryState(temp_state)
            }


        } else if (name === "qualificationRemove") {
            let temp_state = [...qualificationState];
            if (temp_state[index].empQualificationId) {
                setSelectedItem(temp_state[index])
                setSelectedIndex(index)
                setdeleteQualicationHistory(true)
            } else {
                temp_state.splice(index, 1);
                //setSelectedIndex(index)
                setQualificationState(temp_state)
            }


        }
        else if (name === "proofRemove") {
            let temp_state = [...employeeProofState];
            if (temp_state[index].employeeProofId) {
                setSelectedItem(temp_state[index])
                setSelectedIndex(index)
                setDeleteProof(true)
            } else {
                temp_state.splice(index, 1);
                setEmployeeProofState(temp_state)
            }

        }
        else if (name === "onRemoveFileUpload") {
            let temp_state = [...uploadState];
            if (temp_state[index].employeeDocumentId) {
                setSelectedItem(temp_state[index])
                setSelectedIndex(index)
                setdeleteFileUpload(true)
            } else {
                temp_state.splice(index, 1);
                setUploadState(temp_state)

            }

        }
    };

    const onDeleteForm = () => {
        let temp_state = [...employeeProofState];
        temp_state.splice(selectedIndex, 1);
        setEmployeeProofState(temp_state)
        setDeleteProof(false)
        const employeeProofId = selectedItem.employeeProofId
        const req = {
            "employeeProofId": employeeProofId
        }
        dispatch(deleteEmployeeProof(req))
        Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
        // setCreateModule(false)

    }

    const onDeleteFileUploadForm = () => {
        let temp_state = [...uploadState];
        temp_state.splice(selectedIndex, 1);
        setUploadState(temp_state)
        setdeleteFileUpload(false)
        const employeeDocumentId = selectedItem.employeeDocumentId
        const req = {
            isActive: "false",
            "employeeDocumentId": employeeDocumentId
        }
        dispatch(updateEmployeeDocument(req, employeeDocumentId))
        Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
        // setCreateModule(false)

    }

    const onDeleteQualificationForm = () => {
        let temp_state = [...qualificationState];
        temp_state.splice(selectedIndex, 1);
        setQualificationState(temp_state)
        setdeleteQualicationHistory(false)
        const qualificationId = selectedItem.empQualificationId
        const req = {
            "empQualificationId": qualificationId
        }
        dispatch(deleteEmployeeQualification(req))
        Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
        // setCreateModule(false)

    }


    const onDeleteJobHistoryForm = () => {
        let temp_state = [...jobHistoryState];
        temp_state.splice(selectedIndex, 1);
        setJobHistoryState(temp_state)
        setdeleteJobHistory(false)
        const jobHistoryId = selectedItem.empJobHistoryId
        const req = {
            "employeeJobHistoryId": jobHistoryId
        }
        dispatch(deleteEmployeeJobHistory(req))
        Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

    }


    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;

        setIsChecked(isChecked);

        // Update the `state` based on the checkbox value
        setState(prevState => ({
            ...prevState,
            permanentAddress1: isChecked ? prevState?.address1 || "" : "",
            permanentCityId: isChecked ? prevState?.cityId || "" : "",
            permanentStateId: isChecked ? prevState?.stateId || "" : "",
            permanentLandmark: isChecked ? prevState?.landmark || "" : "",
            PermantPostalCode: isChecked ? prevState?.postalCode || "" : "",
            permanentCountryId: isChecked ? prevState?.countryId || "" : "",
        }));

        console.log("Checkbox is checked:", isChecked);
    };


    const validateFields = (fields, data, context) => {
        for (let key of fields) {
            if (!data[key] || data[key] === "") {
                Alert({
                    title: "Warning!",
                    description: `PLEASE FILL IN THE ${key.toUpperCase()} FIELD${context ? ` IN ${context}` : ""}.`,
                    type: "warning",
                    placement: "topRight",
                    duration: 3,
                });
                return false;
            }
        }
        return true;
    };





    const onViewProof = (index, name) => {
        if (name == "employeProof") {
            const proofDoc = state?.setEmployeeProof || []

            let alertTimeout;
            const showAlert = () => {
                Alert({
                    description: "Processing... Please wait!",
                    type: "info",
                    placement: "topRight",
                    duration: 5, // Duration is 5 seconds
                });
                alertTimeout = setTimeout(() => { }, 5000);
            };



            const timer = setTimeout(() => setLoading(false), 5000);

            let fileName = proofDoc.length ? proofDoc[index] : []
            if (fileName != undefined && Object.keys(fileName).length) {
                showAlert();
            }
            console.log(fileName)
            if (fileName != undefined && Object.keys(fileName).length) {
                const timerr = setTimeout(() => setviewProof(true), 5000);

                fileName = proofDoc.length > index ? proofDoc[index].proofDocument : state.fileNames[index]
                const fileNameWithoutExt = fileName.split('.').slice(0, -1).join('.');
                const fileExtension = fileName.split('.').pop();
                const type = `${fileExtension}`;
                const file = `${imagePath}${employeePath}${empToken}/${encodeURI(fileNameWithoutExt)}.${fileExtension}`;
                console.log(empToken);
                console.log(file);
                const imageFormats = ["png", "jpg", "jpeg", "bmp", "svg", "webp"];
                const isImage = imageFormats.includes(type.toLowerCase());
                fileSetState({
                    isImage: isImage,
                    file: file,
                    type: type
                })
                proofDoc[index].proofDocument = fileName
                setState({
                    ...state,
                    setEmployeeProof: proofDoc,

                });




                return () => clearTimeout(timer, timerr);
            } else {
                showMessage('warning', "Please upload File to view")
                Alert({
                    description: "Please upload File to view",
                    type: "error",
                    placement: "topRight",
                    duration: 3,
                });
                setLoading(false);
            }

        } else if (name === "fileUploadView") {
            const proofDoc = state?.setFileUploadInfo || []
            let alertTimeout;
            const showAlert = () => {
                Alert({
                    description: "Processing... Please wait!",
                    type: "info",
                    placement: "topRight",
                    duration: 5, // Duration is 5 seconds
                });
                alertTimeout = setTimeout(() => { }, 5000);
            };
            const timer = setTimeout(() => setLoading(false), 5000);

            let fileName = proofDoc.length ? proofDoc[index] : []
            if (fileName != undefined && Object.keys(fileName).length) {
                showAlert();
            }
            if (fileName != undefined && Object.keys(fileName).length) {
                const timerr = setTimeout(() => setviewProof(true), 5000);

                fileName = proofDoc.length > index ? proofDoc[index].fileName : state.fileUploads[index]
                console.log(fileName)
                const fileNameWithoutExt = fileName.split('.').slice(0, -1).join('.');
                const fileExtension = fileName.split('.').pop();
                const type = `${fileExtension}`;
                const file = `${imagePath}${employeePath}${empToken}/${encodeURI(fileNameWithoutExt)}.${fileExtension}`;
                console.log(empToken);
                console.log(file);
                const imageFormats = ["png", "jpg", "jpeg", "bmp", "svg", "webp"];
                const isImage = imageFormats.includes(type.toLowerCase());
                fileSetState({
                    isImage: isImage,
                    file: file,
                    type: type
                })
                proofDoc[index].fileName = fileName
                setState({
                    ...state,
                    setFileUploadInfo: proofDoc
                });
                return () => clearTimeout(timer, timerr);
            } else {
                showMessage('warning', "Please upload File to view")
                Alert({
                    description: "Please upload File to view",
                    type: "error",
                    placement: "topRight",
                    duration: 3,
                });
                setLoading(false);
            }



        }
        setLoading(true);

    };

    const onSubmitForm = () => {

        if (deleteModule) {
            const deleteRequest = { isActive: false };
            setLoaderModule(true);
            dispatch(updateEmployee(deleteRequest, selectedItem.employeeId));
            return;
        }
        let proofDocNames = state?.setEmployeeProof || []
        let fileUploadNames = state?.setFileUploadInfo || []

        console.log(proofDocNames)
        const employeeProof = employeeProofState;
        const fileUpload = uploadState;
        const proofDetails = []
        const employeeDocumentsDetails = []

        employeeProof.forEach((ele, index) => {
            let req = { ...ele, proofDocument: proofDocNames[index]?.proofDocument || "" }
            proofDetails.push(req)
        })

        fileUpload.forEach((ele, index) => {
            let req = { ...ele, fileName: fileUploadNames[index]?.fileName || "" }
            employeeDocumentsDetails.push(req);
        });




        const unitFilterList = state?.unitFilterList || [];
        const unitId = state?.unit || [];
        const filteredData = unitFilterList.length > 0
            ? unitFilterList
            : [].filter(item => unitId.includes(item.unitId));
        const unitDetails = state?.unitDetails || [];


        const requestData = {
            firstName,
            lastName,
            email,
            phoneNo,
            dateOfJoin: dateConversion(state.dateOfJoin, "YYYY-MM-DD"),
            dateOfRelieving: dateConversion(state.dateOfRelieving, "YYYY-MM-DD"),
            shiftId,
            reportTo: reportTo ? reportTo : 0,
            employeeTypeId,
            loginInfo: [{
                userName: email,
                password,
                roleId,
            }],
            employeeProofDetails: proofDetails,
            empQualification: qualificationState,
            employeeDocuments: employeeDocumentsDetails,
            employeeDetails: [{
                dateOfBirth: dateConversion(dateOfBirth, "YYYY-MM-DD"),
                fatherName,
                bloodGroupId,
                motherTongueId,
                age,
                motherName,
                nationalityId,
                profilePicture: profile?.name || state.profilePicture,
                emergencyRelation,
                employeeToken: empToken ? empToken : '',
                emergencyContactNumber,
                genderId,
                martialStatus: martialStatusId === "1" ? "single" : "married",
                noOfChildrens: state.noOfchildrens === "-" ? 0 : state.noOfchildrens,
            }],
            addressDetails: [
                {
                    address: state?.address1 || permanentAddress1,
                    landMark: state?.landmark || permanentLandmark,
                    cityId: state?.cityId || permanentCityId,
                    stateId: state?.stateId || permanentStateId,
                    countryId: state?.countryId || 1,
                    postalCode: state?.postalCode || PermantPostalCode,
                    currentStatus: true,
                },
                {
                    address: permanentAddress1,
                    landMark: permanentLandmark,
                    cityId: permanentCityId,
                    stateId: permanentStateId,
                    countryId: permanentCountryId || 1,
                    postalCode: PermantPostalCode,
                    currentStatus: false,
                },
            ],
            empJobHistory: jobHistoryState,
            empSalary: [{
                salaryAmt,
                commissionPct,
                grossPay,
                deduction,
                netPay,
                salaryTypeId: empSalaryTypeId,
                healthInsuranceNo: healthInsurance,
                pf: pfNumber,
            }],
            empPosition: [{
                designationId,
                departmentId,
            }],
            unitDetails: filteredData,
        };
        console.log(requestData)
        // return
        // Validate top-level fields
        const requiredFields = ["firstName", "lastName", "email", "phoneNo", "dateOfJoin", "dateOfRelieving", "shiftId", "employeeTypeId"];
        if (!validateFields(requiredFields, requestData)) return;

        if (!validateFields(
            ["roleId", "password"],
            requestData.loginInfo[0],
            "EMPLOYE INFO"
        )) return;

        const phoneRegex = /^[+]?[0-9]{10,15}$/;
        if (!phoneRegex.test(requestData.phoneNo)) {
            Alert({
                title: "Invalid Phone Number",
                description: "Please enter a valid phone number (10-digits).",
                type: "error",
                placement: "topRight",
                duration: 3,
            });
            return;
        }

        // Validate nested fields
        if (!validateFields(
            ["dateOfBirth", "fatherName", "bloodGroupId", "motherTongueId", "age", "motherName", "nationalityId", "emergencyRelation", "emergencyContactNumber", "genderId"],
            requestData.employeeDetails[0],
            "EMPLOYEE DETAILS"
        )) return;

        if (!validateFields(
            ["stateId", "cityId", "countryId"],
            requestData.addressDetails[0],
            "ADDRESS DETAILS"
        )) return;
        if (!validateFields(
            ["stateId", "cityId", "countryId"],
            requestData.addressDetails[1],
            "PERMANENT ADDRESS DETAILS"
        )) return;



        if (!validateFields(
            ["salaryTypeId"],
            requestData.empSalary[0],
            "SALARY DETAILS"
        )) return;
        if (!validateFields(
            ["designationId", "departmentId"],
            requestData.empPosition[0],
            "SALARY INFO"
        )) return;


        // Check if all fields in jobHistoryState are empty
        const jobHistory = requestData.empJobHistory.every(item =>
            !item.companyName &&
            !item.jobTitle &&
            !item.startDate &&
            !item.endDate
        );

        // If all fields are empty, set job history to an empty array
        if (jobHistory && requestData.empJobHistory.length > 0) {
            Alert({
                title: "Warning!",
                description: "Please remove unfilled data of job history",
                type: "warning",
                placement: "topRight",
                duration: 3,
            });
            return;
        }
        const employeeProofDetails = requestData.employeeProofDetails.every(item =>
            !item.proofTypeId &&
            !item.proofDocument &&
            !item.proofNumber
        );

        // If all fields are empty, set job history to an empty array
        if (employeeProofDetails && requestData.employeeProofDetails.length > 0) {
            Alert({
                title: "Warning!",
                description: "Please remove unfilled data of proof details",
                type: "warning",
                placement: "topRight",
                duration: 3,
            });
            return;
        }
        const empQualification = requestData.empQualification.every(item =>
            !item.qualificationId &&
            !item.startDate &&
            !item.endDate
        );

        // If all fields are empty, set job history to an empty array
        if (empQualification && requestData.empQualification.length > 0) {
            Alert({
                title: "Warning!",
                description: "Please remove unfilled data of  qualification details",
                type: "warning",
                placement: "topRight",
                duration: 3,
            });
            return;
        }
        console.log(employeData)

        if (editData) {
            if (state?.password != state?.confirmPassword) {
                // showMessage("warning", "Please Check Password")
                Alert({
                    title: "Warning!",
                    description: "Please Check Password",
                    type: "warning",
                    placement: "topRight",
                    duration: 3,
                });
                return false;
            }
            const unitFilterList = state?.unitFilterList || [];
            let unitIds = state?.unit || [];
            console.log("Unit IDs:", unitIds);

            const filteredData = unitFilterList.filter(item => unitIds.includes(item.unitId));

            const unitDetails = state?.unitDetails || [];



            let updatedUnitDetails = unitDetails.map(unit => {
                return {
                    ...unit,
                    isActive: unitIds.includes(unit.unitId)
                };
            });

            let hasChanges = unitDetails.some((unit, index) => unit.isActive !== updatedUnitDetails[index].isActive);

            if (hasChanges) {
                requestData.unitDetails = updatedUnitDetails;
            } else {
                requestData.unitDetails = filteredData;
            }

            setLoaderModule(true);
            if (Array.isArray(requestData.addressDetails) && Array.isArray(employeData[0]?.address)) {
                requestData.addressDetails = requestData.addressDetails.map((v, idx) => ({
                    ...v,
                    addressInfoId: employeData[0].address[idx]?.addressInfoId || null, // Fallback to null if not found
                }));
            } else {
                console.error("Invalid data structure: addressDetails or address is not an array.");
                // setCreateModule(false)
            }

            if (requestData && employeData[0]?.employeeId) {
                dispatch(updateEmployee(requestData, employeData[0].employeeId));

            } else {

                console.error("Invalid requestData or employeeId: Ensure both are valid.");
                // setCreateModule(false)
                setLoaderModule(false)
            }

        } else {
            if (state?.password != state?.confirmPassword) {
                Alert({
                    title: "Warning!",
                    description: "Please Check Password",
                    type: "warning",
                    placement: "topRight",
                    duration: 3,
                }); return false;
            }
            setLoaderModule(true);
            dispatch(createEmployee(requestData));
        }
    };




    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";

    let sliderData = [
        {
            title: "Admission & Education Info",
            slides: [
                {
                    content: (
                        <FormLayout
                            defaultState={state}
                            setDefaultState={setState}
                            dynamicForm={state?.initialEmployeeDetails || []}
                            onChangeCallback={{ "onHandleCheckBox": onHandleCheckBox, "handleUnit": handleUnit }}
                            noOfColumn={3}
                        />
                    ),
                    title: "Employee Info",
                    description: "Description for Slide 1-2",
                    marginTop: "20px",
                    left: "50px",


                },
                {
                    content: (
                        <div>
                            <FormLayout
                                defaultState={state}
                                setDefaultState={setState}
                                dynamicForm={personalDetails}
                                noOfColumn={4}
                                onSearch={() => profileUpload()}

                            />
                        </div>
                    ),
                    title: "Personal Info",
                    marginTop: "-50px",
                    left: "50px",

                },
                {
                    content: (
                        <div style={{ display: "flex", gap: "20px" }}>
                            <div style={{ flex: 1 }}>
                                <h5 style={{ marginBottom: "10px", fontWeight: "bold", color: "#4D6D9A" }}>Current Address</h5>

                                <FormLayout
                                    defaultState={state}
                                    setDefaultState={setState}
                                    dynamicForm={addressInfoForm}
                                    noOfColumn={2}
                                />
                            </div>
                            <div style={{ flex: 1 }}>
                                {/* Container for checkbox with proper alignment */}
                                <div style={{ display: "flex", alignItems: "center", marginLeft: "400px", marginBottom: "-21px" }}>
                                    <label style={{ marginRight: "10px", color: "gray" }}>Same as current address</label>
                                    <input type="checkbox" style={{ height: "13px", width: "13px" }} onChange={handleCheckboxChange} checked={isChecked} />
                                </div>
                                <h5 style={{ marginBottom: "10px", fontWeight: "bold", color: "#4D6D9A" }}>Permanent Address</h5>
                                <FormLayout
                                    defaultState={state}
                                    setDefaultState={setState}
                                    dynamicForm={permanentAddressInfoForm}
                                    noOfColumn={2}
                                />
                            </div>
                        </div>
                    ),
                    marginTop: "-50px",
                    title: "Address Info",
                    left: "50px",

                },

                {
                    content: (
                        <div>
                            <FormLayout
                                defaultState={state}
                                setDefaultState={setState}
                                dynamicForm={salaryForm}
                                noOfColumn={4}
                            />
                        </div>
                    ),
                    title: "Salary Info",
                    marginTop: "10px",
                    left: "50px",

                },
                {
                    content: (
                        <div  >
                            <div >
                                <div style={{ position: "fixed", width: "70rem", height: "60vh", overflowY: "scroll" }}>
                                    <div className="py-2" >
                                        <Button className="float-end" variant="primary" onClick={() => addJobHistory()} >
                                            {"+"}
                                        </Button>
                                    </div>
                                    <div className="row">
                                        <div >
                                            <div className="table-responsive mt-4">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Company Name</th>
                                                            <th scope="col">Job Title</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(jobHistoryState ? jobHistoryState : []).map((item, index) => {
                                                            return (
                                                                <TableLayout key={index} defaultState={state} /* ref={jobHistoryFormRef} */ setDefaultState={setJobHistoryState} index={index} layoutType="table" dataList={jobHistoryState} dynamicForm={jobHistoryForm} iconPress={{ "onRemove": onRemove }} /* onSubmit={onSubmit} */ />
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ),
                    title: "Previous experience",
                    marginTop: "-300px",
                    left: "50px",

                },
                {
                    content: (
                        <div >
                            <div style={{ position: "fixed", width: "70rem", height: "60vh", overflowY: "scroll", scrollbarWidth: "0" }}>
                                <style>
                                    {`
                                ::-webkit-scrollbar {
                                    display: none; 
                                }
                            `}
                                </style>
                                <div className="px-2" >
                                    <div className="py-2" >
                                        <Button className="float-end" variant="primary" onClick={() => addUploadFile()} >
                                            {"+"}
                                        </Button>
                                    </div>
                                    <div className="row">
                                        <div>
                                            <div className="table-responsive mt-3">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">File Type</th>
                                                            <th scope="col">Upload</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(uploadState ? uploadState : []).map((item, index) => {
                                                            return (
                                                                <TableLayout key={index}
                                                                    defaultState={state}
                                                                    setDefaultState={setUploadState}
                                                                    index={index}
                                                                    layoutType="table"
                                                                    dataList={uploadState}
                                                                    dynamicForm={fileUploaderForm}
                                                                    onChangeCallback={{ "onWriteDocument": onWriteDocument }}
                                                                    iconPress={{ "onView": onViewProof, "onRemove": onRemove }}
                                                                // onSubmit={onSubmit}
                                                                />
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ),
                    title: "FIle  Info",
                    marginTop: "-300px",
                    left: "50px",

                },


                {
                    content: (
                        <>
                            {/* Internal Styles for Scrollbar */}
                            <style>
                                {`
                                    .custom-scrollbar {
                                        scrollbar-width: none; /* Firefox */
                                        -ms-overflow-style: none; /* IE 10+ */
                                    }
                                    .custom-scrollbar::-webkit-scrollbar {
                                        width: 0; /* Chrome, Safari, Opera */
                                        height: 0; /* For horizontal scrolling */
                                    }
                                `}
                            </style>
                            <div style={{ display: "flex", gap: "20px" }}>

                                {/* Column for Permanent Address */}
                                <div
                                    style={{
                                        flex: 1,
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                        padding: "10px",
                                        borderRadius: "8px",
                                        background: "#fff"
                                    }}
                                >
                                    <h5 style={{ marginBottom: "10px", fontWeight: "bold", color: "#4D6D9A" }}>Qualification Info</h5>

                                    <div
                                        className="table-responsive mt-0 custom-scrollbar"
                                        style={{ height: "400px", overflowY: "scroll" }}
                                    >
                                        <BsDatabaseAdd
                                            style={{ float: "right", fontSize: "20px", cursor: "pointer" }}
                                            onClick={addEducation}
                                        />
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Qualification</th>
                                                    <th scope="col">Start Date</th>
                                                    <th scope="col">End Date</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(qualificationState ? qualificationState : []).map((item, index) => {
                                                    return (
                                                        <TableLayout
                                                            key={index}
                                                            defaultState={state}
                                                            setDefaultState={setQualificationState}
                                                            index={index}
                                                            layoutType="table"
                                                            dataList={qualificationState}
                                                            dynamicForm={qualificationForm}
                                                            iconPress={{ "onRemove": onRemove }}
                                                        />
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* Column for Proof Details */}
                                <div
                                    style={{
                                        flex: 1,
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                        padding: "10px",
                                        borderRadius: "8px",
                                        background: "#fff"
                                    }}
                                >
                                    <h5 style={{ marginBottom: "10px", fontWeight: "bold", color: "#4D6D9A" }}>Proof Info</h5>

                                    <div
                                        className="table-responsive mt-0 custom-scrollbar"
                                        style={{ height: "300px", overflowY: "scroll" }}
                                    >
                                        <BsDatabaseAdd
                                            style={{ float: "right", fontSize: "20px", cursor: "pointer" }}
                                            onClick={addproof}
                                        />
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Proof Type</th>
                                                    <th scope="col">Upload Proof</th>
                                                    <th scope="col">Proof Number</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(employeeProofState.length ? employeeProofState : []).map((item, index) => {
                                                    return (
                                                        <TableLayout
                                                            key={index}
                                                            defaultState={state}
                                                            setDefaultState={setEmployeeProofState}
                                                            onChangeCallback={{ "onWriteDocument": onWriteDocument }}

                                                            index={index}
                                                            layoutType="table"
                                                            dataList={employeeProofState}
                                                            dynamicForm={proofUploaderForm}
                                                            iconPress={{ "onView": onViewProof, "onRemove": onRemove }}
                                                        />
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                    ),
                    marginTop: "100px",
                    title: "Qualification & Proof Info",
                    left: "50px",

                }

            ],
        },
    ];

    const stepIndicatorPosition = {
        left: "17%", // Customize the left position
        top: "70px", // Optional customization for top
    };


    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        } else if (buttonName == 'Rejoin') {
            setState({
                ...state,
                employeeDetailsList: [],
            })
            resetState()
            setRejoinModule(true)

        }
    };
    const { sidebarOpen } = useSidebar();

    const onClose = () => {
        setviewProof(false)

    }

    const handleStep7 = (value) => {
        setIsStep7(value);
    };

    return (
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
            <Loading status={loaderModule} />
            <div style={{ marginTop: "-10px" }}>
                <View isOpen={createModule} size="fullscreen" savetitle={modelButtonLbl} onClose={setCreateModule} isLoading={loaderModule}  onSubmit={onSubmitForm} title={`${modelTitle} Employee`} disable={isStep7}>

                    <MultiSlider sliderData={sliderData} stepIndicatorPosition={stepIndicatorPosition} onReachStep7={handleStep7} />

                </View>

                <View centered={true} size={fileState.isImage ? 'md' : 'lg'} savetitle="Close" isOpen={viewProof} onClose={setviewProof} title="Employee Documents">
                    {loading && <Loader color="#6C63FF" size="12px" speed="1.5s" />}

                    <div className="overflow-auto" style={{ height: "500px" }}>
                        {
                            fileState.isImage ?
                                <img src={fileState?.file} width="100%" height="75%"></img> :
                                <object data={fileState?.file} type="application/pdf" width="100%" height="100%">
                                    <a href={fileState?.file}></a>
                                </object>}

                    </div>
                </View>
                <View size={'lg'} isOpen={detailsView} onClose={setdetailsView} savetitle="Yes" title="Employe Details">
                    <ProfileDetails data={employeDetails} employeeDetailsList={state?.employeeDetailsList || []} employeePromotionList={state?.employeePromotionList || []} />
                </View>

                <View size={'md'} isOpen={uploadModule} savetitle="upload" onClose={setUploadModule} title="Upload Image">
                    <AppImageCropper state={state} setState={setState} close={setUploadModule}></AppImageCropper>
                </View>

                <View centered={true} size={'md'} isOpen={rejoinModule} onClose={setRejoinModule} savetitle="Submit" onSubmit={onRejoinForm} isLoading={loaderModule} title="Rejoin Employee" >
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={reJoinForm} noOfColumn={1} onSubmit={onDelete} onSearch={onEmployeSearch} />
                    <p className="text-muted" ><b>Please Search Before Submit..!</b></p>
                    {searchData ? displaySearchData() : ""}
                </View>


                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onDelete} title="Delete Batch">
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={relivingForm} noOfColumn={1} onSubmit={onDelete} />
                </View>

                <View centered={true} size={'sm'} isOpen={deleteProof} onClose={setDeleteProof} savetitle="Yes" onSubmit={onDeleteForm} title="Delete Proof" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                    <h6 className="text-capitalize mb-1">Are you sure ? You won't be able to revert this!</h6>
                </View>
                <View centered={true} size={'sm'} isOpen={deleteJobHistory} onClose={setdeleteJobHistory} savetitle="Yes" onSubmit={onDeleteJobHistoryForm} title="Delete Job History" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                    <h6 className="text-capitalize mb-1">Are you sure ? You won't be able to revert this!</h6>
                </View>
                <View centered={true} size={'sm'} isOpen={deleteQualicationHistory} onClose={setdeleteQualicationHistory} savetitle="Yes" onSubmit={onDeleteQualificationForm} title="Delete Qualification History" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                    <h6 className="text-capitalize mb-1">Are you sure ? You won't be able to revert this!</h6>
                </View>
                <View centered={true} size={'sm'} isOpen={deleteFileUpload} onClose={setdeleteFileUpload} savetitle="Yes" onSubmit={onDeleteFileUploadForm} title="Delete File History" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                    <h6 className="text-capitalize mb-1">Are you sure ? You won't be able to revert this!</h6>
                </View>

                <SimpleCard >
                    <CustomTable buttonData={pageItems.create ? ['add', 'rejoin'] : []} handleClick={handleClick} tableName={"Employee Data"} columns={column} data={itemList} />
                </SimpleCard>

            </div>
        </div>
    )
};

export default Employee;