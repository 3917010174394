
const batchForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                   
                    {
                        label: "Banner Image",
                        inputType: "file",
                        fieldName:"image",
                        onChange :"onDocument",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Unit",
                        inputType: "select",
                        options: "unitList",
                        uniqueKey: "unitId",
                        displayKey: "unitName",
                        onChange :"onSelectUnit",
                        fieldName:"unitId",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Valid Upto",
                        inputType: "date",
                        fieldName:"validTill",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        description: "Is Default",
                        inputType: "checkbox",
                        fieldName:"isDefault",
                        // onChange : "onDefault",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }
                                                                     
                ]
            }
        ]
    }
];

const batchEditForm = [
    {
        title: "Basic Information",
        formBody : [
            {
                formField: [
                   
                    {
                        label: "Banner Image",
                        inputType: "file",
                        fieldName:"image",
                        onChange :"onDocument",
                        defaultValue: "",
                        config: {
                            required: "required",
                        },
                    },
                    {
                        label: "Unit",
                        inputType: "select",
                        options: "unitList",
                        uniqueKey: "unitId",
                        displayKey: "unitName",
                        onChange :"onSelectUnit",
                        fieldName:"unitId",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        label: "Valid Upto",
                        inputType: "date",
                        fieldName:"validTill",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    },
                    {
                        description: "Is Default",
                        inputType: "checkbox",
                        fieldName:"isDefault",
                        disabled: true,
                        // onChange : "onDefault",
                        defaultValue: "",
                        config: {
                            required: "",
                        },
                    }
                                                                     
                ]
            }
        ]
    }
];

export {
    batchForm,
    batchEditForm,
};