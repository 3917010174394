import { returnApiCallPromise } from './ApiContent';
import { appMenu } from './APIContainer';

//GET
export function getAppMenu(request) {
    const requestObj = request ? { params: request } : "";
    return (dispatch) => {
        returnApiCallPromise("GET", appMenu, requestObj)
            .then(response => {
                dispatch({
                    type: 'GET_APP_MENU_SUCCESS',
                    payload: response.data
                })
            }).catch(error => {

                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'GET_APP_MENU_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

export function asyncGetAppMenuInfo(request) {
    const requestObj = request ? { params: request } : "";
    return returnApiCallPromise("GET", appMenu, requestObj)
        .then(response => {
            return {
                error: false,
                message: "Succsss",
                data: response?.data?.data || []
            }
        }).catch(error => {
            let errorMessage = error;
            if (error.response) {
                if (error.response.data.error) {
                    errorMessage = error.response.data.message;
                }
            }
            return {
                error: true,
                data: [],
                message: errorMessage
            }
        })
}

//POST
export function createAppMenu(request) {

    return (dispatch) => {
        returnApiCallPromise("POST", appMenu, request)
            .then(response => {
                if (response.data.error) {
                    dispatch({
                        type: 'CREATE_APP_MENU_FAILURE',
                        errorMessage: response.data.message
                    })
                } else {
                    dispatch({
                        type: 'CREATE_APP_MENU_SUCCESS',
                        payload: response.data
                    })
                }
            }).catch(error => {
                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'CREATE_APP_MENU_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}

export function updateAppMenu(request, appMenuId) {
    return (dispatch) => {
        returnApiCallPromise("PUT", appMenu + "/" + appMenuId, request)
            .then(response => {
                if (response.data.error) {
                    dispatch({
                        type: 'UPDATE_APP_MENU_FAILURE',
                        errorMessage: response.data.message
                    })
                } else {
                    dispatch({
                        type: 'UPDATE_APP_MENU_SUCCESS',
                        payload: response.data
                    })
                }
            }).catch(error => {
                let errorMessage = error;
                if (error.response) {
                    if (error.response.data.error) {
                        errorMessage = error.response.data.message;
                    }
                }
                dispatch({
                    type: 'UPDATE_APP_MENU_FAILURE',
                    errorMessage: errorMessage
                })
            })
    }
}