import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { bookTypeForm } from "./formData";
import { createBookType, getBookType, updateBookType } from "app/api/BokkTypeApi";
import Alert from "app/components/atoms/alert";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false;

const BookType = ({ navigation }) => {

    const dispatch = useDispatch();
    const customerFormRef = useRef();


    const getBookTypeSuccess = useSelector((state) => state.bookTypeReducer.getBookTypeSuccess);
    const getBookTypeList = useSelector((state) => state.bookTypeReducer.getBookTypeList);
    const getBookTypeFailure = useSelector((state) => state.bookTypeReducer.getBookTypeFailure);

    const createBookTypeSuccess = useSelector((state) => state.bookTypeReducer.createBookTypeSuccess);
    const createBookTypeData = useSelector((state) => state.bookTypeReducer.createBookTypeData);
    const createBookTypeFailure = useSelector((state) => state.bookTypeReducer.createBookTypeFailure);

    const updateBookTypeSuccess = useSelector((state) => state.bookTypeReducer.updateBookTypeSuccess);
    const updateBookTypeData = useSelector((state) => state.bookTypeReducer.updateBookTypeData);
    const updateBookTypeFailure = useSelector((state) => state.bookTypeReducer.updateBookTypeFailure);

    const bookTypeErrorMessage = useSelector((state) => state.bookTypeReducer.errorMessage);

    const [pageItems, setpageItems] = useState({

    }
    );

    useEffect(() => {
        let data = pagePermission(pageItems, "Super Admin", "Book Type")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "s.No",
            "text": "s.No",
            "sort": true,
            formatter: (cellContent, row, index) => (
                index + 1
            )
        },
        {
            "dataField": "booksType",
            "text": "Book Type",
            "sort": true
        },
        /*  {
             "dataField": "name",
             "text": "name",
             "sort": true
         }, */
        /*  {
             "dataField": "edition",
             "text": "Edition",
             "sort": true,
         },
         {
             "dataField": "authorName",
             "text": "Author Name",
             "sort": true,
         }, */
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },
    ]

    if (pageItems.delete || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index);
                        }}
                    ></FiTrash2>}
                </div>
            )
        })
    }
    const [state, setState] = useState({
        batchName: "",
        batchStart: "",
        batchEnd: ""
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getBookType(requestData))
    }, [navigation]);

    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])

    useEffect(() => {
        if (getBookTypeSuccess) {
            setItemList(getBookTypeList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BOOK_TYPE" })
        } else if (getBookTypeFailure) {
            setItemList([])
            showMessage("warning", bookTypeErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_BOOK_TYPE" })
        }
    }, [getBookTypeSuccess, getBookTypeFailure]);

    useEffect(() => {
        if (createBookTypeSuccess) {
            const temp_state = [...itemList, createBookTypeData[0]];
            setItemList(temp_state)
            closeModule()
            setLoaderModule(false)
            Alert({  description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_BOOK_TYPE" })
        } else if (createBookTypeFailure) {
            setItemList([])
            setLoaderModule(false)
            showMessage("warning", bookTypeErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_BOOK_TYPE" })
        }
    }, [createBookTypeSuccess, createBookTypeFailure]);

    useEffect(() => {
        if (updateBookTypeSuccess) {
            updateTable(updateBookTypeData[0])
            // setLoaderModule(false)
            dispatch({ type: "RESET_UPDATE_BOOK_TYPE" })
        } else if (updateBookTypeFailure) {
            setLoaderModule(false)
            showMessage("warning", bookTypeErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_BOOK_TYPE" })
        }
    }, [updateBookTypeSuccess, updateBookTypeFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.bookTypeId != updatedItem.bookTypeId;
            });
            Alert({  description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({  description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            booksType: "",
            // bookTypeId: "" ,
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            booksType: data?.booksType || '',
            /*  authorName: data?.authorName || '',
             edition: data?.edition || '',
             title: data?.title || '',
             material: data?.material || '',
             bookTypeId:  data?.bookTypeId || '' ,
             shortDescription:  data?.shortDescription || '' , */
            /*  batchName: data?.batchName || "",
             batchStart: data?.batchStart || "",
             batchEnd: data?.batchEnd || "" */
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            booksType: state?.booksType || '',

        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateBookType(requestDate, selectedItem.bookTypeId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: false
            }
            setLoaderModule(true)
            dispatch(updateBookType(deleteRequest, selectedItem.bookTypeId))
        } else {
            setLoaderModule(true)
            dispatch(createBookType(requestDate))
        }
    }

    const handleCloseModal = () => {
        setCreateModule(false);
    };

    const {
        batchName,
        batchStart,
        batchEnd
    } = state;

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const { sidebarOpen } = useSidebar();

    return (
        <div>
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
                <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={handleCloseModal} onSubmit={validateForm} isLoading={loaderModule} title={`${modelTitle} BookType`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={bookTypeForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>


                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} isLoading={loaderModule} title="Delete BookType">
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>
                </View>

                <SimpleCard >
                    <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Book Type"} columns={column} data={itemList} initialPage={1} />
                </SimpleCard>

            </div>
        </div>
    )
};

export default BookType;
