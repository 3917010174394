import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { useSidebar } from "app/components/sidebar/sidebarContext";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { periodTimeForm } from "./formData";
import Alert from "app/components/atoms/alert";
import { createPeriodDays, getPeriodDays, updatePeriodDays } from "app/api/PeriodDays";
import { createPeriodTime, getPeriodTime, updatePeriodTime } from "app/api/PeriodTimeApi";
import { formatTime } from "app/utils/app-functions";
import { timeConversion } from "app/utils/app-functions";
import dayjs from 'dayjs';
import { lightTheme } from "styles/theme";
let editData = false;

const Period = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getPeriodTimeSuccess = useSelector((state) => state.periodTimeReducer.getPeriodTimeSuccess);
    const getPeriodTimeList = useSelector((state) => state.periodTimeReducer.getPeriodTimeList);
    const getPeriodTimeFailure = useSelector((state) => state.periodTimeReducer.getPeriodTimeFailure);

    const getPeriodDaysSuccess = useSelector((state) => state.periodDaysReducer.getPeriodDaysSuccess);
    const getPeriodDaysList = useSelector((state) => state.periodDaysReducer.getPeriodDaysList);
    const getPeriodDaysFailure = useSelector((state) => state.periodDaysReducer.getPeriodDaysFailure);
    const periodDaysErrorMessage = useSelector((state) => state.periodDaysReducer.errorMessage);

    const updatePeriodDaysSuccess = useSelector((state) => state.periodDaysReducer.updatePeriodDaysSuccess);
    const updatePeriodDaysData = useSelector((state) => state.periodDaysReducer.updatePeriodDaysData);
    const updatePeriodDaysFailure = useSelector((state) => state.periodDaysReducer.updatePeriodDaysFailure);

    const createPeriodDaysSuccess = useSelector((state) => state.periodDaysReducer.createPeriodDaysSuccess);
    const createPeriodDaysData = useSelector((state) => state.periodDaysReducer.createPeriodDaysData);
    const createPeriodDaysFailure = useSelector((state) => state.periodDaysReducer.createPeriodDaysFailure);

    const createPeriodTimeSuccess = useSelector((state) => state.periodTimeReducer.createPeriodTimeSuccess);
    const createPeriodTimeData = useSelector((state) => state.periodTimeReducer.createPeriodTimeData);
    const createPeriodTimeFailure = useSelector((state) => state.periodTimeReducer.createPeriodTimeFailure);

    const updatePeriodTimeSuccess = useSelector((state) => state.periodTimeReducer.updatePeriodTimeSuccess);
    const updatePeriodTimeData = useSelector((state) => state.periodTimeReducer.updatePeriodTimeData);
    const updatePeriodTimeFailure = useSelector((state) => state.periodTimeReducer.updatePeriodTimeFailure);

    const periodErrorMessage = useSelector((state) => state.periodTimeReducer.errorMessage);
    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Masters", "Period Time")
        setpageItems(data)

    }, [navigation]);
    const column = [
        {
            "dataField": "periodTitle",
            "text": "Period Title",
            "sort": true
        },
        {
            "dataField": "fromTime",
            "text": "From Time",
            "sort": true,
            formatter: (cellContent, row, index) => (

                row.fromTime + "--" + row.toTime
            )
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        }
    ]
    if (pageItems.delete || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            swal
                                .fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No",
                                })
                                .then((result) => {
                                    if (result.value) {

                                        onDeleteItem(row, index);
                                    } else {
                                        swal.fire({
                                            title: "Cancelled!",
                                            text: "Permission denied.",
                                            type: "error",
                                            icon: "error",
                                            timer: 1500,
                                        });
                                    }
                                });
                        }}
                    ></FiTrash2>}
                </div>
            )
        })
    }
    const [state, setState] = useState({
        periodTitle: "",
        fromTime: "",
        toTime: "",
        isSpecial: false,
        isBreak: false,
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [previousState, setPreviousState] = useState(false);
    const [periodDaysState, setPeriodDaysState] = useState(false);
    const [periodDaysModule, setPeriodDaysModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getPeriodTime(requestData))
        dispatch(getPeriodDays())
    }, [navigation]);

    useEffect(() => {
        if (getPeriodTimeSuccess) {
            setItemList(getPeriodTimeList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_PERIOD_TIME" })
        } else if (getPeriodTimeFailure) {
            setItemList([])
            setLoaderModule(false)
            showMessage("warning", periodErrorMessage?.message)
            dispatch({ type: "RESET_GET_PERIOD_TIME" })
        }
    }, [getPeriodTimeSuccess, getPeriodTimeFailure]);

    useEffect(() => {
        if (getPeriodDaysSuccess) {
            setPeriodDaysState(getPeriodDaysList)
            getPeriodDaysList.length > 0 ? setPreviousState(true) : setPreviousState(false)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_PERIOD_DAYS" })
        } else if (getPeriodDaysFailure) {
            setPeriodDaysState([])
            showMessage("warning", periodDaysErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_PERIOD_DAYS" })
        }
    }, [getPeriodDaysSuccess, getPeriodDaysFailure]);

    useEffect(() => {
        if (createPeriodDaysSuccess) {
            setPeriodDaysState(createPeriodDaysData)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Working Days Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_PERIOD_DAYS" })
        } else if (createPeriodDaysFailure) {
            setLoaderModule(false)
            showMessage("warning", periodDaysErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_PERIOD_DAYS" })
        }
    }, [createPeriodDaysSuccess, createPeriodDaysFailure]);

    useEffect(() => {
        if (updatePeriodDaysSuccess) {
            setPeriodDaysState(updatePeriodDaysData)
            setLoaderModule(false)
            Alert({ description: 'Updated Working Days Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            dispatch({ type: "RESET_UPDATE_PERIOD_DAYS" })
        } else if (updatePeriodDaysFailure) {
            setLoaderModule(false)
            showMessage("warning", periodDaysErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_PERIOD_DAYS" })
        }
    }, [updatePeriodDaysSuccess, updatePeriodDaysFailure]);

    useEffect(() => {
        if (createPeriodTimeSuccess) {
            const temp_state = [...itemList, createPeriodTimeData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_PERIOD_TIME" })
        } else if (createPeriodTimeFailure) {
            setLoaderModule(false)
            showMessage("warning", periodErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_PERIOD_TIME" })
        }
    }, [createPeriodTimeSuccess, createPeriodTimeFailure]);

    useEffect(() => {
        if (updatePeriodTimeSuccess) {
            updateTable(updatePeriodTimeData[0])
            dispatch({ type: "RESET_UPDATE_PERIOD_TIME" })
        } else if (updatePeriodTimeFailure) {
            setLoaderModule(false)
            showMessage("warning", periodErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_PERIOD_TIME" })
        }
    }, [updatePeriodTimeSuccess, updatePeriodTimeFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.periodTimeId != updatedItem.periodTimeId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            periodTitle: "",
            fromTime: "",
            toTime: "",
            isSpecial: false,
            isBreak: false,
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            periodTitle: data?.periodTitle || "",
            fromTime: data?.fromTime,
            toTime: data?.toTime,
            isSpecial: data?.isSpecial || false,
            isBreak: data?.isBreak || false,
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const handleCheckbox = async (event, index) => {
        if(pageItems.create && pageItems.create){
            event.persist();
            let temp_state = [...periodDaysState]
            temp_state[index].status = event.target.checked
            setPeriodDaysState(temp_state)
        }
       
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onCreateDays = () => {
        const selectedId = periodDaysState[0]?.periodDaysId || null
        let filterItem = periodDaysState.filter((itm) => { return itm.status == true })
        let ids = []
        filterItem.map((v) => { ids.push(v.dayId) })
        const requestData = {
            dayIds: ids.toString()
        }
        if (selectedId != null) {
            dispatch(updatePeriodDays(requestData, selectedId))
        } else {
            dispatch(createPeriodDays(requestData))
        }
    }

    const onSubmitForm = () => {

        let adjustedToTime = toTime;

        // const [hour, minute] = toTime.split(':').map(Number);



        const requestDate = {
            periodTitle: periodTitle,
            fromTime: fromTime,
            toTime: toTime,
            isSpecial: isSpecial,
            isBreak: isBreak,
        }

        const fromTimeDate = new Date(`1970-01-01T${fromTime}:00`);
        const toTimeDate = new Date(`1970-01-01T${toTime}:00`);

        if (fromTime === toTime) {
            Alert({ description: 'FromTime and toTime must not be the same', type: 'warning', placement: 'topRight', duration: 3, });
            return false;
        }
        else if (toTimeDate < fromTimeDate) {
            Alert({ description: 'ToTime cannot be earlier than fromTime..', type: 'warning', placement: 'topRight', duration: 3 });
            return false;
        }

        // return
        function validateFormData(requestDate) {
            for (let key in requestDate) {
                if (requestDate.hasOwnProperty(key)) {
                    if (key !== "isSpecial" && key !== "isBreak" && requestDate[key] === "") {
                        return false;
                    }
                }
            }
            return true;
        }
        if (!validateFormData(requestDate)) {
            Alert({ description: 'ENTER ALL THE DATA', type: 'warning', placement: 'topRight', duration: 3 });

        } else {
            if (editData) {
                setLoaderModule(true)
                dispatch(updatePeriodTime(requestDate, selectedItem.periodTimeId))

            } else if (deleteModule) {
                const deleteRequest = {
                    isActive: "false"
                }
                setLoaderModule(true)
                dispatch(updatePeriodTime(deleteRequest, selectedItem.periodTimeId))
            } else {
                setLoaderModule(true)
                dispatch(createPeriodTime(requestDate))
            }
        }


    }

    const {
        periodTitle,
        fromTime,
        toTime,
        isSpecial,
        isBreak
    } = state;

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };
    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const { sidebarOpen } = useSidebar();

    return (
        <div>
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
                {/* <SmallLoader value={loaderModule} /> */}
                <View isOpen={createModule} size="md" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} isLoading={loaderModule} title={`${modelTitle} Period`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={periodTimeForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>

                {/*   <ModalView show={createModule} size="md" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={onSubmitForm} title={`${modelTitle} Period Time`}>
                <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={periodTimeForm} noOfColumn={1} onSubmit={onSubmitForm} />
            </ModalView> */}

                <View centered={true} size={'sm'} show={deleteModule} onClose={setDeleteModule} savetitle="Yes" onSubmit={onSubmitForm} isLoading={loaderModule} title="Delete Period Time">
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>
                </View>


                <div className="row" style={{ display: "flex", marginLeft: "30px", gap: "20px", flexWrap: "wrap" }}>
                    {/* First Card */}
                    <div style={{
                        flex: "1",
                        minWidth: sidebarOpen ? "600px" : "900px",
                        transition: "min-width 0.5s ease"
                    }} >
                        <SimpleCard>
                            <CustomTable
                                buttonData={pageItems.create ? ['add'] : []}
                                handleClick={handleClick}
                                tableName={"Period Time"}
                                columns={column}
                                data={itemList}
                                state={state}
                            />
                        </SimpleCard>
                    </div>

                {/* Second Card */}
                <div style={{ flex: "1", minWidth: "300px" }}>
                    <SimpleCard description="Period Days" >
                        <div style={{ marginTop: "50px", display: "flex", flexDirection: "column", gap: "10px" }}>
                            <div className="checkbox-container mt-12 ml-12">
                                {(periodDaysState || []).map((ele, idx) => (
                                    <div key={idx} className="d-flex align-items-center mt-2">
                                        <label className="d-flex align-items-center">
                                            <input
                                                type="checkbox"
                                                name={ele?.dayName}
                                                checked={ele?.status}
                                                onChange={(event) => handleCheckbox(event, idx)}
                                                className="me-2"
                                            />
                                            <span>{ele.dayName || ""}</span>
                                        </label>
                                    </div>
                                ))}
                                <Button
                                    className="mr-2 mt-4"
                                    style={{
                                        backgroundColor:  lightTheme.primaryColor,
                                        border: "none",
                                        fontSize: "12px",
                                    }}
                                    onClick={() => onCreateDays()}
                                >
                                    Submit
                                </Button>
                            </div>
                            </div>
                        </SimpleCard>
                    </div>
                </div>


            </div>
        </div >
    )
};

export default Period;
