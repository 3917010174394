import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { qualificationForm } from "./formData";
import { createQualification, getQualification, updateQualification } from "app/api/QualificationApi";
import { FiEdit, FiSend, FiTrash2 } from 'react-icons/fi';
import Alert from "app/components/atoms/alert";
import { useSidebar } from "app/components/sidebar/sidebarContext";

let editData = false;

const Qualification = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getQualificationSuccess = useSelector((state) => state.qualificationReducer.getQualificationSuccess);
    const getQualificationList = useSelector((state) => state.qualificationReducer.getQualificationList);
    const getQualificationFailure = useSelector((state) => state.qualificationReducer.getQualificationFailure);

    const createQualificationSuccess = useSelector((state) => state.qualificationReducer.createQualificationSuccess);
    const createQualificationData = useSelector((state) => state.qualificationReducer.createQualificationData);
    const createQualificationFailure = useSelector((state) => state.qualificationReducer.createQualificationFailure);

    const updateQualificationSuccess = useSelector((state) => state.qualificationReducer.updateQualificationSuccess);
    const updateQualificationData = useSelector((state) => state.qualificationReducer.updateQualificationData);
    const updateQualificationFailure = useSelector((state) => state.qualificationReducer.updateQualificationFailure);

    const qualificationErrorMessage = useSelector((state) => state.qualificationReducer.errorMessage);

    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Masters", "Qualification")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "index",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => {
                return index + 1
            }
        },
        {
            "dataField": "qualificationName",
            "text": "Qualification Name",
            "sort": true
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },

    ]

    if (pageItems.delete || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index);

                        }}
                    ></FiTrash2>}
                </div>
            )
        })
    }


    const [state, setState] = useState({
        qualificationName: ""
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getQualification(requestData))
    }, [navigation]);

    useEffect(() => {
        if (getQualificationSuccess) {
            setItemList(getQualificationList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_QUALIFICATION" })
        } else if (getQualificationFailure) {
            setItemList([])
            showMessage("warning", qualificationErrorMessage?.message)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_QUALIFICATION" })
        }
    }, [getQualificationSuccess, getQualificationFailure]);

    useEffect(() => {
        if (createQualificationSuccess) {
            const temp_state = [...itemList, createQualificationData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_QUALIFICATION" })
        } else if (createQualificationFailure) {
            setLoaderModule(false)
            showMessage("warning", qualificationErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_QUALIFICATION" })
        }
    }, [createQualificationSuccess, createQualificationFailure]);


    useEffect(() => {
        if (updateQualificationSuccess) {
            updateTable(updateQualificationData[0])
            dispatch({ type: "RESET_UPDATE_QUALIFICATION" })
        } else if (updateQualificationFailure) {
            setLoaderModule(false)
            showMessage("warning", qualificationErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_QUALIFICATION" })
        }
    }, [updateQualificationSuccess, updateQualificationFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.qualificationId != updatedItem.qualificationId;
            });
            Alert({ description: 'Deleted Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }


    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const onCloseForm = () => {
        setCreateModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            qualificationName: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            qualificationName: data?.qualificationName || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            qualificationName: qualificationName
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateQualification(requestDate, selectedItem.qualificationId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            dispatch(updateQualification(deleteRequest, selectedItem.qualificationId))
        } else {
            setLoaderModule(true)
            dispatch(createQualification(requestDate))
        }
    }

    const {
        qualificationName
    } = state;

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const { sidebarOpen } = useSidebar();

    return (
        <div >
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>

                {/* <SmallLoader value={loaderModule} /> */}
                <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={onCloseForm} isLoading={loaderModule} onSubmit={validateForm} title={`${modelTitle} Qualification`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={qualificationForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>


                <View centered={true} size={'sm'} isOpen={deleteModule} onClose={setDeleteModule} isLoading={loaderModule} savetitle="Yes" onSubmit={onSubmitForm} title="Delete Batch">
                    <h6 className="text-capitalize mb-1">Are you sure ?</h6>
                </View>

                <SimpleCard >
                    <CustomTable buttonData={['add']} handleClick={handleClick} tableName={"Qualification"} columns={column} data={itemList} initialPage={1} />
                </SimpleCard>
            </div>
        </div>
    )
};

export default Qualification;
