import * as yup from "yup";
import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DateTime from "react-datetime";
import { Form, Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import SimpleCard from 'app/components/atoms/simple-card/SimpleCard'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MdEdit, MdDelete } from "react-icons/md";
import swal from "sweetalert2";
import _ from "lodash";
import { pagePermission, showMessage } from "app/utils/app-functions";
import FormLayout from "app/components/render/formlayout";
import View from "app/components/atoms/modalView/Modalview";
import CustomTable from "app/components/atoms/custom-table/CustomTable";
import formRender from "app/components/render/formcomponent";
import { homeWorkTypeForm } from "./formData";
import { createExamType, getExamType, updateExamType } from "app/api/ExamTypeApi";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { getExamVariant } from "app/api/ExamVariant";
import Alert from "app/components/atoms/alert";
import { createHomeWorkType, getHomeWorkType, updateHomeWorkType } from "app/api/HomeWorkTypeApi";
import { useSidebar } from "app/components/sidebar/sidebarContext";


let editData = false;

const HomeWorkType = ({ navigation }) => {
    const dispatch = useDispatch();
    const customerFormRef = useRef();

    const getHomeWorkTypeSuccess = useSelector((state) => state.homeWorkTypeReducer.getHomeWorkTypeSuccess);
    const getHomeWorkTypeList = useSelector((state) => state.homeWorkTypeReducer.getHomeWorkTypeList);
    const getHomeWorkTypeFailure = useSelector((state) => state.homeWorkTypeReducer.getHomeWorkTypeFailure);

    const createHomeWorkTypeSuccess = useSelector((state) => state.homeWorkTypeReducer.createHomeWorkTypeSuccess);
    const createHomeWorkTypeData = useSelector((state) => state.homeWorkTypeReducer.createHomeWorkTypeData);
    const createHomeWorkTypeFailure = useSelector((state) => state.homeWorkTypeReducer.createHomeWorkTypeFailure);

    const updateHomeWorkTypeSuccess = useSelector((state) => state.homeWorkTypeReducer.updateHomeWorkTypeSuccess);
    const updateHomeWorkTypeData = useSelector((state) => state.homeWorkTypeReducer.updateHomeWorkTypeData);
    const updateHomeWorkTypeFailure = useSelector((state) => state.homeWorkTypeReducer.updateHomeWorkTypeFailure);

    const homeWorkTypeErrorMessage = useSelector((state) => state.homeWorkTypeReducer.errorMessage);


    const [pageItems, setpageItems] = useState({});

    useEffect(() => {
        let data = pagePermission(pageItems, "Masters", "Home Work Type")
        setpageItems(data)

    }, [navigation]);

    const column = [
        {
            "dataField": "sNo",
            "text": "S.No",
            "sort": true,
            formatter: (cellContent, row, index) => (
                index + 1
            )
        },
        {
            "dataField": "typeName",
            "text": "Type Name",
            "sort": true
        },
        {
            "dataField": "typeKey",
            "text": "Type Key",
            "sort": true,
        },
        {
            dataField: "isActive",
            text: "Status",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => {
                const conInfo = { inputType: "status", status: cellContent }
                return formRender(null, null, conInfo, null);
            }
        },

    ]
    if (pageItems.delete || pageItems.update) {
        column.push({
            text: "Action",
            sort: false,
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, row, index) => (
                <div className="icon-container">
                    {pageItems.update && <FiEdit
                        className="text-primary cursor-pointer"
                        size={18}
                        onClick={() => onEditForm(row, index)}
                    ></FiEdit>}
                    {pageItems.delete && <FiTrash2
                        className="text-danger cursor-pointer"
                        size={18}
                        onClick={() => {
                            onDeleteItem(row, index);

                        }}
                    ></FiTrash2>}
                </div>
            )
        })
    }

    const [state, setState] = useState({
        departmentName: "",
        description: ""
    });

    const [createModule, setCreateModule] = useState(false);
    const [loaderModule, setLoaderModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [viewModule, setViewModule] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
        setLoaderModule(true)
        const requestData = {
            isActive: "true"
        }
        dispatch(getHomeWorkType(requestData))
    }, [navigation]);

    const confromDialog = () => {
        return (
            swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No",
                })
                .then((result) => {
                    if (result.value) {
                        onSubmitForm()
                    } else {
                        setDeleteModule(false)
                        swal.fire({
                            title: "Cancelled!",
                            text: "Permission denied.",
                            type: "error",
                            icon: "error",
                            timer: 1500,
                        });
                    }
                })
        )
    }

    useEffect(() => {
        if (deleteModule) {
            confromDialog()
        }
    }, [deleteModule])


    useEffect(() => {
        if (getHomeWorkTypeSuccess) {
            setItemList(getHomeWorkTypeList)
            setLoaderModule(false)
            dispatch({ type: "RESET_GET_HOME_WORK_TYPE" })
        } else if (getHomeWorkTypeFailure) {
            setItemList([])
            setLoaderModule(false)
            showMessage("warning", homeWorkTypeErrorMessage?.message)
            dispatch({ type: "RESET_GET_HOME_WORK_TYPE" })
        }
    }, [getHomeWorkTypeSuccess, getHomeWorkTypeFailure]);

    useEffect(() => {
        if (createHomeWorkTypeSuccess) {
            const temp_state = [...itemList, createHomeWorkTypeData[0]];
            setItemList(temp_state)
            setLoaderModule(false)
            closeModule()
            Alert({ description: 'Created Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });
            dispatch({ type: "RESET_CREATE_HOME_WORK_TYPE" })
        } else if (createHomeWorkTypeFailure) {
            setLoaderModule(false)
            showMessage("warning", homeWorkTypeErrorMessage?.message)
            dispatch({ type: "RESET_CREATE_HOME_WORK_TYPE" })
        }
    }, [createHomeWorkTypeSuccess, createHomeWorkTypeFailure]);

    useEffect(() => {
        if (updateHomeWorkTypeSuccess) {
            updateTable(updateHomeWorkTypeData[0])
            Alert({ description: 'Updated Successfully.', type: 'success', placement: 'topRight', duration: 3, onClose: () => console.log('Notification closed'), });

            dispatch({ type: "RESET_UPDATE_HOME_WORK_TYPE" })
        } else if (updateHomeWorkTypeFailure) {
            setLoaderModule(false)
            showMessage("warning", homeWorkTypeErrorMessage?.message)
            dispatch({ type: "RESET_UPDATE_HOME_WORK_TYPE" })
        }
    }, [updateHomeWorkTypeSuccess, updateHomeWorkTypeFailure]);

    const updateTable = (updatedItem) => {
        const temp_state = [...itemList];
        if (deleteModule) {
            let remainingList = _.remove(temp_state, function (n) {
                return n.typeId != updatedItem.typeId;
            });
            showMessage("success", "Deleted Successfully")
            setItemList(remainingList)
        } else {
            temp_state[selectedIndex] = updatedItem;
            showMessage("success", "Updated Successfully")
            setItemList(temp_state)
        }
        setLoaderModule(false)
        closeModule()
    }

    const closeModule = () => {
        editData = false;
        setCreateModule(false)
        setDeleteModule(false)
    }

    const resetState = () => {
        setState({
            ...state,
            typeName: "",
            typeKey: ""
        })
    }

    const onCreateForm = () => {
        resetState()
        editData = false;
        setCreateModule(true)
    }

    const onEditForm = (data, index) => {
        setState({
            ...state,
            typeName: data?.typeName || "",
            typeKey: data?.typeKey || ""
        })
        editData = true;
        setSelectedIndex(index)
        setSelectedItem(data)
        setCreateModule(true)
    }

    const onDeleteItem = (data, index) => {
        editData = false;
        setSelectedIndex(index)
        setSelectedItem(data)
        setDeleteModule(true)
    }

    const validateForm = async () => {
        const validatefom = customerFormRef.current.validateForm();
    }

    const onSubmitForm = () => {
        const requestDate = {
            typeName: typeName,
            typeKey: typeKey
        }
        if (editData) {
            setLoaderModule(true)
            dispatch(updateHomeWorkType(requestDate, selectedItem.typeId))
        } else if (deleteModule) {
            const deleteRequest = {
                isActive: "false"
            }
            setLoaderModule(true)
            dispatch(updateHomeWorkType(deleteRequest, selectedItem.typeId))
        } else {
            setLoaderModule(true)
            dispatch(createHomeWorkType(requestDate))
        }
    }

    const handleClick = (buttonName) => {
        if (buttonName == 'Add') {
            onCreateForm();
        }
    };

    const {
        typeName,
        typeKey
    } = state;

    const modelTitle = editData ? "Edit " : "Create ";
    const modelButtonLbl = editData ? "" : "Save";
    const { sidebarOpen } = useSidebar();

    return (
        <div style={sidebarOpen ? { marginLeft: "10px", marginTop: "-10px" } : { marginLeft: "10px", marginTop: "-50px" }}>
            <div style={{ marginTop: "-10px" }}>
                <View isOpen={createModule} size="sm" savetitle={modelButtonLbl} onClose={setCreateModule} onSubmit={validateForm} isLoading={loaderModule} title={`${modelTitle} Homework Type`}>
                    <FormLayout ref={customerFormRef} defaultState={state} setDefaultState={setState} dynamicForm={homeWorkTypeForm} noOfColumn={1} onSubmit={onSubmitForm} />
                </View>

            <SimpleCard >
                <CustomTable buttonData={pageItems.create ? ['add'] : []} handleClick={handleClick} tableName={"Homework Type"} columns={column} data={itemList} initialPage={1} />
            </SimpleCard>

            </div>
        </div>
    )
};

export default HomeWorkType;
