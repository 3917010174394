import { returnApiCallPromise } from './ApiContent';
import { homework,homeworkStudentList } from './APIContainer';

//GET

export function asyncgetStudentHomeworkList(request) {
  const requestObj = request ? { params: request } : "";
  return  returnApiCallPromise("GET", homeworkStudentList, requestObj)
      .then(response => {
        return {
          error: false,
          message:"Succsss",
          data: response?.data?.data || []
        }
      }).catch(error => {
        let errorMessage = error;
        if (error.response) {
          if (error.response.data.error) {
            errorMessage = error.response.data.message;
          }
        }
        return {
          error: true,
          data:[],
          message:errorMessage
        }
      })
}
